export const getSenderByPlatform = (selectedPlatforms: any) => {
  const userData: any = []
  for (const platform of Object.keys(selectedPlatforms)) {
    const user: any = localStorage.getItem('user')
    const userObj = user ? JSON.parse(user) : []
    const selectedPlatform = userObj[platform]
    const telegramOutreach = userObj?.['outreach']

    if (
      (selectedPlatform && selectedPlatform.length > 0) ||
      (platform === 'telegram' && (telegramOutreach.length > 0 || (selectedPlatform && selectedPlatform.length > 0)))
    ) {
      if (platform === 'linkedin') {
        const matchedUsers = selectedPlatform.filter((user: any) => selectedPlatforms[platform].includes(user.email))
        if (matchedUsers.length > 0) {
          userData.push(...matchedUsers)
        }
      } else if (platform === 'telegram') {
        const matchedUsers = (
          selectedPlatform && Object.keys(selectedPlatform)?.length > 0
            ? [selectedPlatform, ...telegramOutreach]
            : telegramOutreach
        ).filter(
          (user: any) =>
            selectedPlatforms[platform].includes(user.userName) || selectedPlatforms[platform].includes(user.username),
        )
        if (matchedUsers.length > 0) {
          userData.push(...matchedUsers)
        }
      } else {
        const matchedUsers = selectedPlatform.filter((user: any) => selectedPlatforms[platform].includes(user.username))
        if (matchedUsers.length > 0) {
          userData.push(...matchedUsers)
        }
      }
    }
  }
  return userData
}

export const getTelegramTableData = (user: any, allCampaignsAccount?: any) => {
  const OutreachAccounts =
    user?.outreach?.map((acc: any, index: number) => {
      const phoneNumber = `${acc?.area_code}${acc?.number}`
      const allCampaigns = allCampaignsAccount?.filter((obj: any) => obj?.telegram_number === phoneNumber)
      const campaign_names = allCampaigns?.[0]?.campaign_names || []
      const profile_picture = allCampaigns?.[0]?.image || ''
      const profile_picture_created_at = allCampaigns?.[0]?.image_created_at || ''
      return {
        key: index,
        id: acc.id,
        phoneNumber: phoneNumber,
        handle: `@${acc.userName ? acc.userName : 'N/A'}`,
        number: acc.number,
        area_code: acc?.area_code,
        managed: acc?.isManaged,
        status: acc.status === false ? 'Disconnected' : 'Connected',
        type: 'Outreach',
        name: acc?.sender_full_name,
        company: acc?.sender_company,
        campaigns: campaign_names,
        profilePhoto: profile_picture,
        profilePhoto_created_at: profile_picture_created_at,
      }
    }) || []

  const privateUser = user?.telegram && Object?.keys(user.telegram)?.length > 0 ? user.telegram : null
  const phoneNumber = `${privateUser?.area_code}${privateUser?.number}`
  const allCampaigns = allCampaignsAccount?.filter((obj: any) => obj?.telegram_number === phoneNumber)
  const campaign_names = allCampaigns?.[0]?.campaign_names || []
  const profile_picture = allCampaigns?.[0]?.image || ''
  const profile_picture_created_at = allCampaigns?.[0]?.image_created_at || ''
  const telegramAccount = privateUser
    ? [
        {
          key: user?.telegrma?.length + 1,
          id: 1,
          phoneNumber: phoneNumber,
          number: privateUser.number,
          area_code: privateUser?.area_code,
          handle: 'Personal',
          managed: false,
          status: privateUser.status === false ? 'Disconnected' : 'Connected',
          type: 'Connect+',
          campaigns: campaign_names,
          profilePhoto: profile_picture,
          profilePhoto_created_at: profile_picture_created_at,
        },
      ]
    : []

  return [...telegramAccount, ...OutreachAccounts]
}
export const getInstagramAccounts = (user: any) => {
  const instagramData = user?.instagram
  if (instagramData?.length > 0) {
    const usernames = instagramData.map((ins: any) => ins.username)
    return usernames
  } else {
    return []
  }
}

export const getInstagramTableData = (user: any) => {
  if (user?.instagram?.length > 0) {
    return user.instagram.map((u: any) => ({
      id: u.id,
      handle: `@${u.username}`,
      email: u.email,
      profilePhoto: u.profilePhoto || '',
    }))
  } else {
    return []
  }
}

export const getLinkedinAccounts = (user: any) => {
  const linkedinData = user?.linkedin
  if (linkedinData?.length > 0) {
    const usernames = linkedinData.map((lin: any) => lin.email)
    return usernames
  } else {
    return []
  }
}

export const getLinkedinTableData = (user: any) => {
  if (user?.linkedin?.length > 0) {
    return user.linkedin.map((u: any) => ({
      id: u.id,
      handle: `@${u.username}`,
      email: u.email,
      profilePhoto: u.profilePhoto || '',
    }))
  } else {
    return []
  }
}

export const getFacebookAccounts = (user: any) => {
  const facebookData = user?.facebook
  if (facebookData?.length > 0) {
    const usernames = facebookData.map((fa: any) => fa.username)
    return usernames
  } else {
    return []
  }
}

export const getTelegramAccounts = (user: any) => {
  const telegramData = user?.telegram
  const outreachData = user?.outreach || []
  if (outreachData?.length > 0 || telegramData) {
    // const usernames = outreachData.map((tg: any) => `${tg.username || tg.userName} (${tg.area_code}${tg.number})`)
    const usernames = outreachData.map((tg: any) => tg.username || tg.userName)
    const personalTG = telegramData?.username || telegramData?.userName
    if (personalTG) {
      return [...usernames, personalTG]
    }
    return usernames
  } else {
    return []
  }
}

export const getTelegramNumbers = (user: any) => {
  const telegramData = user?.telegram
  const outreachData = user?.outreach || []

  const result: Record<string, string> = {}

  outreachData.forEach((tg: any) => {
    const username = tg.username || tg.userName
    if (username) {
      result[username] = `${tg.area_code}${tg.number}`
    }
  })

  if (telegramData?.userName || telegramData?.username) {
    const personalUsername = telegramData.username || telegramData.userName
    result[personalUsername] = `${telegramData.area_code}${telegramData.number}`
  }

  return result
}

export const getFacebookTableData = (user: any) => {
  if (user?.facebook?.length > 0) {
    return user.facebook.map((u: any) => ({
      id: u.id,
      username: `${u.username}`,
      email: u.email,
      profilePhoto: u.profilePhoto || '',
      displayName: u.displayName || '',
    }))
  } else {
    return []
  }
}
