import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import premium_badge from 'LEGACY/Assets/svg/premium_badge.svg'
import { Col, Divider, Input, message, Popconfirm, Popover, Spin, Typography } from 'antd'
import { isArray } from 'lodash'
import { ConfirmBox } from 'LEGACY/common/constants/modal.constants'
import { useLazyDeleteListQuery, useLazyRenameListQuery } from 'LEGACY/API/CompaniesApi'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_LISTS } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

// import arrow_down from 'LEGACY/Assets/svg/arrow_down.svg'
// import arrow_up from 'LEGACY/Assets/svg/arrow_up.svg'
// import add from 'LEGACY/Assets/svg/add.svg'
import { useAuth } from 'common/hooks/useAuth.hooks'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'

const items = [
  {
    name: 'Exposed Contacts',
    value: 'exposed_contacts',
    href: '/exposedContacts',
    isPremium: false,
  },
  // {
  //   name: 'Import telegram contacts',
  //   value: 'import_telegram_contacts',
  //   href: '/importTelegramContacts',
  //   isPremium: false,
  //   children: [
  //     {
  //       name: 'Raw leads',
  //       value: 'raw_leads',
  //       href: '/importTelegramContacts/rawLeads',
  //     },
  //     {
  //       name: 'Matched leads',
  //       value: 'matched_leads',
  //       href: '/importTelegramContacts/matchedLeads',
  //     },
  //   ],
  // },
  // {
  //   name: 'All Contacts',
  //   value: 'all_contacts',
  //   isPremium: false,
  // },
  // {
  //   name: 'AI Contacts',
  //   value: 'AI Contacts',
  //   isPremium: true,
  // },
  // {
  //   name: 'Group Contacts',
  //   value: 'Group Contacts',
  //   isPremium: true,
  // },
  // {
  //   name: 'Imported Contacts',
  //   value: 'Imported Contacts',
  //   isPremium: true,
  // },
]

interface Props {
  listContact: any
  currentDefaultList: any
  currentList: any
  onChangeCurrentDetailList: (value: any) => void
  onChangeCustomList: (value: any) => void
  onCreateList: () => void
  refetchLists: any
  onChangeNewList: (list: any) => void
  setIndexDefault: (list: any) => void
}

export const SideBar = ({
  listContact,
  currentDefaultList,
  currentList,
  refetchLists,
  onChangeCurrentDetailList,
  onChangeCustomList,
  onCreateList,
  onChangeNewList,
  setIndexDefault,
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userDetails } = useAuth()
  const [listIdToRename, setListIdToRename] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [openPopover, setOpenPopover] = React.useState(false)
  const [isImportedTelegram, setIsImportedTelegram] = useState(false)
  const { lists_loading } = useAppSelector((state) => state.companies)
  const [refetchDeleteList] = useLazyDeleteListQuery()
  const [refetchRenameList] = useLazyRenameListQuery()
  // const [collapsedItems, setCollapsedItems] = React.useState<any>({})

  // const toggleCollapse = (itemValue: any) => {
  //   setCollapsedItems((prevState: any) => ({
  //     ...prevState,
  //     [itemValue]: !prevState[itemValue], // Toggle collapse state for this item
  //   }))
  // }

  const handleChangeItem = (item: any) => {
    if (item.isPremium) return
    onChangeCustomList(null)
    onChangeCurrentDetailList(item.value)
    if (item.href) {
      navigate(item.href)
    }
  }

  const handleChangeCustomList = (list: any) => {
    onChangeCurrentDetailList('')
    onChangeCustomList(list)
  }

  const handleDeleteList = async (listId: string) => {
    try {
      setLoading(true)
      const result = await refetchDeleteList({ id: listId })

      if (result) {
        let listData = await refetchLists()
        if (listData) {
          dispatch(SET_LISTS(listData.data))
          onChangeNewList(listData.data)
          onChangeCustomList(listData.data[0])
        }
        message.success(`List deleted successfully`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const handleRenameList = async (event: any) => {
    if (event.keyCode === 27) {
      setListIdToRename('')
    }

    if (13 === event.keyCode) {
      try {
        setLoading(true)
        let isListExist = listContact.filter((element: any) => element.name === event.target.value)
        if (isListExist.length === 0) {
          const result = await refetchRenameList({ id: listIdToRename, name: event.target.value })
          if (result) {
            let listData = await refetchLists()
            if (listData) {
              onChangeNewList(listData.data)
              dispatch(SET_LISTS(listData.data))
              onChangeCustomList({
                ...currentList,
                name: event.target.value,
              })
            }
            setListIdToRename('')
            message.success(`List renamed successfully`)
          }
          setLoading(false)
        } else {
          setListIdToRename('')
          setLoading(false)
          message.success(`We already have a list with the same name!`)
        }
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }
  }

  useEffect(() => {
    if (isImportedTelegram) return

    const hasCompletedImport = [...(userDetails?.outreach ?? []), ...(userDetails?.connected_telegrams ?? [])].some(
      (item) => item.import_status === 'completed',
    )

    if (hasCompletedImport) {
      setIsImportedTelegram(true)
    }
  }, [userDetails, isImportedTelegram])

  return (
    <div className={`${styles.sideBar} sidebarExposedContact`}>
      <p className={styles.sideBar__title}>My List</p>

      <p className={styles.sideBar__list__title}>Default list</p>
      {items.map((item) => {
        const isActiveItem = currentDefaultList === item.value
        // const isCollapsed = collapsedItems[item.value] || false
        return (
          <React.Fragment key={item.value}>
            <div
              className={classNames(styles['sideBar__list__item'], {
                [styles['sideBar__list__item--active']]: isActiveItem,
                [styles['sideBar__list__item__premium']]: item.isPremium,
              })}
              onClick={() => {
                // if (item.children) {
                //   toggleCollapse(item.value)
                // }
                setIndexDefault(0)
                handleChangeItem(item)
              }}>
              <Typography>{item.name}</Typography>
              {item.isPremium && <img src={premium_badge} alt='Small' className={styles.premium_badge} />}
              {/* {item.children &&
                item.children.length > 0 &&
                (item.value === 'import_telegram_contacts' && isImportedTelegram ? (
                  <img src={isCollapsed ? arrow_down : arrow_up} alt='Small' className={styles.arrow_down} />
                ) : (
                  <img src={add} alt='Small' />
                ))} */}
            </div>
            {/* {item.children &&
              item.value === 'import_telegram_contacts' &&
              isImportedTelegram &&
              !isCollapsed &&
              item.children.map((child) => {
                const isActiveChild = currentDefaultList === child.value
                return (
                  <div
                    key={child.value}
                    className={classNames(styles['sideBar__list__item'], {
                      [styles['sideBar__list__item--active']]: isActiveChild,
                      [styles['sideBar__list__item__premium']]: item.isPremium,
                    })}
                    onClick={() => {
                      setIndexDefault(0)
                      handleChangeItem(child)
                    }}>
                    <Typography className='ml-4'>{child.name}</Typography>
                    {item.isPremium && <img src={premium_badge} alt='Small' className={styles.premium_badge} />}
                  </div>
                )
              })} */}
          </React.Fragment>
        )
      })}

      <p className={styles.sideBar__list__title}>Custom List</p>

      <div className={styles.sideBar__list__custom}>
        <div>
          {lists_loading ? (
            <>
              <div
                style={{
                  padding: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  marginTop: 10,
                  marginBottom: 10,
                }}>
                <LoadingBox />
              </div>
            </>
          ) : (
            <>
              {isArray(listContact) &&
                [...listContact]
                  ?.sort((a: any, b: any) => {
                    if (a.created_at === null && b.created_at === null) return 0
                    if (a.created_at === null) return 1 // Nulls last
                    if (b.created_at === null) return -1 // Nulls last
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                  })
                  ?.map((list: any) => {
                    const isActiveList = currentList?.id === list.id
                    return listIdToRename === list.id ? (
                      <Input
                        placeholder=''
                        className='renameListInput'
                        onKeyDown={handleRenameList}
                        defaultValue={list.name}
                        disabled={loading}
                        autoFocus
                      />
                    ) : (
                      <div
                        key={list.id}
                        className={classNames(styles['sideBar__list__item'], {
                          [styles['sideBar__list__item--active']]: isActiveList,
                        })}
                        onClick={() => {
                          setIndexDefault(list?.type === '1' ? 1 : 0)
                          handleChangeCustomList(list)
                        }}>
                        <div>
                          <Typography>{list.name} </Typography>
                          <Typography.Text type='secondary'>
                            {list.type === '1' ? 'Companies List' : 'Contacts List'}
                          </Typography.Text>
                        </div>
                        <Popover
                          placement='right'
                          style={{ padding: 0 }}
                          title={''}
                          content={
                            <div className='listOptions_exposedContact'>
                              <Typography
                                onClick={() => {
                                  setOpenPopover(false)
                                  setListIdToRename(list.id)
                                }}>
                                Rename List
                              </Typography>
                              <Divider style={{ margin: '3px 0' }} />

                              <Popconfirm
                                title={ConfirmBox.DELETE_LIST}
                                description={
                                  <Col>
                                    <div>{`${ConfirmBox.DELETE_LIST_ARE_YOU_SURE}`}</div>
                                  </Col>
                                }
                                onConfirm={() => {
                                  setOpenPopover(false)
                                  handleDeleteList(list.id)
                                }}
                                onCancel={() => {
                                  message.error('You have left this action!')
                                }}
                                okText='Yes'
                                cancelText='No'>
                                <Typography> Delete List</Typography>
                              </Popconfirm>
                            </div>
                          }
                          open={openPopover && isActiveList}
                          onOpenChange={setOpenPopover}
                          trigger='click'>
                          <div className={styles['moreOptions']}>
                            {loading && isActiveList ? (
                              <Spin
                                style={{ margin: '0 10px' }}
                                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                              />
                            ) : (
                              <MoreOutlined style={{ transform: 'rotate(90deg)' }} />
                            )}
                          </div>
                        </Popover>
                      </div>
                    )
                  })}
            </>
          )}
        </div>
      </div>

      <p className={styles.sideBar__createList} onClick={onCreateList}>
        + Create List
      </p>
    </div>
  )
}
