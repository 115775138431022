import { ComponentType } from 'react'
import Login from 'common/components/Login/Login'
import SignUpPage from 'common/components/Signup/sign-up'
import AffiliateSignUp from 'common/components/Signup/AffiliateSignup/AffiliateSignup'
import ResetPasswordPage from 'common/components/Signup/reset-password'
import SignupRegistrationCode from 'common/components/Signup/signupRegistrationCode'
import Settings from 'common/components/Settings/SettingsPage'
import Home from 'common/components/Home/Home'
import Tasks from 'common/components/Tasks/Tasks'
import Campaigns from 'common/components/Campaigns/HomePage/Campaigns'
import Builder from 'common/components/Campaigns/Builder/Builder'
import CreateNewCampaign from 'common/components/Campaigns/CreateCampaign/CreateNewCampaign'
import CampaignSummaryView from 'common/components/Campaigns/CreateCampaign/CampaignSummaryView'
import Chat from 'common/components/Chat/Chat'
import Onboarding from 'common/components/Settings/Pages/Onboarding/Onboarding'
import Upgrade from 'common/components/Settings/Pages/Upgrade/Upgrade'
import { GettingStarted } from 'common/components/GettingStarted/GettingStarted'
/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////// LEGACY //////////////////////////////////////
import IntentSignalsPage from 'LEGACY/Research/intent-signals/intent-signals' ///////
import ContactsPage from 'LEGACY/Research/contacts/contacts' ////////////////////////
import ExposedContacts from 'LEGACY/MyContacts/Outreach/ExposedContacts'
import { ImportCustomListMyContact } from 'LEGACY/MyContacts/Outreach/components/ImportCustomList'
import ImportTelegramContacts from 'LEGACY/MyContacts/Outreach/ImportTelegramContacts'
import RawLeads from 'LEGACY/MyContacts/Outreach/RawLeads'
import MatchedLeads from 'LEGACY/MyContacts/Outreach/MatchedLeads'
import BuyConvrt from 'LEGACY/common/components/BuyConvrt/BuyConvrt'
/////////////////////////////////////// LEGACY //////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export interface RouteConfig {
  path: string
  element: ComponentType<any>
  props?: Record<string, any>
  needsScreenSize?: boolean
  unmount?: boolean
}

export interface UserMetadata {
  id: string
  name: string
  email: string
  subscription_created_at: string
  flag?: boolean
  subscription_type?: string
  subscription_days?: number
  addons?: {
    requires_onboarding: boolean
    onboarding_status: boolean
    pricing: boolean
    unified_inbox?: boolean
    learning_center?: boolean
  }
  credits?: {
    contacts_outreached_left: number
  }
  force_pricing?: boolean
}

const authenticatedRoutes: RouteConfig[] = [
  { path: '/settings/*', element: Settings, needsScreenSize: true },
  { path: '/settings/success', element: Settings, needsScreenSize: true },
  { path: '/settings/slack/success', element: Settings, needsScreenSize: true },
  { path: '/settings/salesforce/success', element: Settings, needsScreenSize: true },
  { path: '/getting-started', element: GettingStarted },
  { path: '/home', element: Home },
  { path: '/tasks', element: Tasks },
  { path: '/outreach', element: Campaigns, needsScreenSize: true },
  { path: '/outreach/create-new-campaign/campaign-builder', element: Builder, needsScreenSize: true },
  { path: '/outreach/create-new-campaign', element: CreateNewCampaign, needsScreenSize: true },
  { path: '/outreach/create-new-campaign/summary', element: CampaignSummaryView },
  { path: '/outreach/create-new-campaign/edit-manually', element: Builder, needsScreenSize: true },
  { path: '/outreach/create-new-campaign/edit-manually/view', element: Builder, needsScreenSize: true },
  { path: '/unifiedInbox', element: Chat, needsScreenSize: true, unmount: true },
  { path: '/onboarding', element: Onboarding },
]

const publicRoutes: RouteConfig[] = [
  { path: '/login', element: Login },
  { path: '/cs-login', element: Login, props: { isCustomerService: true } },
  { path: '/signup', element: SignUpPage },
  { path: '/signup/:token', element: SignUpPage },
  { path: '/register', element: AffiliateSignUp },
  { path: '/register/:uuid', element: AffiliateSignUp },
  { path: '/reset-password', element: ResetPasswordPage },
  { path: '/reset-password/:userId', element: ResetPasswordPage },
  { path: '/premium-signup/:registrationCode', element: SignupRegistrationCode },
]

const upgradeRoutes: RouteConfig[] = [
  { path: '/upgrade', element: Upgrade },
  { path: '/upgrade/success', element: Upgrade },
  { path: '/upgrade/canceled', element: Upgrade },
  { path: '/upgrade/subscription-canceled', element: Upgrade },
]

const web3Routes: RouteConfig[] = [
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////// RESEARCH /////////////////////////////////////////////
  { path: '/companies', element: IntentSignalsPage }, ///////////////////////////////////////////////
  { path: '/companies/:selectedId', element: IntentSignalsPage }, ///////////////////////////////////
  { path: '/contacts', element: ContactsPage }, /////////////////////////////////////////////////////
  { path: '/contacts/:selectedId', element: ContactsPage }, /////////////////////////////////////////
  //////////////////////////////////////////// RESEARCH /////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// MY CONTACTS ///////////////////////////////////////////
  { path: '/exposedContacts', element: ExposedContacts }, ///////////////////////////////////////////
  { path: '/exposedContacts/import-custom-list', element: ImportCustomListMyContact }, //////////////
  { path: '/exposedContacts/import-custom-list/:list_id', element: ImportCustomListMyContact }, /////
  { path: '/exposedContacts/:selectedId', element: ExposedContacts }, ///////////////////////////////
  { path: '/importTelegramContacts', element: ImportTelegramContacts }, /////////////////////////////
  { path: '/importTelegramContacts/rawLeads', element: RawLeads }, //////////////////////////////////
  { path: '/importTelegramContacts/matchedLeads', element: MatchedLeads }, //////////////////////////
  { path: '/importTelegramContacts/matchedLeads/:selectedId', element: MatchedLeads }, //////////////
  /////////////////////////////////////////// MY CONTACTS ///////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////
]

const web3UpgradeRoutes: RouteConfig[] = [
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////// RESEARCH /////////////////////////////////////////////
  { path: '/checkout', element: BuyConvrt }, ////////////////////////////////////////////////
  { path: '/checkout/success', element: BuyConvrt }, ////////////////////////////////////////
  //////////////////////////////////////////// RESEARCH /////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////
]

export const getAvailableRoutes = (shouldHideCheckout: boolean, user_type: string) => {
  const base = [...publicRoutes, ...authenticatedRoutes]
  const isWeb3User = user_type === 'web3'

  if (shouldHideCheckout) {
    return isWeb3User ? [...base, ...web3Routes] : base
  }

  const additionalRoutes = isWeb3User ? [...web3UpgradeRoutes, ...web3Routes] : [...upgradeRoutes]
  return [...base, ...additionalRoutes]
}
