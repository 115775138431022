import { Tag, Tooltip, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { includes, isArray } from 'lodash'

import { FancyImage } from 'LEGACY/Research/contacts/components/FancyImage/FancyImage'
import { IContactsTable } from 'LEGACY/Research/contacts/interfaces/contacts-table.interface'
import { TagsList } from 'LEGACY/Research/contacts/components/TagsList/TagsList'
import classNames from 'classnames'
import styles from './contactTable.module.scss'
import email from 'LEGACY/Assets/svg/email_icon.svg'
import telegram from 'LEGACY/Assets/svg/telegram_logo.svg'
import linkedin from 'LEGACY/Assets/svg/lead_icon4.svg'

// import moment from 'moment-timezone'

export const contactsTableColumn: ColumnsType<IContactsTable> = [
  {
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    width: 150,
    render: (text: string, item: any) => {
      const job_title = item?.job_title
      return (
        <div className='tblLogoName'>
          <FancyImage
            className={`${styles['company_logo']} 'mainPicture'`}
            url={item?.photo_url}
            name={text}
            size={24}
          />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Tooltip placement='topLeft' title={text}>
              <span className={`${styles['table__text__name']} textTooltip`}>
                {text?.length > 17 ? `${text.slice(0, 17)?.trim()}...` : text}
              </span>
            </Tooltip>

            <Tooltip placement='topLeft' title={job_title}>
              <span className={`${styles['table__text__role']} textTooltip`}>
                {job_title?.length > 17 ? `${job_title.slice(0, 17)?.trim()}...` : job_title}
              </span>
            </Tooltip>
          </div>
        </div>
      )
    },
  },

  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    width: 140,
    render: (text: string, item: any) => {
      const tagsList = item?.tags ? JSON.parse(item?.tags || {}) : []
      const tags: string[] = []
      tagsList.forEach((element: any) => {
        element.value !== '' && element.type !== 'name' && tags.push(element.value)
      })

      return <div className='tblFieldText'>{tags.length > 0 ? <TagsList tags={tags} /> : <span>{''}</span>}</div>
    },
  },

  {
    title: 'Channels',
    dataIndex: 'channels',
    width: 120,
    render: (text: boolean, item: any) => {
      const { contactsTracking } = item

      const isIncludeContact = (social: string) => includes(contactsTracking?.[social], item?.contact_id)

      return (
        <div className={styles.channelsContainer}>
          {isIncludeContact('email') && (
            <div className={classNames(styles.iconWrapper)}>
              <img src={email} alt='' />
            </div>
          )}
          {isIncludeContact('linkedin') && (
            <div className={classNames(styles.iconWrapper)}>
              <img src={linkedin} alt='' />
            </div>
          )}
          {isIncludeContact('telegram') && (
            <div className={classNames(styles.iconWrapper)}>
              <img src={telegram} style={{ marginRight: '6px' }} alt='' />
            </div>
          )}
        </div>
      )
    },
  },

  {
    title: 'Company Name',
    dataIndex: 'company_name',
    key: 'company_name',
    width: 150,
    render: (text: string, item: any) => {
      const isHaveCompany = item?.company_name && item?.company_id
      if (isHaveCompany) {
        return (
          <>
            <div className='tblLogoName'>
              {text && (
                <FancyImage
                  className={`${styles.company_logo} mainPicture`}
                  size={30}
                  url={item?.company_s3_logo}
                  iscompany={true}
                  name={text}
                />
              )}
              <Tooltip placement='topLeft' title={text}>
                <span className={`${styles['table__text']} textTooltip`}>{text}</span>
              </Tooltip>
            </div>
          </>
        )
      }
      return <span></span>
    },
  },

  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: 120,
    render: (text: string, item: any) => {
      if (item?.source && item?.is_imported) {
        let sourceData = ''
        const splitSource = item.source?.split('/')
        if (isArray(splitSource) && splitSource.length > 0) {
          sourceData = splitSource[splitSource.length - 1]
        }

        return (
          <Tooltip placement='topLeft' title={sourceData}>
            <Typography className={`${styles['table__text']} textTooltip`}>CSV</Typography>
          </Tooltip>
        )
      }

      return <Typography className={`${styles['table__text']} textTooltip`}>Convrt</Typography>
    },
  },

  {
    title: 'Campaigns',
    dataIndex: 'campaigns',
    key: 'campaigns',
    width: 120,
    render: (text: string, item: any) => {
      const allCampaigns = item?.campaigns?.filter((obj: any) => obj?.recipient === item?.telegram_account)

      const firstThreeCampaigns = allCampaigns?.slice(0, 1)
      const additionalCampaigns = allCampaigns?.slice(1)

      return (
        <>
          {firstThreeCampaigns?.map((campaign: any, index: number) =>
            campaign?.campaign_name?.length > 12 ? (
              <Tooltip title={campaign?.campaign_name} key={index}>
                <Tag className={styles.tag} key={index}>
                  {campaign?.campaign_name.slice(0, 12)?.trim() + '...'}
                </Tag>
              </Tooltip>
            ) : (
              <Tag className={styles.tag} key={index}>
                {campaign?.campaign_name}
              </Tag>
            ),
          )}
          {additionalCampaigns?.length > 0 && (
            <Tooltip
              overlayStyle={{ maxWidth: '600px', backgroundColor: 'white' }}
              overlayInnerStyle={{ backgroundColor: 'white' }}
              title={additionalCampaigns?.map((campaign: any) => (
                <Tag className={styles.tag} key={'additional'}>
                  {campaign?.campaign_name}
                </Tag>
              ))}>
              <Tag className={styles.tag} key={'additionalLength'}>
                +{additionalCampaigns?.length}
              </Tag>
            </Tooltip>
          )}
        </>
      )
    },
  },

  // {
  //   title: 'Date added',
  //   dataIndex: 'created_at',
  //   key: 'created_at',
  //   width: 120,
  //   render: (text: string, item: any) => {
  //     return <Typography>{item?.created_at ? moment(item.created_at).format('MM/DD/YYYY') : '-'}</Typography>
  //   },
  // },
]
