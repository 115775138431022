import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const SocialConnectionApi: any = createApi({
  reducerPath: 'SocialConnectionApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    /* ---------------------------- */
    /* ---------- General ------- */
    /* ---------------------------- */
    checkSignInStatus: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/checkSignInStatus?operationId=${encodeURIComponent(
          params.operationId,
        )}`,
        method: 'GET',
      }),
    }),
    getSocialLogs: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/getSocialLogs?platform=${encodeURIComponent(
          params.platform,
        )}`,
        method: 'GET',
      }),
    }),
    /* ---------------------------- */
    /* ---------- Instagram ------- */
    /* ---------------------------- */
    instagramLogin: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/instagram/sign-in`,
        method: 'POST',
        body: {
          username: params.username,
          password: params.password,
          country_code: params.country_code,
          email: params.email,
          location: params.location,
        },
      }),
    }),
    instagramInstagrapiLogin: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/instagram/instagrapi/sign-in`,
        method: 'POST',
        body: {
          username: params.username,
          password: params.password,
          country_code: params.country_code,
          email: params.email,
          two_factor_code: params.two_factor_code,
        },
      }),
    }),
    instagramSendVerificationCode: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/instagram/sendVerificationCode`,
        method: 'POST',
        body: {
          username: params.username,
          verificationCode: params.verificationCode,
        },
      }),
    }),
    instagramGetAccountStatus: builder.query({
      query: (params: any) => ({
        url: `${
          process.env.REACT_APP_API_URL
        }/social-connection/instagram/getAccountStatus?username=${encodeURIComponent(params.username)}`,
        method: 'GET',
      }),
    }),
    /* ---------------------------- */
    /* ---------- Linkedin ------- */
    /* ---------------------------- */
    linkedinLogin: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/linkedin/sign-in`,
        method: 'POST',
        body: {
          password: params.password,
          country_code: params.country_code,
          email: params.email,
          location: params.location,
        },
      }),
    }),
    linkedinSendVerificationCode: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/linkedin/sendVerificationCode`,
        method: 'POST',
        body: {
          email: params.email,
          verificationCode: params.verificationCode,
        },
      }),
    }),
    linkedinGetAccountStatus: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/linkedin/getAccountStatus?email=${encodeURIComponent(
          params.email,
        )}`,
        method: 'GET',
      }),
    }),
    /* ---------------------------- */
    /* ---------- Facebook ------- */
    /* ---------------------------- */
    facebookLogin: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/facebook/sign-in`,
        method: 'POST',
        body: {
          username: params.username,
          password: params.password,
          country_code: params.country_code,
          email: params.email,
          location: params.location,
        },
      }),
    }),
    facebookSendVerificationCode: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/facebook/sendVerificationCode`,
        method: 'POST',
        body: {
          username: params.username,
          verificationCode: params.verificationCode,
        },
      }),
    }),
    facebookSendPINCode: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/social-connection/facebook/sendPINCode`,
        method: 'POST',
        body: {
          username: params.username,
          PINCode: params.PINCode,
        },
      }),
    }),
    facebookGetAccountStatus: builder.query({
      query: (params: any) => ({
        url: `${
          process.env.REACT_APP_API_URL
        }/social-connection/facebook/getAccountStatus?username=${encodeURIComponent(params.username)}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyCheckSignInStatusQuery,
  useLazyGetSocialLogsQuery,
  // Instagram
  useLazyInstagramLoginQuery,
  useLazyInstagramInstagrapiLoginQuery,
  useLazyInstagramSendVerificationCodeQuery,
  useLazyInstagramGetAccountStatusQuery,
  // Linkedin
  useLazyLinkedinLoginQuery,
  useLazyLinkedinSendVerificationCodeQuery,
  useLazyLinkedinGetAccountStatusQuery,
  // Facebook
  useLazyFacebookLoginQuery,
  useLazyFacebookSendVerificationCodeQuery,
  useLazyFacebookSendPINCodeQuery,
  useLazyFacebookGetAccountStatusQuery,
} = SocialConnectionApi
