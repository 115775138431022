// import { useAuth } from 'common/hooks/useAuth.hooks'
// import styles from './OnBoardingPaymentSuccessfulyPage.module.scss'
import { useEffect, useState } from 'react'
import styles from './OnBoardingQrcodPage.module.scss'
import { Tooltip, Popover } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import { post } from 'LEGACY/API/axios'
import LoadingBox from '../../../../General/LoadingBox/LoadingBox'

// const telegram_app = require(''LEGACY/Assets/mp4/OutreachAutomation.mp4') as any
const add_account = require('LEGACY/Assets/mp4/add_account.mp4') as any //from 'LEGACY/Assets/svg/telegram_app.svg'

interface Props {
  areaCode?: any
  phoneNumber?: any
  handleGetChatLoginCode: any
  closeIcon?: any
}

const OnBoardingQrcodPage = ({ areaCode, phoneNumber, handleGetChatLoginCode, closeIcon = true }: Props) => {
  const [code, setCode] = useState('')
  const [getCode, setGetCode] = useState(false)
  const [tryAgain, setTryAgain] = useState(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [openPopover, setOpenPopover] = useState(false)

  const handleCopyLink = (url: string) => {
    try {
      //const link = `https://app.convrt.io/premium-signup/${url}`
      const tempInput = document.createElement('input')
      tempInput.value = url
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      setOpenPopover(true)
      setTimeout(() => {
        setOpenPopover(false)
      }, 850)
    } catch (error) {
      console.error('Unable to copy link to clipboard', error)
    }
  }

  useEffect(() => {
    let timeoutId: any

    const changeAccountInfo = async () => {
      try {
        const res = await post('/user/getChatLoginCode', { areaCode, phoneNumber })

        setLoading(false)
        if (res.status === 200) {
          setCode(res.data.data.login_code)
          handleGetChatLoginCode(true)
        } else {
          setGetCode(false)
          setTryAgain(true)
        }
      } catch (err) {
        setLoading(false)
        setGetCode(false)
        setTryAgain(true)
      }
    }
    if (getCode === true) {
      setLoading(true)
      timeoutId = setTimeout(() => {
        if (loading === true) {
          setGetCode(false)
          setTryAgain(true)
          setLoading(false)
        }
      }, 60000)
      changeAccountInfo()
    }

    return () => clearTimeout(timeoutId)
  }, [getCode])

  return (
    <div className={styles.container}>
      {closeIcon && (
        <div
          style={{
            position: 'absolute',
            width: '40px',
            height: '25px',
            top: '17px',
            right: '0px',
            zIndex: '1010',
            background: 'white',
          }}></div>
      )}
      <div className={styles.containerText}>
        <p>{`Hooray! Let's add the account to your device.`}</p>
        <section>
          You are almost done, connect the managed account to your device and collect leads like never before. <br />
        </section>
      </div>
      <div className={styles.bodyPage}>
        <div className={styles.leftPage}>
          <div className={styles.caption}>Your Managed Phone Number</div>
          <div>
            <div className={styles.phone_number}>
              <span>{areaCode}</span>
              {`|`}
              <span>{phoneNumber}</span>
              <Tooltip title='Copy Phone Number'>
                <div className={styles.copyRegistrationLink}>
                  <CopyOutlined
                    onClick={() => {
                      handleCopyLink(areaCode + phoneNumber)
                    }}
                  />
                </div>
              </Tooltip>
              <Popover
                trigger={['click']}
                open={openPopover}
                content={<span>copied to clipboard!</span>}
                placement='bottom'
                arrow={false}
              />
            </div>
            <div style={{ marginTop: '5px', textAlign: 'center' }}>2fa password is “convrtwarmed”</div>
            <div className={styles.code}>
              {getCode ? (
                loading ? (
                  <>
                    <LoadingBox />
                    Code is on it’s way....
                  </>
                ) : (
                  <div className={styles.your_code_font}>Your code: {code}</div>
                )
              ) : (
                <div>
                  <div>I'm ready and followed the steps.</div>
                  <button
                    className={styles.get_code}
                    onClick={() => {
                      setGetCode(true)
                    }}>
                    {tryAgain ? `Try again` : `Send the login code.`}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightPage}>
          <div className={styles.animation}>
            <video src={add_account} autoPlay loop muted />
          </div>
          {/* <img src={telegram_app} alt='' style={{ width: '289px', height: '272px' }} /> */}
          <div className={styles.text}>
            On your Telegram App, press on <b>+ Add Account</b>, if needed then <b>Log in using your phone number</b>,
            type your managed account <b>number</b> and you will get the <b>code</b>.
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingQrcodPage
