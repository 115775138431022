import visible_eye_icon from 'common/assets/svg/visible_eye_icon.svg'
import hidden_eye_icon from 'common/assets/svg/hidden_eye_icon.svg'

import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button, Layout, Divider, Tabs, message, Tooltip } from 'antd'
import styles from './ImportLeadsFromGroupsModal.module.scss'
import {
  useLazyGetExploreLoadingQuery,
  useLazyGetExploreQuery,
  useLazyGetGroupDetailsQuery,
  useLazyGetMyHubQuery,
  useLazyGetSavedFiltersQuery,
} from 'common/APIs/OutreachAI-API'
import GroupsSidebar from './GroupsSidebar'
import GroupsContent from './GroupsContent'
import GroupsFilters from './GroupsFilters'
import GroupsTable from './GroupsTable'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_ARE_LEADS_FROM_GROUPS,
  SET_DEFAULT_COLUMNS,
  SET_GLOBAL_COSIAL_HUB_FILTERS,
  SET_INITIAL_GROUP_DATA,
  SET_MODULAR_TABLE_KEY,
  SET_SELECTED_LEADS,
} from 'common/components/Campaigns/state/outreachAICampaignSlice'
import { useLazySaveCampaignLeadsQuery, useLazyUpdateDefaultColumnsQuery } from 'common/APIs/OutreachApi'
import _, { capitalize } from 'lodash'
import { useLazyGetAllIcpQuery } from 'common/APIs/SettingsApi'
import { useLazyGetListsFastQuery } from 'LEGACY/API/CompaniesApi'
import { useActiveFiltersNoSearch } from 'common/hooks/useFilters'
import {
  useLazyGetContactsByCompanyIdForHubQuery,
  useLazyGetContactsByIdQuery,
  useLazyGetTelegramContactsQuery,
} from 'LEGACY/API/ContactsApi'
import { SET_CURRENT_CONTACTS } from 'LEGACY/Research/contacts/state/slice/contactsSlice'
import GroupsTableLists from './GroupsTableLists'
import { useUser } from 'Utils/UserContext'
import {
  defaultColumnsByAction,
  getMergedColumns,
} from 'common/components/GeneralComponents/ModularTable/ModularColumns'
import ListsFilter from '../../Leads/ListsFilter'
import { trackEvent } from 'eventConfig'

const { Header } = Layout
const { TabPane } = Tabs

const mapLeads = (contacts: any, selected_list_name: any, selected_platform: any) => {
  return contacts.map((c: any) => {
    const location = (c.city ? c.city + ', ' : '') + (c.state ? c.state + ', ' : '') + (c.country || '')
    return {
      platform: selected_platform,
      convrt_source: 'lists',
      source: selected_list_name,
      biography: c.bio,
      jobTitle: c.job_title,
      company_website: c.company_domain,
      company: c.company_name,
      company_id: c.company_id,
      company_photo: c.company_s3_logo,
      location: location,
      name: c.full_name,
      categories: JSON.parse(c.tags),
      index: c.index,
      is_doxxed: c.is_doxed,
      linkedin: c.linkedin,
      telegram: c.telegram_account,
      twitter: c.twitter,
      profile_pic_ur: c.item_s3_logo,
      vertical: c.vertical,
      // The rest of c
      ...c,
    }
  })
}

const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  backgroundColor: 'white',
  height: 'calc(100vh - 210px)',
}

const sortData = (data: any[], sortKey: string, ascending: boolean = true) => {
  if (!data) return []

  return data.sort((a, b) => {
    if (a.is_web3Event && !b.is_web3Event) return -1
    if (!a.is_web3Event && b.is_web3Event) return 1
    if (a.is_web3Event && b.is_web3Event) return 0

    if (a.is_web3Group && !b.is_web3Group) return -1
    if (!a.is_web3Group && b.is_web3Group) return 1
    if (a.is_web3Group && b.is_web3Group) return 0

    if (a[sortKey] < b[sortKey]) return ascending ? -1 : 1
    if (a[sortKey] > b[sortKey]) return ascending ? 1 : -1
    return 0
  })
}

const proccessLists = (lists: any, platform: any) => {
  if (!lists || lists.length === 0) {
    return []
  }

  return lists
    ?.map((list: any) => {
      if (list.contact_count === 0 || list.contact_count === '0') {
        return null
      }
      return {
        platform: ['linkedin', 'telegram'].includes(platform) ? platform : 'telegram',
        leads: Number(list.contact_count) || 0,
        id: list.id,
        name: list.name,
        type: list.type,
        track_mode: true,
        status: 'success',
        added_at: list.created_at,
        locked: false,
        collected_leads: Number(list.contact_count) || 0,
        is_list: true,
        ...list,
      }
    })
    .filter((list: any) => list)
}
interface ImportLeadsFromGroupsModalProps {
  open: boolean
  onClose: () => void
  isLoading: boolean
  default_filters?: string
  screenSize: { width: number; height: number }
}

const ImportLeadsFromGroupsModal: React.FC<ImportLeadsFromGroupsModalProps> = ({
  open,
  onClose,
  isLoading,
  default_filters,
  screenSize,
}) => {
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 8,
    total: 10,
  })
  const { user } = useUser()
  const isWeb3 = user?.user_type === 'web3'
  const [searchValue, setSearchValue] = useState('')
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [saveCampaignLeads] = useLazySaveCampaignLeadsQuery()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { initial_group_data: groupsData } = useAppSelector((state) => state.outreachAICampaign)
  const [getGroupDetails, { isFetching: isGroupDataFetching }] = useLazyGetGroupDetailsQuery()
  const [getSavedFilters, { data: savedFilters }] = useLazyGetSavedFiltersQuery()
  const [getExplore, { data: exploreData }] = useLazyGetExploreQuery()
  const [getListsFast, { data: listData }] = useLazyGetListsFastQuery()
  const [getMyHub, { data: myHubData }] = useLazyGetMyHubQuery()
  const [groupData, setGroupData] = useState<any>()
  const [loadingLeads, setLoadingLeads] = useState(false)
  const [showNewLeads, setShowNewLeads] = useState(false)
  const [getContactsById] = useLazyGetContactsByIdQuery()

  const [filterObject, setFilterObject] = useState<any>({
    a: 'Explore All',
    b: default_filters ? default_filters : '',
    c: [],
  })
  const [selectedType, setSelectedType] = useState('Result')
  const [selectedGroup, setSelectedGroup] = useState<any>('')
  const [selectedGroupName, setSelectedGroupName] = useState('')
  const [groupType, setGroupType] = useState('')
  const { selected_leads, active_platforms, campaign_id, ai_campaign_name, global_social_hub_filter } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const [filteredData, setFilteredData] = useState(groupData)
  const [smallerThan, setSmallerThan] = useState<number | undefined>(0)
  const [greaterThan, setGreaterThan] = useState<number | undefined>(1000000)
  const dispatch = useAppDispatch()
  const [initialLoading, setInitialLoading] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState(default_filters === 'Lists' ? '1' : '2')
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [openCustomImport, setOpenCustomImport] = useState(false)
  const [importData, setImportData] = useState<any>({})
  const [getICP, { data: icpData }] = useLazyGetAllIcpQuery() // Get ICP data
  const [myHubLoading, setMyHubLoading] = useState(false)
  const firstTimeRef = useRef(true)
  const [loadIncrement, setLoadIncrement] = useState(9)
  const [getExploreLoading] = useLazyGetExploreLoadingQuery()
  const [exploreLoading, setExploreLoading] = useState({})
  const [tableIsList, setTableIsList] = useState(false)
  const [activeFilters, setActiveFilters] = useActiveFiltersNoSearch()
  const [selectedContacts, setSelectedContacts] = useState<any>([])
  const [refetchGetTelegramContacts] = useLazyGetTelegramContactsQuery()
  const [refetchGetContacts, { data: contacts, isLoading: isContactsLoading, isFetching }] =
    useLazyGetContactsByCompanyIdForHubQuery()
  const [filteredContacts, setFilteredContacts] = useState<any>(null)
  const [updateDefaultColumns] = useLazyUpdateDefaultColumnsQuery()

  // For now. Change when implementing multi-channel campaigns

  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
  const selectedPlatform = truePlatformsArray?.[0]

  const suggestionsData = [
    ...(groupsData?.suggestions?.facebook_groups || []),
    ...(groupsData?.suggestions?.my_facebook_groups || []),
    ...(groupsData?.suggestions?.my_instagram_groups || []),
    ...(groupsData?.suggestions?.linkedin_searches || []),
    ...(groupsData?.suggestions?.instagram_hashtags || []),
    ...(groupsData?.suggestions?.web3Events || []),
    ...(groupsData?.suggestions?.web3Groups || []),
  ]

  const myHubGroups = [
    ...(groupsData?.groups?.gfp || []),
    ...(groupsData?.groups?.linkedin || []),
    ...(isWeb3 ? groupsData?.groups?.web3Events || [] : []),
    ...(isWeb3 ? groupsData?.groups?.web3Groups || [] : []),
    ...(proccessLists(groupsData?.lists, selectedPlatform) || []),
  ]
  // displayed groups are all of the groups that match the selected category and channel
  const displayedGroups = activeTabKey === '1' ? myHubGroups : suggestionsData

  const handleSelectedRows = (rows: any, keys: any) => {
    // Get all the current page items' keys
    const currentPageItemKeys = contacts?.rows?.map((row: any) => row.index) || []

    setSelectedContacts((prev: any[]) => {
      // Remove any previous selections from current page
      const otherPagesSelections = prev.filter((id) => !currentPageItemKeys.includes(id))

      // Add new selections from current page
      return [...otherPagesSelections, ...keys]
    })
  }

  const goToProgress = () => {
    setActiveTabKey('1')
    setFilterObject({
      ...filterObject,
      a: 'On Importing Progress',
    })
  }

  const goToMyHub = (type?: string) => {
    setActiveTabKey('1')
    setFilterObject({
      ...filterObject,
      b: type ? type : 'Conferences',
    })
  }

  const onLinkedinCollect = async (search: any) => {
    setImportData({
      platform: 'linkedin',
      source_type: search?.type === 'search_people' ? 'linkedin_search' : 'sales_nav',
      url: search?.search_value,
      searchName: search?.name,
    })
  }
  const onBack = () => {
    setSelectedGroup('')
    setSelectedGroupName('')
    setSelectedTags([])
    setGroupData([])
  }
  const handleAddLeads = async () => {
    setLoadingAdd(true)
    const selectedData = groupData.filter((d: any) => selectedRowKeys.includes(d.key))
    const newLeadsToAdd = selectedData.filter(
      (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
    )
    let source = newLeadsToAdd[0]?.followed_username
    if (!source) {
      source = selectedGroupName
    }

    const leadsForCampaign = newLeadsToAdd?.map((lead: any) => ({
      ...lead,
      platform: selectedPlatform,
      convrt_source: selectedGroup?.is_web3Event
        ? 'web3Event'
        : selectedGroup?.is_web3Group
        ? 'web3Group'
        : 'social_hub',
      categories: [lead?.category],
      source: source,
      type: lead?.type,
    }))
    if ((selectedGroup?.is_web3Event || selectedGroup?.is_web3Group) && selected_leads?.length === 0) {
      dispatch(SET_DEFAULT_COLUMNS(defaultColumnsByAction.web3Events))
      await updateDefaultColumns({ campaign_id: campaign_id, default_columns: defaultColumnsByAction.web3Events })
    } else {
      if (user?.user_type === 'web3') {
        const columns = getMergedColumns([...leadsForCampaign, ...selected_leads])
        dispatch(SET_DEFAULT_COLUMNS(columns))
        dispatch(SET_MODULAR_TABLE_KEY(columns.toString()))
        await updateDefaultColumns({ campaign_id: campaign_id, default_columns: columns })
      }
    }
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    dispatch(SET_SELECTED_LEADS(leadsForCampaign))
    await saveCampaignLeads({
      leads: leadsForCampaign,
      campaign_id: campaign_id,
      mode: 'groups',
    })

    setSelectedRowKeys([])
    setLoadingAdd(false)
    onClose()
  }
  const handleAddLeadsList = async () => {
    setLoadingAdd(true)
    console.log('handle add leads list')
    let ids = ''
    selectedContacts?.forEach((item: any) => {
      if (item && ids.indexOf(item) === -1) {
        ids += item + ','
      }
    })
    const { data: contactsData } = await getContactsById(ids)
    const resultRows = contactsData?.rows?.map((contact: any) => ({
      ...contact,
      key: contact.index,
    }))
    const newLeadsToAdd = resultRows.filter(
      (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
    )
    const leadsForCampaign = mapLeads(newLeadsToAdd, selectedGroupName, selectedPlatform)
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    if (selected_leads.length === 0) {
      dispatch(SET_DEFAULT_COLUMNS(defaultColumnsByAction.lists))
      await updateDefaultColumns({ campaign_id: campaign_id, default_columns: defaultColumnsByAction.lists })
    } else {
      const columns = getMergedColumns([...newLeadsToAdd, ...selected_leads])
      dispatch(SET_DEFAULT_COLUMNS(columns))
      dispatch(SET_MODULAR_TABLE_KEY(columns.toString()))
      await updateDefaultColumns({ campaign_id: campaign_id, default_columns: columns })
    }
    dispatch(SET_SELECTED_LEADS(leadsForCampaign))

    await saveCampaignLeads({
      leads: leadsForCampaign,
      campaign_id: campaign_id,
      mode: 'lists',
    })

    trackEvent('ADD_LEADS_FROM_LIST', {
      list_name: selectedGroupName,
      list_type: selectedGroup?.type === '1' ? 'companies' : 'contacts',
      list_id: selectedGroup?.id,
      campaign_id: campaign_id,
      campaign_name: ai_campaign_name,
      user: user?.email,
      user_id: user?.id,
      number_of_leads: leadsForCampaign.length,
    })
    setSelectedRowKeys([])
    setLoadingAdd(false)
    onClose()
  }
  const checkFieldsForKeywords = (group: any, keyword: string) => {
    const lowerKeyword = keyword.toLowerCase()

    if (selectedPlatform === 'linkedin') {
      return (
        group?.biography?.toLowerCase().includes(lowerKeyword) ||
        group?.location?.toLowerCase().includes(lowerKeyword) ||
        group?.skills?.toLowerCase().includes(lowerKeyword) ||
        group?.company?.toLowerCase().includes(lowerKeyword) ||
        group?.experience?.toLowerCase().includes(lowerKeyword) ||
        group?.connection_level?.toLowerCase().includes(lowerKeyword) ||
        group?.headline?.toLowerCase().includes(lowerKeyword) ||
        group?.position?.toLowerCase().includes(lowerKeyword)
      )
    }

    return group?.biography?.toLowerCase().includes(lowerKeyword)
  }

  const handleFilterChange = ({ keywords, operator, conditionedKeywords }: any) => {
    let filtered = groupData

    if (keywords.length > 0) {
      filtered = filtered.filter((group: any) =>
        keywords.some((keyword: any) => checkFieldsForKeywords(group, keyword)),
      )
    }

    if (conditionedKeywords.length > 0) {
      if (operator === 'AND') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => checkFieldsForKeywords(group, keyword)),
        )
      } else if (operator === 'AND NOT') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => !checkFieldsForKeywords(group, keyword)),
        )
      }
    }

    if (
      selectedPlatform === 'instagram' &&
      smallerThan !== undefined &&
      smallerThan !== null &&
      typeof smallerThan === 'number' &&
      smallerThan >= 0
    ) {
      filtered = filtered?.filter((group: any) => group.follower_count && group.follower_count >= smallerThan)
    }

    if (
      selectedPlatform === 'instagram' &&
      greaterThan !== undefined &&
      greaterThan !== null &&
      typeof greaterThan === 'number' &&
      greaterThan >= 0
    ) {
      filtered = filtered?.filter((group: any) => group.follower_count && group.follower_count <= greaterThan)
    }
    if (selectedTags?.length > 0) {
      filtered = filtered.filter((lead: any) => selectedTags.includes(lead.category))
    }
    setFilteredData(filtered)
  }
  const rowHeight = 62
  const reservedHeight = 440 // Space reserved for headers, footers, etc.
  const tableLine = () => {
    return Math.max(1, Math.floor(((screenSize?.height ?? 0) - reservedHeight) / rowHeight))
  }
  const maxHeight = () => {
    return String((tableLine() + 1) * 62) + 'px'
  }
  const handleOnLeads = async () => {
    if (showNewLeads === false) {
      setShowNewLeads(true)
      setLoadingLeads(true)
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: true })
      setGroupData(data)
      setFilteredData(data)
      setLoadingLeads(false)
    } else {
      setShowNewLeads(false)
      const { data } = await getGroupDetails({ group: selectedGroup })
      setGroupData(data)
      setFilteredData(data)
    }
  }

  const handleSelectGroup = (group: any) => {
    if (group.platform === selectedPlatform || selectedPlatform === 'telegram') {
      setSelectedGroup(group)
    } else {
      message.error(`You can only select leads from ${capitalize(selectedPlatform)} for this campaign.`)
    }
  }

  const handleCustomImport = () => {
    setActiveTabKey('1')
  }

  const handleUnSelectRows = (rows: any) => {
    const keysToRemove = new Set(rows.map((row: any) => row))
    setSelectedContacts((prev: any) => prev.filter((id: any) => !keysToRemove.has(id)))
  }

  const handleSelectAllData = () => {
    if (user?.addons?.allow_select_all_data) {
      const newContactsIds = [...selectedContacts, ...contacts.allContactsIdsData]
      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      setSelectedContacts(uniqueContactsIds)
    } else {
      message.warning('Your plan does not support selecting all data.')
    }
  }
  const handleSelectFifty = () => {
    const newContactsIds = [...selectedContacts, ...contacts.contacts_ids]
    const uniqueContactsIds = Array.from(new Set(newContactsIds))
    setSelectedContacts(uniqueContactsIds)
  }
  // const handleSelectAllTelegram = () => {
  //   if (user?.addons?.allow_select_all_telegram) {
  //     const newContactsIds = [...selectedContacts, ...contacts.allContactsIdsTGData]
  //     const uniqueContactsIds = Array.from(new Set(newContactsIds))
  //     setSelectedContacts(uniqueContactsIds)
  //   } else {
  //     message.warning('Your plan does not support selecting all Telegram prospects.')
  //   }
  // }
  // const handleSelectAllLinkedin = () => {
  //   if (user?.addons?.allow_select_all_linkedin) {
  //     const newContactsIds = [...selectedContacts, ...contacts.allContactsIdsLinkedinData]
  //     const uniqueContactsIds = Array.from(new Set(newContactsIds))
  //     setSelectedContacts(uniqueContactsIds)
  //   } else {
  //     message.warning('Your plan does not support selecting all Linkedin prospects.')
  //   }
  // }
  const fetchData = async () => {
    if (!Object.keys(activeFilters)?.length) return

    const tempFilters = { ...activeFilters }
    if (tempFilters?.contactsExcludeList) {
      tempFilters.contactsExcludeList = tempFilters.contactsExcludeList?.map((item: any) => item.key)
    }
    if (tempFilters?.contactsIncludeList) {
      tempFilters.contactsIncludeList = tempFilters.contactsIncludeList?.map((item: any) => item.key)
    }
    const result = await refetchGetContacts({
      company_ids: '',
      current: tableParams.current,
      pageSize: tableParams.pageSize,
      filters: _.omitBy(tempFilters, _.isNil),
      order_by: '',
      platform: selectedPlatform,
    })
    dispatch(SET_CURRENT_CONTACTS(contacts || result?.data))
    setFilteredContacts(result?.data?.rows || result?.data)
  }
  const applyFilters = (dataArr: any, filters: any) => {
    if (!filters) {
      return dataArr
    }

    const data = dataArr?.rows || dataArr || []
    const t = data.filter((lead: any) => {
      // For each filter type, if there are selected values, check if the lead matches
      return Object.entries(filters).every(([key, selectedValues]: any) => {
        if (selectedValues.length === 0) return true // No filter applied for this type

        switch (key) {
          case 'company':
            return selectedValues.includes(lead.company_name)
          case 'location':
            return selectedValues.includes(lead.location)
          case 'tags':
            // For arrays, check if there's at least one match
            try {
              const tags = JSON.parse(lead.tags)
              const valuesArray = tags?.map((tag: any) => tag.value)
              return selectedValues.some((value: any) => valuesArray.includes(value))
            } catch (e) {
              return false
            }
          case 'vertical':
            try {
              const vertical = JSON.parse(lead.vertical)?.[0]
              return selectedValues.includes(vertical)
            } catch (e) {
              return false
            }
          case 'jobTitle':
            return selectedValues.includes(lead.jobTitle || lead.job_title)
          case 'accounts':
            const isTelegram = lead.telegram_account !== null
            const isLinkedin = lead.linkedin !== null
            if (selectedValues.includes('telegram') && selectedValues.includes('linkedin')) {
              return isTelegram && isLinkedin
            }
            if (selectedValues.includes('telegram')) {
              return isTelegram
            }
            if (selectedValues.includes('linkedin')) {
              return isLinkedin
            }
            return true
          default:
            return true
        }
      })
    })
    trackEvent('APPLY_FILTERS_LISTS_TO_CAMPAIGN', {
      list_name: selectedGroupName,
      list_type: selectedGroup?.type === '1' ? 'companies' : 'contacts',
      list_id: selectedGroup?.id,
      campaign_id: campaign_id,
      campaign_name: ai_campaign_name,
      user: user?.email,
      user_id: user?.id,
      filter: filters,
    })
    return t
  }
  const handleApplyFilters = (filters: any, resetData?: any) => {
    if (resetData) {
      setFilteredContacts([])
    } else {
      const filteredData = applyFilters(contacts, filters)
      setFilteredContacts(filteredData)
    }
  }
  useEffect(() => {
    const groupDetails = async () => {
      setTableIsList(false)
      await getSavedFilters()
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: showNewLeads })
      if (selectedGroup?.type) {
        setGroupType(selectedGroup?.type)
      }
      setGroupData(data)
      setFilteredData(data)
    }
    const handleListSelection = async () => {
      setTableIsList(true)
      setActiveFilters({
        contactsList: [
          {
            list_id: selectedGroup.id,
          },
        ],
      })
      setSelectedContacts([])
      await fetchData()
    }
    if (selectedGroup) {
      selectedGroup?.is_list ? handleListSelection() : groupDetails()
    }
  }, [selectedGroup])
  const getAllExplore = async () => {
    if (firstTimeRef.current) {
      firstTimeRef.current = false
      const { data: loading } = await getExploreLoading()
      if (loading?.loadingStates) {
        setExploreLoading(loading?.loadingStates)
      }

      if (initialLoading) {
        setInitialLoading(false)
      }

      const [{ data: explore }, { data: myHub }, { data: lists }] = await Promise.all([
        getExplore(),
        getMyHub(),
        getListsFast(),
      ])
      dispatch(SET_INITIAL_GROUP_DATA({ ...explore, lists: lists, ...myHub }))
      setMyHubLoading(false)
    } else {
      const { data: loading } = await getExploreLoading()
      if (loading?.loadingStates) {
        setExploreLoading(loading?.loadingStates)
      }
      const [{ data: explore }, { data: myHub }, { data: lists }] = await Promise.all([
        getExplore(),
        getMyHub(),
        getListsFast(),
      ])
      dispatch(SET_INITIAL_GROUP_DATA({ ...explore, lists: lists, ...myHub }))
      if (initialLoading) {
        setInitialLoading(false)
      }
    }
  }
  useEffect(() => {
    getAllExplore()
    getICP()
    const intervalId = setInterval(() => {
      getAllExplore()
    }, 60000)

    return () => clearInterval(intervalId)
  }, [open])

  // useEffect(() => {
  //   if (exploreData || listData || myHubData) {
  //     dispatch(
  //       SET_INITIAL_GROUP_DATA({
  //         ...exploreData,
  //         ...listData,
  //         ...myHubData,
  //       }),
  //     )
  //   }
  // }, [exploreData, listData, myHubData])

  useEffect(() => {
    const isExploreDataLoading = !exploreData && displayedGroups?.length === 0
    const isListDataLoading = !listData && displayedGroups?.length === 0
    const isMyHubDataLoading = !myHubData && displayedGroups?.length === 0

    if (isExploreDataLoading) {
      setInitialLoading(true)
    } else if (isMyHubDataLoading || isListDataLoading) {
      setMyHubLoading(true)
    } else {
      setInitialLoading(false)
      setMyHubLoading(false)
    }
  }, [exploreData, listData, myHubData, displayedGroups])

  useEffect(() => {
    if (contacts?.totalNumber) setTableParams({ ...tableParams, total: Number(contacts?.totalNumber) })

    const getTelegram = async () => {
      await refetchGetTelegramContacts()
    }

    getTelegram()
  }, [contacts])

  useEffect(() => {
    fetchData().catch(console.error)
  }, [activeFilters])

  useEffect(() => {
    if (global_social_hub_filter) {
      setTimeout(() => {
        goToMyHub(global_social_hub_filter)
        dispatch(SET_GLOBAL_COSIAL_HUB_FILTERS(''))
      }, 100)
    }
  }, [global_social_hub_filter])

  const headerStyle: React.CSSProperties = {
    color: 'black',
    fontWeight: 600,
    fontSize: '18px',
    height: 80,
    paddingInline: 24,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: tableIsList ? '' : '1px solid #DFE1E6',
    alignContent: 'center',
  }
  return (
    <Modal
      className='groupsModal'
      centered
      closable={false}
      open={open}
      footer={null}
      onCancel={onClose}
      width='87%'
      maskClosable={false}>
      {selectedGroup ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>
                Add Leads From {selectedGroup?.name || selectedGroupName || 'N/A'}
                <Divider type='vertical' />{' '}
                <span style={{ fontSize: '14px' }}>
                  {tableIsList ? selectedContacts?.length || 0 : selectedRowKeys?.length || 0} leads{' '}
                  <span style={{ fontWeight: 500 }}>were selected</span>
                </span>
              </span>
              <div className={styles.buttons}>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                  Cancel
                </span>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onBack}>
                  Back
                </span>
                <Button
                  loading={loadingAdd}
                  type='primary'
                  onClick={tableIsList ? handleAddLeadsList : handleAddLeads}
                  disabled={
                    tableIsList
                      ? !selectedContacts?.length || isContactsLoading
                      : !selectedRowKeys.length || isGroupDataFetching
                  }>
                  Add Leads
                </Button>
              </div>
            </div>
          </Header>

          {!tableIsList && (
            <div className={styles.title}>
              <span>
                Displaying{' '}
                <span style={{ fontWeight: 600 }}>all of {filteredData?.length?.toLocaleString()} available leads</span>{' '}
                from {selectedGroupName}
              </span>
              <Tooltip title={`${showNewLeads ? 'Show' : 'Hide'} leads from my other campaigns`}>
                <img
                  className={styles.hiddenEye}
                  onClick={handleOnLeads}
                  src={showNewLeads ? hidden_eye_icon : visible_eye_icon}
                  alt=''
                />
              </Tooltip>
            </div>
          )}

          <Layout
            style={{
              backgroundColor: 'white',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignSelf: 'stretch',
            }}>
            <Layout.Content
              style={{
                padding: 0,
                margin: 0,
                backgroundColor: 'white',
              }}>
              {tableIsList ? (
                <GroupsTableLists
                  platform={selectedPlatform}
                  selectedGroup={selectedGroupName}
                  selected_items={selectedContacts}
                  data={
                    isFetching
                      ? null
                      : filteredContacts?.map((contact: any) => ({
                          ...contact,
                          openCompanyDrawer: () => {},
                          key: contact.index,
                        })) || []
                  }
                  groupType={groupType}
                  isLoading={isContactsLoading || isFetching}
                  selectedRowKeys={selectedContacts}
                  setSelectedRowKeys={(keys: any) => handleSelectedRows([], keys)}
                  onLeads={handleOnLeads}
                  loadingLeads={isContactsLoading || isFetching}
                  showNewLeads={showNewLeads}
                  isList={true}
                  tableParams={tableParams}
                  setTableParams={setTableParams}
                  handleSelectFifty={handleSelectFifty}
                  handleSelectAllData={handleSelectAllData}
                  handleDeselectAll={() => setSelectedContacts([])}
                  handleSelectedRows={handleSelectedRows}
                  handleRemoveRow={() => {}}
                  handleUnSelectRows={handleUnSelectRows}
                  refetch={fetchData}
                />
              ) : (
                <GroupsTable
                  selectedGroup={selectedGroupName}
                  data={filteredData}
                  groupType={groupType}
                  isLoading={isGroupDataFetching}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  onLeads={handleOnLeads}
                  loadingLeads={loadingLeads}
                  showNewLeads={showNewLeads}
                  numberOfLine={tableLine()}
                  group={selectedGroup}
                />
              )}
            </Layout.Content>

            {tableIsList ? (
              <ListsFilter onFilter={handleApplyFilters} clearFilters={true} data={contacts?.rows || []} />
            ) : (
              <GroupsFilters
                onFilterChange={handleFilterChange}
                selectedPlatform={selectedPlatform}
                groupType={groupType}
                smallerThan={smallerThan}
                setSmallerThan={setSmallerThan}
                greaterThan={greaterThan}
                setGreaterThan={setGreaterThan}
                data={groupData}
                tagsSelected={selectedTags}
                setTagsSelected={setSelectedTags}
                savedFilters={savedFilters}
                getSavedFilters={getSavedFilters}
                className={tableIsList ? 'fullHeightFilters' : ''}
                maxHeight={maxHeight()}
              />
            )}
          </Layout>
        </Layout>
      ) : (
        <Layout style={layoutStyle}>
          <Header className={styles.safari} style={headerStyle}>
            <div className={styles.header}>
              <span>Social Hub</span>
              <Tabs
                activeKey={activeTabKey}
                onChange={(key) => {
                  setActiveTabKey(key)
                  setFilterObject({
                    a: key === '2' ? 'Explore All' : 'All',
                    b: '',
                    c: [],
                  })
                }}>
                <TabPane tab='Explore' key='2' />
                <TabPane tab={<span>My Hub</span>} key='1' />
              </Tabs>
              <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                Cancel
              </span>
            </div>
          </Header>
          <Layout>
            <GroupsSidebar
              myGroups={activeTabKey === '1'}
              setFilterObject={setFilterObject}
              filterObject={filterObject}
              setLoadIncrement={setLoadIncrement}
              setSelectedType={setSelectedType}
              setSearchValue={setSearchValue}
            />
            <GroupsContent
              exploreLoading={exploreLoading}
              initialLoading={initialLoading}
              selectedType={selectedType}
              groupsData={sortData(displayedGroups, 'name', true)}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              setSelectedGroup={handleSelectGroup}
              setSelectedGroupName={setSelectedGroupName}
              myGroupsTab={activeTabKey === '1'}
              handleCustomImport={handleCustomImport}
              onLinkedinCollect={onLinkedinCollect}
              openCustomImport={openCustomImport}
              setOpenCustomImport={setOpenCustomImport}
              importData={importData}
              setImportData={setImportData}
              icpData={icpData}
              myHubLoading={myHubLoading}
              goToProgress={goToProgress}
              loadIncrement={loadIncrement}
              setLoadIncrement={setLoadIncrement}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              goToMyHub={goToMyHub}
            />
          </Layout>
        </Layout>
      )}
    </Modal>
  )
}
export default ImportLeadsFromGroupsModal
