import { useLazyGetMyTemplatesQuery, useLazyGetTempLeadsQuery } from 'common/APIs/OutreachAI-API'
import {
  RESET_SELECTED_LEADS,
  SET_ACTIVE_PLATFORMS,
  SET_AI_CAMPAIGN_NAME,
  SET_AI_LEADS_TYPE,
  SET_ARE_LEADS_FROM_GROUPS,
  SET_BLOCK_EDITING,
  SET_CAMPAIGN_ID_AI,
  SET_CAMPAIGN_SETTINGS_AI,
  SET_CAMPAIGN_STATUS,
  SET_CURRENT_COLLAPSE_KEY,
  SET_DEFAULT_COLUMNS,
  SET_DEFAULT_TEMPLATES,
  SET_GENERATE_MESSAGES,
  SET_IS_CAMPAIGN_RUNNING_ALREADY,
  SET_IS_EDIT_CAMPAIGN,
  SET_NODES_DATA,
  SET_NON_EDITABLE_STEPS,
  SET_PROMPT,
  SET_SAVED_PROMPT_PARAMS,
  SET_SELECTED_LEADS,
  SET_SELECTED_PLATFORMS_ACCOUNTS,
  SET_SELECT_CUSTOM_TEMPLATE,
  SET_SETUP_METHOD,
  SET_SKIP_PROMPT,
  SET_STEPS,
  SET_STORED_LEADS_FROM_DB,
  SET_USER_POINTS,
  SET_USER_UPDATED_SEQUENCE,
} from 'common/components/Campaigns/state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { message } from 'antd'
import {
  useLazyArchiveCampaignQuery,
  useLazyGetCampaignSummaryQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazySetCampaignsActiveQuery,
  useLazyUnarchiveCampaignQuery,
  useLazyUpdateDefaultColumnsQuery,
} from 'common/APIs/OutreachApi'
import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  SET_BUILDER_DATA,
  SET_BUILDER_EDGES,
  SET_BUILDER_NODES,
  SET_USE_CUSTOM_FLOW,
} from 'common/components/Campaigns/Builder/Slice/CampaignBuilderSlice'
import {
  filterPlaceholderNodesAndEdges,
  getLayoutedElements,
} from 'common/components/Campaigns/Builder/Utils/BuilderUtils'
import {
  buildRecipientArray,
  getDefaultColumnsByRecipient,
  // mergeTemplateNodesWithStepsNodes,
  replaceMessageIds,
} from 'common/utils/campaignHelpers'
import { useLocation } from 'react-router-dom'
import { useLazyGetPointsStoreQuery } from 'common/APIs/SettingsApi'

interface Campaign {
  campaign_id: string
  campaign_name: string
  status: any
  run_at: string
}

interface InactiveCampaignInfo {
  campaign_name: string
  nextActiveMoment: moment.Moment
}

export const useCampaigns = (campaigns?: any[]) => {
  const { default_templates, saved_prompt_params } = useAppSelector((state) => state.outreachAICampaign)
  const [refetchGetCampaignSummary] = useLazyGetCampaignSummaryQuery()
  const [getTempLeads] = useLazyGetTempLeadsQuery()
  const [refetchArchiveCampaign] = useLazyArchiveCampaignQuery()
  const [refetchUnarchiveCampaign] = useLazyUnarchiveCampaignQuery()
  const [refetchSetCampaignsActive] = useLazySetCampaignsActiveQuery()
  const [inactiveCampaigns, setInactiveCampaigns] = useState<InactiveCampaignInfo[]>([])
  const [getMyTemplates] = useLazyGetMyTemplatesQuery()
  const [getCampaignRecipients] = useLazyGetCampaignsRecipientsByIdQuery()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [updateDefaultColumns] = useLazyUpdateDefaultColumnsQuery()

  const getCampaignSummaryView = async (campaign_id: string, status?: any, addLeads?: boolean) => {
    const { data: campaignSummaryData } = await refetchGetCampaignSummary({ campaign_id })
    if (!campaignSummaryData?.response) return

    if (campaignSummaryData && Object.keys(campaignSummaryData)?.length > 0) {
      const [{ data: templates }, { data }, { data: recipientsData }, points_store] = await Promise.all([
        getMyTemplates(),
        getTempLeads({ campaign_id }),
        getCampaignRecipients({ campaign_id }),
        refetchGetPointsStore(),
      ])

      const params = new URLSearchParams(location.search)
      const urlCampaignId = params.get('campaign_id')
      console.log('campaignSummaryData', campaignSummaryData)
      const prompt = JSON.parse(campaignSummaryData?.response?.prompt || '[]')
      const isCampaignRan = campaignSummaryData?.response?.is_running_already || false
      const campaignStatus = campaignSummaryData?.response?.status
      const custom_flow = campaignSummaryData?.custom_flow
      const steps = JSON.parse(campaignSummaryData?.response?.steps || '[]')
      const showPrompt =
        prompt?.setup_method === 'ai' ||
        (!(saved_prompt_params?.industry === '') &&
          !(saved_prompt_params?.location === '') &&
          !(saved_prompt_params?.target_persone === ''))
      const collapseKey = +prompt?.current_collapse_key // === 2 ? '1' : prompt?.current_collapse_key || '4'

      const recipientsAi = buildRecipientArray(data)
      const storedRecipientsAi = buildRecipientArray(recipientsData?.response)
      const nodes_data = JSON.parse(campaignSummaryData?.response?.steps || '[]')
      const setup_method = prompt?.setup_method ? prompt.setup_method : showPrompt ? 'ai' : ''
      const nonEditableSteps = campaignSummaryData?.nonEditableSteps

      if (isCampaignRan) {
        dispatch(SET_NON_EDITABLE_STEPS(nonEditableSteps))
      }

      dispatch(SET_STEPS(steps))
      dispatch(RESET_SELECTED_LEADS([]))

      dispatch(SET_DEFAULT_COLUMNS(prompt?.default_columns))
      dispatch(
        SET_USER_POINTS({
          points: points_store?.data?.response?.points,
          pointsStore: points_store?.data?.response?.pointsStore,
        }),
      )
      if (!prompt.default_columns || prompt.default_columns?.length === 0) {
        // check one of the leads source
        const columns = getDefaultColumnsByRecipient(recipientsAi[0] || {})
        dispatch(SET_DEFAULT_COLUMNS(columns))
        await updateDefaultColumns({ campaign_id: campaign_id, default_columns: columns })
      }
      dispatch(SET_CAMPAIGN_ID_AI(campaignSummaryData?.response?.campaign_id || urlCampaignId || ''))
      dispatch(SET_AI_CAMPAIGN_NAME(campaignSummaryData?.response?.campaign_name || ''))
      dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(prompt?.selected_platforms_accounts || {}))
      dispatch(SET_ACTIVE_PLATFORMS(prompt?.active_platforms || {}))
      dispatch(SET_PROMPT(prompt?.prompt || {}))
      dispatch(SET_SAVED_PROMPT_PARAMS(prompt?.saved_prompt_params || {}))
      dispatch(SET_SELECTED_LEADS([...recipientsAi, ...storedRecipientsAi]))
      dispatch(SET_STORED_LEADS_FROM_DB(storedRecipientsAi.map((rec: any) => rec.index)))
      dispatch(SET_NODES_DATA(nodes_data))
      dispatch(SET_SETUP_METHOD(setup_method))
      dispatch(SET_ARE_LEADS_FROM_GROUPS(prompt?.are_leads_from_groups || data?.[0]?.mode === 'groups' || false))
      dispatch(SET_BLOCK_EDITING(true))
      dispatch(SET_CAMPAIGN_STATUS(campaignSummaryData?.response?.status || ''))
      dispatch(SET_GENERATE_MESSAGES(false))
      dispatch(SET_IS_CAMPAIGN_RUNNING_ALREADY(isCampaignRan))
      dispatch(SET_CAMPAIGN_SETTINGS_AI(prompt?.campaign_settings_ai || {}))
      dispatch(SET_SKIP_PROMPT(prompt?.skip_prompt || false))
      dispatch(SET_AI_LEADS_TYPE(prompt?.ai_leads_type))
      dispatch(SET_USER_UPDATED_SEQUENCE(prompt?.user_updated_sequence))
      dispatch(SET_IS_EDIT_CAMPAIGN(true))
      if (custom_flow) {
        console.log('-===-custom_flow')
        console.log(custom_flow)
        console.log('-===-')
        const nodesFromSteps = custom_flow?.nodes?.map((node: any) => {
          const step_node = steps.filter((step: any) => step.step_id === node.id)
          if (step_node?.length > 0) {
            const nd = step_node[0]
            const message_id = nd.message_id
            const data = nd.data
            if (!data) {
              return node
            }
            return {
              ...node,
              data: { ...node.data, ...data, messages: node?.data?.messages || data.messages, message_id: message_id },
            }
          }
          return node
        })

        const newData = { nodes: nodesFromSteps, edges: custom_flow.edges }
        dispatch(SET_USE_CUSTOM_FLOW(true))
        dispatch(SET_BUILDER_DATA(newData))
        const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(
          newData.nodes,
          newData.edges,
          isCampaignRan ? nonEditableSteps : false,
        )
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)
        const allowEdit = status === 'draft' || campaignStatus === 'draft' || campaignStatus === 'awaiting'
        dispatch(SET_BUILDER_NODES(allowEdit ? newData.nodes : layoutedNodes))
        dispatch(SET_BUILDER_EDGES(allowEdit ? newData.edges : layoutedEdges))
      } else if (prompt?.select_custom_template) {
        dispatch(SET_SELECT_CUSTOM_TEMPLATE(prompt?.select_custom_template))
        const template = prompt?.select_custom_template?.template
        const nodesFromSteps = template?.nodes?.map((node: any) => {
          const step_node = steps.filter((step: any) => step.step_id === node.id)
          if (step_node?.length > 0) {
            const nd = step_node[0]
            const message_id = nd.message_id
            const data = nd.data
            if (!data) {
              return node
            }
            return {
              ...node,
              data: { ...node.data, ...data, messages: node?.data?.messages || data.messages, message_id: message_id },
            }
          }
          return node
        })
        const updatedNodes = replaceMessageIds(nodesFromSteps)
        const edges = template.edges
        const { filteredNodes, filteredEdges } = isCampaignRan
          ? filterPlaceholderNodesAndEdges(updatedNodes, edges, isCampaignRan ? nonEditableSteps : false)
          : { filteredNodes: updatedNodes, filteredEdges: edges }
        dispatch(SET_BUILDER_DATA({ nodes: filteredNodes, edges: filteredEdges }))
        dispatch(SET_BUILDER_NODES(filteredNodes))
        dispatch(SET_BUILDER_EDGES(filteredEdges))
        dispatch(SET_USE_CUSTOM_FLOW(true))
      }
      console.log('add leads', addLeads)
      console.log('collpase key', collapseKey)

      if (addLeads && (custom_flow || prompt?.select_custom_template || nodes_data?.length > 0)) {
        dispatch(SET_CURRENT_COLLAPSE_KEY('2'))
      } else {
        dispatch(SET_CURRENT_COLLAPSE_KEY(collapseKey))
      }
      if (templates && default_templates?.length === 0) {
        const temps = templates?.filter((temp: any) => temp?.type === 'default')
        dispatch(SET_DEFAULT_TEMPLATES(temps))
      }
    }
  }

  const handleArchiveCampaignModal = (
    item: any,
    setCampaignName: (name: string) => void,
    setCampaignId: (id: string) => void,
    setOpenArchiveCampaignModal: (open: boolean) => void,
  ) => {
    setCampaignName(item.campaign_name)
    setCampaignId(item.campaign_id)
    setOpenArchiveCampaignModal(true)
  }

  const handleArchiveCampaign = async (
    campaignId: string,
    campaignName: string,
    setOpenArchiveCampaignModal: (open: boolean) => void,
    setTriggerGetCampaigns: any,
  ) => {
    try {
      await refetchArchiveCampaign({ campaign_ids: [campaignId], archive: true })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      setOpenArchiveCampaignModal(false)
      message.success(`${campaignName} Archived Successfully!`)
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleUnarchiveCampaign = async (item: any, setTriggerGetCampaigns: any) => {
    try {
      await refetchUnarchiveCampaign({ campaign_ids: [item.campaign_id], archive: false })
      setTriggerGetCampaigns((prev: boolean) => !prev)

      message.success(`${item.campaign_name} Unarchived Successfully!`)
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleCampaignActiveStatus = async (
    checked: boolean,
    campaign_name: any,
    campaign_id: any,
    setCampaignName: (name: string) => void,
    setCampaignId: (id: string) => void,
    setOpenDisableCampaignModal: (open: boolean) => void,
    setCampaignLoading: (loading: any) => void,
    setCampaignStatuses: (statuses: any) => void,
    setCampaignStates?: (states: any) => void,
  ) => {
    if (!checked) {
      setCampaignName(campaign_name)
      setCampaignId(campaign_id)
      setOpenDisableCampaignModal(true)
    } else {
      setCampaignLoading((prevStates: any) => ({
        ...prevStates,
        [campaign_id]: true,
      }))
      await refetchSetCampaignsActive({ campaign_ids: [campaign_id], is_active: checked, status: 'running' })
    }
  }

  const handleConfirmation = async (
    campaignId: string,
    setOpenDisableCampaignModal: (open: boolean) => void,
    setCampaignStates: (states: any) => void,
    setCampaignLoading: (loading: any) => void,
    setCampaignStatuses: (statuses: any) => void,
  ) => {
    setOpenDisableCampaignModal(false)
    setCampaignLoading &&
      setCampaignLoading((prevStates: any) => ({
        ...prevStates,
        [campaignId]: true,
      }))
    await refetchSetCampaignsActive({ is_active: false, campaign_ids: [campaignId], status: 'paused' })
    setTimeout(() => {
      setCampaignStates((prevStates: any) => ({
        ...prevStates,
        [campaignId]: false,
      }))
      setCampaignStatuses &&
        setCampaignStatuses((prevStates: any) => ({
          ...prevStates,
          [campaignId]: 'paused',
        }))
      setCampaignLoading &&
        setCampaignLoading((prevStates: any) => ({
          ...prevStates,
          [campaignId]: false,
        }))
    }, 2000)
  }

  const filterDuplicates = (data: any[]): any[] => {
    const uniqueEntries: { [key: string]: any } = {}

    data.forEach((entry) => {
      const key = entry?.user_telegram_id
      const entryTime =
        entry?.send_type === 'web'
          ? new Date(entry?.created_at).getTime() + 8 * 60 * 60 * 1000
          : new Date(entry?.created_at).getTime()

      if (!uniqueEntries[key] || new Date(uniqueEntries[key]?.created_at).getTime() < entryTime) {
        uniqueEntries[key] = entry
      }
    })

    return Object.values(uniqueEntries)
  }

  const useCountdowns = (cooldowns: any[]) => {
    const [countdowns, setCountdowns] = useState<number[]>([])

    useEffect(() => {
      const updateCountdowns = () => {
        const now = new Date()
        const result = filterDuplicates(cooldowns)
        const newCountdowns = result
          .map((cooldown) => {
            const targetTime = new Date(cooldown.created_at)
            const cooldownTime = cooldown.send_type === 'web' ? 8 : 24
            targetTime.setHours(targetTime.getHours() + cooldownTime)
            const difference = targetTime.getTime() - now.getTime()
            return Math.floor(difference / 1000)
          })
          .filter((countdown) => countdown > 0)
        setCountdowns(newCountdowns)
      }

      updateCountdowns()

      const intervalId = setInterval(updateCountdowns, 1000)

      return () => clearInterval(intervalId)
    }, [cooldowns])

    return countdowns
  }

  // Bulk functions
  const handleBulkArchiveCampaign = async (campaignIds: string[], setTriggerGetCampaigns: any) => {
    try {
      await refetchArchiveCampaign({ campaign_ids: campaignIds, archive: true })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      message.success(`Campaigns Archived Successfully!`)
      return true
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleBulkUnarchiveCampaign = async (campaignIds: string[], setTriggerGetCampaigns: any) => {
    try {
      await refetchUnarchiveCampaign({ campaign_ids: campaignIds, archive: false })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      message.success(`Campaigns Unarchived Successfully!`)
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleBulkCampaignActiveStatus = async (
    campaignIds: string[],
    isActive: boolean,
    status: string,
    setCampaignLoading: (loading: any) => void,
    setTriggerGetCampaigns: any,
  ) => {
    setCampaignLoading((prevStates: any) =>
      campaignIds.reduce(
        (acc, id) => {
          acc[id] = true
          return acc
        },
        { ...prevStates },
      ),
    )

    try {
      await refetchSetCampaignsActive({ campaign_ids: campaignIds, is_active: isActive, status })
      setCampaignLoading((prevStates: any) =>
        campaignIds.reduce(
          (acc, id) => {
            acc[id] = false
            return acc
          },
          { ...prevStates },
        ),
      )
      setTriggerGetCampaigns((prev: boolean) => !prev)
      message.success(`Campaigns ${isActive ? 'Activated' : 'Deactivated'} Successfully!`)
    } catch (e) {
      setCampaignLoading((prevStates: any) =>
        campaignIds.reduce(
          (acc, id) => {
            acc[id] = false
            return acc
          },
          { ...prevStates },
        ),
      )
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleBulkConfirmation = async (
    campaignIds: string[],
    setCampaignLoading: (loading: any) => void,
    setCampaignStates: (states: any) => void,
    setCampaignStatuses: (statuses: any) => void,
  ) => {
    setCampaignLoading((prevStates: any) =>
      campaignIds.reduce(
        (acc, id) => {
          acc[id] = true
          return acc
        },
        { ...prevStates },
      ),
    )

    try {
      await refetchSetCampaignsActive({ is_active: false, campaign_ids: campaignIds, status: 'paused' })
      setTimeout(() => {
        setCampaignStates((prevStates: any) =>
          campaignIds.reduce(
            (acc, id) => {
              acc[id] = false
              return acc
            },
            { ...prevStates },
          ),
        )
        setCampaignStatuses((prevStates: any) =>
          campaignIds.reduce(
            (acc, id) => {
              acc[id] = 'paused'
              return acc
            },
            { ...prevStates },
          ),
        )
        setCampaignLoading((prevStates: any) =>
          campaignIds.reduce(
            (acc, id) => {
              acc[id] = false
              return acc
            },
            { ...prevStates },
          ),
        )
      }, 2000)
    } catch (e) {
      setCampaignLoading((prevStates: any) =>
        campaignIds.reduce(
          (acc, id) => {
            acc[id] = false
            return acc
          },
          { ...prevStates },
        ),
      )
      message.error('Something went wrong. Please try again.')
    }
  }

  const isActive = (schedule: { timeZone: string; timeRanges: { [key: string]: string[] } }): boolean => {
    const now = moment().tz(schedule.timeZone)
    const dayOfWeek = now.format('dddd')
    const currentTime = now.format('HHmm')

    const activeToday = schedule.timeRanges[dayOfWeek]
    if (!activeToday) return false
    const startTime = activeToday[0] + activeToday[1]
    const endTime = activeToday[2] + activeToday[3]

    return currentTime >= startTime && currentTime <= endTime
  }

  const getNextActiveMoment = (schedule: { timeZone: string; timeRanges: any }): moment.Moment => {
    const timeZone = schedule?.timeZone
    const now = moment().tz(timeZone)
    let nextActiveMoment = moment.tz('2100-01-01', timeZone) // Future date as a fallback

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    // Find today's index and rearrange the array so today is first
    const todayIndex = now.day()
    const orderedDays = [...daysOfWeek.slice(todayIndex), ...daysOfWeek.slice(0, todayIndex)]

    for (const day of orderedDays) {
      const times = schedule.timeRanges[day]
      if (!times) continue // Skip days without a schedule
      const [startHour, startMinute] = times
      const startTime = now
        .clone()
        .add(orderedDays.indexOf(day), 'days')
        .hour(parseInt(startHour, 10))
        .minute(parseInt(startMinute, 10))
        .second(0)

      // If we've found a day in the future or today but later than now, return its start time
      if (startTime.isAfter(now) && startTime.isBefore(nextActiveMoment)) {
        nextActiveMoment = startTime
        break
      }
    }

    // Adjust for the scenario where the next active time is next week
    if (nextActiveMoment.year() === 2100 && schedule?.timeRanges[daysOfWeek[todayIndex]]) {
      const [startHour, startMinute] = schedule?.timeRanges[daysOfWeek[todayIndex]]

      nextActiveMoment = now
        .clone()
        .day(daysOfWeek[todayIndex])
        .add(1, 'weeks')
        .hour(parseInt(startHour, 10))
        .minute(parseInt(startMinute, 10))
        .second(0)
    }

    return nextActiveMoment
  }

  useEffect(() => {
    if (campaigns) {
      const newInactiveCampaigns: InactiveCampaignInfo[] = campaigns.reduce<InactiveCampaignInfo[]>((acc, campaign) => {
        if (campaign.status !== 'running') {
          return acc
        }
        const schedule = JSON.parse(campaign.run_at) as {
          timeZone: string
          timeRanges: { [day: string]: string[] }
        }
        if (schedule && !isActive(schedule)) {
          let nextActiveMoment = getNextActiveMoment(schedule)
          acc.push({
            campaign_name: campaign.campaign_name,
            nextActiveMoment: nextActiveMoment,
          })
        }
        return acc
      }, [])

      setInactiveCampaigns(newInactiveCampaigns)
    }
  }, [campaigns])

  const getNextActiveTime = (campaign: Campaign): moment.Moment | null => {
    if (campaign.status !== 'running') return null
    const schedule = JSON.parse(campaign.run_at) as {
      timeZone: string
      timeRanges: { [day: string]: string[] }
    }
    if (schedule && !isActive(schedule)) {
      return getNextActiveMoment(schedule)
    }
    return null
  }

  return {
    getCampaignSummaryView,
    handleArchiveCampaignModal,
    handleArchiveCampaign,
    handleUnarchiveCampaign,
    handleCampaignActiveStatus,
    handleConfirmation,
    handleBulkArchiveCampaign,
    handleBulkUnarchiveCampaign,
    handleBulkCampaignActiveStatus,
    handleBulkConfirmation,
    useCountdowns,
    inactiveCampaigns,
    getNextActiveTime,
  }
}
