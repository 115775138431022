import { QuestionOutlined } from '@ant-design/icons'
import { Dispatch } from '@reduxjs/toolkit'
import { FloatButton } from 'antd'
import LearningCenter from 'common/components/GeneralComponents/LearningCenter/LearningCenter'
import { SET_LEARNING_OPEN } from 'common/components/GeneralComponents/LearningCenter/state/slice/learningSlice'
import NotificationStrip from 'common/components/GeneralComponents/Notifications/NotificationStrip'

export const getRequireReloginAccounts = (localUser: any) => {
  const facebookAccounts = localUser?.facebook || []
  const instagramAccounts = localUser?.instagram || []
  const linkedinAccounts = localUser?.linkedin || []

  const platforms = [
    { platform: 'facebook', accounts: facebookAccounts },
    { platform: 'instagram', accounts: instagramAccounts },
    { platform: 'linkedin', accounts: linkedinAccounts },
  ]

  return platforms.reduce((acc, { platform, accounts }) => {
    const requireRelogin = accounts
      .filter((acc: any) => acc.require_relogin)
      .map((acc: any) => ({
        username: platform === 'linkedin' ? acc.email : acc.username,
        platform,
      }))
    return {
      ...acc,
      [platform]: requireRelogin,
    }
  }, {})
}

export const PayWall = (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      fontSize: '20px',
      zIndex: 13,
    }}>
    <p style={{ paddingLeft: '80px' }}>Coming soon...</p>
  </div>
)

export const shouldDisplayPayWall = (userData: any, pathname: string) => {
  if (!userData?.addons?.unified_inbox && pathname.includes('unifiedInbox')) {
    return PayWall
  }

  if (
    userData?.subscription_type === 'BETA' &&
    pathname !== '/login' &&
    pathname !== '/signup' &&
    !pathname.startsWith('/reset-password') &&
    !pathname.startsWith('/premium-signup/') &&
    !pathname.includes('checkout') &&
    !pathname.includes('upgrade') &&
    !pathname.includes('settings') &&
    !pathname.includes('outreach') &&
    !pathname.includes('unifiedInbox') &&
    !pathname.includes('onboarding')
  ) {
    return PayWall
  }

  if (pathname.includes('tasks') || pathname.includes('home')) {
    return PayWall
  }

  return null
}

export const renderNotifications = (platform: any, accounts: any) => {
  return accounts.map(({ username, platform: plt }: any) => (
    <NotificationStrip
      key={username}
      username={username}
      platform={platform}
      actionText={`Login to ${username}`}
      modalType='relogin'
      type='warning'
      data={{ username, platform: plt }}
      message=''
    />
  ))
}

export const renderPlatformNotifications = (platform: string, accounts: any) => {
  return accounts.map(({ username, platform: plt }: any) => (
    <NotificationStrip
      key={username}
      username={username}
      platform={platform}
      actionText={`Login to ${username}`}
      modalType='relogin'
      type='warning'
      data={{ username, platform: plt }}
      message=''
    />
  ))
}

export const renderCustomNotifications = ({
  showSidebar,
  isResolutionBad,
  isSafari,
  isMobile,
  facebookUsersWithMissingPin,
  requireReloginAccounts,
  user,
  outOfOutreach,
}: {
  showSidebar: boolean
  isResolutionBad: boolean
  isSafari: boolean
  isMobile: boolean
  facebookUsersWithMissingPin: any[]
  requireReloginAccounts: any
  user: any
  outOfOutreach: boolean
}) => {
  // const pathname = window.location.pathname
  const notifications = []

  // if (showSidebar && isResolutionBad && !isMobile && !pathname.startsWith('/upgrade')) {
  //   notifications.push(
  //     <NotificationStrip
  //       key='zoomout'
  //       username='zoomout'
  //       platform=''
  //       message='Recommendation: For the best experience, we recommend zooming out to 80%.'
  //       type='info'
  //       style={{ background: 'rgb(25,188,254, .1)' }}
  //     />,
  //   )
  // }

  // if (showSidebar && isSafari && !isMobile && !pathname.startsWith('/upgrade')) {
  //   notifications.push(
  //     <NotificationStrip
  //       key='chrome'
  //       username='chrome'
  //       platform=''
  //       message='Recommendation: for the best user experience, we recommend using Google Chrome.'
  //       type='info'
  //       style={{ background: 'rgb(25,188,254, .1)' }}
  //     />,
  //   )
  // }

  if (showSidebar && user && facebookUsersWithMissingPin.length > 0) {
    facebookUsersWithMissingPin.forEach((fbUser) =>
      notifications.push(
        <NotificationStrip
          key={fbUser.id}
          username={fbUser.username}
          platform=''
          message={`Your Facebook account ${fbUser.username} PIN-code is not configured, please add it.`}
          actionText='Setup your PIN-code'
          modalType='pinCode'
          type='warning'
          data={fbUser.username}
        />,
      ),
    )
  }

  const platforms = ['instagram', 'facebook', 'linkedin']
  platforms.forEach((platform) => {
    if (showSidebar && user && requireReloginAccounts[platform]?.length > 0) {
      notifications.push(
        ...renderPlatformNotifications(
          platform.charAt(0).toUpperCase() + platform.slice(1),
          requireReloginAccounts[platform],
        ),
      )
    }
  })

  if (showSidebar && user && outOfOutreach) {
    notifications.push(
      <NotificationStrip
        key='outOfOutreach'
        username=''
        platform=''
        message="Your campaigns have stopped sending to new contacts because you've reached the send rate limit for this billing cycle. Upgrade now to resume sending."
        actionText='Resume Now'
        modalType='upgrade'
        type='warning'
        data={user.username}
      />,
    )
  }

  return notifications
}

export const renderLearningCenter = ({
  userData,
  notForLearning,
  locationPathName,
  forcePricing,
  floatVisible,
  dispatch,
}: {
  userData: any
  notForLearning: string[]
  locationPathName: string
  forcePricing: boolean
  floatVisible: boolean
  dispatch: Dispatch<any>
}) => {
  if (userData?.addons?.learning_center && !notForLearning.includes(locationPathName) && !forcePricing) {
    return (
      <>
        <FloatButton
          shape='square'
          type='primary'
          style={{
            borderRadius: 50,
            display: !floatVisible ? 'none' : 'block',
          }}
          icon={<QuestionOutlined />}
          className='outreachHelpFloater'
          onClick={() => {
            dispatch(SET_LEARNING_OPEN(true))
          }}
        />
        <LearningCenter />
      </>
    )
  }
  return null
}
