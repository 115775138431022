import React, { useEffect, useState } from 'react'
import { Button, Divider, Dropdown, Input, InputNumber, Layout, List, message, Modal, Select, Tag, Tooltip } from 'antd'
import styles from './GroupsFilters.module.scss'
import { EllipsisOutlined, InfoCircleOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons'
import crown from 'common/assets/svg/crown_icon.svg'
import { Collapse } from 'antd'
import { useLazyDeleteFilterQuery, useLazySaveFilterQuery } from 'common/APIs/OutreachAI-API'
import AreYouSureModal from 'common/components/GeneralComponents/Modals/AreYouSureModal'

const { Sider } = Layout
const { Option } = Select
const { Panel } = Collapse

const siderStyle: React.CSSProperties = {
  border: '1px solid #dfe1e6',
  borderRadius: '10px',
  background: 'white',
  padding: '15px',
  overflowX: 'hidden',
  overflowY: 'auto',
  // maxHeight: '484px',
}
const selectStyle: any = {
  textAlign: 'left',
  marginBottom: '10px',
  marginTop: '10px',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.001em',
  width: '100%',
}

interface GroupsFiltersProps {
  onFilterChange: ({ keywords, operator, conditionedKeywords }: any) => any
  selectedPlatform: any
  groupType?: string
  smallerThan: number | undefined
  setSmallerThan: React.Dispatch<React.SetStateAction<number | undefined>>
  greaterThan: number | undefined
  setGreaterThan: React.Dispatch<React.SetStateAction<number | undefined>>
  data: any
  tagsSelected: any
  setTagsSelected: any
  savedFilters: any
  getSavedFilters: any
  className?: any
  maxHeight: string
}

const GroupsFilters: React.FC<GroupsFiltersProps> = ({
  onFilterChange,
  selectedPlatform,
  groupType,
  smallerThan,
  setSmallerThan,
  greaterThan,
  setGreaterThan,
  data,
  tagsSelected,
  setTagsSelected,
  savedFilters,
  getSavedFilters,
  className = '',
  maxHeight,
}) => {
  const [keywords, setKeywords] = useState<string[]>([])
  const [operator, setOperator] = useState('AND')
  const [conditionedKeywords, setConditionedKeywords] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const [conditionedInputValue, setConditionedInputValue] = useState('')
  const [newFilterModalOpen, setNewFilterModalOpen] = useState(false)
  const [newFilterName, setNewFilterName] = useState('')
  const [isLoadingNewFilter, setIsLoadingNewFilter] = useState(false)
  const [saveFilterQuery] = useLazySaveFilterQuery()
  const [deleteFilterQuery] = useLazyDeleteFilterQuery()
  const [selectedFilterId, setSelectedFilterId] = useState<number | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [toDeleteFilter, setToDeleteFilter] = useState<any>(null)
  // const [maxHeight, setMaxHeight] = useState('')
  const handleAddKeyword = () => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue && !keywords.includes(trimmedValue)) {
      setKeywords((prevKeywords) => [...prevKeywords, trimmedValue])
      setInputValue('')
    }
  }

  const handleAddConditionedKeyword = () => {
    const trimmedValue = conditionedInputValue.trim()
    if (trimmedValue && !conditionedKeywords.includes(trimmedValue)) {
      setConditionedKeywords((prevExcludeKeywords) => [...prevExcludeKeywords, trimmedValue])
      setConditionedInputValue('')
    }
  }

  const handleResetKeywords = () => {
    setKeywords([])
  }

  const handleResetConditionedKeywords = () => {
    setConditionedKeywords([])
  }

  const handleSmallerThanChange = (value: number | null) => {
    setSmallerThan(value || undefined)
  }

  const handleGreaterThanChange = (value: number | null) => {
    setGreaterThan(value || undefined)
  }
  const saveFilter = async () => {
    setIsLoadingNewFilter(true)
    await saveFilterQuery({
      name: newFilterName,
      filter: {
        keywords: keywords,
        operator: operator,
        conditionedKeywords: conditionedKeywords,
        smallerThan: smallerThan,
        greaterThan: greaterThan,
        tagsSelected: tagsSelected,
        platform: selectedPlatform,
      },
    })
    await getSavedFilters()
    setIsLoadingNewFilter(false)
    setNewFilterModalOpen(false)
  }
  const getOptions = (data: any) => {
    // get all the unique values of the persons category
    const unique: any = []
    for (let i = 0; i < data?.length; i++) {
      if (!unique.includes(data[i]?.category)) {
        unique.push(data[i]?.category)
      }
    }
    const options = unique
      .map((category: string) => {
        return { label: category, value: category }
      })
      .filter(
        (option: any) => option.label !== '' && option.label !== 'undefined' && option.label !== null && option.label,
      )
    return options
  }
  // Function to handle deletion with confirmation modal
  const handleDelete = async (item: any) => {
    setDeleteModalOpen(true)
    setToDeleteFilter(item)
  }
  const onDelete = async (item: any) => {
    try {
      await deleteFilterQuery({ id: item.id }).unwrap()
      await getSavedFilters() // Fetch saved filters after deleting one
      resetFilters()
      message.success('Filter removed successfully!')
      setDeleteModalOpen(false)
    } catch (error) {
      message.error('Failed to remove filter. Please try again.')
      setDeleteModalOpen(false)
    }
  }
  const getMenuItems = (item: any) => [
    {
      key: 'delete',
      label: 'Remove Filter',
      onClick: () => handleDelete(item),
    },
  ]
  const handleOnSelect = (filter: any) => {
    setSelectedFilterId(filter.id)
    const { filter: item } = filter
    setKeywords(item.keywords || [])
    setOperator(item.operator || 'AND')
    setConditionedKeywords(item.conditionedKeywords || [])
    setSmallerThan(item.smallerThan)
    setGreaterThan(item.greaterThan)
    setTagsSelected(item.tagsSelected || [])
  }

  const resetFilters = () => {
    setKeywords([])
    setOperator('AND')
    setConditionedKeywords([])
    setSmallerThan(0)
    setGreaterThan(1_000_000)
    setTagsSelected([])
  }
  const handleOnReset = () => {
    resetFilters()
    setSelectedFilterId(null) // Reset the selected filter
  }

  useEffect(() => {
    onFilterChange({ keywords, operator, conditionedKeywords })
  }, [keywords, operator, conditionedKeywords, smallerThan, greaterThan, tagsSelected])

  return (
    <Sider style={{ ...siderStyle, maxHeight }} width={250} className={`${className} filterGroupsSider`}>
      <>
        <Modal
          open={newFilterModalOpen}
          onOk={saveFilter}
          centered
          onCancel={() => setNewFilterModalOpen(false)}
          okText='OK'
          cancelText='Cancel'
          confirmLoading={isLoadingNewFilter} // Add loading state to the save button
          className='newFilterModal'
          bodyStyle={{ width: '100%' }}
          footer={
            <div style={{ marginTop: 5 }}>
              <Button
                className={styles.cancelButton}
                onClick={() => setNewFilterModalOpen(false)}
                style={{ marginRight: 2 }}>
                Cancel
              </Button>
              <Button type='primary' className={styles.okButton} onClick={saveFilter} loading={isLoadingNewFilter}>
                OK
              </Button>
            </div>
          }
          title={<div className='newFilterTitle'>Save New Filter</div>}>
          <Input
            placeholder='Enter filter name'
            className='newFIlterInput'
            value={newFilterName}
            onChange={(e) => setNewFilterName(e.target.value)}
          />
        </Modal>
        <AreYouSureModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={() => onDelete(toDeleteFilter)}
          title='Are you sure you want to remove this filter?'
          message='This action cannot be undone.'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}>
          <Collapse
            className='FilterCollapse'
            ghost
            defaultActiveKey={['qualify']}
            style={{ width: '100%', border: 'none' }}>
            <div style={{ position: 'sticky', top: '10px', left: '-10px', zIndex: '1000', padding: '0px 0' }}>
              <div
                style={{
                  position: 'relative',
                  left: '-10px', // Offset container padding to start from the very left edge
                  right: '-10px', // Offset container padding to stretch to the right edge
                  top: '-30px',
                  backgroundColor: 'white',
                  padding: '0px 10px', // Internal padding for the sticky content
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <h1 style={{ fontSize: '16px' }}>Filters</h1>
                <div
                  onClick={handleOnReset}
                  style={{
                    fontSize: '12px',
                    color: '#7043ff',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}>
                  Reset Filters
                </div>
              </div>
            </div>

            <Divider style={{ padding: 0, margin: 0, marginBottom: 10 }} />
            <Panel
              key={'qualify'}
              header={<div style={{ height: 10 }}>Qualify</div>}
              style={{ marginLeft: 2, padding: 0, margin: 0 }}>
              <div className={styles.container}>
                <div className={styles.input}>
                  <Input
                    placeholder='Enter keywords'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={handleAddKeyword}
                    suffix={
                      keywords?.length > 0 && (
                        <UndoOutlined style={{ cursor: 'pointer' }} onClick={handleResetKeywords} />
                      )
                    }
                  />
                  {keywords?.length > 0 && (
                    <div className={styles.chips} style={{ marginTop: '16px' }}>
                      {keywords?.map((keyword, index) => (
                        <Tag
                          className={`${styles.tags} groupsFilterTags`}
                          key={keyword}
                          closable
                          onClose={() => setKeywords((prevKeywords) => prevKeywords.filter((k) => k !== keyword))}>
                          {keyword}
                        </Tag>
                      ))}
                    </div>
                  )}
                </div>
                <Select value={operator} onChange={setOperator} style={{ width: '100%' }}>
                  <Option value='AND'>AND</Option>
                  <Option value='AND NOT'>AND NOT</Option>
                </Select>
                <Input
                  placeholder='Enter keywords'
                  value={conditionedInputValue}
                  onChange={(e) => setConditionedInputValue(e.target.value)}
                  onPressEnter={handleAddConditionedKeyword}
                  suffix={
                    conditionedKeywords?.length > 0 && (
                      <UndoOutlined style={{ cursor: 'pointer' }} onClick={handleResetConditionedKeywords} />
                    )
                  }
                />
                <div className={styles.chips}>
                  {conditionedKeywords?.map((keyword, index) => (
                    <Tag
                      className={`${styles.tags} ${
                        operator === 'AND NOT' ? `${styles.redTags} groupsFilterRedTags` : 'groupsFilterTags'
                      }`}
                      key={keyword}
                      closable
                      onClose={() =>
                        setConditionedKeywords((prevExcludeKeywords) =>
                          prevExcludeKeywords.filter((k) => k !== keyword),
                        )
                      }>
                      {keyword}
                    </Tag>
                  ))}
                </div>
              </div>
            </Panel>
            <Divider style={{ padding: 0, margin: 0 }} />
            {selectedPlatform === 'instagram' && (
              <>
                <Panel header={'Followers'} key={'followers'} style={{}}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      marginLeft: 10,
                      padding: 5,
                    }}>
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center', padding: 5 }}>
                      <div style={{ fontSize: '12px', width: '35px' }}>MIN</div>
                      <InputNumber
                        controls={false}
                        width={10}
                        value={smallerThan}
                        defaultValue={smallerThan}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                        onChange={handleSmallerThanChange}
                      />
                    </div>
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center', padding: 5 }}>
                      <div style={{ fontSize: '12px', width: '35px' }}>MAX</div>
                      <InputNumber
                        controls={false}
                        width={100}
                        value={greaterThan}
                        defaultValue={greaterThan}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                        onChange={handleGreaterThanChange}
                      />
                    </div>
                  </div>
                </Panel>
                <Divider style={{ padding: 0, margin: 0 }} />

                <Panel header={'Category'} key={'category'} style={{ marginLeft: 2 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      width: '100%',
                    }}>
                    <Select
                      mode='multiple'
                      maxTagCount={0}
                      placeholder='Select Categories'
                      className={`onboardingICP`}
                      style={{ ...selectStyle, minWidth: '160px' }}
                      filterSort={(optionA, optionB) => {
                        const isSelectedA = tagsSelected?.includes(optionA.value)
                        const isSelectedB = tagsSelected?.includes(optionB.value)

                        // Selected items come first, then sort alphabetically among each group
                        if (isSelectedA && !isSelectedB) return -1
                        if (!isSelectedA && isSelectedB) return 1

                        // If both or neither are selected, sort alphabetically by label
                        const optionALabel: string = String(optionA?.label || '')
                        const optionBLabel: string = String(optionB?.label || '')
                        return optionALabel.localeCompare(optionBLabel)
                      }}
                      value={tagsSelected}
                      defaultValue={tagsSelected}
                      onChange={(value) => setTagsSelected(value)}
                      options={getOptions(data)}
                    />
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center', padding: 5 }}></div>
                  </div>
                </Panel>
              </>
            )}
          </Collapse>
        </div>

        {selectedPlatform === 'linkedin' && groupType === 'search_people_sales_nav' && (
          <>
            <Divider />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginLeft: 10,
                padding: 5,
              }}>
              <div style={{ marginBottom: 10 }} className={styles.advancedFilterTitle}>
                <span>Advanced Filters</span>
                <Tooltip title=''>
                  <img src={crown} alt='' />
                </Tooltip>
              </div>

              <div style={{ position: 'relative', width: '100%' }}>
                {/* Collapse component */}
                <Collapse
                  ghost
                  accordion
                  expandIconPosition='end'
                  style={{ width: '100%' }}
                  className='groupsFiltersCollapse'>
                  {['Company', 'Connection Level', 'Skills', 'Experience', 'Position']?.map((item) => (
                    <Panel
                      collapsible='disabled'
                      header={<span className={styles.filterTitle}>{item}</span>}
                      key={item} // Updated to unique key
                    ></Panel>
                  ))}
                </Collapse>

                {/* Overlay div */}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // fontSize: '18px',
                    // fontWeight: 'bold',
                    // color: '#333',
                  }}>
                  <div className={styles.innerOverlay}>
                    <p>Advanced Filters are not included in your plan</p>
                    <span style={{ color: '#5E6C84', fontSize: '12px' }}>
                      {' '}
                      This feature allows you to filter groups by Company, Connection Level, Skills, Experience, and
                      Position.
                    </span>
                    <Button type='primary' onClick={() => window.open(`${window.location.origin}/upgrade`)}>
                      Upgrade
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <>
          <Divider style={{ padding: 0 }} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Button
              type='dashed'
              icon={<PlusOutlined />}
              onClick={() => {
                setNewFilterModalOpen(true)
              }}>
              Save As Filter
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}>
            <div style={{ marginBottom: 20, overflow: 'auto' }} className={styles.filterTitle}>
              <Collapse ghost expandIconPosition='right' style={{ padding: 0, border: 'none', marginTop: 20 }}>
                <Collapse.Panel
                  header={`Saved Filters (${savedFilters?.length || 0})`}
                  key='1'
                  style={{ padding: 0 }}
                  className='filterSavedContent'>
                  <List
                    bordered={false}
                    dataSource={savedFilters}
                    style={{
                      backgroundColor: 'white',
                      padding: '5px',
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                    renderItem={(item: any) => (
                      <List.Item
                        onClick={() => handleOnSelect(item)}
                        className={`${styles['saved-filter-item']} ${
                          item.id === selectedFilterId ? styles['selected-item'] : ''
                        }`} // Apply selected style
                      >
                        <div
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            padding: '2px',
                            paddingLeft: 10,
                            paddingRight: 15,
                          }}>
                          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                            <Tooltip title={item.name}>
                              <span style={{ fontWeight: '400', fontSize: '12px' }}>
                                {item.name.length > 15 ? item.name.slice(0, 15) + '...' : item.name}
                              </span>
                            </Tooltip>
                            {selectedPlatform !== 'instagram' && item?.filter?.platform === 'instagram' && (
                              <div>
                                <Tooltip
                                  title={'Some filters cannot be applied here: (Number of followers, Lead Category)'}>
                                  <InfoCircleOutlined style={{ width: '10px', height: '10px' }} />
                                </Tooltip>
                              </div>
                            )}
                            <Dropdown
                              menu={{ items: getMenuItems(item) }}
                              trigger={['click']}
                              className={styles['more-options']}>
                              <EllipsisOutlined
                                style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to List.Item
                              />
                            </Dropdown>
                          </div>
                        </div>
                      </List.Item>
                    )}
                    className={styles['saved-filters-list']}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </>
      </>
    </Sider>
  )
}

export default GroupsFilters
