import React, { useEffect } from 'react'
import { Modal, Card, Tooltip } from 'antd'
// import { ReactComponent as AiIcon } from 'common/assets/svg/add_leads_ai.svg'
import { ReactComponent as CustomLeadsIcon } from 'common/assets/svg/add_leads_csv.svg'
import { ReactComponent as GroupIcon } from 'common/assets/svg/add_leads_group.svg'
import { ReactComponent as ListIcon } from 'common/assets/svg/List.svg'
import { ReactComponent as ConferencesIcon } from 'common/assets/svg/conferences_icon.svg'
import styles from './AddLeadsModal.module.scss'
import premium_badge from 'common/assets/svg/crown.svg'
import { useLazyGetUserInfoQuery } from 'common/APIs/UserApi'
import { useIntercom } from 'react-use-intercom'
import { useUser } from 'Utils/UserContext'

interface AddLeadsModalProps {
  open: boolean
  onClose: () => void
  onAi: () => void
  onAiCompanies: () => void
  onCsv: () => void
  onGroups: () => void
  onFilteredGroups: (type: string) => void
}

const AddLeadsModal: React.FC<AddLeadsModalProps> = ({
  open,
  onClose,
  onAi,
  onAiCompanies,
  onCsv,
  onGroups,
  onFilteredGroups,
}) => {
  // const { skip_prompt, saved_prompt_params, active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const { user } = useUser()
  const isWeb3User = user?.user_type === 'web3' // && user?.addons?.build_with_ai
  const addons = user?.addons
  const { groups_campaign } = addons
  const [getUserInfo] = useLazyGetUserInfoQuery()
  const { startTour } = useIntercom()
  // const platform = Object.keys(active_platforms).filter((platform) => active_platforms[platform])?.[0]

  // const promptSkipped =
  //   skip_prompt &&
  //   !Object.entries(saved_prompt_params).every(
  //     ([key, value]) => key === 'campaign_type' || (value !== null && value !== undefined && value !== ''),
  //   )

  // useEffect(() => {
  //   const getAllGroups = async () => {
  //     const { data } = await getExplore({})
  //     const { data: hub } = await getMyHub({})
  //     const merged = {
  //       ...data,
  //       ...hub,
  //     }
  //     dispatch(SET_INITIAL_GROUP_DATA(merged))
  //   }
  //   if (groups_campaign) {
  //     getAllGroups()
  //   }
  // }, [])

  useEffect(() => {
    const getUser = async () => {
      const { data } = await getUserInfo()
      if (!data?.hasLeads) {
        startTour(581449)
      }
    }
    getUser()
  }, [])

  // const aiDisabled = platform === 'facebook'
  // const aiCompaniesDisabled = false

  const csvDisabled = false
  const groupsDisabled = false

  return (
    <Modal
      centered
      title={<p style={{ fontSize: '20px' }}>Add Leads to Your Campaign</p>}
      open={open}
      onCancel={onClose}
      closable={false}
      //   closeIcon={<span className={styles.closeIcon}>Close</span>}
      footer={null}
      width={700}
      className={styles.addLeadsModal}>
      <span className={styles.closeIcon} onClick={onClose}>
        Close
      </span>
      <div className={styles.smallCardsContainer}>
        <Card
          hoverable
          onClick={onGroups}
          className={`addLeadsModal ${styles.card} ${groupsDisabled ? `${styles.disabled} ${styles.skip}` : ''} ${
            !groups_campaign ? styles.skipPremium : ''
          }`}>
          <GroupIcon className={styles.icon} />

          <h3>Leads from Social Hub</h3>
          <p>
            Add leads from thousands of{' '}
            {!isWeb3User
              ? 'Facebook and Instagram groups, pages, and communities'
              : 'Telegram lists, conferences, and groups'}{' '}
            directly to your campaign for targeted outreach.
          </p>
          {!groups_campaign && (
            <div className={styles.premium}>
              <Tooltip placement='top' title={'Not available in your plan'}>
                <img src={premium_badge} alt='Small' className={styles.premium_badge} />
              </Tooltip>
            </div>
          )}
        </Card>

        <Card
          hoverable
          onClick={onCsv}
          className={`addLeadsModal ${styles.card} ${csvDisabled && styles.disabled && styles.skip}`}>
          <CustomLeadsIcon className={styles.icon} />
          <h3>Import from CSV</h3>
          <p>Import your custom leads from CSV files to expand your prospect database or enrich your data.</p>
        </Card>
      </div>
      {isWeb3User ? (
        <div className={styles.smallCardsContainer}>
          <Card
            hoverable
            onClick={() => onFilteredGroups('Lists')}
            className={`addLeadsModal ${styles.card} ${groupsDisabled ? `${styles.disabled} ${styles.skip}` : ''} ${
              !groups_campaign ? styles.skipPremium : ''
            }`}>
            <ListIcon className={styles.icon} style={{ height: '80px', width: '80px' }} />
            <h3>Leads from Lists</h3>
            <p>Add leads from your own Lists directly to your campaign for targeted outreach.</p>
            {!groups_campaign && (
              <div className={styles.premium}>
                <Tooltip placement='top' title={'Not available in your plan'}>
                  <img src={premium_badge} alt='Small' className={styles.premium_badge} />
                </Tooltip>
              </div>
            )}
          </Card>
          <Card
            hoverable
            onClick={() => onFilteredGroups('Conferences')}
            className={`addLeadsModal ${styles.card} ${groupsDisabled ? `${styles.disabled} ${styles.skip}` : ''} ${
              !groups_campaign ? styles.skipPremium : ''
            }`}>
            <ConferencesIcon className={styles.icon} style={{ height: '80px', width: '80px' }} />
            <h3>Leads from Conferences</h3>
            <p>Add leads from thousands of Conferences directly to your campaign for targeted outreach.</p>
            {!groups_campaign && (
              <div className={styles.premium}>
                <Tooltip placement='top' title={'Not available in your plan'}>
                  <img src={premium_badge} alt='Small' className={styles.premium_badge} />
                </Tooltip>
              </div>
            )}
          </Card>
        </div>
      ) : (
        // <div className={styles.smallCardsContainer}>
        //   <Card
        //     hoverable
        //     onClick={onAi}
        //     className={`addLeadsModal ${styles.card} ${aiDisabled && styles.disabled} ${
        //       (promptSkipped || aiDisabled) && styles.skip
        //     }`}>
        //     <AiIcon className={styles.icon} />
        //     <div className={styles.cardContent}>
        //       <h3>Add Leads with Magic AI</h3>
        //       <p>Discover leads identified by our AI, tailored to your Ideal Customer Profile.</p>
        //     </div>
        //     {(promptSkipped || platform === 'facebook') && (
        //       <div className={styles.comingSoon}>
        //         <span>{platform !== 'facebook' ? 'Prompt Skipped' : 'Not Available'}</span>
        //       </div>
        //     )}
        //   </Card>

        //   <Card
        //     hoverable
        //     onClick={onAiCompanies}
        //     className={`addLeadsModal ${styles.card} ${aiCompaniesDisabled ? styles.disabled : ''} ${
        //       promptSkipped || aiCompaniesDisabled ? styles.skip : ''
        //     } ${!companies_ai_leads ? styles.skipPremium : ''}`}>
        //     {!companies_ai_leads && (
        //       <div className={styles.premium}>
        //         <img src={premium_badge} alt='' />
        //       </div>
        //     )}
        //     <AiIcon className={styles.icon} />
        //     <div className={styles.cardContent}>
        //       <h3>
        //         Add Businesses with Magic AI
        //         {/* <br />
        //       <br /> */}
        //       </h3>

        //       <p>Easily add matched business pages and start reaching out to them with just a click.</p>
        //     </div>
        //     {promptSkipped && (
        //       <div className={styles.comingSoon}>
        //         <span>Prompt Skipped</span>
        //       </div>
        //     )}
        //   </Card>
        // </div>
        <></>
      )}
    </Modal>
  )
}

export default AddLeadsModal
