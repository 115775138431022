import { Tooltip } from 'antd'

export const TagsList = ({ tags }: { tags: any[] }) => {
  const sortedTags = tags.sort((a, b) => a.length - b.length)

  const toShowTags = sortedTags.slice(0, 2)

  const toTooltipTags = sortedTags.slice(2)

  const TooltipContent = () => {
    return (
      <div className='tagsTooltip'>
        {toTooltipTags.length > 0 &&
          toTooltipTags.map((tag, index) => (
            <span className='tooltipContactTags' key={index}>
              {tag}
            </span>
          ))}
      </div>
    )
  }

  return (
    <>
      {toShowTags.map((tag, index) =>
        tag.length > 9 ? (
          <Tooltip title={tag} placement='top' key={index}>
            <span className='contactTags' key={index}>{`${tag.slice(0, 9)}...`}</span>
          </Tooltip>
        ) : (
          <span className='contactTags' key={index}>
            {tag}
          </span>
        ),
      )}
      {toTooltipTags.length > 0 && (
        <Tooltip
          overlayInnerStyle={{ minWidth: '400px', width: 'auto', paddingBottom: '10px' }}
          placement='topLeft'
          title={TooltipContent}>
          <span className='contactTagsNumber' key={'number'}>{`+${toTooltipTags.length}`}</span>
        </Tooltip>
      )}
    </>
  )
}
