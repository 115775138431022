import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Input, Layout, Modal, Space, message } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import classnames from 'classnames'
import add_to_list from 'LEGACY/Assets/svg/add_to_list.svg'
import styles from './AddToListModal.module.scss'
import { ContactsApi, useGetForCompaniesListMutation, useGetForContactsListMutation } from 'LEGACY/API/ContactsApi'
import { injectionRegex } from 'LEGACY/common/static-data/userData'
import { MESSAGE_ERROR } from 'LEGACY/common/constants/messages.constants'
import { useAppDispatch, useAppSelector } from 'state'
import {
  CompaniesApi,
  useLazyAddNewListQuery,
  useLazyGetListsQuery,
  useLazyUpdateListQuery,
} from 'LEGACY/API/CompaniesApi'
import OutOfCreditsModal from 'LEGACY/General/OutOfCredits/OutOfCreditsModal'

import { SET_LISTS } from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'
import LoadingBox from 'LEGACY/General/LoadingBox/LoadingBox'
import { SET_LISTS as SET_LISTS_EXPOSED } from 'LEGACY/MyContacts/state/slice/companiesSlice'

interface AddToListModalProps {
  open: boolean
  onClose: () => void
  isCompany?: boolean
}

const pageLookUp = {
  home: 'Home',
  events: 'Events',
  contacts: 'People',
  outreach: 'Outreach',
  companies: 'Research',
  settings: 'Profile Settings',
  company_admin: 'Company Admin Panel',
  exposedContacts: 'exposedContacts',
}

const AddToListModal: React.FC<AddToListModalProps> = ({ open, onClose, isCompany = false }) => {
  const locationPathName = (useLocation().pathname.split('/')[1] as keyof typeof pageLookUp) || 'home'
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [loading, setLoading] = useState(false)
  const { selected_contacts } = useAppSelector((state) => state.contacts)
  const { selected_companies } = useAppSelector((state) => state.companies)
  const { selected_contacts: selected_exposedContact } = useAppSelector((state) => state.myContacts)
  const { selected_companies: selected_exposedCompany } = useAppSelector((state) => state.myContactsCompanies)

  const [selectedList, setSelectedList] = useState<string>('')
  const [addToListInput, setAddToListInput] = useState(false)
  const { company_lists } = useAppSelector((state) => state.companies)
  const [refetchAddList] = useLazyAddNewListQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchGetForCompaniesList] = useGetForCompaniesListMutation()
  const [refetchGetForContactsList] = useGetForContactsListMutation()
  const [refetchUpdateList] = useLazyUpdateListQuery()
  const [form] = Form.useForm()
  const [textState, setTextState] = useState<any>('')
  const dispatch = useAppDispatch()

  const invalidateQueryGetContacts = () => {
    isCompany
      ? dispatch(CompaniesApi.util.invalidateTags(['getCompanies']))
      : dispatch(ContactsApi.util.invalidateTags(['getContactsByCompanyId']))
  }

  const handleAddItemsToListCompany = async (newList?: any) => {
    setLoading(true)
    const selectedListNew = newList?.id ? newList.id : selectedList
    const list = newList?.id ? [newList] : company_lists?.filter((item: any) => item.id === selectedListNew)

    if (list.length > 0) {
      let com_assignedItems = list[0].company_ids ? list[0].company_ids : ''

      if (locationPathName === 'exposedContacts') {
        selected_exposedCompany?.forEach((item) => {
          if (com_assignedItems.indexOf(item) === -1) com_assignedItems += item + ','
        })
      } else {
        selected_companies?.forEach((item) => {
          if (com_assignedItems.indexOf(item) === -1) com_assignedItems += item + ','
        })
      }

      await refetchUpdateList({
        id: selectedListNew,
        items_list: com_assignedItems,
        field_name: 'company_ids',
        page_type: 'company',
      })

      let ids = ''
      if (locationPathName === 'exposedContacts') {
        selected_exposedCompany?.forEach((item: any) => {
          if (item && ids.indexOf(item) === -1) {
            ids += item + ','
          }
        })
      } else {
        selected_companies?.forEach((item: any) => {
          if (item && ids.indexOf(item) === -1) {
            ids += item + ','
          }
        })
      }

      const result = await refetchGetForContactsList({
        company_ids: ids,
      })

      let assignedItems = list[0].contact_ids ? list[0].contact_ids : ''

      for (let index = 0; index < result?.data?.rows?.length; index++) {
        if (assignedItems.indexOf(result?.data?.rows[index]?.index) === -1)
          assignedItems += result?.data?.rows[index]?.index + ','
      }
      // let assignedItems: any = new Set(list[0].contact_ids ? list[0].contact_ids.split(',') : [])

      // result?.data?.rows?.forEach((row: any) => {
      //   assignedItems.add(row.index)
      // })

      // assignedItems = Array.from(assignedItems).join(',')

      await refetchUpdateList({ id: selectedListNew, items_list: assignedItems, field_name: 'contact_ids' })
    }
    invalidateQueryGetContacts()
    let getLists = await refetchLists()
    if (getLists) {
      const { data } = getLists
      dispatch(SET_LISTS(data))
      dispatch(SET_LISTS_EXPOSED(data))
    }
    setLoading(false)
    message.success(`Items added to the list successfully`)
    onClose()
  }

  const handleAddItemsToListContact = async (newList?: any) => {
    setLoading(true)

    const selectedListNew = newList?.id ? newList.id : selectedList
    const list = newList?.id ? [newList] : company_lists?.filter((item: any) => item.id === selectedListNew)

    if (list.length > 0) {
      let ids = ''
      locationPathName === 'exposedContacts'
        ? selected_exposedContact?.forEach((item: any) => {
            if (item && ids.indexOf(item) === -1) {
              ids += item + ','
            }
          })
        : selected_contacts?.forEach((item: any) => {
            if (item && ids.indexOf(item) === -1) {
              ids += item + ','
            }
          })

      const result = await refetchGetForCompaniesList({
        contact_ids: ids,
      })

      let assignedItems = list[0].company_ids ? list[0].company_ids : ''
      for (let index = 0; index < result?.data?.rows?.length; index++) {
        if (assignedItems.indexOf(result?.data?.rows[index]?.company_id) === -1)
          assignedItems += result?.data?.rows[index]?.company_id + ','
      }

      // let assignedItems: any = new Set(list[0].company_ids ? list[0].company_ids : [])

      // result?.data?.rows?.forEach((row: any) => {
      //   assignedItems.add(row.index?.company_id)
      // })

      // assignedItems = Array.from(assignedItems).join(',')

      await refetchUpdateList({ id: selectedListNew, items_list: assignedItems, field_name: 'company_ids' })
      let con_assignedItems = list[0].contact_ids ? list[0].contact_ids : ''
      locationPathName === 'exposedContacts'
        ? selected_exposedContact.forEach((item) => {
            if (con_assignedItems.indexOf(item) === -1) con_assignedItems += item + ','
          })
        : selected_contacts.forEach((item) => {
            if (con_assignedItems.indexOf(item) === -1) con_assignedItems += item + ','
          })

      await refetchUpdateList({ id: selectedListNew, items_list: con_assignedItems, field_name: 'contact_ids' })
    }
    invalidateQueryGetContacts()
    let getLists = await refetchLists()
    if (getLists) {
      const { data } = getLists
      dispatch(SET_LISTS(data))
      dispatch(SET_LISTS_EXPOSED(data))
    }
    setLoading(false)
    message.success(`Items added to the list successfully`)
    onClose()
  }

  const handleAddItemsToList = (list?: any) => {
    if (isCompany) {
      handleAddItemsToListCompany(list)
    } else {
      handleAddItemsToListContact(list)
    }
  }

  const handleSelectedItem = (id: any) => {
    if (selectedList === id) {
      setSelectedList('')
    } else {
      setSelectedList(id)
    }
  }

  const handleAddNewItem = async (event: any) => {
    try {
      setLoading(true)
      if (13 === event.keyCode || event?._reactName === 'onChange') {
        if (injectionRegex.test(event.target.value)) {
          message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
        } else {
          let checkItem = company_lists.find((item: any) => item.name === event.target.value)
          if (!checkItem) {
            let result = await refetchAddList({ name: event.target.value, type: isCompany ? 1 : 2 })
            if (result?.error?.status === 470) {
              setOutOfCredits(true)
            } else {
              if (result) {
                let getLists = result
                if (getLists) {
                  const { data } = getLists
                  dispatch(SET_LISTS(data))
                  dispatch(SET_LISTS_EXPOSED(data))
                }

                // message.success(`The list '${event.target.value}' was created successfully`)
                setAddToListInput(!addToListInput)
                handleSelectedItem(getLists.data[0].id)
                return getLists.data[0]
              }
            }
          } else {
            setLoading(false)
            return message.error(`You already have a list with the same name!`)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleCreateAndAdd = async (event: any) => {
    const list = await handleAddNewItem(event)

    if (list) {
      handleAddItemsToList(list)
    }
  }

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: '400px', maxWidth: '400px' }}>
        {loading && (
          <div className={styles.loadingOverlay}>
            <LoadingBox />
          </div>
        )}
        <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
          <Layout>
            <Header className={styles.headerStyle}>
              <img src={add_to_list} alt='' />
              <h1>Add to List</h1>
            </Header>
            <Content className={styles.contentStyle} style={{ borderBottom: '1px solid #ebecf0' }}>
              <div className={styles.createNew}>
                <Form
                  id='listForm'
                  className={styles.addNewContainer}
                  form={form}
                  onFinish={() => {
                    handleCreateAndAdd(textState)
                  }}>
                  <Input
                    form='listForm'
                    placeholder='Name your list...'
                    className='addNewInput'
                    onChange={(event: any) => {
                      setTextState(event)
                    }}
                  />
                  <div className={styles.rightButton}>
                    <Button
                      type='primary'
                      disabled={!textState?.target?.value}
                      form='listForm'
                      key='submit'
                      htmlType='submit'>
                      Create & Add
                    </Button>
                  </div>
                </Form>
              </div>
            </Content>
            <Content className={styles.contentStyle}>
              <>
                {company_lists?.map((item: any) => {
                  return (
                    <div
                      className={classnames(
                        styles.rowListItem,
                        selectedList === item.id ? [styles.selectedList, styles.noHover] : '',
                      )}
                      onClick={() => {
                        handleSelectedItem(item.id)
                      }}>
                      <span>{item.name}</span>
                    </div>
                  )
                })}
              </>
            </Content>

            <Footer className={styles.footerStyle}>
              <div className={styles.section}>
                <div className={styles.back} onClick={onClose}>
                  <span>Cancel</span>
                </div>

                <Button className={styles.button} disabled={!selectedList} onClick={handleAddItemsToList}>
                  Add to List
                </Button>
              </div>
            </Footer>
          </Layout>
        </Space>
      </Modal>

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
          type='limit'
        />
      )}
    </>
  )
}
export default AddToListModal
