import React, { useEffect, useRef, useState } from 'react'
import { Modal, Input, Form, Button, Statistic, Popover } from 'antd'
import styles from './SocialConnectionModal.module.scss'
import axios from 'axios'
import {
  useLazyInstagramGetAccountStatusQuery,
  useLazyInstagramLoginQuery,
  useLazyInstagramSendVerificationCodeQuery,
  useLazyLinkedinLoginQuery,
  useLazyLinkedinSendVerificationCodeQuery,
  useLazyLinkedinGetAccountStatusQuery,
  useLazyFacebookLoginQuery,
  useLazyFacebookSendVerificationCodeQuery,
  useLazyFacebookSendPINCodeQuery,
  useLazyFacebookGetAccountStatusQuery,
  useLazyGetSocialLogsQuery,
} from '../../APIs/SocialConnectionApi'
import facebook_pin_explanation from 'common/assets/png/facebook_pin_explanation.png'
import facebook_icon from 'common/assets/svg/facebook_logo.svg'
import telegram_icon from 'common/assets/svg/telegram_icon.svg'
import linkedin_icon from 'common/assets/svg/linkedin_logo.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import trophy_icon from 'common/assets/svg/trophy_icon.svg'
import { useLazyGetUserDetailsQuery } from 'common/APIs/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
import { InfoCircleOutlined } from '@ant-design/icons'
import InputOTP from 'common/components/GeneralComponents/UI/InputOTP'
import { useIntercom } from 'react-use-intercom'
import { ERRORS, STATUSES } from './SocialConfig'
// import FlagInput from '../OutreachConnection/FlagInput'
import { trackEvent, SOCIAL_MAPPER, SOCIAL_VERIFICATION_MAPPER } from 'eventConfig'
import CountrySelect from './CountrySelect'
import RotatingText from './RotatingText'

const { Countdown } = Statistic

interface SocialConnectionModalProps {
  open: boolean
  onClose: () => void
  platform: 'instagram' | 'facebook' | 'linkedin'
  setCheckLocalStorage?: any
  step?: string
  selectedUsername?: any
}

const platformConfigs: any = {
  instagram: {
    icon: instagram_icon,
    loginQuery: useLazyInstagramLoginQuery,
    verificationQuery: useLazyInstagramSendVerificationCodeQuery,
    accountStatusQuery: useLazyInstagramGetAccountStatusQuery,
    // twoFAQuery: useLazyInstagramInstagrapiLoginQuery,
  },
  linkedin: {
    icon: linkedin_icon,
    loginQuery: useLazyLinkedinLoginQuery,
    verificationQuery: useLazyLinkedinSendVerificationCodeQuery,
    accountStatusQuery: useLazyLinkedinGetAccountStatusQuery,
    // twoFAQuery: useLazyLinkedinLoginQuery,
  },
  facebook: {
    icon: facebook_icon,
    loginQuery: useLazyFacebookLoginQuery,
    verificationQuery: useLazyFacebookSendVerificationCodeQuery,
    accountStatusQuery: useLazyFacebookGetAccountStatusQuery,
    // twoFAQuery: useLazyFacebookLoginQuery,
  },
  telegram: {
    icon: telegram_icon,
    loginQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    verificationQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    twoFAQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    accountStatusQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    // loginQuery: useLazyTelegramLoginQuery,
    // verificationQuery: useLazyTelegramSendVerificationCodeQuery,
  },
  discord: {
    icon: discord_icon,
  },
}

const SocialConnectionModal: React.FC<SocialConnectionModalProps> = ({
  open,
  onClose,
  platform,
  setCheckLocalStorage,
  step,
  selectedUsername,
}) => {
  const POLLING_INTERVAL = 5000
  const [form] = Form.useForm()
  const platformUpperCase = platform[0].toLocaleUpperCase() + platform.slice(1)
  const [currentStep, setCurrentStep] = useState<string>(step !== undefined ? step : 'initiating')
  const [prevStep, setPrevStep] = useState(step !== undefined ? step : 'incoming')
  const [failedSigninAttemps, setFailedSigninAttemps] = useLocalStorage<any>('failed_signin_attempt', null)
  const [user, setUser] = useLocalStorage<any>('user', null)
  const addons = user?.addons
  const total_number_of_accounts_connected = addons?.total_number_of_accounts_connected || 0
  const [numberOfConnectedAccounts, setNumberOfConnectedAccounts] = useState(total_number_of_accounts_connected)
  const [username, setUsername] = useState(selectedUsername !== undefined ? selectedUsername : '')
  const [email, setEmail] = useState('')
  // eslint-disable-next-line
  const [password, setPassword] = useState('')
  // const [carouselIndex, setCarouselIndex] = useState(0)
  const [disabledButton, setDisabledButton] = useState(false)
  // const carouselRef = useRef<any>()
  const inputRef = useRef<any>(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [PINCode, setPINCode] = useState('')
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [countryCode, setCountryCode] = useState('US')
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const [openPopover, setOpenPopover] = useState(false)
  const { icon, loginQuery, verificationQuery, accountStatusQuery } = platformConfigs[platform]
  const [login] = loginQuery()
  const [sendPINCode] = useLazyFacebookSendPINCodeQuery()
  const [sendVerificationCode] = verificationQuery()
  // const [twoFALogin] = twoFAQuery()
  const [getAccountStatus] = accountStatusQuery()
  const [nowDate, setNowDate] = useState(Date.now())
  const [error, setError] = useState('')
  const { boot, showNewMessage } = useIntercom()
  const [getSocialLogs] = useLazyGetSocialLogsQuery()
  const isPolling = useRef(false)
  const err = ERRORS?.[platform]?.[error] || ERRORS?.[platform]?.['general_error']
  const loadingSteps = ['loading', 'require_check_signedin_app', 'verify_on_app']

  const location = (() => {
    if (selectedUsername) {
      return step === 'pin_code_required' ? 'pin_code_required_strip' : 'relogin_strip'
    }

    if (window.location.pathname?.includes('settings')) return 'settings'
    if (window.location.pathname?.includes('onboarding')) return 'onboarding'

    return 'campaigns'
  })()

  const handleCancel = () => {
    isPolling.current = false
    onClose()
    setCurrentStep('initiating')
    form.resetFields()
  }

  const handleIntercom = () => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage(`I need help connecting my ${platformUpperCase} account!`)
  }

  const startPollingAccountStatus = async () => {
    isPolling.current = true
    const pollAccountStatus = async () => {
      if (!isPolling.current) return
      try {
        const statusResponse =
          platform === 'linkedin' ? await getAccountStatus({ email }) : await getAccountStatus({ username })

        const { status_code, updated_at } = statusResponse.data || {}

        if (status_code && prevStep !== status_code) {
          if (STATUSES.includes(status_code)) {
            if (status_code === 'verify_on_app' || status_code === 'require_check_signedin_app') {
              const serverDate =
                updated_at && !isNaN(new Date(updated_at).getTime()) ? new Date(updated_at) : new Date()

              const serverTimeUTC = serverDate.getTime()
              const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
              const adjustedNow = serverTimeUTC - timezoneOffset

              setNowDate(adjustedNow)
              setPrevStep(status_code)
            }
            if (SOCIAL_MAPPER.hasOwnProperty(status_code)) {
              trackEvent(SOCIAL_MAPPER[status_code], {
                latest_step: prevStep,
                platform: platform,
                status_code: status_code,
                type: SOCIAL_MAPPER[status_code],
                location: location,
                ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
              })
            }

            if (SOCIAL_VERIFICATION_MAPPER.hasOwnProperty(status_code)) {
              trackEvent('SOCIAL_ACCOUNT_LOGIN_VERIFICATION', {
                latest_step: prevStep,
                platform: platform,
                status_code: status_code,
                verification_type: status_code,
                location: location,
                ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
              })
            }

            return setCurrentStep(status_code)
          } else {
            trackEvent('ERROR_SCREEN_VIEWED', {
              latest_step: prevStep,
              platform: platform,
              status_code: status_code,
              error_type: status_code,
              location: location,
              ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
            })
            setError(status_code)
            return setCurrentStep('error')
          }
        } else if (statusResponse?.data?.id) {
          setUser(statusResponse.data)
          return setCurrentStep('active')
        } else {
          setTimeout(pollAccountStatus, POLLING_INTERVAL)
        }
      } catch (error) {
        console.log('Error fetching account status:', error)
      }
    }

    pollAccountStatus()
  }

  const handleLogin = async () => {
    setPrevStep(currentStep)
    switch (currentStep) {
      case 'active':
        const { data: userData } = await getUserDetails()
        if (userData?.id) {
          setUser(userData)
        }

        return onClose()

      case 'incoming':
        try {
          const values = await form.validateFields()
          setUsername(values?.username || '')
          setPassword(values?.password)
          setEmail(values?.email || '')
          setCurrentStep('loading')
          const { data, error: signInError } = await login({ ...values, country_code: countryCode, location })
          if (data?.status_code !== 'running' && signInError?.status_code !== 'running') {
            console.log('Login Error:', signInError)
            setError(data?.status_code)
            trackEvent('ERROR_SCREEN_VIEWED', {
              latest_step: prevStep,
              platform: platform,
              status_code: data?.status_code,
              error_type: data?.status_code,
              location: location,
              ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
            })
            return setCurrentStep('error')
          }

          trackEvent('ACCOUNT_DETAILS_SCREEN_EXITED', {
            latest_step: prevStep,
            platform: platform,
            status_code: data?.status_code,
            location: location,
            ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
          })
          break
        } catch (e) {
          return console.log('Error in incoming', e)
        }

      case 'require_authentication_code':
      case 'require_another_authentication_code':
      case 'require_2fa_code':
      case 'require_another_2fa_code':
        try {
          setCurrentStep('loading')
          if (platform === 'linkedin') {
            await sendVerificationCode({
              email: email,
              verificationCode: verificationCode,
            })
          } else {
            await sendVerificationCode({
              username: username,
              verificationCode: verificationCode,
            })
          }

          const event =
            currentStep === 'require_authentication_code'
              ? 'REQUIRE_AUTH_CODE_SCREEN_EXITED'
              : currentStep === 'require_another_authentication_code'
              ? 'REQUIRE_ANOTHER_AUTH_CODE_SCREEN_EXITED'
              : currentStep === 'require_2fa_code'
              ? 'REQUIRE_2FA_AUTH_CODE_SCREEN_EXITED'
              : 'REQUIRE_ANOTHER_2FA_AUTH_CODE_SCREEN_EXITED'

          trackEvent(event, {
            latest_step: prevStep,
            platform: platform,
            verificationCode: verificationCode,
            verification_type: currentStep,
            location: location,
            ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
          })
          break
        } catch (e) {
          return console.log('Error in require_authentication_code', e)
        }

      case 'require_register_phone':
        try {
          setCurrentStep('loading')
          if (platform === 'linkedin') {
            await sendVerificationCode({
              email: email,
              verificationCode: verificationCode,
            })
          } else {
            await sendVerificationCode({
              username: username,
              verificationCode: verificationCode,
            })
          }
          trackEvent('REQUIRE_REGISTER_PHONE_SCREEN_EXITED', {
            latest_step: prevStep,
            platform: platform,
            verificationCode: verificationCode,
            verification_type: currentStep,
            location: location,
            ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
          })
          break
        } catch (e) {
          return console.log('Error in require_register_phone', e)
        }

      case 'verify_on_app':
      case 'require_check_signedin_app':
        setCurrentStep('loading')
        break

      case 'pin_code_required':
        try {
          setCurrentStep('loading')
          await sendPINCode({ username: username, PINCode: PINCode })

          trackEvent('REQUIRE_PIN_CODE_SCREEN_EXITED', {
            latest_step: prevStep,
            platform: platform,
            PINCode: PINCode,
            verification_type: currentStep,
            location: location,
            ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
          })
          break
        } catch (e) {
          return console.log('Error in pin_code_required', e)
        }

      case 'authenticate_another_case':
        setCurrentStep('error')
        break

      // instagrapi - no longer in use.
      // case 'require_2fa_code':
      //   try {
      //     const { data } = await twoFALogin({
      //       username: username,
      //       password: password,
      //       country_code: countryCode,
      //       two_factor_code: verificationCode,
      //     })
      //     break
      //   } catch (e) {
      //     return console.log('Error in incoming', e)
      //   }
    }
  }

  useEffect(() => {
    if (loadingSteps?.includes(currentStep)) {
      // setTimeout(() => {
      //   startPollingAccountStatus()
      // }, 3000)
      const timeoutId = setTimeout(() => {
        startPollingAccountStatus()
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
        isPolling.current = false
      }
    } else if (currentStep === 'initiating') {
      const checkActiveAccounts = async () => {
        const { data } = await getSocialLogs({ platform })
        if (data?.length === 0) {
          // No active sessions, can initiate a new one
          setCurrentStep('incoming')
        } else {
          // getAccountStatus for the first object
          const username = data?.[0]?.social_username
          if (platform === 'linkedin') {
            setEmail(username)
          } else {
            setUsername(username)
          }
          setCurrentStep('loading')
        }
      }
      if (total_number_of_accounts_connected > numberOfConnectedAccounts) {
        checkActiveAccounts()
      }
    }
  }, [currentStep, numberOfConnectedAccounts])

  useEffect(() => {
    const fetchGeoInfo = async () => {
      try {
        const response = await axios.get('http://ip-api.com/json/')
        setCountryCode(response.data.countryCode)
        // setCountryCode('US')
      } catch (error) {
        console.error('Failed to fetch country code:', error)
      }
    }

    fetchGeoInfo()
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, verificationCode, PINCode])

  useEffect(() => {
    if (
      (currentStep === 'pin_code_required' && PINCode?.length < 6) ||
      ((currentStep === 'require_authentication_code' ||
        currentStep === 'require_another_authentication_code' ||
        currentStep === 'require_2fa_code' ||
        currentStep === 'require_another_2fa_code' ||
        currentStep === 'require_register_phone') &&
        verificationCode?.length < 6)
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [PINCode, verificationCode, currentStep])

  const handleCountdownFinish = () => {
    console.log('Expired')
    if (currentStep === 'verify_on_app' || currentStep === 'require_check_signedin_app') {
      setCurrentStep('require_authentication_code')
      setDisabledButton(false)
    }
  }

  const handleContactSupport = () => {
    window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
  }

  const handleUseDifferentMethod = async () => {
    setCurrentStep('loading')
    if (platform === 'linkedin') {
      await sendVerificationCode({
        email: email,
        verificationCode: 'switch_verification_method',
      })
    } else {
      await sendVerificationCode({
        username: username,
        verificationCode: 'switch_verification_method',
      })
    }
    trackEvent('SWITCH_VERIFICATION_METHOD', {
      latest_step: prevStep,
      platform: platform,
      verificationCode: 'switch_verification_method',
      verification_type: currentStep,
      location: location,
      ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
    })
  }

  useEffect(() => {
    const { facebook, instagram, linkedin } = user
    const length = facebook?.length + instagram?.length + linkedin?.length
    setNumberOfConnectedAccounts(length)
    if (total_number_of_accounts_connected <= length && !selectedUsername) {
      setCurrentStep('upgrade')
    }
  }, [user])

  useEffect(() => {
    if (error && error !== '') {
      const attempts = +failedSigninAttemps + 1
      setFailedSigninAttemps(attempts)
    }

    if (currentStep === 'active') {
      localStorage.setItem('failed_signin_attempt', `0`)
      setFailedSigninAttemps(0)
    }
  }, [error, currentStep])

  const buttonConfig = {
    loading: {
      cancel: null,
      next: null,
    },
    incoming: {
      cancel: { text: 'Cancel', action: handleCancel },
      next: { text: 'Connect', action: handleLogin },
    },
    active: {
      cancel: null,
      next: { text: 'Finish', action: handleLogin },
    },
    pin_code_required: {
      cancel: null,
      next: { text: 'Next', action: handleLogin },
    },
    action_required: {
      cancel: null,
      next: { text: 'Confirm', action: handleLogin },
    },
    error: {
      cancel:
        err?.action_button_text === 'request_support' ? null : { text: 'Request Support', action: handleIntercom },
      next:
        err?.action_button_text === 'request_support'
          ? { text: 'Request Support', action: handleIntercom }
          : { text: 'Try Again', action: () => setCurrentStep('incoming') },
    },
    upgrade: {
      cancel: { text: 'Cancel', action: handleCancel },
      next: { text: 'Upgrade Plan', action: handleContactSupport },
    },
    default: {
      cancel: { text: 'Cancel', action: handleCancel },
      next: { text: 'Next', action: handleLogin },
    },
  }

  const getButtonConfig = (step: any) => {
    switch (step) {
      case 'loading':
      case 'initiating':
      case 'require_check_signedin_app':
      case 'verify_on_app':
        return buttonConfig.loading
      case 'active':
        return buttonConfig.active
      case 'incoming':
        return buttonConfig.incoming
      case 'pin_code_required':
        return buttonConfig.pin_code_required
      case 'require_register_phone':
      case 'require_authentication_code':
      case 'require_another_authentication_code':
      case 'require_2fa_code':
      case 'require_another_2fa_code':
        return buttonConfig.action_required
      case 'upgrade':
        return buttonConfig.upgrade
      case 'error':
        return buttonConfig.error
      default:
        return buttonConfig.default
    }
  }

  const { cancel, next } = getButtonConfig(currentStep)

  const languageInfoPopover = (
    <Popover
      content={
        <div>
          For changing your {platformUpperCase} account language follow this{' '}
          <span
            style={{ color: '#7043ff', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              switch (platform) {
                case 'instagram':
                  window.open(
                    'https://help.instagram.com/601739652100106/?cms_platform=iphone-app&helpref=platform_switcher',
                    '_blank',
                  )
                  break
                case 'facebook':
                  window.open('https://www.facebook.com/help/327850733950290', '_blank')
                  break
                case 'linkedin':
                  window.open('https://www.linkedin.com/help/linkedin/answer/a521833', '_blank')
                  break
              }
            }}>
            guide
          </span>
        </div>
      }>
      <InfoCircleOutlined style={{ marginLeft: '3px', cursor: 'pointer' }} />
    </Popover>
  )

  const getLoadingText = () => {
    switch (prevStep) {
      case 'pin_code_required':
      case 'require_authentication_code':
      case 'require_2fa_code':
      case 'require_another_authentication_code':
      case 'require_another_2fa_code':
        return 'Verifying...'
      default:
        return null
    }
  }

  const messages = [
    'This action should take up to 1-2 min. Please bear with us while we connect your account.',
    `Please check your primary device for ${platformUpperCase} to see if you received a login notification. If so, please approve it.`,
    `Please ensure you have the email, phone, or authenticator app associated with your account ready in case ${platformUpperCase} requires it.`,
  ]

  const StepContent = () => {
    switch (currentStep) {
      case 'loading':
        const text = getLoadingText()
        const loadingText = text ? text : `Connecting to ${platform === 'linkedin' ? email : username}`

        // trackEvent('LOADING_SCREEN_VIEWED', {
        //   latest_step: prevStep,
        //   platform: platform,
        //   ...(platform === 'linkedin' ? { platformEmail: email } : { platformUsername: username }),
        // })

        return (
          <div className={styles.loadingScreen}>
            <div className={styles.loaderContainer}>
              <div className={styles.loader}></div>
            </div>
            <p className={styles.truncatedText}>{loadingText}</p>
            {text === null ? (
              <RotatingText messages={messages} spanStyle={{ maxWidth: '410px' }} />
            ) : (
              <span>
                This action should take up to 1-2 min. Please bear with <br />
                us while we connect your account.
              </span>
            )}
          </div>
        )
      case 'initiating': // 1
        return (
          <div className={styles.loadingScreen}>
            <div className={styles.loaderContainer}>
              <div className={styles.loader}></div>
            </div>
            <p>Initiating Connection...</p>
            <span>Establishing connection. This may take a few seconds.</span>
          </div>
        )
      case 'incoming': // 2
        return (
          <Form form={form} layout='vertical' className={`socialConnectionForm ${styles.stepContent}`}>
            {platform === 'linkedin' && (
              <div className={styles.inputContainer}>
                <span style={{ color: '#5E6C84' }}>{platformUpperCase} Email Address</span>
                <Form.Item
                  name='email'
                  rules={[{ required: true, message: 'Please input your email!' }]}
                  hasFeedback
                  initialValue={email}>
                  <Input style={{ width: 250, margin: '0px !important' }} placeholder='Email' defaultValue={username} />
                </Form.Item>
              </div>
            )}
            {(platform === 'instagram' || platform === 'facebook') && (
              <div className={styles.inputContainer}>
                <span style={{ color: '#5E6C84' }}>
                  {platformUpperCase} Username{platform === 'facebook' && ' or Email'}
                </span>
                <Form.Item
                  name='username'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                  hasFeedback
                  initialValue={username}>
                  <Input style={{ width: 250 }} placeholder='Username' defaultValue={username} />
                </Form.Item>
              </div>
            )}
            <div className={styles.inputContainer}>
              <span style={{ color: '#5E6C84' }}>Password</span>
              <Form.Item
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
                hasFeedback>
                <Input.Password style={{ width: 250 }} placeholder='Password' />
              </Form.Item>
            </div>
            <div className={styles.inputContainer}>
              <span style={{ color: '#5E6C84' }}>{platformUpperCase}'s Country</span>
              <Form.Item
                name='countryCode'
                rules={[{ required: true, message: 'Please select a country!' }]}
                initialValue={countryCode}
                hasFeedback>
                <CountrySelect
                  defaultValue={countryCode}
                  onChange={(e: any) => {
                    setCountryCode(e)
                  }}
                />
              </Form.Item>
            </div>
          </Form>
        )
      case 'require_authentication_code': // 3
      case 'require_2fa_code':
        return (
          <div className={styles.bodyContainer}>
            <p>
              {platformUpperCase} requires verification code
              <br /> to complete the login
            </p>
            <span className={styles.description}>
              Please insert the verification code you received from {platformUpperCase}
              <br />
              on either email, text message or from your authenticator app.
            </span>
            <div className={styles.inputCode}>
              <span className={styles.title}>Verification Code</span>
              <Input
                ref={inputRef}
                style={{ width: '100%' }}
                value={verificationCode}
                defaultValue={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder='Enter Verification Code'
              />
            </div>
          </div>
        )
      case 'active': // 4
        return (
          <div className={styles.success}>
            <img src={trophy_icon} alt='' />
            <p className={styles.title}>Success!</p>
            <span className={styles.description}>
              Your account has been connected successfully! <br />
            </span>
          </div>
        )
      case 'require_register_phone': // 5
        return (
          <div className={styles.bodyContainer}>
            <p>
              {platformUpperCase} requires phone number verification
              <br /> to complete the login
            </p>
            <span className={styles.description}>Please enter you phone number.</span>
            <div className={styles.inputCode}>
              <span className={styles.title}>Phone Number</span>
              <Input
                ref={inputRef}
                style={{ width: '100%' }}
                value={verificationCode}
                defaultValue={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder='Enter your phone number'
              />
            </div>
          </div>
        )
      case 'require_another_authentication_code': // 6
      case 'require_another_2fa_code':
        return (
          <div className={styles.bodyContainer}>
            <p>
              {platformUpperCase} requires another verification code
              <br /> to complete the login
            </p>
            <span className={styles.description}>
              Please insert the latest verification code you received from {platformUpperCase}
              <br /> on either email, text message or from your authenticator app.
            </span>
            <div className={styles.inputCode}>
              <span className={styles.title}>Verification Code</span>
              <Input
                ref={inputRef}
                style={{ width: '100%' }}
                value={verificationCode}
                defaultValue={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder='Enter Verification Code'
              />
            </div>
          </div>
        )
      case 'verify_on_app': // 7
      case 'require_check_signedin_app': // 9
        return (
          // <div className={styles.digitCode}>
          //   <span>
          //     {platformUpperCase} requires you to approve to login on your other device. Please check your
          //     {platformUpperCase} notifications on your other devices and approve the login.
          //   </span>
          //   <div className={styles.timer}>
          //     <p>Please verify within</p>
          //     <Countdown
          //       className='loginTimer'
          //       value={nowDate + 100 * 1600}
          //       format='mm:ss'
          //       onFinish={handleCountdownFinish}
          //     />
          //     <p>minutes. Otherwise, login with different</p>
          //   </div>
          //   <p style={{ margin: 0 }}> method will be selected.</p>
          // </div>

          <div className={styles.bodyContainer}>
            <p>
              {platformUpperCase} requires you to approve to
              <br /> login on your other device
            </p>
            <div className={styles.description}>
              <div className={styles.countdown}>
                <span>Please verify within</span>
                <Countdown
                  className='loginTimer'
                  value={nowDate + 100 * 1600}
                  format='mm:ss'
                  onFinish={handleCountdownFinish}
                />
                <span>minutes. Otherwise, login</span>
              </div>
              <span>with different method will be selected.</span>
            </div>
            {currentStep === 'require_check_signedin_app' && platform === 'linkedin' && (
              <Button onClick={handleUseDifferentMethod}>Use a different authentication method</Button>
            )}
          </div>
        )
      case 'pin_code_required': // 8
        return (
          <>
            <div className={styles.bodyContainer}>
              <p>
                Facebook requires a 6-digit PIN Code
                <br /> to complete the login
              </p>
              <span className={styles.description}>
                Please enter your 6-digit Facebook Messenger PIN code that you set up for your Messenger account in
                order to complete the connection. <br />
                Can't find your code?{' '}
                <span
                  onClick={() => {
                    window.open('https://www.facebook.com/help/messenger-app/479091330683112', '_blank')
                  }}
                  style={{ cursor: 'pointer', color: '#7043ff', textDecoration: 'underline' }}>
                  Quick Reset Guide
                </span>
                <Popover
                  open={openPopover}
                  trigger='click'
                  content={
                    <div className={styles.popoverContent}>
                      <img src={facebook_pin_explanation} alt='' style={{ height: '215px' }} />
                      <p>Reset Messanger PIN Code</p>
                      <span style={{ marginTop: '0px' }}>
                        Facebook requires 6-Digits pin code in order to send and recieve messages,
                        <br />
                        please reset it and add it to convrt before continuing.
                        <br />
                        Check the{' '}
                        <span
                          onClick={() => {
                            window.open('https://www.facebook.com/help/messenger-app/479091330683112', '_blank')
                          }}
                          style={{ cursor: 'pointer', color: '#7043ff', textDecoration: 'underline' }}>
                          Quick Reset Guide
                        </span>
                        , and follow the section above.
                      </span>
                      <div className={styles.bottom}>
                        <Button type='primary' onClick={() => setOpenPopover(false)}>
                          Got it
                        </Button>
                      </div>
                    </div>
                  }>
                  <InfoCircleOutlined
                    onClick={() => {
                      setOpenPopover(true)
                    }}
                    style={{ cursor: 'pointer', marginLeft: '5px', color: 'gray' }}
                  />
                </Popover>
              </span>
              <div className={styles.inputCode}>
                <InputOTP
                  length={6}
                  onChange={(otp) => {
                    setPINCode(otp)
                  }}
                  value={PINCode}
                  disabled={loading}
                />
              </div>
            </div>
          </>
        )
      // case 'require_check_signedin_app': // 9
      //   return (
      //     <div className={styles.digitCode}>
      //       <span>Waiting for app approval... This may take a few minutes. Please hold tight.</span>
      //     </div>
      //   )
      case 'authenticate_another_case': // 10
      case 'error':
        return (
          <div className={styles.unknown}>
            <div className={styles.centered}>
              <p style={{ color: '#EF1A2C', fontSize: '18px', marginBottom: '5px' }}>
                {err?.title || 'Login was unsuccessful'}
              </p>
              <span style={{ color: '#5E6C84' }}>
                <span style={{ fontWeight: 600 }}>Reason: </span>
                {err.reason}{' '}
              </span>
            </div>
            <div style={{ color: '#5E6C84' }}>
              <p style={{ fontWeight: 600, fontSize: '16px', color: 'black' }}>What can I do?</p>
              <span className={styles.bullet}>
                {err?.what_can_i_do}{' '}
                {err?.information_icon && err?.information_icon === 'language_change' ? languageInfoPopover : ''}
              </span>
            </div>
          </div>
        )
      case 'upgrade':
        return (
          <div className={styles.bodyContainer}>
            <p style={{ margin: '0px' }}>
              Your current plan allows connecting up to {total_number_of_accounts_connected} accounts to Convrt.
            </p>
            <span style={{ margin: '0px', textAlign: 'center' }}>
              You already have {numberOfConnectedAccounts} active accounts.
              <br />
              If you need assistance or have questions, feel free to contact our support team.
            </span>
          </div>
        )
      default:
        return null
    }
  }

  const modalTitle =
    currentStep === 'loading' || currentStep === 'initiating' || currentStep === 'active'
      ? `${platformUpperCase} Account Connection`
      : step === 'pin_code_required'
      ? `Complete ${platformUpperCase} Account Setup`
      : `${platformUpperCase} Account Connection`

  return (
    <Modal
      // title={modalTitle}
      className='socialConnectionModal ModalNoPadding'
      closable={false}
      centered
      open={open}
      onOk={handleLogin}
      onCancel={handleCancel}
      okText='Next'
      cancelText='Cancel'
      footer={false}>
      <div className={styles.stepContentContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <img src={icon} alt='' />
            <p>{modalTitle}</p>
          </div>
        </div>
        <div className={styles.container}>
          {total_number_of_accounts_connected <= numberOfConnectedAccounts && !selectedUsername ? (
            <div className={styles.bodyContainer}>
              <p style={{ margin: '0px' }}>
                Your current plan allows connecting up to {total_number_of_accounts_connected} accounts to Convrt.
              </p>
              <span style={{ margin: '0px', textAlign: 'center' }}>
                You already have {numberOfConnectedAccounts} active accounts.
                <br />
                If you need assistance or have questions, feel free to contact our support team.
              </span>
            </div>
          ) : (
            StepContent()
          )}
        </div>
        {cancel || next ? (
          <div className={styles.actions}>
            <Button type='primary' onClick={next.action} loading={loading} disabled={disabledButton}>
              {next.text}
            </Button>
            {cancel && (
              <Button className={styles.cancelButton} onClick={cancel.action} disabled={loading}>
                {cancel.text}
              </Button>
            )}
          </div>
        ) : (
          <div className={styles.actions}></div>
        )}
      </div>
    </Modal>
  )
}

export default SocialConnectionModal
