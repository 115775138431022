import React from 'react'
import styles from './CustomPopover.module.scss'
import { Skeleton } from 'antd'

interface CustomPopoverProps {
  paused: number
  continuing: number
  completed: number
  revoked: number
  positive: number
  neutral: number
  negative: number
  loading?: boolean
}

const CustomPopover: React.FC<CustomPopoverProps> = ({
  paused,
  continuing,
  completed,
  revoked,
  positive,
  neutral,
  negative,
  loading,
}) => {
  const total = paused + continuing + completed + revoked

  const pausedPercentage = (paused / total) * 100
  const continuingPercentage = (continuing / total) * 100
  const completedPercentage = (completed / total) * 100
  const revokedPercentage = (revoked / total) * 100
  const nothingPercentageLeads =
    pausedPercentage + continuingPercentage + completedPercentage + revokedPercentage > 0 ? 0 : 100

  const totalMessages = positive + negative + neutral

  const positivePercentage = (positive / totalMessages) * 100
  const negativePercentage = (negative / totalMessages) * 100
  const neutralPercentage = (neutral / totalMessages) * 100
  const nothingPercentage = positivePercentage + negativePercentage + neutralPercentage > 0 ? 0 : 100

  const borderRadius =
    (positive > 0 && neutral === 0 && negative === 0) ||
    (neutral > 0 && positive === 0 && negative === 0) ||
    (negative > 0 && positive === 0 && neutral === 0)
      ? '12px'
      : 'initial'

  const segments = [
    { name: 'paused', percentage: pausedPercentage },
    { name: 'continuing', percentage: continuingPercentage },
    { name: 'completed', percentage: completedPercentage },
    { name: 'revoked', percentage: revokedPercentage },
  ]

  // Get the indices of segments that are visible (percentage > 0).
  const activeIndices = segments
    .map((seg, idx) => (seg.percentage > 0 ? idx : null))
    .filter((idx) => idx !== null) as number[]

  // Determine the first and last active segment indices.
  const firstActiveIndex = activeIndices.length > 0 ? activeIndices[0] : -1
  const lastActiveIndex = activeIndices.length > 0 ? activeIndices[activeIndices.length - 1] : -1

  // Returns an object with border radius overrides.
  const getBorderRadiusStyle = (index: number) => {
    const isOnlyActive = activeIndices.length === 1
    const style: React.CSSProperties = {}
    if (index === firstActiveIndex) {
      // For the very first active segment, round the left side.
      style.borderTopLeftRadius = '12px'
      style.borderBottomLeftRadius = '12px'
    }
    if (index === lastActiveIndex) {
      // For the very last active segment, round the right side.
      style.borderTopRightRadius = '12px'
      style.borderBottomRightRadius = '12px'
    }
    if (isOnlyActive) {
      // If there is only one segment visible, round all corners.
      style.borderRadius = '12px'
    }
    return style
  }

  return (
    <div className={styles.customPopover}>
      <h3 style={{ marginTop: 0, fontSize: '14px' }}>Leads Overview</h3>
      <div className={styles.progressBar}>
        {loading && <div className={styles.loadingBar}></div>}
        {!loading && (
          <>
            {nothingPercentageLeads ? (
              <div
                className={styles.paused}
                style={{
                  width: `${nothingPercentageLeads}%`,
                  borderRadius: '12px',
                }}></div>
            ) : (
              <>
                {segments.map((seg, idx) => (
                  <div
                    key={seg.name}
                    className={styles[seg.name]}
                    style={{
                      width: `${seg.percentage}%`,
                      ...getBorderRadiusStyle(idx),
                    }}></div>
                ))}
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.labels}>
        <div className={styles.text}>
          <span className={styles.header}>
            Ready<span className={styles.hide}> to Start</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#DFE1E6' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                paused?.toLocaleString()
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Continuing <span className={styles.hide}></span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: 'rgba(25, 188, 254, 0.50)' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                continuing?.toLocaleString()
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Completed <span className={styles.hide}></span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#19BCFE' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                completed?.toLocaleString()
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Revoked <span className={styles.hide}></span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#00a0e0' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                revoked?.toLocaleString()
              )}
            </span>
          </div>
        </div>
      </div>

      <h3 style={{ borderTop: '1px solid #DFE1E6', paddingTop: 14, fontSize: '14px' }}>Chat Messages Overview</h3>
      <div className={styles.progressBar}>
        {loading && <div className={styles.loadingBar}></div>}
        {!loading && (
          <>
            <div
              className={styles.negative}
              style={{
                width: `${negativePercentage}%`,
                borderRadius: negative > 0 && positive === 0 && neutral === 0 ? borderRadius : '12px 0px 0px 12px',
              }}></div>
            <div
              className={styles.neutral}
              style={{
                width: `${neutralPercentage}%`,
                borderRadius: neutral > 0 && positive === 0 && negative === 0 ? borderRadius : 'initial',
              }}></div>
            <div
              className={styles.positive}
              style={{
                width: `${positivePercentage}%`,
                borderRadius: positive > 0 && neutral === 0 && negative === 0 ? borderRadius : '0px 12px 12px 0px',
              }}></div>
            <div
              className={styles.nothing}
              style={{
                width: `${nothingPercentage}%`,
                borderRadius: '12px',
              }}></div>
          </>
        )}
      </div>
      <div className={styles.labels}>
        <div className={styles.text}>
          <span className={styles.header}>
            Negative <span className={styles.hide}>Messages</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#FF5C5C' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                negative
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Neutral <span className={styles.hide}>Messages</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#F9C628' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                neutral
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Positive <span className={styles.hide}>Messages</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#02C3AB' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                positive
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomPopover
