import React, { useState, useEffect } from 'react'
import { Card, Tooltip, Switch, Dropdown, message, Divider } from 'antd'
import { unionBy } from 'lodash'
import {
  // TeamOutlined,
  AppstoreAddOutlined,
  FormOutlined,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons'

import { useUpdateCategoryStatusMutation } from 'common/APIs/SettingsApi'

import { EditCategorizerSider } from './CategorizerSider/EditCategorizer'
import { RemoveCategorizer } from './CategorizerSider/RemoveCategorizer'
import styles from './CategoryCard.module.scss'

interface categorizer {
  index: number
  category_name: string
  is_active: boolean
  category_description: string
  personas: any
}
interface CategoryCardProps {
  data: categorizer
  handleChange: any
  handleEdit: any
  handleRemove: any
  listPersonas: any
}

const propsTooltip = {
  color: '#fff',
  overlayStyle: {
    maxWidth: '600px',
    backgroundColor: 'white',
  },
  overlayInnerStyle: { backgroundColor: 'white' },
}

const CategorizerCard: React.FC<CategoryCardProps> = ({
  data,
  listPersonas,
  handleChange,
  handleEdit,
  handleRemove,
}) => {
  const { category_name, index, is_active, category_description, personas } = data
  const [checked, setChecked] = useState<boolean>(is_active)
  const [isEditModal, setIsEditModal] = useState(false)
  const [isRemoveModal, setIsRemoveModal] = useState(false)
  const [openPopover, setOpenPopover] = useState<boolean>(false)

  const [updateCategoryStatus] = useUpdateCategoryStatusMutation()

  const items =
    category_name?.toLowerCase() !== 'no category yet'
      ? [
          {
            key: '1',
            label: <span>Edit Categorizer</span>,
            icon: <FormOutlined style={{ marginRight: '8px' }} />,
            onClick: () => {
              setOpenPopover(false)
              setIsEditModal(true)
            },
            className: styles.optionMenu,
          },
          {
            key: '2',
            label: <span>Delete Categorizer</span>,
            icon: <DeleteOutlined style={{ marginRight: '8px' }} />,
            onClick: () => {
              setOpenPopover(false)
              setIsRemoveModal(true)
            },
            className: styles.optionMenu,
          },
        ]
      : []

  const handleChangeSwitch = async (id: number, e: any) => {
    setChecked(e)
    await updateCategoryStatus({
      index: id,
      is_active: e,
    }).unwrap()
    handleChange(id, e)
    message.success('Category updated successfully!')
  }

  useEffect(() => {
    setChecked(is_active)
  }, [data])

  return (
    <>
      <Card
        className={`addLeadsModal ${styles.groupCard}`}
        hoverable
        onClick={() => {
          setIsEditModal(true)
        }}>
        <div className={styles.cardHeader}>
          <div className={styles.groupMenu}>
            <div className={styles.cardImage}>
              <AppstoreAddOutlined style={{ color: checked ? '#7043FF' : '' }} />
            </div>
            <div
              className={styles.groupButton}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}>
              <Switch checked={checked} onChange={(e) => handleChangeSwitch(index, e)} size='small' />
              <Dropdown
                trigger={['click']}
                menu={{ items }}
                placement='bottomRight'
                open={openPopover}
                onOpenChange={setOpenPopover}>
                <div className={styles['moreOptions']}>
                  <MoreOutlined
                    style={{
                      transform: 'rotate(90deg)',
                      fontWeight: '600',
                      fontSize: '18px',
                      color: checked ? '#7043FF' : '#5E6C84',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          <div className={styles.groupName}>{category_name}</div>
        </div>
        <div className={styles.leads}>
          {personas?.length > 0 &&
            unionBy(personas, 'id').map((persona: any, index: number) => {
              return (
                <>
                  <div key={index} className={styles.platform}>
                    <span>{persona.name}</span>
                  </div>
                  <Divider type='vertical' />
                </>
              )
            })}
        </div>
        <div className={styles.cardBody}>
          <div className={styles.groupDescription}>
            <Tooltip {...propsTooltip} color='#222' title={category_description}>
              {category_description}
            </Tooltip>
          </div>
        </div>
      </Card>
      {isEditModal && (
        <EditCategorizerSider
          data={data}
          open={isEditModal}
          onClose={() => {
            setIsEditModal(false)
          }}
          onChange={handleEdit}
          listPersonas={listPersonas}
        />
      )}
      <RemoveCategorizer
        onChange={handleRemove}
        data={data}
        open={isRemoveModal}
        onClose={() => setIsRemoveModal(false)}
      />
    </>
  )
}

export default CategorizerCard
