import React, { useState } from 'react'

import { Button, Input, message, Skeleton, Space, TablePaginationConfig, Typography } from 'antd'

import styles from './ExposedContacts.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import {
  fetchCustomList,
  fetchExposed,
  useGetContactsByCompanyIdQuery,
  useGetExposedContactsQuery,
  useUpdateMetaDataContactMutation,
} from 'LEGACY/API/ContactsApi'
import { SideBar } from 'LEGACY/MyContacts/components/Sidebar'
import { Tab } from 'LEGACY/MyContacts/components/Tab'
import CompanyContact from 'LEGACY/MyContacts/Company'
import MyContact from 'LEGACY/MyContacts/Contacts'
import { SelectComponent } from 'LEGACY/common/components/SelectComponent'
import {
  SET_CURRENT_ORDER_BY,
  SET_CURRENT_SEARCH,
  SET_LISTS,
  SET_SELECTED_COMPANIES,
} from 'LEGACY/MyContacts/state/slice/companiesSlice'
import {
  SET_LISTS as SET_LISTS_GLOBAL,
  SET_LISTS_LOADING,
} from 'LEGACY/Research/intent-signals/state/slice/companiesSlice'

import { useDebounce } from 'LEGACY/helper/useDebounce'
import { useGetCompaniesQuery, useGetExposedCompaniesQuery, useLazyGetListsQuery } from 'LEGACY/API/CompaniesApi'
import { IExposedContact } from 'LEGACY/Research/contacts/interfaces/contact.interface'
import { SET_SELECTED_CONTACTS } from 'LEGACY/MyContacts/state/slice/contactsSlice'
import { intersection, isEmpty, lowerCase } from 'lodash'
import AddContactModal from './components/ImportCustomList/AddContactOptionModal'
import { useNavigate } from 'react-router-dom'
import { AddListSider } from './components/AddListSider'
import emptyIcon from 'LEGACY/Assets/svg/empty_list_icon.svg'
import { SET_CURRENT_CUSTOM_LIST } from './state/importContactSlice'
import { IMPORT_TYPE_NUMBER } from './components/ImportCustomList/ImportCustomList.type'
import import_company from 'LEGACY/Assets/svg/import_company.svg'
import { useImmer } from 'use-immer'
import {
  dataTypeContact,
  formatOrderBy,
  getResultFromImport,
  initTableParam,
  MeetingBookedTableCell,
  PriorityTableCell,
} from './outreach.helper'

const ExposedContacts = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentCustomList: currentList } = useAppSelector((state) => state.importContact)
  const { my_contact_current_order_by, my_contact_current_search } = useAppSelector(
    (state) => state.myContactsCompanies,
  )
  const { lists } = useAppSelector((state) => state.myContactsCompanies)
  const setList = (data: any) => {
    dispatch(SET_LISTS(data))
    dispatch(SET_LISTS_GLOBAL(data))
  }
  const setCurrentList = (data: any) => dispatch(SET_CURRENT_CUSTOM_LIST(data))
  const setSearchQuery = (value: any) => dispatch(SET_CURRENT_SEARCH(value))
  const setSortOption = (value: any) => dispatch(SET_CURRENT_ORDER_BY(value))

  const [indexDefault, setIndexDefault] = useState(0)
  const [typeContact, setTypeContact] = useState(dataTypeContact[indexDefault]?.value)
  const [currentDefaultList, setCurrentDefaultList] = useState('legacy_exposed_contacts')
  const [openImportModal, setOpenImportModal] = useState(false)
  const [tableParamsContact, setTableParamsContact] = useState<TablePaginationConfig>(initTableParam)
  const [tableParamsCompany, setTableParamsCompany] = useState<TablePaginationConfig>(initTableParam)
  const [openSiderAddList, setOpenSiderAddList] = useState(false)
  const [contactListFormat, setContactList] = useState<any>()
  const [customListData, setCustomListData] = useState<any>({})
  const isCompanyContact = typeContact === 'company'

  const [updateMetaDataContact] = useUpdateMetaDataContactMutation()
  const formatedOrderBy = formatOrderBy(my_contact_current_order_by)
  const debouncedSearchQuery = useDebounce(my_contact_current_search, 300)

  const [getExposedContactParams, setGetExposedContactParams] = useImmer(() => {
    let paramFetchContact: any = { ...tableParamsContact }
    let paramFetchCompanies: any = { ...tableParamsCompany }

    if (currentDefaultList !== 'all_contacts') {
      paramFetchContact.list = currentDefaultList
      paramFetchCompanies.list = currentDefaultList
    } else {
      delete paramFetchContact.list
      delete paramFetchCompanies.list
    }

    return {
      paramFetchContact,
      paramFetchCompanies,
    }
  })

  const [getCustomContactParams, setGetCustomContactParams] = useImmer(() => {
    let paramFetchContact: any = { ...tableParamsContact }
    let paramFetchCompanies: any = { ...tableParamsCompany }

    return {
      paramFetchContact,
      paramFetchCompanies,
    }
  })

  const [refetchLists] = useLazyGetListsQuery()

  const {
    data: contacts,
    isFetching: isFetchingContact,
    error: contactsError,
    // refetch: refetchGetExposedContact,
  } = useGetExposedContactsQuery(getExposedContactParams.paramFetchContact, { refetchOnMountOrArgChange: true })

  const {
    data: contactsByList,
    isFetching: isFetchingContactByList,
    error: contactsByListError,
    refetch: refetchGetContactByList,
  } = useGetContactsByCompanyIdQuery(getCustomContactParams.paramFetchContact, { refetchOnMountOrArgChange: false })

  const {
    data: companiesOutreach,
    isFetching: isFetchingCompany,
    error: companyErrors,
    // refetch: refetchGetExposedCompany,
  } = useGetExposedCompaniesQuery(getExposedContactParams.paramFetchCompanies, { refetchOnMountOrArgChange: true })

  const {
    data: companiesByList,
    isFetching: isFetchingCompanyByList,
    refetch: refetchGetCompanyByList,
    error: companyByListErrors,
  } = useGetCompaniesQuery(getCustomContactParams.paramFetchCompanies, { refetchOnMountOrArgChange: true })

  const companyListFormat = currentList ? companiesByList : companiesOutreach

  const isEmptyCustomList =
    !debouncedSearchQuery &&
    my_contact_current_order_by === 'none' &&
    customListData &&
    +customListData?.company?.totalNumber === 0 &&
    +customListData?.contact?.totalNumber === 0

  const handleInvalidateCacheAfterUpdate = async () => {
    const errorCatch = (error: any) => message.error(error?.message || 'Error when get contacts')
    currentList
      ? fetchCustomList({
          ...getCustomContactParams.paramFetchContact,
          invalidateCache: true,
        }).catch(errorCatch)
      : fetchExposed({
          ...getExposedContactParams.paramFetchContact,
          invalidateCache: true,
        }).catch(errorCatch)
  }

  const handleUpdatePriority = async (contactId: string, priority: string, updatedIndex: number) => {
    try {
      await updateMetaDataContact({
        contactId,
        priority,
      })
      setContactList((data: any) => {
        return {
          ...data,
          rows: data?.rows?.map((item: IExposedContact, i: number) => {
            const newItem = { ...item }
            if (updatedIndex === i) {
              newItem.priority = priority
            }
            return newItem
          }),
        }
      })
      handleInvalidateCacheAfterUpdate()
    } catch (error) {
      console.log(error)
      message.error('Error')
    }
  }

  const priorityColumn = {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    width: 100,
    onCell: (record: any) => ({
      onClick: (e: any) => {
        e.stopPropagation()
      },
    }),
    render: (text: string, item: any, index: number) => {
      return (
        <PriorityTableCell
          key={item.te}
          item={item}
          handleUpdatePriority={async (id, val) => {
            await handleUpdatePriority(id, val, index)
          }}
        />
      )
    },
  }

  const handleUpsertMeetingBooked = async (contactId: string, meeting_booked: string, updatedIndex: number) => {
    try {
      await updateMetaDataContact({
        contactId,
        meeting_booked,
      })
      setContactList((data: any) => {
        return {
          ...data,
          rows: data?.rows?.map((item: any, i: number) => {
            const newItem = { ...item }
            if (updatedIndex === i) {
              newItem.meeting_booked = meeting_booked
            }
            return newItem
          }),
        }
      })
      handleInvalidateCacheAfterUpdate()
    } catch (error) {
      console.log(error)
      message.error('Error')
    }
  }

  const meetingBookedColumn = {
    title: 'Meeting Booked',
    dataIndex: 'meeting_booked',
    key: 'meeting_booked',
    width: 160,
    onCell: (record: any) => ({
      onClick: (e: any) => {
        e.stopPropagation()
      },
    }),
    render: (text: string, item: any, index: number) => {
      return (
        <MeetingBookedTableCell
          key={item.te}
          item={item}
          handleUpsertMeetingBooked={async (id, val) => {
            await handleUpsertMeetingBooked(id, val, index)
          }}
        />
      )
    },
  }

  const fetchList = async () => {
    try {
      dispatch(SET_LISTS_LOADING(true))
      const res = await refetchLists()
      if (res) {
        setList(res.data)

        const resultImport = getResultFromImport()
        if (resultImport) {
          const { listId } = resultImport
          const newList = res.data?.find((item: any) => item?.id === listId)

          if (newList) setCurrentList(newList)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(SET_LISTS_LOADING(false))
    }
  }

  const connectedContactCount = React.useMemo(() => {
    if (currentList) return 0

    const listIdCONNECTED = contacts?.connectedContact?.map((item) => {
      if (item?.convrt_index?.includes('csv_')) {
        let newId = item?.convrt_index?.replace(/^csv_/, '')
        return newId
      }
      return item?.convrt_index
    })

    const resultConnected = intersection(listIdCONNECTED, contacts?.allIdContacts)

    return resultConnected.length || 0
  }, [contacts, currentList])

  const isLoading = isFetchingCompany || isFetchingCompanyByList || isFetchingContact || isFetchingContactByList

  const formatTotalContactCount = React.useMemo(() => {
    const isHaveDataCount = contactListFormat?.totalNumber || +contactListFormat?.totalNumber === 0

    return isHaveDataCount && !isLoading ? contactListFormat?.totalNumber : <Skeleton.Input size='small' active />
  }, [contactListFormat, isCompanyContact, isLoading])

  const formatedTotalCompanyCount = React.useMemo(() => {
    const isHaveDataCompanyCount = companyListFormat?.totalNumber || +companyListFormat?.totalNumber === 0
    const isHaveDataContactCount = contactListFormat?.companiesCount || +contactListFormat?.companiesCount === 0

    if (isCompanyContact) {
      if (isHaveDataCompanyCount && !isLoading) {
        return companyListFormat?.totalNumber
      } else return <Skeleton.Input size='small' active />
    }

    return isHaveDataContactCount ? (
      debouncedSearchQuery && !isLoading ? (
        contactListFormat?.companiesCount
      ) : isHaveDataCompanyCount && !isLoading ? (
        companyListFormat?.totalNumber
      ) : (
        <Skeleton.Input size='small' active />
      )
    ) : (
      <Skeleton.Input size='small' active />
    )
  }, [contactListFormat, companyListFormat, currentList, isCompanyContact, debouncedSearchQuery, isLoading])

  const handleChangeTableParams = (param: any, isCompanyTable: boolean) => {
    let keyChange = ''
    if (isCompanyTable) {
      keyChange = 'paramFetchCompanies'
      setTableParamsCompany(param)
    } else {
      keyChange = 'paramFetchContact'
      setTableParamsContact(param)
    }
    const setFunc = currentList ? setGetCustomContactParams : setGetExposedContactParams
    setFunc((draft: any) => {
      Object.assign(draft[keyChange], { current: param.current, pageSize: param.pageSize })
    })
  }

  const handleResetTableParam = () => {
    setTableParamsCompany(initTableParam)
    setTableParamsContact(initTableParam)

    const changeTableParam = (draft: any) => {
      Object.assign(draft.paramFetchCompanies, { ...initTableParam })
      Object.assign(draft.paramFetchContact, { ...initTableParam })
    }

    setGetExposedContactParams(changeTableParam)
    setGetCustomContactParams(changeTableParam)
  }

  const handleResetFilterData = () => {
    !isEmpty(my_contact_current_search) && setSearchQuery('')
    !isEmpty(formatedOrderBy) && setSortOption('none')
  }

  const handleChangeCustomList = (list: any) => {
    setContactList({})
    setCurrentList(list)
    setCurrentDefaultList('')
    handleResetFilterData()
  }

  const handleSearch = (query: string) => {
    handleResetTableParam()
    setSearchQuery(query)
  }

  const handleOrderBy = (value: string) => {
    handleResetTableParam()
    setSortOption(value)

    if (currentList) {
      setGetCustomContactParams((draft) => {
        const formatedOrderBy = formatOrderBy(value)
        draft.paramFetchContact.order_by = formatedOrderBy
        draft.paramFetchCompanies.order_by = formatedOrderBy
      })
    } else {
      setGetExposedContactParams((draft) => {
        const formatedOrderBy = formatOrderBy(value)
        draft.paramFetchContact.order_by = formatedOrderBy
        draft.paramFetchCompanies.order_by = formatedOrderBy
      })
    }
  }

  const handleAddNewListSuccess = (lists: any) => {
    setOpenSiderAddList(false)
    setCurrentDefaultList('')
    setCurrentList(lists?.[0])
    setList(lists)

    localStorage.setItem('recentAddedList', JSON.stringify(lists?.[0]))

    setCustomListData({
      contact: {
        totalNumber: 0,
      },
      company: {
        totalNumber: 0,
      },
    })
  }

  React.useEffect(() => {
    if (currentList && Object.keys(currentList).length > 0) {
      setCurrentDefaultList('')
      setContactList(contactsByList)
      setCurrentList(currentList)

      const keyResultImport = 'importResult'
      const resultAfterImportContact = getResultFromImport()

      const recentAddedList = localStorage.getItem('recentAddedList')
      const recentAddedListData = recentAddedList ? JSON.parse(recentAddedList) : null

      if (currentList.id !== recentAddedListData?.id) {
        setCustomListData((pre: any) => ({
          ...pre,
          contact: contactsByList ? contactsByList : {},
          company: companiesByList ? companiesByList : {},
        }))
        setIndexDefault(+currentList.type === IMPORT_TYPE_NUMBER.COMPANY ? 1 : 0)

        let paramFetchCompanies: any = {}
        let paramFetchContact: any = {}

        if (resultAfterImportContact) {
          const { listId } = resultAfterImportContact
          if (currentList.id === listId) {
            paramFetchCompanies.invalidateCache = true
            paramFetchContact.invalidateCache = true
            localStorage.removeItem(keyResultImport)
          }
        }

        const listFilter = [
          {
            list_id: currentList.id,
          },
        ]
        const paramGetContactFromList: any = {
          companiesId: '',
          filters: {
            contactsList: listFilter,
          },
        }

        setGetCustomContactParams((draft) => {
          draft.paramFetchCompanies.invalidateCache = paramFetchCompanies.invalidateCache
          draft.paramFetchCompanies.body = {
            companyList: listFilter,
          }

          draft.paramFetchContact.invalidateCache = paramFetchContact.invalidateCache
          draft.paramFetchContact.filters = paramGetContactFromList.filters
        })
      }
      localStorage.removeItem('recentAddedList')
    } else {
      setCurrentDefaultList('legacy_exposed_contacts')
      setCurrentList(null)
      setContactList(contacts)
    }
  }, [currentList, contacts, contactsByList, companiesByList])

  React.useEffect(() => {
    fetchList()
    const currentList = localStorage.getItem('current_list')
    if (currentList) {
      setCurrentList(JSON.parse(currentList))
    }
  }, [])

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTypeContact(dataTypeContact[indexDefault]?.value)
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [indexDefault])

  React.useEffect(() => {
    if (typeContact || currentList) {
      dispatch(SET_SELECTED_CONTACTS([]))
      dispatch(SET_SELECTED_COMPANIES([]))

      handleResetTableParam()
    }
  }, [typeContact, currentList])

  React.useEffect(() => {
    const changeSearch = (draft: any) => {
      draft.paramFetchCompanies.search = debouncedSearchQuery
      draft.paramFetchContact.search = debouncedSearchQuery
    }
    const setFunc = currentList ? setGetCustomContactParams : setGetExposedContactParams
    setFunc(changeSearch)
  }, [debouncedSearchQuery, currentList])

  const renderContentTable = () => {
    return (
      <>
        <div className={styles['myContact__header']}>
          <div className={styles['myContact__header__left']}>
            <div className={styles['myContact__header__left__text']}>
              Displaying <Space />
              <span>
                All of <Space />
                {isLoading
                  ? 0
                  : isCompanyContact
                  ? companyListFormat?.totalNumber || 0
                  : contactListFormat?.totalNumber || 0}
              </span>
            </div>
            <Tab
              tabList={dataTypeContact}
              index={indexDefault}
              onTabChange={(value: string) => setTypeContact(value)}
            />
          </div>

          <div className={styles['myContact__header__right']}>
            {/* <div className={styles['myContact__header__right__filter']}>
              <img src={filterIcon} alt='filter icon' />
              <Typography>Filters</Typography>
            </div> */}
            <Input
              placeholder='Search Contact or Company'
              style={{ height: '34px', width: '268px' }}
              value={my_contact_current_search}
              onChange={(e) => handleSearch(e.target.value)}
            />

            <SelectComponent
              prefixText='Sort By'
              defaultValue='none'
              width='200px'
              height='32px'
              options={[
                { label: 'None', value: 'none' },
                { label: 'Alphabetical (A to Z)', value: 'ASC' },
                { label: 'Alphabetical (Z to A)', value: 'DESC' },
                { label: 'Newest first', value: 'newest' },
                { label: 'Oldest first', value: 'oldest' },
              ]}
              value={my_contact_current_order_by}
              onChange={(value: string) => {
                handleOrderBy(value)
              }}
            />
          </div>
        </div>

        <div className={styles['myContact__metric']}>
          <div className={styles['myContact__metric__item']}>
            <Typography className={styles['myContact__metric__item__label']}>Total Contacts</Typography>
            <Typography className={styles['myContact__metric__item__value']}>{formatTotalContactCount}</Typography>
          </div>
          <div className={styles['myContact__metric__item']}>
            <Typography className={styles['myContact__metric__item__label']}>Total Companies</Typography>
            <Typography className={styles['myContact__metric__item__value']}>{formatedTotalCompanyCount}</Typography>
          </div>
          <div className={styles['myContact__metric__item']}>
            <Typography className={styles['myContact__metric__item__label']}>Contacted Contacts</Typography>
            <Typography className={styles['myContact__metric__item__value']}>
              {contactListFormat && !isLoading ? connectedContactCount : <Skeleton.Input size='small' active />}
            </Typography>
          </div>
        </div>
        <div className={styles['myContact__table']}>
          {isCompanyContact ? (
            <CompanyContact
              companies={isLoading ? {} : companyListFormat}
              isLoading={isFetchingCompany || isFetchingCompanyByList}
              companyErrors={currentList ? companyByListErrors : companyErrors}
              tableParams={tableParamsCompany}
              handleChangeTableParams={(param: any) => handleChangeTableParams(param, true)}
              currentList={currentList}
              refetchGetContacts={() => {
                refetchGetCompanyByList()
                refetchGetContactByList()
              }}
            />
          ) : (
            <MyContact
              contacts={isLoading ? {} : contactListFormat}
              contactsError={currentList ? contactsByListError : contactsError}
              isLoading={isFetchingContact || isFetchingContactByList}
              additionalColumns={[meetingBookedColumn, priorityColumn]}
              currentList={currentList}
              tableParams={tableParamsContact}
              handleChangeTableParamsContact={(param: any) => handleChangeTableParams(param, false)}
              refetchGetContacts={() => {
                refetchGetCompanyByList()
                refetchGetContactByList()
              }}
            />
          )}
        </div>
      </>
    )
  }

  const renderContentEmptyCustomList = () => {
    const isCompanyImport = +currentList?.type === IMPORT_TYPE_NUMBER.COMPANY

    const textImport = isCompanyImport ? 'Companies' : 'Contacts'
    const srcImage = isCompanyImport ? import_company : emptyIcon
    return (
      <div className={styles.emptyList}>
        <img src={srcImage} alt='empty' />
        <div>
          <Typography className={styles.emptyList__title}>No {textImport} added</Typography>
          <Typography className={styles.emptyList__description}>
            This view is empty in order to create your list add some {lowerCase(textImport)}
          </Typography>
        </div>
        <Button type='primary' className={styles.emptyList__button} onClick={() => setOpenImportModal(true)}>
          Add {textImport}
        </Button>
      </div>
    )
  }

  return (
    <div className={`${styles['myContact']} myContact`}>
      <SideBar
        setIndexDefault={setIndexDefault}
        listContact={lists}
        currentDefaultList={currentDefaultList}
        currentList={currentList}
        onCreateList={() => setOpenSiderAddList(true)}
        onChangeCurrentDetailList={(value) => {
          setCurrentDefaultList(value)
          setCurrentList(null)
          handleResetFilterData()
        }}
        onChangeCustomList={handleChangeCustomList}
        refetchLists={refetchLists}
        onChangeNewList={setList}
      />

      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        <div className={styles['myContact__top']}>
          <div className={styles['myContact__top__left']}>{currentList ? currentList.name : 'Exposed Contacts'}</div>

          <div className={styles['myContact__top__right']}>
            <Button type='primary' onClick={() => setOpenSiderAddList(true)}>
              Add New List
            </Button>
            {/* <MoreOutlined className={styles['myContact__top__right__icon']} /> */}
          </div>
        </div>

        {currentList && isEmptyCustomList ? renderContentEmptyCustomList() : renderContentTable()}
      </div>

      <AddContactModal
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
        onClickImportCustomList={() => {
          navigate(`/exposedContacts/import-custom-list/${currentList?.id}?type=${currentList?.type}`)
        }}
      />

      <AddListSider
        isCompany={true}
        open={openSiderAddList}
        onClose={() => setOpenSiderAddList(false)}
        list={lists}
        onAddSuccess={handleAddNewListSuccess}
        refetchLists={refetchLists}
      />
    </div>
  )
}

export default ExposedContacts
