import React, { useEffect, useState } from 'react'
import { Card, Popover, Select, Spin, Tooltip, message } from 'antd'
import small_tick from 'common/assets/svg/small_tick.svg'
import styles from './PricingCard.module.scss'
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useLazyGenerateStripeLinkQuery } from '../../../../APIs/UpgradeApi'
import { useLocalStorage } from 'usehooks-ts'

interface PricingCardProps {
  plan: any
  plans: any
  billingCycle: 'monthly' | 'yearly'
  selectedCard: any
  handleSelectCard: (plan: any, price: any) => void
  setSelectedPrice: any
  selectedOption: any
  onOptionChange: any
  subscription?: any
  isCustomer?: boolean
  trialData?: any
}

const PricingCard: React.FC<PricingCardProps> = ({
  plan,
  plans,
  billingCycle,
  selectedCard,
  handleSelectCard,
  setSelectedPrice,
  selectedOption,
  onOptionChange,
  subscription,
  isCustomer,
  trialData,
}) => {
  const optionsObj = plan.pricingOptions[billingCycle][selectedOption]
  const [price, setPrice] = useState(optionsObj.price)
  const [loading, setLoading] = useState(false)
  const [generateStripeLink] = useLazyGenerateStripeLinkQuery()
  const [user] = useLocalStorage<any>('user', null)
  const isNew = user?.addons?.show_trial_option
  const isActivePlan =
    plan?.planName === subscription?.plan_name &&
    +subscription?.tier === +selectedOption &&
    ((billingCycle === 'monthly' && subscription?.interval === 'month' && subscription?.interval_count === 1) ||
      (billingCycle === 'yearly' && subscription?.interval === 'year' && subscription?.interval_count === 1))
  const trial_options = plan?.trial_options
  const trialPrice = trial_options?.price?.toFixed(2)
  const trialPriceId = trial_options?.price_id
  const trialDuration = trial_options?.duration_days
  const buttonText = trial_options?.button_text

  const handleSelectChange = (value: string) => {
    const selected = plan.pricingOptions[billingCycle][value]

    if (selected) {
      // setSelectedOption(selected)
      setPrice(selected.price)
      onOptionChange(plan.id, value)
    }
  }
  const getSendRate = (key: string) => {
    const sendRate = plan.features
      .filter((f: any) => f.name === 'Send rate')?.[0]
      ?.value?.[key].replace('Unique Contacts', '')
      .replace(' ', '')
    const toNum = Number(sendRate)
    if (isNaN(toNum)) {
      return sendRate
    }
    let formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return formatter.format(sendRate)
  }
  const getLeads = (option: any) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' })
    const leads = option?.points
    const toNum = Number(leads * 2)
    return formatter.format(toNum)
  }
  const handleUpgrade = async (isTrial?: boolean) => {
    try {
      if (loading || isActivePlan) return
      if (plan.customPrice) {
        window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
      } else {
        setLoading(true)
        const selected = plan.pricingOptions[billingCycle][selectedOption]
        const price_id = selected.price_id
        const { data } = await generateStripeLink({ price_id, isTrial, trialPriceId, trialDuration })
        if (data?.url) {
          window.open(data.url, '_self')
          setLoading(false)
        } else if (data?.not_allowed) {
          message.error('Please contact our Sales team for assistance with making this change.')
          setLoading(false)
        } else if (data?.same_price_id) {
          message.error(
            'You have already switched to this plan. The change is scheduled and will take effect at the end of the current billing period.',
          )
          setLoading(false)
        } else {
          message.error('Something went wrong redirecting to Stripe. Pleae try again or contact support.')
          setLoading(false)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const newSelectedOption = plan.pricingOptions[billingCycle][selectedOption] // || plan.pricingOptions[billingCycle]['1']
    // if (newSelectedOption && selectedCard?.id === plan?.id) {
    if (newSelectedOption) {
      onOptionChange(plan.id, selectedOption)
      setPrice(newSelectedOption.price)
      // setSelectedPrice(newSelectedOption.price)
    }
  }, [billingCycle, plan.pricingOptions, selectedOption])

  return (
    <Card
      className={`${plan.isMostPopular ? 'pricingCardMostPopular' : 'pricingCard'} ${
        selectedCard?.id === plan?.id ? 'pricingCardSelected' : ''
      }`}
      hoverable
      style={{ width: '308px' }}
      onClick={() => {
        handleSelectCard(plan, price)
      }}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p>{plan.planName}</p>
          <span style={{ minHeight: '66px' }}>{plan.description}</span>
          {
            <h1>
              {plan?.customPrice ? 'Custom' : `$${price}`}
              <span>/{billingCycle === 'monthly' ? 'mo' : 'mo'}</span>
            </h1>
          }
          {!plan?.customPrice && isNew ? (
            <Popover
              rootClassName='popoverNoArrow'
              title={trialData?.[0]}
              overlayStyle={{ maxWidth: '350px', zIndex: 9999, maxHeight: '300px' }}
              placement='top'
              showArrow={false}
              content={
                <div className={styles.trialSections}>
                  {trialData?.slice(1)?.map((data: any) => (
                    <span>{data}</span>
                  ))}
                  <p>
                    <br />
                    <span style={{ fontWeight: 600 }}>Will I see the charge immediately?</span>
                    <br />
                    Yes, you’ll be charged ${trialPrice} today.
                    <br />
                    After your trial, the full plan cost will apply based on the plan you selected.
                    <br />
                    <br />
                    <span style={{ fontWeight: 600 }}>Can I change or cancel my plan?</span>
                    <br />
                    Absolutely. You can cancel anytime within the {trialDuration}-day trial if you decide not to
                    continue.{' '}
                  </p>
                </div>
              }>
              <div style={{ display: 'flex' }}>
                <div className={styles.whatsIncluded14}>
                  <p style={{ fontWeight: 'lighter', fontSize: '14px', textOverflow: 'clip' }}>
                    {trialDuration}-day trial for ${trialPrice}
                  </p>
                  {/* <InfoCircleOutlined style={{ marginLeft: '5px', color: 'black' }} /> */}
                </div>
                <span
                  style={{ border: 'none', display: 'inline', fontWeight: 'lighter' }}
                  className={`${styles.cancelAnytime} cancelAnytimeCustom`}>
                  - cancel anytime
                </span>
              </div>
            </Popover>
          ) : (
            <span style={{ paddingBottom: '5px' }}></span>
          )}
        </div>
        {!plan.customPrice && (
          <div className={styles.selector}>
            <span className={styles.uniqueLeads}>
              <span>Unique leads /mo </span>
            </span>
            <div className={styles.select}>
              <Select
                style={{ width: '100%' }}
                value={selectedOption}
                // value={`${optionsObj.magic}-${optionsObj.points}`}
                onChange={handleSelectChange}>
                {/* {plan.pricingOptions[billingCycle].map((option: any) => ( */}
                {Object.entries(plan.pricingOptions[billingCycle])
                  // .sort(([keyA], [keyB]) => {
                  //   const orderA = plan.tierOrder[`tier_${keyA}`]
                  //   const orderB = plan.tierOrder[`tier_${keyB}`]
                  //   return orderA - orderB
                  // })
                  .map(([key, option]: any) => (
                    <Select.Option key={key} value={key} label='1'>
                      <span className={styles.optionText}>
                        <span style={{ fontWeight: 600 }}>{getSendRate(key)}</span>{' '}
                        <span style={{ fontWeight: 400 }}>Sending |</span>{' '}
                        <span style={{ fontWeight: 600 }}>{getLeads(option)}</span>{' '}
                        <span style={{ fontWeight: 400 }}>Collecting</span>{' '}
                      </span>
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        )}
        <div
          className={`${styles.upgradeButton} ${isActivePlan ? styles.activePlan : ''}`}
          onClick={() => handleUpgrade(isNew ? true : false)}>
          <div className={`${styles.buttonText} ${loading ? styles.loadingButton : ''}`}>
            {loading && <Spin indicator={<LoadingOutlined spin style={{ color: 'white', marginRight: '10px' }} />} />}
            <span>
              {isActivePlan ? 'Active Plan' : plan.customPrice ? 'Contact Us' : isNew ? buttonText : 'Update Plan'}
            </span>
          </div>
        </div>
        {!isCustomer && (
          <div className={styles.trial}>
            {!plan.customPrice && (
              <span style={{ color: 'black' }}>
                <Popover
                  rootClassName='popoverNoArrow'
                  title={trialData?.[0]}
                  showArrow={false}
                  placement='bottom'
                  overlayStyle={{ maxWidth: '350px' }}
                  content={
                    <div className={styles.trialSections}>
                      {trialData?.slice(1)?.map((data: any) => (
                        <span>{data}</span>
                      ))}
                      <p>
                        <br />
                        <span style={{ fontWeight: 600 }}>Will I see the charge immediately?</span>
                        <br />
                        Yes, you’ll be charged ${trialPrice} today.
                        <br />
                        After your trial, the full plan cost will apply based on the plan you selected.
                        <br />
                        <br />
                        <span style={{ fontWeight: 600 }}>Can I change or cancel my plan?</span>
                        <br />
                        Absolutely. You can cancel anytime within the {trialDuration}-day trial if you decide not to
                        continue.{' '}
                      </p>
                    </div>
                  }>
                  <div className={styles.whatsIncluded}>
                    <span style={{ color: 'black' }}>What's included in the trial?</span>
                    {/* <InfoCircleOutlined style={{ marginLeft: '5px', color: 'black' }} /> */}
                  </div>
                </Popover>
              </span>
            )}
          </div>
        )}
        <div className={styles.features}>
          {plan.features.map((feature: any) =>
            feature.name === 'all_in' ? (
              <div style={{ color: '#7043ff' }}>All in {feature.value}, plus:</div>
            ) : (
              <div className={styles.feature}>
                <div className={styles.flexDiv}>
                  <img src={small_tick} alt='' />
                  <span>
                    {feature.name}
                    {feature?.value && feature?.value?.[selectedOption] ? `: ${feature?.value?.[selectedOption]}` : ''}
                  </span>
                </div>
                <Tooltip className='customFont' title={feature.description}>
                  <InfoCircleOutlined style={{ cursor: 'pointer', zIndex: 22 }} />
                </Tooltip>
              </div>
            ),
          )}
        </div>
      </div>
      {plan.isMostPopular && !isActivePlan && (
        <div className={styles.mostPopular}>
          <span>Most Popular</span>
        </div>
      )}
      {isActivePlan && (
        <div className={styles.mostPopular}>
          <span style={{ background: 'linear-gradient(to right, #aaaaaa, #9b9b9b)' }}>Active Plan</span>
        </div>
      )}
    </Card>
  )
}

export default PricingCard
