import React, { memo, useEffect, useState } from 'react'
import { Layout, message, Spin } from 'antd'
import Sider from 'antd/es/layout/Sider'
import styles from './Chat.module.scss'
import ChatChannels from './ChatChannels'
import ChatTabs from './Tabs/ChatTabs'
import { useLazyGetDialogsQuery, useLazyInitUnifyQuery, useLazyUnifyQuery } from '../../APIs/Chat-API'
import {
  SET_DIALOGS_DATA,
  SET_DIALOGS_METADATA_BULK,
  SET_IS_UNIFY_FINISHED,
  SET_REUNIFY,
  SET_SELECTED_CHAT_RECIPIENT,
  SET_UNIFIED_ACCOUNTS,
  SET_LOADING_LIMIT_COUNT,
} from './state/chatSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { useTagDialog } from './Tag/Hook/useTagDialog'
import empty_state_messages from 'common/assets/svg/empty_state_messages.svg'
import { LoadingOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { useLocalStorage } from 'usehooks-ts'

const { Content } = Layout

const contentStyle: React.CSSProperties = {
  minHeight: 120,
  lineHeight: '120px',
  overflow: 'scroll',
}

const layoutStyle: React.CSSProperties = {
  overflow: 'hidden',
  height: '100%',
  paddingTop: '0px',
  paddingLeft: '0px',
  borderLeft: '1px solid #DFE1E6',
}

const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100%',
  position: 'sticky',
  top: 0,
  left: 0,
}

const Chat: React.FC<{ screenSize: { width: number; height: number } }> = ({ screenSize }) => {
  const [user] = useLocalStorage<any>('user', null)
  const [collapsed, setCollapsed] = useState(false)
  const [getDialogs] = useLazyGetDialogsQuery()
  const [initUnify] = useLazyInitUnifyQuery()
  const [unifyAccounts] = useLazyUnifyQuery()
  const {
    is_unify_finished,
    close_left_sidebar,
    selected_chat_platform,
    reunify,
    thread_from,
    dialogs_data: dialogsData,
    limit_count,
    loading_limit_count,
  } = useAppSelector((state) => state.chat)
  // const [unifiedFailed, setUnifiedFailed] = useState(false)
  const [noAccounts, setNoAccounts] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [isConnected, setIsConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [firstRefresh, setFirstRefresh] = useState(true)
  const [unifiedFailed, setUnifiedFailed] = useState(false)
  const dispatch = useAppDispatch()
  useTagDialog('') // Init tags

  const getAllDialogs = async () => {
    const { data } = await getDialogs({
      thread_from,
      limit_count,
    })
    if (Object.keys(data)?.length === 0) {
      return undefined
    }

    dispatch(SET_DIALOGS_DATA(data))

    const metadata = Object.entries(data).reduce((acc: any, [platform, platformData]: [string, any]) => {
      if (platformData.dialogData && Array.isArray(platformData.dialogData)) {
        platformData.dialogData.forEach((dialog: any) => {
          acc[dialog.id] = {
            pin: dialog.pin,
            priority: dialog.priority,
            meeting_book: dialog.meeting_book,
          }
        })
      }
      return acc
    }, {})

    dispatch(SET_DIALOGS_METADATA_BULK(metadata))

    switch (selected_chat_platform) {
      case 'telegram':
        dispatch(
          SET_SELECTED_CHAT_RECIPIENT({
            ...data?.telegram?.dialogData?.[0],
            senderData: data?.telegram?.dialogData?.[0]?.receiver_number,
          }),
        )
        return data
      case 'instagram':
        return data
      case 'facebook':
        return data
      default:
        break
    }
  }

  const handleReconnect = async () => {
    if (!isConnected) {
      const { data } = await unifyAccounts()
      if (!data?.allSync) {
        setIsConnected(true)
        const { data } = await getDialogs({
          thread_from,
          limit_count,
        })
        dispatch(SET_DIALOGS_DATA(data))
      }
    }
  }

  const debouncedHandleReconnect = debounce(handleReconnect, 300)

  // useEffect(() => {
  //   setDialogsData(dialogs)
  // }, [dialogs])
  // useEffect(() => {
  //   setDialogsData(dialogs_data)
  // }, [dialogs_data])

  useEffect(() => {
    const unifyAllAccounts = async () => {
      const { data: initData } = await initUnify()

      if (initData?.allEmpty) {
        setNoAccounts(true)
        return message.warning('You have 0 accounts connected to Convrt.')
      }
      await getAllDialogs()
      dispatch(SET_IS_UNIFY_FINISHED(true))
      dispatch(SET_UNIFIED_ACCOUNTS(initData))

      const { data: unifyData } = await unifyAccounts()

      if (unifyData?.isUnified === false) {
        setUnifiedFailed(true)
        return message.error('Data could not be synced. Please try again or contact support.')
      } else if (unifyData?.allSync === false) {
        // trigger again
        // message.warning('Sync did not complete, retrying.')
        return setRetryCount((prevCount) => prevCount + 1)
      } else if (unifyData?.allEmpty) {
        setNoAccounts(true)
        return message.warning('You have 0 accounts connected to Convrt.')
      }
      const dialogsRes = await getAllDialogs()

      if (!dialogsRes) {
        setRetryCount((prevCount) => prevCount + 1)
        return
      }

      setIsConnected(true)
      dispatch(SET_UNIFIED_ACCOUNTS(unifyData))
      dispatch(SET_IS_UNIFY_FINISHED(true))
      dispatch(SET_REUNIFY(false))
    }

    if (user?.addons?.unified_inbox && !is_unify_finished) {
      unifyAllAccounts()
    }

    if (reunify) {
      const reunifyData = async () => {
        dispatch(SET_IS_UNIFY_FINISHED(false))
        const { data: unifyData } = await unifyAccounts()

        if (unifyData?.isUnified === false) {
          setUnifiedFailed(true)
          return message.error('Data could not be synced. Please try again or contact support.')
        } else if (unifyData?.allSync === false) {
          return setRetryCount((prevCount) => prevCount + 1)
        } else if (unifyData?.allEmpty) {
          setNoAccounts(true)
          return message.warning('You have 0 accounts connected to Convrt.')
        }
        const dialogsRes = await getAllDialogs()

        if (!dialogsRes) {
          setRetryCount((prevCount) => prevCount + 1)
          return
        }

        setIsConnected(true)
        dispatch(SET_UNIFIED_ACCOUNTS(unifyData))
        dispatch(SET_IS_UNIFY_FINISHED(true))
        dispatch(SET_REUNIFY(false))
      }
      reunifyData()
    }
  }, [retryCount, reunify])

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const { data } = await getDialogs({ thread_from, limit_count })
      dispatch(SET_DIALOGS_DATA(data))
      setLoading(false)
    }
    if (is_unify_finished) {
      fetch()
    }
  }, [thread_from])

  useEffect(() => {
    const fetch = async () => {
      await getAllDialogs()
      dispatch(SET_LOADING_LIMIT_COUNT(false))
    }
    if (is_unify_finished) {
      fetch()
    }
  }, [limit_count])

  useEffect(() => {
    document.addEventListener('visibilitychange', debouncedHandleReconnect)
    window.addEventListener('click', debouncedHandleReconnect)

    if (dialogsData) {
      const outOfSync = Object.values(dialogsData).every((accountType: any) =>
        accountType.accounts.every((account: any) => account.status.is_syncing === false),
      )

      if (outOfSync) {
        setIsConnected(false)
      } else {
        setIsConnected(true)
      }
    }

    return () => {
      document.removeEventListener('visibilitychange', debouncedHandleReconnect)
      window.removeEventListener('click', debouncedHandleReconnect)
    }
  }, [isConnected, dialogsData])

  const LoadingOverlay = memo(() => (
    <div className={styles.loadingOverlay}>
      <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      <span style={{ margin: '10px 0px' }}>Syncing Data...</span>
    </div>
  ))

  const FailedOverlay = memo(() => (
    <div className={styles.loadingOverlay}>
      <span style={{ margin: '10px 0px' }}>
        Syncing Data Did not complete. Please try again later or contact support.
      </span>
    </div>
  ))

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    if (!collapsed && close_left_sidebar) {
      setCollapsed(true)
    }
  }, [close_left_sidebar])

  if (noAccounts) {
    return (
      <div className={styles.emptyState}>
        <img src={empty_state_messages} alt='empty' />
        <p>No Accounts are Connected to Convrt</p>
        <span>
          Please connect your first account through the{' '}
          <a href='/settings' className={styles.settingsLink}>
            Settings Page
          </a>
        </span>
      </div>
    )
  }

  return (
    <>
      {unifiedFailed && <FailedOverlay />}
      {((!is_unify_finished && user?.addons?.unified_inbox && !unifiedFailed) || loading_limit_count) && (
        <LoadingOverlay />
      )}
      <Layout>
        <Sider
          className='chat_sider'
          collapsible
          collapsed={collapsed}
          onCollapse={handleCollapse}
          width={Math.max(screenSize.width * 0.2, 300)}
          collapsedWidth={126}
          style={siderStyle}>
          <div className={styles.top_section}>
            <h3>Channels</h3>
            {/* <Button className='hidebtn_collapse'>Manage</Button> */}
          </div>
          <div className='hide_collapse'>
            <p className='para'>Select Channel to view chats</p>
            <ChatChannels collapsed={collapsed} firstRefresh={firstRefresh} setFirstRefresh={setFirstRefresh} />
          </div>
        </Sider>
        <Content>
          <Layout style={layoutStyle}>
            <Content className='customScrollContent flex flex-row' style={contentStyle}>
              <ChatTabs loading={loading} screenSize={screenSize} />
            </Content>
          </Layout>
        </Content>
      </Layout>
    </>
  )
}

export default Chat
