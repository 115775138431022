import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Table, Button, TableProps, Tooltip, Dropdown, Popconfirm, Col, Divider, Carousel } from 'antd'
import styles from './Leads.module.scss'
// import Icon_table from 'common/assets/png/user_table.png'
// import Google_icon from 'common/assets/svg/google_icon.svg'
// import info_icon from 'common/assets/svg/info_icon.svg'
import lead_icon1 from 'common/assets/svg/facebook_icon.svg'
import lead_icon2 from 'common/assets/svg/lead_icon2.svg'
import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'
// import Enrich_img from 'common/assets/png/Enrich_img.png'
import CustomCollapseFooter from '../UI/CustomCollapseFooter'
import {
  useLazyExposeCompanyPlatformQuery,
  useLazyGetAllLeadsQuery,
  useLazyUpdateDefaultColumnsQuery,
} from 'common/APIs/OutreachApi'
import { useAppDispatch, useAppSelector } from 'state'
// import CustomAccuracyTag from '../UI/CustomAccuracyTag'
import LoadingIcon from 'common/assets/svg/loading_stars.svg'
import afterLoading from 'common/assets/png/after_load.png'
import LeadsModalAndFilters from './LeadsModalAndFilters'
import ImportLeadsModal from '../UI/ImportLeadsModal'
import { ConfirmBox } from 'common/constants/modal.constants'
import { useDisclaimer } from 'common/hooks/useDisclaimer'
import { useLazyGetPointsStoreQuery } from 'common/APIs/SettingsApi'
import {
  columns as all_cols,
  defaultColumnsByAction,
  defaultColumnsByPlatform,
  getMergedColumns,
} from 'common/components/GeneralComponents/ModularTable/ModularColumns'
import {
  RESET_SELECTED_LEADS,
  SET_INITIAL_AI_DATA,
  UPDATE_INITIAL_AI_DATA,
  SET_INITIAL_AI_COMPANIES_DATA,
  REPLACE_SELECTED_LEADS,
  SET_RESET_CAMPAIGN_DATA,
  SET_SELECTED_LEADS,
  UPDATE_SELECTED_LEADS,
  SET_ARE_LEADS_FROM_GROUPS,
  SET_AI_LEADS_TYPE,
  SET_CURRENT_COLLAPSE_KEY,
  RESET_SAVED_PROMPT_PARAMS,
  SET_LAST_PROMPT_PARAMS,
  SET_DEFAULT_COLUMNS,
  SET_STORED_LEADS_FROM_DB,
  SET_MODULAR_TABLE_KEY,
} from '../../state/outreachAICampaignSlice'
import {
  useLazySetRecipientsFromCsvAiQuery,
  useLazyExposePlatformQuery,
  useLazySaveCampaignLeadsQuery,
} from 'common/APIs/OutreachApi'
import { post } from 'common/APIs/axios'
import { v4 as uuidv4 } from 'uuid'
import { ExpandOutlined, LinkOutlined, UpOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import add_to_list from 'common/assets/svg/add_to_list.svg'
import OutOfPointsUseRemaining from 'common/components/GeneralComponents/Modals/OutOfPoints/OutOfPointsUseRemaining'
import AddLeadsModal from '../UI/AddLeadsModal'
import ImportLeadsFromGroupsModal from '../UI/Groups/ImportLeadsFromGroupsModal'
import { useLazyRemoveTempLeadsQuery } from '../../../../APIs/OutreachAI-API'
import { capitalizeText } from 'common/utils/capitalizeText'
import CompanyInfo from './CompanyInfo'
import { getCompaniesColumns } from './LeadsTableColumns'
import AreYouSureModal from 'common/components/GeneralComponents/Modals/AreYouSureModal'
import useCustomMessage from 'common/components/GeneralComponents/Others/useCustomMessage'
import { trackEvent } from 'eventConfig'
import ModularModalAndFilters from './ ModularModalAndFilters'
import ModularTable from 'common/components/GeneralComponents/ModularTable/ModularTable'
import { FancyImage } from 'common/components/GeneralComponents/UI/FancyImage'
import { TagsList } from 'common/components/GeneralComponents/UI/TagsList'
import ImportLeadsFromCsvModal from 'common/components/Campaigns/CreateCampaign/ImportCSV/ImportLeadsFromCsvModal'
interface DataType {
  key: any
  name: string
  lead_photo: any
  company: string
  company_photo: any
  jobTitle: string
  accuracy: any
  location: string
  industry: string
  businessType: string[]
  source: string
  channels: string
  enrich: string
  facebook: any
  linkedin: any
  instagram: any
  twitter: any
  discord: any
  telegram: any
}

interface LeadsProps {
  onBack: () => void
  onNext: () => void
  addLeadsModal?: boolean
  setAddLeadsModal?: any
  screenSize: { width: number; height: number }
}

const Leads: React.FC<LeadsProps> = ({ onBack, onNext, addLeadsModal, setAddLeadsModal, screenSize }) => {
  const [, setInnerHeight] = useState(window.innerHeight)
  const [saveCampaignLeads] = useLazySaveCampaignLeadsQuery()
  const [removeTempLeads] = useLazyRemoveTempLeadsQuery()
  const [outOfPoints, setOutOfPoints] = useState(false)
  const [openGroupsModal, setOpenGroupsModal] = useState(false)
  const [costPoints, setCostPoints] = useState(0)
  const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()
  const [, setCarouselIndex] = useState(0)
  const carouselRef = useRef<any>()
  const [, { data: campaignRecipientsCsvData }] = useLazySetRecipientsFromCsvAiQuery()
  const [refetcExposePlatform, { data: exposePlatformData }] = useLazyExposePlatformQuery()
  const [exposeCompanyPlatform] = useLazyExposeCompanyPlatformQuery()
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const [isMagic, setIsMagic] = useState(false)
  const [modularModalOpen, setModularModalOpen] = useState(false)
  const { contextHolder, showError } = useCustomMessage()
  const [openGroupsFilter, setOpenGroupsFilter] = useState('')

  const {
    saved_prompt_params,
    selected_leads,
    stored_leads_from_db,
    initial_ai_data,
    initial_ai_companies_data,
    active_platforms,
    reset_campaign_data,
    campaign_id,
    are_leads_from_groups,
    ai_leads_type,
    setup_method,
    last_prompt_params,
    current_collapse_key,
    modular_table_key,
  } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)

  const [actions, setActions] = useState(false)
  const [dropdownDisabled, setDropdownDisabled] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])

  const [excludeLeadsInOtherCampaigns, setExcludeLeadsInOtherCampaigns] = useState(true)
  const [exclusePreviouslySent, setExclusePreviouslySent] = useState(true)

  const [showLeadsModalAndFilters, setShowLeadsModalAndFilters] = useState<boolean>(false)
  const [importFromCsvOpen, setImportFromCsvOpen] = useState(false)
  const [isLoadingCSV, setIsLoadingCSV] = useState(false)
  const [loadingWarning, setLoadingWarning] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(
    selected_leads.length > 0 ? (are_leads_from_groups ? 'groupsTable' : 'table') : 'chooseImportMethod',
  )
  const [openImportLeadsModal, setOpenImportLeadsModal] = useState(false)
  const [filteredLeads, setFilteredLeads] = useState<DataType[]>([])
  const [AIData, setAIData] = useState({
    data: initial_ai_data?.data,
    total_leads:
      initial_ai_companies_data?.total_leads || initial_ai_data?.data?.length || initial_ai_data?.total_leads || 0,
  })
  // const [AICompaniesData, setAICompaniesData] = useState({
  //   data: initial_ai_companies_data?.data,
  //   total_leads: initial_ai_companies_data?.data?.length || initial_ai_companies_data?.total_leads || 0,
  // })
  const [, setKey] = useState(0)
  const [updateDefaultColumns] = useLazyUpdateDefaultColumnsQuery()

  const [getAllLeads, { data: leads_data, isFetching }] = useLazyGetAllLeadsQuery()
  const { data: leadsDataFetched, total_leads } = leads_data ?? {}
  const dispatch = useAppDispatch()

  const [userPoints, setUserPoints] = useState<any>()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const compareParams = (params1: any, params2: any) => {
    if (params1 === undefined || params2 === undefined) {
      return false
    }
    return JSON.stringify(params1) === JSON.stringify(params2)
  }
  const updateInitialAiCompaniesData = (newVal: any) => {
    const oldData = initial_ai_companies_data?.data
    // Find the index of the object to replace
    const index = oldData.findIndex((item: any) => item.id === newVal.id)

    // If the object is found, replace it; otherwise, just add the new object
    let updatedData
    if (index !== -1) {
      updatedData = [...oldData.slice(0, index), newVal, ...oldData.slice(index + 1)]
    } else {
      updatedData = [...oldData, newVal]
    }

    // Dispatch the updated array to the global state
    dispatch(SET_INITIAL_AI_COMPANIES_DATA({ ...initial_ai_companies_data, data: updatedData }))

    setAIData((prev: any) => {
      const data = prev.data
      // Find the index of the object to replace
      const index = data.findIndex((item: any) => item.id === newVal.id)

      // If the object is found, update the array
      if (index !== -1) {
        return { ...prev, data: [...data.slice(0, index), newVal, ...data.slice(index + 1)] }
      }

      // If not found, return the previous state unchanged
      return prev
    })

    const selectedLeadsIndex = selected_leads.findIndex((item: any) => item.key === newVal.id)
    if (selectedLeadsIndex !== -1) {
      const updatedItem = {
        ...selected_leads[selectedLeadsIndex],
        instagram: newVal.instagram,
        linkedin: newVal.linkedin,
        facebook: newVal.facebook,
      }
      dispatch(
        REPLACE_SELECTED_LEADS([
          ...selected_leads.slice(0, selectedLeadsIndex),
          updatedItem,
          ...selected_leads.slice(selectedLeadsIndex + 1),
        ]),
      )
    }
  }

  const companies_columns = getCompaniesColumns({
    userPoints,
    showDisclaimer,
    toggleShowDisclaimer,
    active_platforms,
    setCostPoints,
    setOutOfPoints,
    setShowDisclaimer,
    exposeCompanyPlatform,
    updateInitialAiCompaniesData,
    setIsMagic,
  })

  const toggleLeadsModalAndFilters = async () => {
    const platforms = Object.keys(active_platforms).filter((key) => active_platforms[key] === true)
    if (!showLeadsModalAndFilters === true) {
      // before opening, compare saved prompt params with last prompt params
      // if they are the same, open
      // if they are different, search.
      if (compareParams(saved_prompt_params, last_prompt_params)) {
        setShowLeadsModalAndFilters(!showLeadsModalAndFilters)
      } else {
        setAddLeadsModal(false)
        setCurrentScreen('searching')
        setCarouselIndex(0)
        const { data } = await getAllLeads({
          ...saved_prompt_params,
          platforms,
          table: 'leads',
        })
        dispatch(SET_INITIAL_AI_DATA(data))
        setAIData(data)
      }
    } else {
      // close it
      setShowLeadsModalAndFilters(!showLeadsModalAndFilters)
    }
    dispatch(SET_LAST_PROMPT_PARAMS(saved_prompt_params))
  }

  const handleApplyFilters = (filters: any, resetData?: any) => {
    if (resetData) {
      setFilteredLeads([])
    } else {
      const filteredData = applyFilters(AIData.data, filters)
      setFilteredLeads(filteredData)
    }
  }

  const applyFilters = (dataArr: any, filters: any) => {
    if (!filters) {
      return dataArr
    }

    const data = dataArr?.map((item: any, index: any) => ({
      key: item.id,
      type: item.type || 'leads',
      name: ai_leads_type === 'leads' ? item.full_name : item.name || '',
      lead_photo: item.base_photo_url,
      company: item.company_name || '',
      company_photo: item.company_base_photo_url,
      jobTitle: item.job_title || '',
      accuracy: Math.trunc(item.completion_score * 0.93 * 100),
      location: item.location || '',
      industry: item.industry || '',
      businessType: JSON.parse(item.company_keywords || '[]') || '[]',
      source: 'Convrt.AI',
      channels: 'Channels',
      enrich: 'Enrich',
      company_website: item.company_website,
      company_linkedin: item.company_linkedin,
      facebook: item.facebook,
      linkedin: item.linkedin,
      instagram: item.instagram,
      twitter: item.twitter,
      discord: item.discord,
      telegram: item.telegram,
      index: item.index,
      annual_revenue: item.annual_revenue,
      categories: item.categories,
      description: item.description,
      founded_year: item.founded_year,
      industries: item.industries,
      number_of_employees: item.number_of_employees,
      physical_location: item.physical_location,
      tags: item.tags,
      technologies: JSON.parse(item.technologies || '[]') || '[]',
      total_funding: item.total_funding,
    }))
    return data.filter((lead: any) => {
      // For each filter type, if there are selected values, check if the lead matches
      return Object.entries(filters).every(([key, selectedValues]: any) => {
        if (selectedValues.length === 0) return true // No filter applied for this type

        switch (key) {
          case 'company':
            return selectedValues.includes(lead.company)
          case 'location':
            return selectedValues.includes(lead.location)
          case 'industry':
            return selectedValues.includes(lead.industry)
          case 'businessType':
            // For arrays, check if there's at least one match
            return selectedValues.some((value: any) => lead.businessType.includes(value))
          case 'jobTitle':
            return selectedValues.includes(lead.jobTitle)
          case 'accuracy':
            // For accuracy, handle range checks
            return selectedValues.some((range: any) => {
              const [min, max] = range.split('-').map(Number)
              return lead.accuracy >= min && lead.accuracy <= max
            })
          case 'source':
            return selectedValues.includes(lead.source)
          case 'channels':
            // Check non-null social media fields against selected channels
            const channels = ['facebook', 'linkedin', 'instagram', 'twitter', 'discord']
            const activeChannels = channels.filter((channel) => lead[channel])
            return selectedValues.every((val: any) => activeChannels.includes(val))
          default:
            return true
        }
      })
    })
  }

  const leadsData = useMemo(
    () =>
      AIData.data?.map((item: any, index: any) => ({
        key: item.id,
        type: item.type || 'leads',
        name: ai_leads_type === 'leads' ? item.full_name : item.name || '',
        lead_photo: item.base_photo_url,
        company: item.company_name || '',
        company_photo: item.company_base_photo_url,
        jobTitle: item.job_title || '',
        accuracy: Math.trunc(item.completion_score * 0.93 * 100),
        location: item.location || '',
        industry: item.industry || '',
        businessType: JSON.parse(item.company_keywords || '[]') || '[]',
        source: 'Convrt.AI',
        channels: 'Channels',
        enrich: 'Enrich',
        company_website: item.company_website,
        company_linkedin: item.company_linkedin,
        facebook: item.facebook,
        linkedin: item.linkedin,
        instagram: item.instagram,
        twitter: item.twitter,
        discord: item.discord,
        telegram: item.telegram,
        index: item.index,
        annual_revenue: item.annual_revenue,
        categories: item.categories,
        description: item.description,
        founded_year: item.founded_year,
        industries: item.industries,
        number_of_employees: item.number_of_employees,
        physical_location: item.physical_location,
        tags: item.tags,
        technologies: JSON.parse(item.technologies || '[]') || '[]',
        total_funding: item.total_funding,
      })),
    [AIData],
  )

  const selectedLeadsData: DataType[] = selected_leads?.map((item: any, index: any) => ({
    key: item.key || '',
    type: item.type,
    name: item.name || '',
    lead_photo: item.lead_photo,
    company: item.company || '',
    company_photo: item.company_photo,
    jobTitle: item.jobTitle || '',
    accuracy: item.accuracy,
    location: item.location || '',
    industry: item.industry || '',
    businessType: Array.isArray(item.businessType) ? [...item.businessType] : [],
    source: item.source,
    channels: item.channels,
    enrich: item.enrich,
    company_website: item.company_website,
    company_linkedin: item.company_linkedin,
    facebook: item.facebook,
    linkedin: item.linkedin,
    instagram: item.instagram,
    twitter: item.twitter,
    discord: item.discord,
    telegram: item.telegram,
    index: item.index,
    campaign_id: campaign_id,
    annual_revenue: item.annual_revenue,
    categories: item.categories,
    description: item.description,
    founded_year: item.founded_year,
    industries: item.industries,
    number_of_employees: item.number_of_employees,
    physical_location: item.physical_location,
    tags: item.tags,
    technologies: item.technologies,
    total_funding: item.total_funding,
    platform: item.platform,
    convrt_source: item.convrt_source,
  }))

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Contact Name',
      dataIndex: 'name',
      key: 'name',
      width: 195,
      render: (text: string, data: any) => {
        if (!text) return <></>
        return (
          <span className='flex_items'>
            <FancyImage url={data.lead_photo} className='smallRoundPicture' iscompany={false} />
            <div className={styles.contactName}>
              <Tooltip placement='topLeft' title={text}>
                <span style={{ fontWeight: 600 }}>{text?.length > 16 ? `${text.substring(0, 16)}...` : text}</span>
              </Tooltip>{' '}
              <Tooltip placement='topLeft' title={data.jobTitle}>
                <span style={{ fontSize: '12px' }}>
                  {data?.jobTitle?.length > 15 ? `${data.jobTitle.substring(0, 15)}...` : data.jobTitle}
                </span>
              </Tooltip>
            </div>
          </span>
        )
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 220,
      render: (text: string, data: any) => (
        <span className='flex_items'>
          {data.company_photo !== '' && (
            <FancyImage url={data.company_photo} className='smallRoundPicture' iscompany={false} />
          )}
          <div className={styles.companyDiv}>
            <div>
              <Tooltip placement='topLeft' title={text}>
                <span>{text?.length > 17 ? `${text.substring(0, 17)}...` : text}</span>
              </Tooltip>
              <CompanyInfo data={data} />
            </div>
            <div className={styles.logos}>
              <Tooltip title={data.company_website ? 'Visit Website' : 'No Website Available'}>
                <LinkOutlined
                  onClick={() => data.company_website && window.open(data.company_website, '_blank')}
                  style={{
                    color: data.company_website ? '#1890ff' : 'gray',
                    cursor: data.company_website ? 'pointer' : 'default',
                  }}
                />
              </Tooltip>
              <Tooltip title={data.company_linkedin ? 'Visit LinkedIn' : 'No LinkedIn Available'}>
                <img
                  onClick={() => data.company_linkedin && window.open(data.company_linkedin, '_blank')}
                  src={lead_icon4}
                  alt='LinkedIn'
                  style={{
                    filter: data.company_linkedin ? 'none' : 'grayscale(100%)',
                    cursor: data.company_linkedin ? 'pointer' : 'default',
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </span>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 240,
      render: (text: string, data: any) => (
        <Tooltip placement='topLeft' title={text}>
          <span>{text?.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      width: 120,
      render: (text: string, data: any) => <span>{capitalizeText(text)}</span>,
    },
    {
      title: 'Business Type',
      dataIndex: 'businessType',
      key: 'businessType',
      width: 145,
      render: (businessTypes: string[]) => {
        return (
          <div className='tblFieldText'>
            {businessTypes?.length > 0 ? <TagsList tags={businessTypes} sliceNum={1} /> : <span>{''}</span>}
          </div>
        )
      },
    },
    // {
    //   title: 'Accuracy',
    //   dataIndex: 'accuracy',
    //   key: 'accuracy',
    //   width: 100,
    //   render: (accuracy: any) => <CustomAccuracyTag number={accuracy} />,
    // },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 100,
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      key: 'channels',
      width: 180,
      render: (text: string, data: any) => {
        const style = 'grayscale(100%)'
        const instagramUrl = data.instagram.startsWith('https://www.instagram.com/')

        const socialMediaIcons = [
          {
            platform: 'facebook',
            url: active_platforms['facebook'] === true && data.facebook !== null ? data.facebook : '',
            icon: lead_icon1,
            index: data.index,
            source: data.source,
          },
          {
            platform: 'instagram',
            url:
              active_platforms['instagram'] === true && data.instagram !== null && data.instagram !== ''
                ? instagramUrl
                  ? `${data.instagram}`
                  : `https://instagram.com/${data.instagram}`
                : '',
            icon: lead_icon2,
            index: data.index,
            source: data.source,
          },
          {
            platform: 'telegram',
            url: active_platforms['telegram'] === true && data.telegram !== null ? data.telegram : '',
            icon: lead_icon3,
            index: data.index,
            source: data.source,
          },
          {
            platform: 'linkedin',
            url: active_platforms['linkedin'] === true && data.linkedin !== null ? data.linkedin : '',
            icon: lead_icon4,
            index: data.index,
            source: data.source,
          },
          {
            platform: 'discord',
            url: active_platforms['discord'] === true && data.discord !== null ? data.discord : '',
            icon: lead_icon5,
            index: data.index,
            source: data.source,
          },
          {
            platform: 'twitter',
            url: active_platforms['twitter'] === true && data.twitter !== null ? data.twitter : '',
            icon: lead_icon6,
            index: data.index,
            source: data.source,
          },
        ]

        return (
          <div className='img_group'>
            {socialMediaIcons.map(({ platform, url, icon, index, source }) => {
              return (
                <>
                  {url.length === 0 && source !== 'CSV' ? (
                    <Popconfirm
                      title={`Get ${platform.charAt(0).toUpperCase() + platform.slice(1)} Details`}
                      description={
                        <Col>
                          <div>{`This action will cost you ${
                            userPoints?.pointsStore[`magic_${platform}`]
                          } Magic point. Read disclaimer:`}</div>
                          {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                          {showDisclaimer ? (
                            <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                              {ConfirmBox.DISCLAIMER}
                            </p>
                          ) : (
                            <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                              {'Disclaimer'}
                            </span>
                          )}
                        </Col>
                      }
                      onCancel={() => {
                        toggleShowDisclaimer()
                        setShowDisclaimer(false)
                      }}
                      onConfirm={async () => {
                        toggleShowDisclaimer()
                        setShowDisclaimer(false)
                        const dataExposePlatform = await refetcExposePlatform({
                          index: [index],
                          platform: [platform],
                          isBusiness: ai_leads_type === 'companies_leads' ? true : false,
                        })

                        if (dataExposePlatform?.error?.status === 470) {
                          if (dataExposePlatform?.error?.data?.type === 'magic_points') {
                            setIsMagic(true)
                          } else {
                            setIsMagic(false)
                          }
                          setCostPoints(dataExposePlatform?.error?.data?.cost_point)
                          setOutOfPoints(true)
                        } else {
                          const data = dataExposePlatform?.data?.leads_index?.[0]?.[platform]
                          if (platform === 'instagram') {
                            const instagramUrl = data.startsWith('https://www.instagram.com/')
                              ? data
                              : `https://www.instagram.com/${data}`
                            window.open(instagramUrl, '_blank')
                          } else {
                            window.open(data, '_blank')
                          }
                        }
                      }}
                      disabled={!active_platforms[platform] ? true : false}>
                      <img
                        key={platform}
                        style={{
                          filter: 'style',
                          opacity: active_platforms[platform] ? 1 : 0.25, //!url ? 0.25 : 1,
                          cursor: 'pointer', //url ? 'pointer' : 'default',
                          height: '20px',
                          width: '20px',
                        }}
                        src={icon}
                        alt={platform}
                      />
                    </Popconfirm>
                  ) : (
                    <img
                      key={platform}
                      onClick={() => url && window.open(url, '_blank')}
                      style={{
                        filter: !url ? style : '',
                        opacity: !url ? 0.25 : 1,
                        cursor: url ? 'pointer' : 'default',
                        height: '20px',
                        width: '20px',
                      }}
                      src={icon}
                      alt={platform}
                    />
                  )}
                </>
              )
            })}
          </div>
        )
      },
    },
  ]

  const handleBack = () => {
    onBack()
    setKey((prevKey) => prevKey + 1) // Change key to re-mount component when going back
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setDropdownDisabled(selectedRows.length <= 0)
      setSelectedRowKeys(selectedRows)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  }

  useEffect(() => {
    if (leadsDataFetched) {
      if (isFetching) {
        setCurrentScreen('searching')
        setCarouselIndex(0)
      } else {
        if (selected_leads?.length === 0) {
          setCurrentScreen(leadsDataFetched?.length === 0 ? 'noResults' : 'greatNews')
        } else {
          if (are_leads_from_groups) {
            setCurrentScreen('groupsTable')
          } else {
            setCurrentScreen('table')
          }
        }
      }
    }

    if (are_leads_from_groups && !isFetching) {
      setCurrentScreen('groupsTable')
    }
    // check the dependencies
  }, [leadsDataFetched, selected_leads, are_leads_from_groups, ai_leads_type])

  useEffect(() => {
    if (initial_ai_data?.data !== undefined) {
      dispatch(UPDATE_INITIAL_AI_DATA(exposePlatformData))
    }

    if (selected_leads !== undefined && exposePlatformData) {
      dispatch(UPDATE_SELECTED_LEADS(exposePlatformData))
    }
  }, [exposePlatformData])

  useEffect(() => {
    if (ai_leads_type === 'leads' && initial_ai_data?.data !== undefined) {
      setAIData({
        data: initial_ai_data?.data,
        total_leads: initial_ai_data?.total_leads || initial_ai_data?.data?.length || 0,
      })
    } else if (ai_leads_type === 'companies_leads' && initial_ai_companies_data?.data !== undefined) {
      setAIData({
        data: initial_ai_companies_data?.data,
        total_leads: initial_ai_companies_data?.total_leads || initial_ai_companies_data?.data?.length || 0,
      })
    }
  }, [ai_leads_type, initial_ai_data, initial_ai_companies_data])

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth])

  const handleAILeadsType = async (type: string) => {
    const platforms = Object.keys(active_platforms).filter((key) => active_platforms[key] === true)
    trackEvent(type === 'leads' ? 'USE_AI_TO_GENERATE_LEADS' : 'USE_AI_TO_GENERATE_COMPANIES_LEADS')
    dispatch(SET_AI_LEADS_TYPE(type))

    // TODO: if there are no leads selected, and default columns is the same as defaultcolumns[platform], change default columns
    if (selected_leads.length === 0) {
      const default_cols =
        type === 'leads' ? defaultColumnsByAction.ai_leads : defaultColumnsByAction.ai_leads_companies
      dispatch(SET_DEFAULT_COLUMNS(default_cols))
    }
    const initialData = type === 'leads' ? initial_ai_data : initial_ai_companies_data
    const action = type === 'leads' ? SET_INITIAL_AI_DATA : SET_INITIAL_AI_COMPANIES_DATA
    if (initialData?.data?.length > 0 && compareParams(saved_prompt_params, last_prompt_params)) {
      setAddLeadsModal(false)
      setShowLeadsModalAndFilters(true)
    } else {
      setAddLeadsModal(false)
      setCurrentScreen('searching')
      setCarouselIndex(0)
      const { data } = await getAllLeads({
        ...saved_prompt_params,
        platforms,
        table: type === 'leads' ? 'leads' : 'companies',
      })
      dispatch(action(data))
      setAIData(data)

      if (selected_leads?.length > 0) {
        if (data?.data?.length === 0) {
          showError('No matching data found. Please refine your selection criteria and try again.')
        } else {
          setShowLeadsModalAndFilters(true)
        }
      }
    }
    // set state of last
    dispatch(SET_LAST_PROMPT_PARAMS(saved_prompt_params))
  }

  const handleAILeads = () => handleAILeadsType('leads')
  const handleAICompanies = () => handleAILeadsType('companies_leads')

  const handleGroupsLeads = async () => {
    trackEvent('USE_GROUPS_TO_FIND_LEADS')
    setAddLeadsModal(false)
    setOpenGroupsModal(true)
    if (selected_leads.length === 0) {
      dispatch(SET_DEFAULT_COLUMNS(defaultColumnsByPlatform?.[truePlatformsArray[0]] || []))
    }
  }

  const handleFilteredGroups = async (type: string) => {
    setOpenGroupsFilter(type)
    trackEvent('USE_GROUPS_TO_FIND_LEADS')
    setAddLeadsModal(false)
    setOpenGroupsModal(true)
    if (selected_leads.length === 0) {
      dispatch(SET_DEFAULT_COLUMNS(defaultColumnsByPlatform?.[truePlatformsArray[0]] || []))
    }
  }

  // const handleImportLeads = () => {
  //   post('/log', {
  //     action: `Import Your Own Leads`,
  //   })
  //   setOpenImportLeadsModal(true)
  // }

  const handleResetPrompt = () => {
    dispatch(RESET_SAVED_PROMPT_PARAMS({}))
    dispatch(SET_CURRENT_COLLAPSE_KEY('2'))
    setCurrentScreen('chooseImportMethod')
  }

  // New Search Screen:

  const messages = [
    'Convrt AI is searching for leads...',
    'Getting the relevant leads based on the ICP...',
    'Preparing the leads...',
  ]

  useEffect(() => {
    let interval: NodeJS.Timeout
    interval = setInterval(() => {
      setCarouselIndex((prevIndex) => {
        const newIndex = prevIndex + 1
        if (newIndex < messages.length) {
          if (carouselRef.current) {
            carouselRef.current.goTo(newIndex)
          }
          return newIndex
        }
        return prevIndex
      })
    }, 6000)

    return () => clearInterval(interval)
  }, [])

  const handleRemoveFromTable = async () => {
    setLoadingWarning(true)
    const result = selected_leads.filter(
      (item: any) => !selectedRowKeys.some((filterItem: any) => filterItem.key === item.key),
    )

    const indexes = selectedRowKeys?.map((row: any) => row.key)
    const keys = selectedRowKeys?.map((row: any) => row.index)
    setSelectedRowKeys([])
    dispatch(RESET_SELECTED_LEADS(result))
    const afterRemoval = stored_leads_from_db?.filter((item: any) => !keys.includes(item))
    dispatch(SET_STORED_LEADS_FROM_DB(afterRemoval))
    await removeTempLeads({ campaign_id: campaign_id, indexes: indexes })
    // should also remove from camp
    setDropdownDisabled(true)
    setActions(false)
    setLoadingWarning(false)
    setOpenWarningModal(false)
  }

  const handleWarning = () => {
    setOpenWarningModal(true)
  }

  const searchingScreen = (
    <div className={styles.container}>
      <div className={styles.searchingLeads} style={{ minHeight: 0 }}>
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
          <img src={LoadingIcon} className='loading-image' alt='' />
        </div>
        <h1>Convrt AI is searching for leads...</h1>
      </div>
      <div className={styles.carousel}>
        <Carousel
          autoplay={false}
          dots={false}
          ref={carouselRef}
          effect='scrollx'
          style={{ textAlign: 'center' }}
          // style={{ height: 22, lineHeight: 'normal' }}
        >
          {messages.map((message, index) => (
            <p key={index} style={{ color: '#666 !important' }}>
              {message}
            </p>
          ))}
        </Carousel>
      </div>
    </div>
  )

  const greatNewsScreen = (
    <div className={styles.container}>
      <div className={styles.searchingLeads}>
        <div className='loading-section'>
          <img src={afterLoading} alt='' />
        </div>
        <h1>Great News! </h1>
        <h2>
          Convrt AI has found{' '}
          {total_leads === undefined
            ? Number(
                (ai_leads_type === 'leads' ? initial_ai_data : initial_ai_companies_data)?.total_leads,
              )?.toLocaleString() || '0'
            : Number(total_leads)?.toLocaleString()}{' '}
          leads that can match your campaign!
        </h2>
        <p>
          To proceed, you need to include leads in your campaign. Simply click
          <br /> the button below to begin adding your preferred leads.
        </p>
        {/* <Button type='primary' onClick={handleShowClick} > */}
        <Button style={{ margin: 0 }} type='primary' onClick={toggleLeadsModalAndFilters}>
          Add Leads by AI
        </Button>
        <div className={styles.allMethods}>
          <span onClick={() => setAddLeadsModal(true)}>All Import Methods</span>
        </div>
      </div>
    </div>
  )

  const noResultsScreen = (
    <div className={styles.container}>
      <div className={styles.searchingLeads}>
        <div className='loading-section'>
          <img src={afterLoading} alt='' />
        </div>
        <h1>Sorry, no results were found by Convrt AI.</h1>
        <h2>Currently, there are no leads that match your campaign criteria.</h2>
        <p>
          To enhance your search, consider adjusting your prompt in the Campaign Type section.
          <br />
          Trying different keywords or parameters might yield better results, or you can use your own leads.
        </p>
        <Button style={{ margin: 0 }} type='primary' onClick={handleResetPrompt}>
          Reset AI Prompt
        </Button>
        <div className={styles.allMethods}>
          <span onClick={() => setAddLeadsModal(true)}>All Import Methods</span>
        </div>
      </div>
    </div>
  )

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.actions}>
          <img src={add_to_list} alt='' />
          <span>Remove from table</span>
        </div>
      ),
      onClick: stored_leads_from_db?.length > 0 ? handleWarning : handleRemoveFromTable,
      disabled: false,
    },
  ]

  const tableScreen = (
    <div className={styles.container}>
      {modularModalOpen && (
        <ModularModalAndFilters
          open={modularModalOpen}
          onClose={() => setModularModalOpen(false)}
          selectedLeadsData={[...selected_leads]}
        />
      )}
      <div className={styles.matchesLine}>
        <span>
          <span>{selectedRowKeys?.length} Selected</span>
          <Divider type='vertical' />
          {/* Total of are/is included in the campaign */}
          Total of{' '}
          <span style={{ fontWeight: 600 }}>
            {selectedLeadsData ? selectedLeadsData?.length : 0} lead{selectedLeadsData?.length === 1 ? '' : 's'}
          </span>{' '}
          {selectedLeadsData?.length === 1 ? 'is' : 'are'} included in the campaign
        </span>
        <div className={styles.importLeads}>
          <div style={{ display: 'flex', gap: 15, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Tooltip title='Expand'>
              <Button
                ghost
                type='primary'
                style={{ border: 'none', opacity: 1, display: 'flex', alignItems: 'center', padding: 0, margin: 0 }}
                onClick={() => setModularModalOpen(true)}>
                <ExpandOutlined style={{ cursor: 'pointer', zIndex: 50 }} width={10} height={10} />
              </Button>
            </Tooltip>
            <span style={{ cursor: 'pointer' }} onClick={() => setAddLeadsModal(true)}>
              Add More Leads
            </span>
          </div>
        </div>
      </div>

      {/* <Table
        key={key}
        scroll={{ x: 'max-content', y: innerHeight < 900 ? 230 : 300 }}
        tableLayout='auto'
        dataSource={selectedLeadsData}
        columns={ai_leads_type === 'leads' ? columns : companies_columns}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: selectedLeadsData.length,
          pageSize: 50,
          showSizeChanger: false,
        }}
        className='Leads_Table'
      /> */}
      <ModularTable
        openDrawer={false}
        setOpenDrawer={() => {}}
        key={modular_table_key}
        scroll={{ x: 'max-content', y: window.innerHeight < 900 ? 230 : 300 }}
        tableLayout='auto'
        data={selected_leads}
        rootClassName='ModularTableNotExpanded'
        columns={all_cols}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: selectedLeadsData?.length,
          pageSize: 50,
          showSizeChanger: false,
        }}
        style={{ width: '100%', height: '100%' }}
      />
      <CustomCollapseFooter
        id='4'
        onBack={handleBack}
        // {...(!block_editing && { onBack: handleBack })}

        {...(onNext ? { onNext: onNext } : {})}
        leftButton={
          <Dropdown disabled={dropdownDisabled} menu={{ items }} placement='top' trigger={['click']} arrow>
            <div className={`${styles.actionsButton} ${dropdownDisabled && styles.disabledActionsButton}`}>
              <span>Actions</span>
              <UpOutlined
                style={{ color: dropdownDisabled ? 'lightgray' : '#7043ff', fontSize: '11px' }}
                rotate={actions ? 180 : 0}
              />
            </div>
          </Dropdown>
        }
      />
    </div>
  )

  const groupsTableScreen = (
    <div className={styles.container}>
      {modularModalOpen && (
        <ModularModalAndFilters
          open={modularModalOpen}
          onClose={() => setModularModalOpen(false)}
          selectedLeadsData={[...selected_leads]}
        />
      )}
      <div className={styles.matchesLine}>
        <span>
          You selected <span style={{ fontWeight: 600 }}>{selected_leads ? selected_leads?.length : 0} leads</span> to
          the campaign
        </span>
        <div className={styles.importLeads}>
          <Tooltip title='Expand'>
            <Button
              ghost
              type='primary'
              style={{ border: 'none', opacity: 1, padding: 0, margin: 0, marginBottom: 2 }}
              onClick={() => setModularModalOpen(true)}>
              <ExpandOutlined style={{ cursor: 'pointer', zIndex: 50 }} width={10} height={10} />
            </Button>
          </Tooltip>
          <span style={{ cursor: 'pointer' }} onClick={() => setAddLeadsModal(true)}>
            Add More Leads
          </span>
        </div>
      </div>
      {/* <Table
        key={key}
        scroll={{ x: 500 }}
        dataSource={selected_leads}
        columns={getGroupsColumns(
          selected_leads?.[0]?.platform || (selected_leads?.[0]?.source_type === 'instagram_group' && 'instagram'),
        )}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: selected_leads.length,
          pageSize: 5,
          showSizeChanger: false,
        }}
        className='Leads_Table'
      /> */}
      <ModularTable
        openDrawer={false}
        setOpenDrawer={() => {}}
        key={modular_table_key}
        scroll={{ x: 'max-content', y: window.innerHeight < 900 ? 230 : 300 }}
        tableLayout='auto'
        data={selected_leads}
        columns={all_cols}
        rootClassName='ModularTableNotExpanded'
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: selectedLeadsData?.length,
          pageSize: 50,
          showSizeChanger: false,
        }}
        style={{ width: '100%', height: '100%' }}
      />
      <Dropdown disabled={dropdownDisabled} menu={{ items }} placement='top' trigger={['click']} arrow>
        <Button className={styles.actionsButton} onClick={() => {}}>
          <span>Actions</span>
          <UpOutlined
            style={{ color: dropdownDisabled ? 'lightgray' : '#7043ff', fontSize: '11px' }}
            rotate={actions ? 180 : 0}
          />
        </Button>
      </Dropdown>
      <CustomCollapseFooter id='4' onBack={handleBack} onNext={onNext} />
    </div>
  )

  const chooseImportMethodScreen = (
    <div className={styles.container}>
      <div className={styles.searchingLeads}>
        <div className='loading-section'>
          <img src={afterLoading} alt='' />
        </div>
        <h1>Add New Leads</h1>
        {/* <h2>Maximize your campaign's potential by selecting the method that best suits your needs.</h2>
        <p style={{ paddingBottom: '10px' }}>
          Use the power of AI and let our smart technology do the heavy lifting to discover and generate leads tailored
          to your goals,
          <br />
          or take a hands-on approach and import your own leads to ensure every contact meets your specific criteria.
        </p> */}
        <p style={{ marginBottom: '0px' }}>To proceed, you need to include leads in your campaign.</p>
        <p>Simply click the button below to begin adding your preferred leads.</p>
        <div className={styles.buttons}>
          <Tooltip title={truePlatformsArray?.length === 0 ? 'Please choose a platform to continue' : ''}>
            <Button
              type='primary'
              disabled={truePlatformsArray?.length === 0}
              onClick={() => {
                setAddLeadsModal(true)
              }}>
              Add Campaign Leads
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  )

  const onClickCsv = async () => {
    trackEvent('IMPORT_LEADS_FROM_CSV')
    setAddLeadsModal(false)
    setImportFromCsvOpen(true)
    if (selected_leads.length === 0) {
      dispatch(SET_DEFAULT_COLUMNS(defaultColumnsByAction.csv))
    }
  }

  const onClickList = () => {
    post('/log', {
      action: `Import Leads From List`,
    })
  }

  const handleOnFinishImportFromCSV = async (campaign_id_tmp: any, campaign_name_tmp: any, csvData: any) => {
    setIsLoadingCSV(true)

    const recipientsCsvAi = []
    for (const [, item] of csvData.entries()) {
      recipientsCsvAi.push({
        key: uuidv4(),
        name: item.full_name || '',
        lead_photo: item.lead_photo || '',
        company: item.company || item.company_name || '',
        company_photo: item.company_photo || '',
        handle: item.handle,
        jobTitle: item.job_title || '',
        profile_url: item.profile_url || '',
        accuracy: '100',
        location: item.location || '',
        industry: item.industry || '',
        businessType: [],
        source: 'CSV',
        index: null,
        channels: item.channels || '',
        enrich: item.enrich || '',
        custom_1: item.custom_1 || '',
        custom_2: item.custom_2 || '',
        facebook: active_platforms.facebook === true ? item.handle : '', //item.facebook || '',
        linkedin: active_platforms.linkedin === true ? item.handle : '', //item.linkedin || '',
        instagram: active_platforms.instagram === true ? item.handle : '', //item.instagram || '',
        twitter: active_platforms.twitter === true ? item.handle : '', //item.twitter || '',
        discord: active_platforms.discord === true ? item.handle : '', //item.discord || '',
        telegram: active_platforms.telegram === true ? item.handle : '', //item.telegram || '',
        convrt_source: 'CSV',
        platform: truePlatformsArray[0],
      })
    }

    await saveCampaignLeads({
      leads: recipientsCsvAi,
      campaign_id: campaign_id,
      mode: 'csv',
    })

    setIsLoadingCSV(false)
    setImportFromCsvOpen(false)
    setOpenImportLeadsModal(false)

    setCurrentScreen('table')

    const handleSet = new Set(selected_leads.map((item: any) => item.handle))
    const tmpData = recipientsCsvAi.filter((item: any) => !handleSet.has(item.handle))
    const columns = getMergedColumns([
      ...selected_leads,
      ...tmpData.map((item: any) => ({ ...item, convrt_source: 'CSV' })),
    ])
    dispatch(SET_DEFAULT_COLUMNS(columns))
    dispatch(SET_MODULAR_TABLE_KEY(columns.toString()))
    await updateDefaultColumns({ campaign_id: campaign_id, default_columns: columns })

    dispatch(SET_SELECTED_LEADS(tmpData))
  }

  useEffect(() => {
    if (reset_campaign_data) {
      setCurrentScreen('chooseImportMethod')
      if (ai_leads_type === 'leads') {
        setAIData({
          data: initial_ai_data?.data,
          total_leads: initial_ai_data?.data?.length || initial_ai_data?.total_leads || 0,
        })
      } else {
        setAIData({
          data: initial_ai_companies_data?.data,
          total_leads: initial_ai_companies_data?.data?.length || initial_ai_companies_data?.total_leads || 0,
        })
      }
      dispatch(SET_RESET_CAMPAIGN_DATA(false))
    }
  }, [reset_campaign_data])

  useEffect(() => {
    if (selected_leads?.length === 0) {
      dispatch(SET_ARE_LEADS_FROM_GROUPS(false))
      setCurrentScreen(
        setup_method === 'manually'
          ? 'chooseImportMethod'
          : initial_ai_data?.data?.length > 0 || initial_ai_companies_data?.data?.length > 0
          ? 'greatNews'
          : 'chooseImportMethod',
      )
    }
  }, [selected_leads, setup_method])

  useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [])

  useEffect(() => {
    if (campaignRecipientsCsvData?.response?.length > 0) {
      setCurrentScreen('table')
      dispatch(SET_SELECTED_LEADS(campaignRecipientsCsvData?.response))
    }
  }, [campaignRecipientsCsvData])

  useEffect(() => {
    if (selected_leads.length > 0 && currentScreen !== 'table' && currentScreen !== 'groupsTable') {
      setCurrentScreen(are_leads_from_groups ? 'groupsTable' : 'table')
    }
  }, [selected_leads])

  const retryWithRemainingPoints = () => {
    setOutOfPoints(false)
  }

  useEffect(() => {
    // if (setup_method === 'manually' && current_collapse_key === '3' && selected_leads?.length === 0) {
    if (current_collapse_key === '2' && selected_leads?.length === 0) {
      setAddLeadsModal(true)
    }
  }, [current_collapse_key, selected_leads])

  return (
    <>
      {contextHolder}
      {currentScreen === 'chooseImportMethod' && chooseImportMethodScreen}
      {currentScreen === 'searching' && searchingScreen}
      {currentScreen === 'greatNews' && greatNewsScreen}
      {currentScreen === 'noResults' && noResultsScreen}
      {currentScreen === 'table' && tableScreen}
      {currentScreen === 'groupsTable' && groupsTableScreen}
      {/* {showFilter && <LeadsFilter open={showFilter} onClose={toggleFilter} onFilter={handleApplyFilters} />} */}
      {showLeadsModalAndFilters && (
        <LeadsModalAndFilters
          open={showLeadsModalAndFilters}
          onClose={toggleLeadsModalAndFilters}
          data={leadsData}
          filteredData={filteredLeads}
          totalData={Number(
            AIData?.total_leads >= 0
              ? AIData?.total_leads
              : initial_ai_data?.total_leads
              ? initial_ai_data?.total_leads
              : initial_ai_companies_data?.total_leads,
          )}
          columns={ai_leads_type === 'leads' ? columns : companies_columns}
          handleApplyFilters={handleApplyFilters}
          isLoading={false}
          setAIData={setAIData}
          AIData={AIData}
        />
      )}
      {openImportLeadsModal && (
        <ImportLeadsModal
          open={openImportLeadsModal}
          onClose={() => setOpenImportLeadsModal(false)}
          onClickCSV={onClickCsv}
          onClickList={onClickList}
        />
      )}

      {importFromCsvOpen && (
        <ImportLeadsFromCsvModal
          open={importFromCsvOpen}
          onClose={() => setImportFromCsvOpen(false)}
          onFinish={handleOnFinishImportFromCSV}
          isLoading={isLoadingCSV}
          setExcludeLeadsInOtherCampaigns={setExcludeLeadsInOtherCampaigns}
          setExclusePreviouslySent={setExclusePreviouslySent}
          excludeLeadsInOtherCampaigns={excludeLeadsInOtherCampaigns}
          exclusePreviouslySent={exclusePreviouslySent}
          showButtonExcludeLeadsInOtherCampaigns={false}
          showButtonExclusePreviouslySent={false}
          fileName={'csv_template_leads_ai.csv'}
          fields={'full_name, company, job_title, handle, custom_1, custom_2'}
        />
      )}

      {addLeadsModal && (
        <AddLeadsModal
          open={addLeadsModal}
          onClose={() => setAddLeadsModal(false)}
          onAi={handleAILeads}
          onAiCompanies={handleAICompanies}
          onCsv={onClickCsv}
          onGroups={handleGroupsLeads}
          onFilteredGroups={handleFilteredGroups}
        />
      )}

      {openGroupsModal && (
        <ImportLeadsFromGroupsModal
          open={openGroupsModal}
          onClose={() => {
            setOpenGroupsModal(false)
            setOpenGroupsFilter('')
          }}
          isLoading={false}
          default_filters={openGroupsFilter}
          screenSize={screenSize}
        />
      )}

      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
          isMagic={isMagic}
          isShowUseRemainingPointsButton={false}
        />
      )}
      {openWarningModal && (
        <AreYouSureModal
          open={openWarningModal}
          onClose={() => setOpenWarningModal(false)}
          onConfirm={handleRemoveFromTable}
          title='Are you sure you want to remove these leads?'
          message={`This action is irreversible and cannot be undone. Please confirm to proceed.`}
          loading={loadingWarning}
        />
      )}
    </>
  )
}

export default Leads
