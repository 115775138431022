import { Button, Popover } from 'antd'
import styles from './CustomHover.module.scss'
import { useState } from 'react'
import { useUser } from 'Utils/UserContext'
interface CustomHoverProps {
  image: any
  title: string
  body: string
  children: any
  navigate: any
  hkey: any
}
export default function CustomHover({ image, title, body, children, navigate, hkey }: CustomHoverProps) {
  const { user } = useUser()
  let topMap: any = {}
  if (user?.user_type === 'web3') {
    topMap = {
      gettingStarted: 15,
      research: 70,
      exposedContacts: 125,
      outreach: 180,
      unified: 235,
      settings: 530,
    }
  } else {
    topMap = {
      gettingStarted: 15,
      outreach: 70,
      home: 125,
      tasks: 180,
      unified: 235,
      settings: 530,
    }
  }
  const [popoverVisible, setPopoverVisible] = useState(false)

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible)
  }

  const content = (
    <div className={styles.popover}>
      <div style={{ width: 400, height: 350, display: 'flex', flexDirection: 'column', gap: 15 }}>
        <img
          src={image}
          alt='Hover'
          style={{ width: '100%', height: 225, borderRadius: '4px', border: ' 1px solid #D9D9D9' }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <span className={styles.title}>{title}</span>
          <span className={styles.body}>{body}</span>
        </div>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'end' }}>
          <Button
            type='primary'
            className={styles.button}
            onClick={(e) => {
              setPopoverVisible(false)
              e.preventDefault()
              e.stopPropagation()
            }}>
            Got it
          </Button>
        </div>
      </div>
    </div>
  )
  return (
    <Popover
      trigger={'hover'}
      placement='right'
      overlayStyle={{
        marginLeft: '40px',
        left: 180,
        width: '440px',
        height: '380px',
        top: topMap?.[hkey],
        borderRadius: '8px',
      }}
      onVisibleChange={handlePopoverVisibleChange}
      visible={popoverVisible}
      rootClassName={hkey === 'settings' ? 'settingsCustomHover' : 'customHover'}
      content={content}>
      {children}
    </Popover>
  )
}
