import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import styles from './WelcomeScreen.module.scss'
import convrt_video_thumbnail from 'common/assets/png/convrt_video_thumbnail.png'
import convrt_logo from 'common/assets/svg/convrt_logo_login.svg'
import { useCalendlyEventListener, PopupButton } from 'react-calendly'
import { trackEvent } from 'eventConfig'
import { useLocalStorage } from 'usehooks-ts'
import SmallLogout from 'common/components/GeneralComponents/UI/SmallLogout/SmallLogout'

interface WelcomeScreenProps {
  updateProgress: (update: any) => void
  onSkipOnboarding?: () => void
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ updateProgress, onSkipOnboarding }) => {
  const [user] = useLocalStorage<any>('user', null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [scheduledEvent, setScheduledEvent] = useState<any>(user?.progress?.payment_status?.scheduledEvent || null)
  const [timeRemaining, setTimeRemaining] = useState<number>(0)

  const handlePlayVideo = () => {
    setIsPlaying(true)
    trackEvent('PLAY_DEMO_CLICKED', {
      where: 'Upgrade',
      user_attributes: {
        subscription_type: user?.subscription_type,
        force_pricing: user?.force_pricing,
        plan_type: user?.plan_type,
        plan: user?.plan,
        settings_id: user?.settings_id,
      },
    })
  }

  const getSchedules = async (URI: string) => {
    const response = await fetch(URI, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
    })

    const data = await response.json()
    setScheduledEvent(data)
    updateProgress({ scheduledEvent: data, welcome_screen_selection: 'Get Onboarding' })
    trackEvent('GET_ONBOARDING', {
      where: 'Upgrade',
      user_attributes: {
        subscription_type: user?.subscription_type,
        force_pricing: user?.force_pricing,
        plan_type: user?.plan_type,
        plan: user?.plan,
        settings_id: user?.settings_id,
      },
    })
  }

  const formatTime = (ms: number) => {
    const days = Math.floor(ms / (1000 * 60 * 60 * 24))
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((ms / (1000 * 60)) % 60)
    // const seconds = Math.floor((ms / 1000) % 60)

    if (days > 0) {
      return `${days}D ${hours}H ${minutes.toString().padStart(2, '0')}M`
    } else {
      return `${hours}H ${minutes.toString().padStart(2, '0')}M`
    }
  }

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      //   trackEvent('ONBOARDING_SCHEDULED')
      getSchedules(e.data.payload.event.uri)
    },
  })

  useEffect(() => {
    // Check if there's a scheduled event
    if (scheduledEvent && scheduledEvent.resource) {
      const startTime = new Date(scheduledEvent.resource.start_time)
      const intervalId = setInterval(() => {
        const now = new Date()
        const timeDiff = startTime.getTime() - now.getTime()
        if (timeDiff <= 0) {
          clearInterval(intervalId)
        }
        setTimeRemaining(Math.max(timeDiff, 0))
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [scheduledEvent])

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={convrt_logo} alt='Convrt Logo' />
      </div>

      <div className={styles.content}>
        <h1 className={styles.title}>Congratulations! 🎉 and Welcome to Convrt AI!</h1>

        <p className={styles.subtitle}>
          You're officially on your way to becoming an Organic Outbound expert.
          <br />
          To get started, watch our short demo video for a quick overview of the platform and how to
          <br />
          make the most of it.
        </p>

        <div className={styles.videoWrapper}>
          {!isPlaying ? (
            <div
              onClick={handlePlayVideo}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}>
              <img
                src={convrt_video_thumbnail}
                alt='Video thumbnail'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '12px',
                }}
              />
            </div>
          ) : (
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                borderRadius: '12px',
              }}
              src='https://www.youtube.com/embed/TUS1c7AuP0k?autoplay=1'
              title='Product Demo'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          )}
        </div>

        <div className={styles.actions}>
          <Button className={styles.skipButton} onClick={onSkipOnboarding}>
            I know the platform
            <br />
            well to start myself
          </Button>

          {scheduledEvent && timeRemaining >= 0 ? (
            <Button className={styles.waitingButton} disabled={true}>
              Meeting starts in:
              <br />
              {formatTime(timeRemaining)}
            </Button>
          ) : (
            <PopupButton
              url='https://calendly.com/d/cmyq-d87-svj/convrt-ai-onboarding-call?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=7043fe'
              text='Get Onboarding'
              rootElement={document.getElementById('root') || document.body}
              className={styles.startButton}
            />
          )}
        </div>
      </div>
      <SmallLogout />
    </div>
  )
}

export default WelcomeScreen
