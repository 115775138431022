import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'
import { Col, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { useDisclaimer } from 'common/hooks/useDisclaimer'
import { ConfirmBox } from 'common/constants/modal.constants'
import OutOfPointsUseRemaining from '../Modals/OutOfPoints/OutOfPointsUseRemaining'
import { useLazyExposePlatformProspectQuery } from 'common/APIs/OutreachApi'
import { useLocation } from 'react-router-dom'
import { SET_MODULAR_TABLE_KEY } from 'common/components/Campaigns/state/outreachAICampaignSlice'
import { useCampaigns } from 'common/components/Campaigns/HomePage/CampaignsTab/Helpers/useCampaigns'

interface ExposePopoverProps {
  platform: string
  children: React.ReactNode
  contact: any
  refetch?: any
}

interface ChannelsProps {
  text: string
  data: any
  refetch?: any
}

const ExposePopover: React.FC<ExposePopoverProps> = ({ platform, children, contact, refetch }: ExposePopoverProps) => {
  const { userPoints } = useAppSelector((state) => state.outreachAICampaign)
  const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()
  const [outOfPoints, setOutOfPoints] = useState(false)
  const [costPoints, setCostPoints] = useState(0)
  const [expose] = useLazyExposePlatformProspectQuery()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { modular_table_key, campaign_status } = useAppSelector((state) => state.outreachAICampaign)
  const params = new URLSearchParams(location.search)
  const urlCampaignId = params.get('campaign_id')
  const { getCampaignSummaryView } = useCampaigns()

  const refetchFallback = async () => {
    const handleGetData = async (campaign_id: string) => {
      const status = campaign_status || 'draft'
      await getCampaignSummaryView(campaign_id, status)
    }
    handleGetData(urlCampaignId || '').then(() => {
      dispatch(SET_MODULAR_TABLE_KEY(modular_table_key + 1))
    })
  }

  return (
    <>
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={() => {}}
          cost_points={costPoints}
          isMagic={false}
          isShowUseRemainingPointsButton={false}
        />
      )}
      <Popconfirm
        title={`Get ${platform.charAt(0).toUpperCase() + platform.slice(1)} Details`}
        description={
          <Col>
            <div>{`This action will cost you ${
              userPoints?.pointsStore?.[`${platform}`]
            } Convrt points. Read disclaimer:`}</div>
            {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
            {showDisclaimer ? (
              <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                {ConfirmBox.DISCLAIMER}
              </p>
            ) : (
              <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                {'Disclaimer'}
              </span>
            )}
          </Col>
        }
        onCancel={() => {
          toggleShowDisclaimer()
          setShowDisclaimer(false)
        }}
        onConfirm={async () => {
          toggleShowDisclaimer()
          setShowDisclaimer(false)
          const dataExposePlatform = await expose({
            entityId: contact.index,
            platform: platform,
          })

          if (dataExposePlatform?.error?.status === 470) {
            setCostPoints(dataExposePlatform?.error?.data?.cost_point)
            setOutOfPoints(true)
          } else {
            const data = dataExposePlatform?.data?.social
            if (platform === 'linkedin') {
              window.open(data, '_blank')
              if (refetch) refetch()
              else refetchFallback()
              return
            }
            window.open(`https://t.me/${data}`, '_blank')
            if (refetch) refetch()
            else refetchFallback()
            return
          }
        }}>
        {children}
      </Popconfirm>
    </>
  )
}
export function ChannelsLists({ text, data, refetch }: ChannelsProps) {
  const style = 'grayscale(100%)'
  const getSocial = (platform: string) => {
    if (data[platform] === '') {
      return 'unexposed'
    }
    if (!data[platform]) {
      return null
    }
    return data[platform]
  }
  const socialMediaIcons = [
    {
      platform: 'telegram',
      url: getSocial('telegram_account') || getSocial('telegram'),
      icon: lead_icon3,
    },
    {
      platform: 'linkedin',
      url: getSocial('linkedin'),
      icon: lead_icon4,
    },
    {
      platform: 'discord',
      url: null,
      icon: lead_icon5,
    },
    {
      platform: 'twitter',
      url: null,
      icon: lead_icon6,
    },
  ]
  const handleOnClick = (url: string, platform: any) => {
    if (url && url !== 'unexposed') {
      if (platform === 'telegram') {
        window.open(`https://t.me/${url}`, '_blank')
        return
      }
      window.open(url, '_blank')
      return
    }
  }
  return (
    <div className='img_group'>
      {socialMediaIcons.map(({ platform, url, icon }) => (
        <>
          {url === 'unexposed' ? (
            <>
              <ExposePopover platform={platform} contact={data} refetch={refetch} key={platform}>
                <img
                  key={platform}
                  style={{
                    filter: !url ? style : '',
                    opacity: !url ? 0.25 : 1,
                    cursor: url ? 'pointer' : 'default',
                  }}
                  src={icon}
                  alt={platform}
                />
              </ExposePopover>
            </>
          ) : (
            <>
              <img
                key={platform}
                onClick={() => handleOnClick(url, platform)}
                style={{
                  filter: !url ? style : '',
                  opacity: !url ? 0.25 : 1,
                  cursor: url ? 'pointer' : 'default',
                }}
                src={icon}
                alt={platform}
              />
            </>
          )}
        </>
      ))}
    </div>
  )
}
