import { Button } from 'antd'
import React from 'react'
import NotSeeing from './NotSeeing'

interface LoadMoreProps {
  loadIncrement: number
  totalLength: number
  onLoadMore: () => void
  onCollectLeads: () => void
  isSmallScreen: boolean
  list?: boolean
}

const LoadMore: React.FC<LoadMoreProps> = ({
  loadIncrement,
  totalLength,
  onLoadMore,
  onCollectLeads,
  isSmallScreen,
  list = false,
}) => {
  return (
    <>
      {loadIncrement < totalLength && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            type='primary'
            onClick={onLoadMore}
            style={{
              fontSize: 18,
              margin: '20px 0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            Load More
          </Button>
        </div>
      )}
      <NotSeeing
        key='notseeing'
        onClick={onCollectLeads}
        isSmallScreen={isSmallScreen}
        list={list}
        style={{ top: 50 + (loadIncrement < totalLength ? 30 : 0) }}
      />
    </>
  )
}

export default LoadMore
