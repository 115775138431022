import styles from './CustomMeetingBook.module.scss'
import { Dropdown, Input } from 'antd'
import threeDots from 'common/assets/svg/three_dots.svg'
import { useRef, useState } from 'react'
import yes from 'common/assets/svg/vSign.svg'
import { useLazyChangeMeetingBookedNameQuery } from '../../../APIs/Chat-API'
import { useLazyGetUserDetailsQuery } from 'common/APIs/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
interface MeetingBookOptionProps {
  tag: any
  icons: any
  handleDeleteTag: any
  getMeetingBooked: any
  setEditVisible: any
  onChange: any
  setDropdownVisible: any
}
export default function MeetingBookOption({
  tag,
  icons,
  handleDeleteTag,
  setEditVisible,
  setDropdownVisible,
  getMeetingBooked,
  onChange,
}: MeetingBookOptionProps) {
  const [nameValue, setNameValue] = useState(tag.name)
  const [isEditing, setIsEditing] = useState(false)
  const [changeNameQuery] = useLazyChangeMeetingBookedNameQuery()
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const [, setUser] = useLocalStorage('user', null)
  const inputRef = useRef<any>(null)

  const onConfirm = async () => {
    setIsEditing(false)
    inputRef.current.blur()
    await changeNameQuery({ tagId: tag.id, newName: nameValue, currentName: tag.name })
    onChange(nameValue)
    await getMeetingBooked()
    setDropdownVisible(false)

    const { data } = await getUserDetails()
    if (data) {
      setUser(data)
    }
  }
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', height: '24px' }}>
      <div className={styles.outcome}>
        <img src={icons.find((icon: any) => icon.name === tag.icon).src} alt='' className={styles.outcomeImage} />
        <Input
          value={nameValue}
          bordered={false}
          ref={inputRef}
          color='black'
          autoFocus={isEditing}
          readOnly={!isEditing}
          onBlur={({ target }) => (isEditing ? target.focus() : null)}
          style={{
            paddingLeft: 1,
            paddingTop: 5,
            paddingBottom: 6,
          }}
          className='meeting-book-option'
          rootClassName='meeting-book-option'
          onChange={(e) => {
            setNameValue(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              onConfirm()
            }
          }}
        />
      </div>
      {tag.deleteable && (
        <>
          {isEditing ? (
            <>
              <img src={yes} alt='Yes' style={{ cursor: 'pointer' }} onClick={onConfirm} />
            </>
          ) : (
            <div
              style={{ display: 'flex', alignItems: 'center', zIndex: 1000, cursor: 'pointer' }}
              className='meetingBookThreeDots'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <div
                          onClick={async () => {
                            handleDeleteTag(tag)
                          }}>
                          Delete
                        </div>
                      ),
                      key: 1,
                    },
                    {
                      label: (
                        <div
                          onClick={async () => {
                            setIsEditing(true)
                            setTimeout(() => {
                              inputRef.current.focus()
                            }, 0)
                          }}>
                          Change Name
                        </div>
                      ),
                      key: 2,
                    },
                  ],
                }}
                onOpenChange={(visible) => {
                  setEditVisible(visible)
                }}>
                <img style={{ zIndex: 1000 }} src={threeDots} alt='threeDots' />
              </Dropdown>
            </div>
          )}
        </>
      )}
    </div>
  )
}
