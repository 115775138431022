import React, { useState } from 'react'
import { Button, Card, Divider, Popover, Tooltip } from 'antd'
import styles from './GroupCard.module.scss'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import locationPin from 'common/assets/svg/locationPin.svg'
import sales_nav from 'common/assets/jpg/linkedin-sales-navigator.jpg'
import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'
import number_of_leads_icon from 'common/assets/svg/number_of_leads_icon.svg'
import { capitalize } from 'lodash'
import groupFallback from 'common/assets/svg/add_leads_group.svg'
import { MyGroupsSwitchOptions, OnboardingsSwitchOptions, CustomEyeButton, CollectLeadsModal } from './MyGroups'
import AreYouSureModal from 'common/components/GeneralComponents/Modals/AreYouSureModal'
import { ClockCircleOutlined, LinkOutlined, WarningOutlined } from '@ant-design/icons'
import hashtag from 'common/assets/png/hashtag.png'
import { CollectLeadsOptionsModal } from './CollectLeadsOptionsModal'
import ShouldJoinGroupModal from './ShouldJoinGroupModal'
import listIcon from 'common/assets/svg/List.svg'
import businessIcon from 'common/assets/svg/businessIcon.svg'
import { FailedCollections } from 'common/components/Campaigns/Helpers/CampaignConstants'
import { FancyImage } from 'common/components/GeneralComponents/UI/FancyImage'
import {
  formatNumber,
  getLogoByPlatform,
  getType,
  getTypeMyHub,
  GroupCardProps,
} from 'common/components/Campaigns/Helpers/GroupHelpers'
import { TagsList } from 'common/components/GeneralComponents/UI/TagsList'

const GroupCard: React.FC<GroupCardProps> = ({
  group,
  onSelect,
  setSelectedGroupName,
  myGroupsTab,
  refinement,
  isSelected,
  onboarding = false,
  pointsStore,
  onLinkedinCollect,
  icpData,
  importData,
  handleFacebookShouldJoin,
  setImportData,
  goToProgress,
  goToMyHub,
  isSmallScreen = false,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [openCollectModal, setOpenCollectModal] = useState(false)
  const [openCollectWithAccountModal, setOpenCollectWithAccountModal] = useState(false)
  const [openNoMoreLeadsModal, setOpenNoMoreLeadsModal] = useState(false)
  const [showShouldJoinModal, setShowShouldJoinModal] = useState(false)
  const [shouldJoinData, setShouldJoinData] = useState<any>(null)

  const isMyGroup = onboarding ? true : myGroupsTab
  const handleSelectGroup = () => {
    if (!openCollectModal) {
      onSelect(group)
      if (group?.is_web3Event || group?.is_web3Group) {
        setSelectedGroupName(group.username)
      } else {
        setSelectedGroupName(group.name || group.username)
      }
    }
  }
  const onContinueCollect = () => {
    myGroupsTab ? setOpenCollectModal(true) : setOpenCollectWithAccountModal(true)
  }
  const handleCollect = async () => {
    if (+group?.number_of_requested_leads >= +group?.leads && group.source_type !== 'hashtag') {
      setOpenNoMoreLeadsModal(true)
    } else {
      if (group.platform === 'linkedin') {
        await onLinkedinCollect(group)
      }
      if (group.platform === 'facebook' && !group.mine && group.locked) {
        const result = await handleFacebookShouldJoin(group)
        if (result === false) {
          setShowShouldJoinModal(true)
          setShouldJoinData(group)
          return
        } else {
          if (setImportData) {
            setImportData({
              account: result,
            })
          }
        }
      }
      myGroupsTab ? setOpenCollectModal(true) : setOpenCollectWithAccountModal(true)
    }
  }

  const handleJoinGroup = () => {
    if (group.username.includes('facebook.com')) {
      window.open(group.username, '_blank')
    } else {
      const url = `https://www.facebook.com/groups/${group.username}`
      window.open(url, '_blank')
    }
  }

  const elipsisDescription =
    group.description?.length > 120 ? (
      <Tooltip title={group.description}>{group.description.slice(0, 120)}...</Tooltip>
    ) : (
      <span>{group.description}</span>
    )
  const description = group?.is_list
    ? 'List saved from the research screen.'
    : group.description?.length > 0
    ? elipsisDescription
    : ''

  const elipsisName =
    group.name?.length > 40 || group?.title?.length > 40 ? (
      <Tooltip title={group.name || group.title}>
        {group?.name?.slice(0, 40)?.trim() || group?.title?.slice(0, 40)?.trim()}...
      </Tooltip>
    ) : (
      <span>
        {group.name
          ? group.source_type === 'hashtag'
            ? `${group.name}`
            : group.name
          : group?.title || group?.username || 'No available name'}
      </span>
    )

  const getFooterByStatus = (
    status:
      | 'failed'
      | 'private_group_not_joined'
      | 'account_suspended'
      | 'cooldown'
      | 'logged_out'
      | 'account_logged_out_or_banned',
  ) => {
    switch (status) {
      case 'cooldown':
        return (
          <div className={styles.groupImportanatStatus}>
            <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
              <Tooltip
                title='Your account is currently on cooldown after fetching another group, and will be ready to start again in up
              to 20 minutes.'>
                <ClockCircleOutlined
                  style={{ position: 'relative', marginRight: '5px', color: 'orange', cursor: 'pointer', zIndex: 3333 }}
                />
                Account on Cooldown.
              </Tooltip>
            </span>
          </div>
        )
      case 'failed':
        return (
          <div className={styles.groupImportanatStatus}>
            <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
              <Tooltip title={`The ${group.platform === 'linkedin' ? 'search' : 'collection'} was not successful`}>
                <WarningOutlined
                  style={{ position: 'relative', marginRight: '5px', color: 'orange', cursor: 'pointer', zIndex: 3333 }}
                />
                {group.platform === 'linkedin' ? 'Search' : 'Collection'} failed.
              </Tooltip>
            </span>
          </div>
        )
      case 'private_group_not_joined':
        return (
          <div className={styles.groupImportanatStatus}>
            <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
              <Tooltip
                title={`This is a private group. You must join and be approved by an admin before you can start collecting.`}>
                <WarningOutlined
                  style={{ position: 'relative', marginRight: '5px', color: 'orange', cursor: 'pointer', zIndex: 3333 }}
                />
                Joining this group is required.
              </Tooltip>
            </span>

            <Button type='primary' style={{ margin: 0 }} onClick={handleJoinGroup}>
              Join
            </Button>
          </div>
        )
      case 'account_suspended':
        return (
          <div className={styles.groupImportanatStatus}>
            <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
              <Tooltip
                title={`Account was not available for collecting
`}>
                <WarningOutlined
                  style={{ position: 'relative', marginRight: '5px', color: 'orange', cursor: 'pointer', zIndex: 3333 }}
                />
                Search failed.
              </Tooltip>
            </span>
          </div>
        )
      case 'logged_out':
        return (
          <div className={styles.groupImportanatStatus}>
            <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
              <Tooltip
                title={`Account is logged out
  `}>
                <WarningOutlined
                  style={{ position: 'relative', marginRight: '5px', color: 'orange', cursor: 'pointer', zIndex: 3333 }}
                />
                Collection failed.
              </Tooltip>
            </span>
          </div>
        )
      case 'account_logged_out_or_banned':
        return (
          <div className={styles.groupImportanatStatus}>
            <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>
              <Tooltip
                title={`Account is logged out or banned
    `}>
                <WarningOutlined
                  style={{ position: 'relative', marginRight: '5px', color: 'orange', cursor: 'pointer', zIndex: 3333 }}
                />
                Collection failed.
              </Tooltip>
            </span>
          </div>
        )
      default:
        return null
    }
  }
  function formatDate(dateString: string) {
    const date = new Date(dateString)
    const day = date.getDate()
    const suffix =
      day >= 11 && day <= 13 ? 'th' : day % 10 === 1 ? 'st' : day % 10 === 2 ? 'nd' : day % 10 === 3 ? 'rd' : 'th'
    const month = date.toLocaleString('en-US', { month: 'short' })
    return `${month} ${day}${suffix} ${date.getFullYear()}`
  }

  let footer = getFooterByStatus(group?.status)
  if (group?.status_code === 'failed') {
    footer = getFooterByStatus('failed')
  }
  if (FailedCollections.includes(group?.status_code) || FailedCollections.includes(group?.status)) {
    footer = getFooterByStatus('failed')
  }
  const getIcpTag = () => {
    if (!icpData?.ICP) return null
    if (icpData.ICP && group?.user_icp_id) {
      const icp = icpData?.ICP?.find((icp: any) => (icp.id ? icp.id : icp.persona_id) === group?.user_icp_id)
      return `ICP Match: ${icp?.name}`
    }
    if (icpData.ICP && group?.icp_id) {
      const icp = icpData?.ICP?.find((icp: any) => (icp.id ? icp.id : icp.persona_id) === group?.icp_id)
      return `ICP Match: ${icp?.name}`
    }
    return null
  }

  // const numFetchedLeads =
  //   group?.progress && group?.number_leads_request ? (+group?.progress / 100) * +group?.number_leads_request : null

  const numCollectedLeads = +group?.collected_leads || 0
  const cumulative_leads_fetched = +group?.cumulative_leads_fetched || 0
  const latest_request = +group?.latest_request || 0
  const numRequestedLeads = +group?.number_of_requested_leads || 0
  const fromIcpTag =
    ['linkedin', 'facebook', 'instagram'].includes(group?.platform) && (group?.user_icp_id || group?.icp_id)
      ? getIcpTag()
      : ''
  const fromAccountTag = group?.mine ? `From Account: ${group?.outreach_username}` : ''
  const eventTags =
    group?.is_web3Event && group?.tags
      ? (group?.tags as unknown as string)?.replace(/{|}/g, '')?.split(',')
      : group?.is_web3Group && group?.tags
      ? JSON.parse((group?.tags as unknown as string) || '[]')?.map((tag: any) => tag?.label)
      : []

  const tags = [group?.query].filter((tag) => tag)
  // const number_leads_request = +group?.number_leads_request
  const notFull = group?.progress === 100 && numCollectedLeads < numRequestedLeads
  const hideProgressBar =
    group?.status === 'success' || group?.status === 'partial_success' || group?.status === 'reach_limit'
  const requestedLeads = latest_request > 0 ? latest_request : numRequestedLeads
  const groupType = getTypeMyHub(group)
  const [createdAtTag, typeTag] = group?.is_list
    ? [
        `${isSmallScreen ? `${formatDate(group.created_at)}` : `Created date: ${formatDate(group.created_at)}`}`,
        `${capitalize(group.type === '1' ? 'Companies' : 'Contacts')} List`,
      ]
    : [null, null]
  const collectionProgress: any = (() => {
    if (group?.platform === 'linkedin') {
      return group?.progress || false // Get the progress from db
    }
    if (numCollectedLeads > numRequestedLeads) {
      return 100 // Set to 100
    }

    if (group?.progress !== undefined && numRequestedLeads > 0) {
      const collectedLeads =
        +numCollectedLeads >= +cumulative_leads_fetched
          ? +numCollectedLeads - +cumulative_leads_fetched
          : numCollectedLeads

      return Math.ceil((collectedLeads / requestedLeads) * 100) // Calculate the percentage manually
    }

    return false
  })()

  const numLeads = myGroupsTab
    ? numCollectedLeads >= 0 && typeof numCollectedLeads === 'number' && group.leads && group.platform !== 'linkedin'
      ? `${formatNumber(numCollectedLeads)}${group.source_type === 'hashtag' ? '' : `/${formatNumber(group?.leads)}`}`
      : `${group.leads?.toLocaleString()}`
    : `${group.leads?.toLocaleString()}`

  const showWarning =
    numCollectedLeads < numRequestedLeads && (group?.status === 'success' || group?.status === 'partial_success') ? (
      <span>
        {numRequestedLeads - numCollectedLeads} leads were not imported. <br />
        Any missed leads will be refunded.
      </span>
    ) : group?.status === 'reach_limit' ? (
      <span>
        These are the unique leads currently available
        <br />
        from this hashtag, prioritizing account safety.
        <br />
        Any missed leads will be refunded.
      </span>
    ) : (
      false
    )

  const popoverContent = (
    <div className={styles.tooltip}>
      <span>
        Total Leads: {numCollectedLeads?.toLocaleString()} / {numRequestedLeads?.toLocaleString()} Requested.
      </span>
      {showWarning && <span>{showWarning}</span>}
    </div>
  )

  const imageUrl =
    group?.type === 'search_people_sales_nav'
      ? sales_nav
      : group?.type === 'search_people'
      ? linkedin_icon
      : group?.is_list
      ? listIcon
      : group?.img_url

  return (
    <Card
      // className={`addLeadsModal ${styles.groupCard} ${group?.progress < 1 ? styles.disabled : ''} ${
      className={`addLeadsModal ${styles.groupCard} ${isSelected ? 'addLeadsModalSelected' : ''}`}
      hoverable
      style={{ cursor: 'default' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={group.username || group?.name || group?.title}
      // onClick={handleSelectGroup}
    >
      {!onboarding && myGroupsTab && (
        <MyGroupsSwitchOptions group={group} refinement={refinement} myGroups={isMyGroup} />
      )}
      {onboarding && <OnboardingsSwitchOptions group={group} onSelect={onSelect} isSelected={isSelected} />}
      {!myGroupsTab && !onboarding && getType(group)}

      <div className={styles.cardImage}>
        <div style={{ minHeight: '46px' }}>
          <>
            {group.source_type === 'hashtag' ? (
              <>
                <img
                  src={hashtag}
                  alt='hashtag'
                  className='mediumRoundPicture'
                  style={{
                    backgroundColor: '#F48FB1',
                  }}
                />
              </>
            ) : (
              <FancyImage url={imageUrl} color={true} fallbackImage={groupFallback} />
            )}
          </>
        </div>
      </div>
      <div className={styles.cardHeader}>
        <div className={styles.groupName}>{elipsisName}</div>
        {group?.url && (
          // <div>
          <LinkOutlined
            style={{ cursor: 'pointer', color: '#1890ff', zIndex: 3333 }}
            onClick={() => window.open(group?.url, '_blank')}
          />
          // </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          overflow: 'hidden',
          width: '100%',
        }}>
        <div className={group.is_list ? styles.leadsList : styles.leads} style={{ whiteSpace: 'nowrap' }}>
          {group.leads && (
            <>
              {group.is_list ? (
                <>
                  <div className={styles.numberOfLeads} style={{ whiteSpace: 'nowrap' }}>
                    <img src={number_of_leads_icon} alt='' />
                    <span>{formatNumber(group.leads)} Leads</span>
                  </div>
                  <Divider type='vertical' />
                </>
              ) : (
                <>
                  <div className={styles.numberOfLeads} style={{ whiteSpace: 'nowrap' }}>
                    <img src={number_of_leads_icon} alt='' />
                    <Popover content={popoverContent}>
                      <span>{numLeads}</span>
                    </Popover>
                  </div>
                  <Divider type='vertical' />
                </>
              )}
            </>
          )}
          {group.is_list ? (
            <>
              <div className={styles.platform} style={{ whiteSpace: 'nowrap' }}>
                <img src={getLogoByPlatform('telegram')} alt='' />
                <span>
                  {formatNumber(group?.telegram_count)}/{formatNumber(group.leads)}
                </span>
              </div>
              <Divider type='vertical' style={{ margin: '0 4px' }} />
              <div className={styles.platform} style={{ whiteSpace: 'nowrap' }}>
                <img src={getLogoByPlatform('linkedin')} alt='' />
                <span>
                  {formatNumber(group?.linkedin_count)}/{formatNumber(group.leads)}
                </span>
              </div>
              <Divider type='vertical' />
            </>
          ) : group?.is_web3Event ? (
            <>
              <div className={styles.platform} style={{ whiteSpace: 'nowrap' }}>
                <img src={getLogoByPlatform(group.platform)} alt='' />
                <span>{group.platform === 'Twitter' ? 'X (Twitter)' : capitalize(group.platform)}</span>
              </div>
              <Divider type='vertical' />
              <div className={styles.platform} style={{ whiteSpace: 'nowrap' }}>
                <span>
                  {group?.city}, {group?.country}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className={styles.platform} style={{ whiteSpace: 'nowrap' }}>
                <img src={getLogoByPlatform(group.platform)} alt='' />
                <span>{group.platform === 'Twitter' ? 'X (Twitter)' : capitalize(group.platform)}</span>
              </div>
              <Divider type='vertical' />
            </>
          )}
          {!group.is_list && !group?.is_web3Event && (
            <div className={styles.groupStatus} style={{ whiteSpace: 'nowrap' }}>
              <img src={group.locked ? group_locked_icon : group_unlocked_icon} alt='' />
              <span>{group.locked ? 'Locked' : 'Unlocked'}</span>
            </div>
          )}

          {myGroupsTab && !group.is_list && (
            <>
              <Popover
                content={
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <img style={{ height: '20px' }} src={groupType?.icon} alt='' />
                    <span>{groupType?.text}</span>
                  </div>
                }
                trigger={'hover'}>
                <div className={styles.groupType} style={{ whiteSpace: 'nowrap', zIndex: '100' }}>
                  <Divider type='vertical' />
                  <img src={groupType?.icon} alt='' />
                  <span>{groupType?.text}</span>
                </div>
              </Popover>
            </>
          )}
        </div>
        <div className={group.is_list ? styles.leadsBelowList : styles.leadsBelow}>
          {!group.is_list && (
            <div className={styles.groupStatusLocation} style={{ whiteSpace: 'nowrap' }}>
              {group?.location ? <img src={locationPin} alt='' /> : <div className={styles.block}></div>}
              <span>{group?.location}</span>
            </div>
          )}
          {group.is_list && (
            <>
              <div className={styles.groupStatusLocation} style={{ whiteSpace: 'nowrap' }}>
                <img src={businessIcon} alt='' />
                <span>{formatNumber(group?.company_count)} Companies</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.groupDescription}>{description}</div>
        {footer && group.collected_leads <= 0 ? (
          footer
        ) : (
          <div className={group.is_list ? styles.groupTagsList : styles.groupTags}>
            <div
              className={`${group.is_list ? styles.hoverableListContainer : styles.hoverableContainer} ${
                collectionProgress === false || collectionProgress === 100
                  ? isHovered
                    ? styles.fadeInUp
                    : styles.fadeOutDown
                  : ''
              }`}
              style={
                collectionProgress >= 0 && collectionProgress < 100
                  ? {}
                  : {
                      position: 'absolute',
                      right: '20px',
                    }
              }
              onClick={(e: any) => {
                e.stopPropagation()
              }}>
              {!onboarding && group?.status !== 'private_group_not_joined' && (
                <CustomEyeButton
                  eyeText='View Leads'
                  buttonText='Collect'
                  onCollect={handleCollect}
                  onViewLeads={handleSelectGroup}
                  myGroupsTab={myGroupsTab}
                  collectionProgress={collectionProgress}
                  isHovered={isHovered}
                  collectedLeads={group?.collected_leads}
                  totalRequested={requestedLeads}
                  actualRequested={group?.number_leads_request}
                  status={group?.status}
                  notFull={notFull}
                  platform={group?.platform}
                  hideProgressBar={hideProgressBar}
                  cumulative_leads_fetched={cumulative_leads_fetched}
                  name={group.name}
                  group={group}
                  onLinkedinCollect={onLinkedinCollect}
                />
              )}
            </div>
            {(collectionProgress === false || collectionProgress === 100 || !myGroupsTab || hideProgressBar) &&
              (true || group?.platform === 'linkedin') && (
                <div
                  style={
                    (collectionProgress >= 0 && collectionProgress < 100) ||
                    group?.status === 'private_group_not_joined'
                      ? { position: 'absolute', bottom: '24px', left: '24px', width: 'fit-content' }
                      : {}
                  }>
                  {fromIcpTag && (
                    <>
                      <span className={styles.icpTag}>{fromIcpTag}</span>
                    </>
                  )}
                  {fromAccountTag && (
                    <>
                      <span className={styles.icpTag}>{fromAccountTag}</span>
                    </>
                  )}
                  {eventTags && (
                    <>
                      <TagsList tags={eventTags} cutLength={15} />
                    </>
                  )}
                  {createdAtTag && <span className={styles.createdAtTag}>{createdAtTag}</span>}
                  {typeTag && (
                    <span
                      className={
                        group.type === 'contacts' || group.type === '2' ? styles.contactTag : styles.companyTag
                      }>
                      {typeTag}
                    </span>
                  )}
                  {tags.length > 0 && (
                    <>
                      {tags.map((tag, index) => (
                        <span className={styles.tag} key={index}>
                          {`# ${tag}`}
                        </span>
                      ))}
                    </>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
      {openCollectModal && (
        <CollectLeadsModal
          open={openCollectModal}
          onClose={() => {
            setOpenCollectModal(false)
          }}
          importData={importData}
          group={group}
          pointsStore={pointsStore}
        />
      )}
      {openCollectWithAccountModal && (
        <CollectLeadsOptionsModal
          open={openCollectWithAccountModal}
          onClose={() => {
            setOpenCollectWithAccountModal(false)
            setImportData({})
          }}
          group={group}
          importData={importData}
          pointsStore={pointsStore}
          goToProgress={goToProgress}
          goToMyHub={goToMyHub}
        />
      )}
      {showShouldJoinModal && (
        <ShouldJoinGroupModal
          onContinue={onContinueCollect}
          open={showShouldJoinModal}
          data={shouldJoinData}
          setOpen={setShowShouldJoinModal}
        />
      )}
      {openNoMoreLeadsModal && (
        <AreYouSureModal
          open={openNoMoreLeadsModal}
          onClose={() => {
            setOpenNoMoreLeadsModal(false)
          }}
          title='No more leads to fetch from this group!'
          message='Please try another one.'
          onConfirm={() => {
            setOpenNoMoreLeadsModal(false)
          }}
          confirmText={'Got it!'}
        />
      )}
    </Card>
  )
}

export default GroupCard
