import lead_icon1 from 'common/assets/svg/facebook_icon.svg'
import lead_icon2 from 'common/assets/svg/lead_icon2.svg'
import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'

interface ChannelsProps {
  text: string
  data: any
  web3?: boolean
}
interface SocialMediaIcon {
  platform: string
  url: string
  icon: string
}
export function Channels({ text, data, web3 = false }: ChannelsProps) {
  const style = 'grayscale(100%)'
  const socialMediaIcons: (SocialMediaIcon | null)[] = [
    web3 ? null : { platform: 'facebook', url: data.facebook, icon: lead_icon1 },
    web3
      ? null
      : {
          platform: 'instagram',
          url:
            data.instagram !== undefined && data.instagram !== ''
              ? `https://instagram.com/${data.instagram || data.username}`
              : '',
          icon: lead_icon2,
        },
    {
      platform: 'telegram',
      url:
        data.telegram !== undefined && data.telegram !== ''
          ? `https://t.me/${data.telegram}`
          : data?.platform === 'telegram'
          ? `https://t.me/${data?.handle || data?.recipient}`
          : '',
      icon: lead_icon3,
    },
    {
      platform: 'linkedin',
      url: data.linkedin !== undefined && data.linkedin !== '' ? data.linkedin : '',
      icon: lead_icon4,
    },
    {
      platform: 'discord',
      url: data.discord !== undefined && data.discord !== '' ? data.discord : '',
      icon: lead_icon5,
    },
    {
      platform: 'twitter',
      url: data.twitter !== undefined && data.twitter !== '' ? data.twitter : '',
      icon: lead_icon6,
    },
  ]
  const filteredIcons = socialMediaIcons.filter((icon): icon is SocialMediaIcon => icon !== null)

  return (
    <div className='img_group'>
      {filteredIcons
        .filter((value) => value)
        .map(({ platform, url, icon }) => (
          <img
            key={platform}
            onClick={() => url && window.open(url, '_blank')}
            style={{
              filter: !url ? style : '',
              opacity: !url ? 0.25 : 1,
              cursor: url ? 'pointer' : 'default',
            }}
            src={icon}
            alt={platform}
          />
        ))}
    </div>
  )
}
