import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DropdownProps, MenuProps, Tooltip, message } from 'antd'
import styles from './CampaignSummarySidebar.module.scss'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_logo from 'common/assets/svg/instagram_logo.svg'
import twitter_logo from 'common/assets/svg/twitter_logo.svg'
import discord_logo from 'common/assets/svg/discord_logo.svg'
import linkedin_logo from 'common/assets/svg/linkedin_logo.svg'
import telegram_logo from 'common/assets/svg/telegram_logo.svg'
// import no_logo from 'common/assets/svg/no_logo.svg'
import { useAppDispatch, useAppSelector } from 'state'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { SET_NODES_DATA, SET_SELECTED_PLATFORMS_ACCOUNTS } from '../state/outreachAICampaignSlice'
import { useLazyUpdateCampaignSendersQuery } from '../../../APIs/OutreachAI-API'
import {
  getFacebookAccounts,
  getInstagramAccounts,
  getLinkedinAccounts,
  getSenderByPlatform,
  getTelegramAccounts,
} from 'common/utils/userHelpers'
import AreYouSureModal from 'common/components/GeneralComponents/Modals/AreYouSureModal'
import { useLocalStorage } from 'usehooks-ts'
import useCampaignNavigation from '../Helpers/useCampaignNavigation'
import { FancyImage } from 'common/components/GeneralComponents/UI/FancyImage'
// import { EditOutlined } from '@ant-design/icons'
// import { useAuth } from 'common/hooks/useAuth.hooks'

const platformIcons: any = {
  facebook: facebook_icon,
  instagram: instagram_logo,
  twitter: twitter_logo,
  discord: discord_logo,
  linkedin: linkedin_logo,
  telegram: telegram_logo,
}

const promptTitle = [
  {
    id: 1,
    title: 'Quick Start',
    description: 'Start engaging with prospects right away to achieve fast results.',
    addon_name: 'prompt_cold_outreach',
    type: 'general',
    image: require('common/assets/png/AI_Prompt_Icon_Outreach.png'),
  },
  {
    id: 2,
    title: 'Choose a Template',
    description: 'Choose from a ready-made saved template or explore the Convrt marketplace for more options.',
    addon_name: 'prompt_templates',
    type: 'templates',
    image: require('common/assets/png/AI_Prompt_Icon_Smart_engagement.png'),
  },
  {
    id: 3,
    title: 'Build From Scratch',
    description:
      'Use the builder to create your own sequence from scratch and personalize it to meet your specific needs.',
    addon_name: 'prompt_custom',
    type: 'custom',
    image: require('common/assets/png/AI_Prompt_Icon_Custom.png'),
  },
]

const CampaignSummarySidebar: React.FC = () => {
  const { userDetails } = useAuth()
  const { navigateWithCampaignId } = useCampaignNavigation()
  const dispatch = useAppDispatch()
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const [selectedAccountToRemove, setSelectedAccountToRemove] = useState<any>({})
  const [openMenu, setOpenMenu] = useState(false)
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [updateCampaignSenders] = useLazyUpdateCampaignSendersQuery()
  const [userMetadata]: any = useLocalStorage('user_metadata', {})
  const [activeLeads, setActiveLeads] = useState([])
  // const [editSchedule, setEditSchedule] = useState(false)
  const {
    prompt,
    saved_prompt_params,
    selected_leads,
    stored_temp_leads,
    selected_platforms_accounts,
    skip_prompt,
    select_custom_template,
    nodes_data,
    campaign_id,
    created_at,
  } = useAppSelector((state) => state.outreachAICampaign)

  const getItemsByPlatform = (platform: string): MenuProps['items'] => {
    switch (platform) {
      case 'instagram':
        const instagramAccounts = getInstagramAccounts(user)
        const instagram: MenuProps['items'] = instagramAccounts.map((item: string) => ({ key: item, label: item }))
        return instagram
      case 'facebook':
        const facebookAccounts = getFacebookAccounts(user)
        const facebook: MenuProps['items'] = facebookAccounts.map((item: string) => {
          const foundAccount = userMetadata.facebook?.find(
            (acc: any) => acc.username?.toLowerCase() === item.toLowerCase(),
          )
          return {
            key: item,
            label: foundAccount && foundAccount.contact_name ? `${item} (${foundAccount.contact_name})` : item,
          }
        })
        return facebook
      case 'linkedin':
        const linkedinAccounts = getLinkedinAccounts(user)
        const linkedin: MenuProps['items'] = linkedinAccounts?.map((item: string) => {
          const foundAccount = userMetadata?.linkedin?.find(
            (acc: any) => acc.username?.toLowerCase() === item.toLowerCase(),
          )
          return {
            key: item,
            label: foundAccount && foundAccount.contact_name ? `${item} (${foundAccount.contact_name})` : item,
          }
        })
        return linkedin
      case 'telegram':
        const telegramAccounts = getTelegramAccounts(user)
        const telegram: MenuProps['items'] = telegramAccounts.map((item: string) => ({ key: item, label: item }))
        return telegram
      default:
        return []
    }
  }
  const handleOpenChange: DropdownProps['onOpenChange'] = (open) => {
    setOpenMenu(open)
  }

  const handleMenuClick = async (e: any, platform: any) => {
    const updatedPlatformAccounts = selected_platforms_accounts[platform] || []
    if (!updatedPlatformAccounts.includes(e.key)) {
      const updatedSelectedPlatformsAccounts = {
        ...selected_platforms_accounts,
        [platform]: [...selected_platforms_accounts[platform], e.key],
      }
      dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(updatedSelectedPlatformsAccounts))

      const senderData = getSenderByPlatform(updatedSelectedPlatformsAccounts)
      // const senderData = allSenders.filter((s: any) => selectedAccounts.includes(s.username))

      const updatedNodesData = nodes_data.map((node: any) => {
        const newNode = { ...node }

        newNode.data = { ...newNode.data, sender: senderData }

        return newNode
      })
      dispatch(SET_NODES_DATA(updatedNodesData))

      await updateCampaignSenders({ campaign_id: campaign_id, senders: senderData, platform: platform })
    }
  }

  const handleAccounts = (account: any, platform: any) => {
    if (selected_platforms_accounts[platform]?.length < 2) {
      message.warning('You must have at least 1 account in this campaign.')
    } else {
      setOpenAreYouSureModal(true)
      setSelectedAccountToRemove({ account: account, platform: platform })
    }
  }

  const handleRemoveAccount = async () => {
    const { platform, account: accountToRemove } = selectedAccountToRemove
    const updatedPlatformAccounts = selected_platforms_accounts[platform] || []

    if (updatedPlatformAccounts.includes(accountToRemove)) {
      const updatedSelectedPlatformsAccounts = {
        ...selected_platforms_accounts,
        [platform]: updatedPlatformAccounts.filter((account: any) => account !== accountToRemove),
      }

      dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(updatedSelectedPlatformsAccounts))

      const senderData = getSenderByPlatform(updatedSelectedPlatformsAccounts)

      const updatedNodesData = nodes_data.map((node: any) => {
        const newNode = { ...node }
        newNode.data = { ...newNode.data, sender: senderData }
        return newNode
      })

      dispatch(SET_NODES_DATA(updatedNodesData))

      await updateCampaignSenders({ campaign_id: campaign_id, senders: senderData, platform: platform })
      setOpenAreYouSureModal(false)
    }
  }

  const tilesOptions = [
    {
      name: 'Created on',
      label: new Date(created_at)?.toISOString()?.slice(0, 10)?.replace(/-/g, '/'),
    },
    {
      name: 'Leads',
      label: activeLeads.length.toLocaleString(),
    },
    {
      name: 'Success Rate',
      label: 'TBD',
    },
  ]

  const handleEditPrompt = () => {
    navigateWithCampaignId('/outreach/create-new-campaign', { state: { summary: true } })
  }

  // const handleEditSchedule = async () => {}

  const AccountList = ({ accounts, platform, userMetadata }: any) => {
    const MAX_VISIBLE_ACCOUNTS = 3
    const hiddenAccounts = accounts.slice(MAX_VISIBLE_ACCOUNTS)
    return (
      <div className={styles.bottom}>
        {accounts.slice(0, MAX_VISIBLE_ACCOUNTS).map((account: string, index: number) => {
          let foundAccount
          if (platform === 'facebook') {
            foundAccount = userMetadata.facebook?.find(
              (acc: any) => acc.username?.toLowerCase() === account.toLowerCase(),
            )
          }

          return (
            <div key={index} className={`${styles.select_items} ${styles.ellipsis}`}>
              <Tooltip
                placement='top'
                title={foundAccount ? `${account} (${foundAccount.contact_name})` : account}
                overlayInnerStyle={{ backgroundColor: 'white', color: '#7043ff' }}>
                <span className={styles.select_items__text}>{account}</span>
              </Tooltip>
              <span className={styles.select_items__remove} onClick={() => handleAccounts(account, platform)}>
                &times;
              </span>
            </div>
          )
        })}

        {/* Tooltip for hidden accounts */}
        {accounts.length > MAX_VISIBLE_ACCOUNTS && (
          <Tooltip
            title={
              <div className={styles.tooltipContent}>
                {hiddenAccounts.map((account: string, index: number) => (
                  <div key={index} className={`${styles.select_items_tooltip}`}>
                    <span className={styles.select_items__text}>{account}</span>
                    <span className={styles.select_items__remove} onClick={() => handleAccounts(account, platform)}>
                      &times;
                    </span>
                  </div>
                ))}
              </div>
            }
            overlayInnerStyle={{ backgroundColor: 'white', color: '#7043ff', width: '300px', display: 'flex' }}
            placement='top'>
            <div className={`${styles.select_items} ${styles.ellipsis}`}>
              <span className={styles.select_items__text}>+{accounts.length - MAX_VISIBLE_ACCOUNTS} More</span>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  useEffect(() => {
    // Filter out leads that are present in `stored_temp_leads`
    const filteredLeads =
      selected_leads?.filter((lead: any) => {
        return !lead.isTemp
      }) || []

    setActiveLeads(filteredLeads)
  }, [selected_leads, stored_temp_leads]) // Add dependencies to re-run on changes

  return (
    <div className={styles.campaignSummarySidebar}>
      <div className={styles.campaignSummarySidebarFilter}>
        <div className={styles.top_section}>
          <h3>Campaign Details</h3>
          <Button onClick={handleEditPrompt}>Edit</Button>
        </div>
        <div className={styles.summaryPrompt}>
          <h1>I want to create a</h1>
          <h1 className={styles.summaryPromptTitle}>
            {saved_prompt_params?.campaign_type} {select_custom_template?.name && `(${select_custom_template.name})`}
          </h1>
          <h1>Campaign {!skip_prompt && 'with'}</h1>
          {!skip_prompt && (
            <>
              <h1 className={styles.summaryPromptTitle}>{saved_prompt_params?.target_persona}</h1>
              <h1>As a</h1>
              <h1 className={styles.summaryPromptTitle}>{saved_prompt_params?.industry}</h1>
              <h1>In</h1>
              <h1 className={styles.summaryPromptTitle}>{saved_prompt_params?.location}</h1>
              <h1>To</h1>
              <h1 className={styles.summaryPromptTitle}>{saved_prompt_params?.goal}</h1>
              <h1>By</h1>
              <h1 className={styles.summaryPromptTitle}>{saved_prompt_params?.expertise}</h1>
            </>
          )}
        </div>
        <div className={styles.summaryboxTiles}>
          {tilesOptions.map((item) => (
            <div className={styles.summaryboxTiles__Content}>
              <p>{item.name}</p>
              <h1>{item.label}</h1>
            </div>
          ))}
        </div>
        <div className={styles.summaryboxTiles__Two}>
          <div className={styles.summaryboxTiles__Two__Content}>
            <p>Made by</p>
            <h1>
              <FancyImage
                url=''
                name={userDetails?.name}
                size={24}
                fontSize={12}
                style={{ marginRight: '5px', textAlign: 'center' }}
              />
              {userDetails.name}
            </h1>
          </div>
          <div className={styles.summaryboxTiles__Two__Content}>
            <p>Campaign Template</p>
            <h1>
              <img src={promptTitle[prompt.id - 1]?.image} alt='' /> {promptTitle[prompt.id - 1]?.title}
            </h1>
          </div>
        </div>
        {Object.entries(selected_platforms_accounts).map(([platform, accounts]: any) =>
          accounts.length > 0 ? (
            <div key={platform} className={`${styles.socialMediaContainer}`}>
              <div className={styles.top}>
                <div className={styles.title}>
                  <img src={platformIcons[platform]} alt={`${platform} icon`} />
                  <p>{platform.charAt(0).toUpperCase() + platform.slice(1)} Accounts</p>
                </div>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: getItemsByPlatform(platform),
                    onClick: (e) => {
                      handleMenuClick(e, platform)
                    },
                  }}
                  onOpenChange={handleOpenChange}
                  open={openMenu}>
                  <div className={styles.addAccount}>
                    <label>Add Account</label>
                  </div>
                </Dropdown>
              </div>
              <AccountList accounts={accounts} platform={platform} userMetadata={userMetadata} />
              {/* <div className={styles.bottom}>
                {['accounts', 'accounts', 'accounts', 'accounts', 'accounts', 'accounts', 'accounts'].map(
                  (account: any, index: number) => {
                    if (platform === 'facebook') {
                      const foundAccount = userMetadata.facebook?.find(
                        (acc: any) => acc.username?.toLowerCase() === account.toLowerCase(),
                      )
                      return (
                        <div key={index} className={`${styles.select_items} ${styles.ellipsis}`}>
                          <Tooltip
                            placement='top'
                            title={foundAccount ? `${account} (${foundAccount.contact_name})` : account}
                            overlayInnerStyle={{ backgroundColor: 'white', color: '#7043ff' }}>
                            <span className={styles.select_items__text}>{account}</span>
                          </Tooltip>
                          <span
                            className={styles.select_items__remove}
                            onClick={() => handleAccounts(account, platform)}>
                            &times;
                          </span>
                        </div>
                      )
                    }
                    return (
                      <div key={index} className={styles.select_items}>
                        <Tooltip title={account} overlayInnerStyle={{ backgroundColor: 'white', color: '#7043ff' }}>
                          <span className={styles.select_items__text}>
                            {account?.length > 16 ? `${account?.slice(0, 16)?.trim()}...` : account}
                          </span>
                        </Tooltip>
                        <span className={styles.select_items__remove} onClick={() => handleAccounts(account, platform)}>
                          &times;
                        </span>
                      </div>
                    )
                  },
                )}
              </div> */}
            </div>
          ) : null,
        )}
        {/* <div className={styles.scheduling}>
          <div className={styles.schedulingHeader}>
            <p>Scheduling</p>
            {!editSchedule && (
              <EditOutlined onClick={() => setEditSchedule(true)} style={{ color: '#7043ff', cursor: 'pointer' }} />
            )}
          </div>
          <div className={styles.schedulingMain}>text</div>
          {editSchedule && (
            <div className={styles.schedulingFooter}>
              <span className={styles.save} onClick={handleEditSchedule}>
                Save
              </span>
              <span className={styles.cancel} onClick={() => setEditSchedule(false)}>
                Cancel
              </span>
            </div>
          )}
        </div> */}
      </div>
      {openAreYouSureModal && (
        <AreYouSureModal
          open={openAreYouSureModal}
          onClose={() => {
            setOpenAreYouSureModal(false)
            setSelectedAccountToRemove({})
          }}
          onConfirm={handleRemoveAccount}
          title={`Are you sure you want to remove ${selectedAccountToRemove?.account} from this campaign?`}
          message='You can add this account again at any time.'
        />
      )}
    </div>
  )
}

export default CampaignSummarySidebar
