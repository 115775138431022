import React, { useState } from 'react'
import { Skeleton, Table } from 'antd'
// import { useAppDispatch, useAppSelector } from 'state'
// import { SET_SELECTED_LEADS } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import { getGroupsColumns } from './GroupsColumns'
import { useAppSelector } from 'state'
import TableRowSelectionDropdown from 'common/components/GeneralComponents/UI/TableRowSelectionDropdown'
const sortData = (data: any[]) => {
  if (!data) return []
  return data.sort((a: any, b: any) => {
    if (a.biography && !b.biography) {
      return -1
    }
    if (!a.biography && b.biography) {
      return 1
    }
    // then sort by follower count
    if (+a.follower_count < +b.follower_count) {
      return 1
    }
    if (+a.follower_count > +b.follower_count) {
      return -1
    }
    return 0
  })
}
interface GroupsTableProps {
  selectedGroup: string
  data: any
  isLoading: boolean
  groupType: string
  selectedRowKeys: any
  setSelectedRowKeys: any
  onLeads: () => void
  loadingLeads: boolean
  showNewLeads: boolean
  group: any
  numberOfLine: number
}

const GroupsTable: React.FC<GroupsTableProps> = ({
  selectedGroup,
  data,
  isLoading,
  groupType,
  selectedRowKeys,
  setSelectedRowKeys,
  onLeads,
  loadingLeads,
  showNewLeads,
  group,
  numberOfLine,
}) => {
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(8)
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1)

  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)

  const platform = data?.[0]?.platform || truePlatformsArray[0]

  const handleSelectChange = (newSelectedRowKeys: any) => {
    if (!loadingLeads) {
      console.log('-==----------')
      console.log(newSelectedRowKeys)
      console.log('-==----------')
      setSelectedRowKeys(newSelectedRowKeys)
    }
  }

  const parseBio = (bio: string) => {
    if (bio?.toLocaleLowerCase() === 'nan') {
      return ''
    } else {
      return bio
    }
  }

  const convertedData: any[] = data?.map((item: any, index: any) => ({
    key: item.username,
    full_name: item.full_name || item.username || '',
    username: item.username || '',
    biography: parseBio(item.biography),
    is_private: item.is_private,
    follower_count: item.follower_count,
    profile_pic_url: item.profile_pic_url,
    display: item.display,
    // linkedin:
    time_in_role_and_company: item?.time_in_role_and_company,
    skills: item?.skills,
    search_name: item?.search_name,
    position: item?.position,
    number_of_followers: item?.number_of_followers,
    nav_url: item?.nav_url,
    location: item?.location,
    headline: item?.headline,
    experience: item?.experience,
    connection_level: item?.connection_level,
    company: item?.company,
    about: item?.about,
    search_id: item?.search_id,
    company_pic_url: item?.company_pic_url,
    category: item?.category,
  }))
  const sortedData = sortData(convertedData)

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    columnTitle: (
      <TableRowSelectionDropdown
        data={sortedData}
        pageSize={pageSize}
        currentPage={currentPage}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={handleSelectChange}
      />
    ),
  }
  return (
    <Table
      dataSource={
        loadingLeads || isLoading
          ? [...Array(10)].map((_, index) => ({
              key: `key${index}`,
            }))
          : sortedData
      }
      columns={
        loadingLeads || isLoading
          ? getGroupsColumns(platform, groupType, group)?.map((column: any) => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return (
                    <Skeleton
                      // className='campaignsPageTable'
                      style={{
                        margin: '0px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '5px',
                        height: '30px',
                        paddingRight: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        opacity: 0.5,
                      }}
                      key={column.dataIndex}
                      title={true}
                      paragraph={false}
                      active
                    />
                  )
                },
              }
            })
          : getGroupsColumns(platform, groupType, group)
      }
      rowSelection={rowSelection}
      pagination={{
        pageSize: numberOfLine,
      }}
      className='groupsTable'
      scroll={{ x: 500 }}
      // scroll={tablePaywall ? {} : undefined}
      // scroll={tablePaywall ? undefined:'auto'}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    />
  )
}

export default GroupsTable
