import React, { useEffect, useState } from 'react'
import { Card, message } from 'antd'
import styles from './AIGerneratePrompt.module.scss'
import stopWatch from 'common/assets/png/stop-watch.png'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_PROMPT,
  SET_SAVED_PROMPT_PARAMS,
  SET_SELECT_CUSTOM_TEMPLATE,
  SET_SELECTED_NODE,
  SET_NODES_DATA,
  SET_STEPS,
  SET_OPEN_TEMPLATES,
  SET_NAVIGATE_TO_BUILDER,
  RESET_SAVED_PROMPT_PARAMS,
  SET_USER_UPDATED_SEQUENCE,
} from '../state/outreachAICampaignSlice'
import premium_badge from 'common/assets/svg/premium_badge.svg'
import AreYouSureModal from 'common/components/GeneralComponents/Modals/AreYouSureModal'
import { useLazyUpdateCampaignDataQuery, useLazyGetMyTemplatesQuery } from '../../../APIs/OutreachAI-API'
import CustomCampaignModal from './CustomCampaign/CustomCampaignModal'
import { RESET_BUILDER, RESET_BUILDER_SETUP_DATA } from '../Builder/Slice/CampaignBuilderSlice'
import { promptTitle } from '../Helpers/CampaignConstants'
import CustomCollapseFooter from '../CreateCampaign/UI/CustomCollapseFooter'
// import AI_Prompt_search_icon from 'common/assets/png/AI_Prompt_search_icon.png'
import AI_Prompt_Icon_Smart_engagement from 'common/assets/png/AI_Prompt_Icon_Smart_engagement.png'
import AI_Prompt_Icon_Outreach from 'common/assets/png/AI_Prompt_Icon_Outreach.png'
import AI_Prompt_Icon_Custom from 'common/assets/png/AI_Prompt_Icon_Custom.png'
import { trackEvent } from 'eventConfig'

const promptImageMap: any = {
  1: AI_Prompt_Icon_Outreach,
  2: AI_Prompt_Icon_Smart_engagement,
  3: AI_Prompt_Icon_Custom,
}

interface CampaignGenerateReportProps {
  currentKey: any
  setCurrentKey: any
  onBack?: () => void
  onNext?: () => void
  onSkip?: () => void
}
const CampaignGenerateReport: React.FC<CampaignGenerateReportProps> = ({
  currentKey,
  setCurrentKey,
  onBack,
  onNext,
  onSkip,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [openCustomCampaignModal, setOpenCustomCampaignModal] = useState(false)
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const [updateCampaignData] = useLazyUpdateCampaignDataQuery()
  const [getMyTemplates, { data: templates }] = useLazyGetMyTemplatesQuery()
  const [confirmedReset, setConfirmedReset] = useState(false)
  const {
    prompt,
    nodes_data,
    saved_prompt_params,
    navigate_to_builder,
    select_custom_template,
    campaign_id,
    active_platforms,
    campaign_settings_ai,
    selected_platforms_accounts,
    skip_prompt,
    are_leads_from_groups,
    ai_leads_type,
    setup_method,
    user_updated_sequence,
  } = useAppSelector((state) => state.outreachAICampaign)
  const { builder_data, use_custom_flow } = useAppSelector((state) => state.CampaignBuilder)
  const dispatch = useAppDispatch()
  const [firstTimePrompt, setFirstTimePrompt] = useState<any>(null)
  const [tempSelectedCard, setTempSelectedCard] = useState<any>(null)
  const [selectedCard, setSelectedCard] = useState<number | null>(prompt ? prompt.id : null)

  const handleNext = () => {
    setFirstTimePrompt(prompt)
    onNext && onNext()
  }

  const handleBack = () => {
    setFirstTimePrompt(prompt)
    onBack && onBack()
  }

  const handleSelectCard = (item: any) => {
    if (item?.id === selectedCard) return
    if (user?.addons[item.addon_name]) {
      if (firstTimePrompt?.id || user_updated_sequence) {
        setTempSelectedCard(item)
        return setOpenAreYouSureModal(true)
      } else {
        setSelectedCard(item.id)
        dispatch(SET_PROMPT(item))
        dispatch(SET_SAVED_PROMPT_PARAMS({ campaign_type: item.title }))
        dispatch(SET_SELECT_CUSTOM_TEMPLATE(null))
        if (item.type === 'templates') {
          dispatch(SET_OPEN_TEMPLATES(true))
          dispatch(SET_NAVIGATE_TO_BUILDER(false))
        } else if (item.type === 'custom') {
          dispatch(SET_NAVIGATE_TO_BUILDER(true))
          dispatch(SET_OPEN_TEMPLATES(false))
        } else {
          dispatch(SET_OPEN_TEMPLATES(false))
          dispatch(SET_NAVIGATE_TO_BUILDER(false))
        }
      }
    }
  }

  const handleResetCampaign = async () => {
    setSelectedCard(tempSelectedCard.id)
    dispatch(SET_PROMPT(tempSelectedCard))
    // dispatch(SET_SAVED_PROMPT_PARAMS({ campaign_type: tempSelectedCard.title }))
    dispatch(SET_SELECT_CUSTOM_TEMPLATE(null))
    dispatch(SET_USER_UPDATED_SEQUENCE(false))
    // dispatch(SET_SETUP_METHOD(''))
    setTempSelectedCard(null)
    dispatch(RESET_BUILDER_SETUP_DATA())
    dispatch(RESET_BUILDER())
    dispatch(SET_SELECTED_NODE(''))
    dispatch(SET_NODES_DATA([]))
    dispatch(SET_STEPS([]))
    setFirstTimePrompt({})

    // dispatch(SET_OPEN_TEMPLATES(false))
    // dispatch(SET_NAVIGATE_TO_BUILDER(false))

    const currentSettings = {
      selected_platforms_accounts,
      prompt: tempSelectedCard,
      saved_prompt_params: { ...saved_prompt_params, campaign_type: tempSelectedCard.title },
      active_platforms,
      campaign_settings_ai,
      skip_prompt,
      are_leads_from_groups,
      select_custom_template: null,
      ai_leads_type,
      setup_method: setup_method,
      allSenders: [],
      current_collapse_key: 3,
    }

    await updateCampaignData({ campaign_id: campaign_id, currentSettings, custom_flow_id: true })

    if (tempSelectedCard.type === 'templates') {
      dispatch(SET_OPEN_TEMPLATES(true))
      dispatch(SET_NAVIGATE_TO_BUILDER(false))
    } else if (tempSelectedCard.type === 'custom') {
      dispatch(SET_NAVIGATE_TO_BUILDER(true))
      dispatch(SET_OPEN_TEMPLATES(false))
    } else {
      dispatch(SET_OPEN_TEMPLATES(false))
      dispatch(SET_NAVIGATE_TO_BUILDER(false))
    }
  }

  const handleCheckSelection = (data: any) => {
    if (data.type === 'custom' && !navigate_to_builder && (!builder_data.nodes || nodes_data?.length === 0)) {
      console.log('clear')
      dispatch(RESET_BUILDER_SETUP_DATA())
      dispatch(SET_SELECT_CUSTOM_TEMPLATE(null))
      dispatch(RESET_SAVED_PROMPT_PARAMS({}))
      setSelectedCard(null)
      // setFirstTimePrompt({})
    } else if (data === 'templates') {
      dispatch(SET_PROMPT({}))
      dispatch(RESET_BUILDER_SETUP_DATA())
      dispatch(SET_SELECT_CUSTOM_TEMPLATE(null))
      dispatch(RESET_SAVED_PROMPT_PARAMS({}))
      setSelectedCard(null)
    }
  }

  useEffect(() => {
    if (selectedCard !== null) {
      if (!prompt.type && prompt.id) {
        // Old campaigns before October 10th - Sequence boxes changes.
        switch (prompt.id) {
          case 4:
            if (select_custom_template?.id) return setSelectedCard(2)
            return setSelectedCard(3)
        }
        return setSelectedCard(1)
      }
      const data = promptTitle.find((item: any) => item.id === selectedCard)
      if (data) {
        trackEvent('OUTREACH_GENERATION_PROMPT', { ...data })
        if (selectedCard !== data.id) {
          handleSelectCard(data)
        } else {
          handleCheckSelection(data)
        }
      }
    }
  }, [selectedCard])

  // useEffect(() => {
  //   if ((builder_data.nodes || nodes_data?.length > 0) && currentKey === '2') {
  //     setOpenAreYouSureModal(true)
  //   }
  // }, [currentKey])

  useEffect(() => {
    if (confirmedReset) {
      setConfirmedReset(false)
      handleResetCampaign()
      // dispatch(SET_OPEN_TEMPLATES(false))
      // dispatch(SET_NAVIGATE_TO_BUILDER(false))
      // dispatch(RESET_BUILDER())
    }
  }, [saved_prompt_params, confirmedReset])

  useEffect(() => {
    const getTemplates = async () => {
      await getMyTemplates()
    }
    getTemplates()
  }, [])

  useEffect(() => {
    if (prompt === 0) {
      setSelectedCard(null)
      setFirstTimePrompt(null)
    }
  }, [prompt])

  // useEffect(() => {
  //   if (!firstTimePrompt || !first_time_sequence) {
  //     setFirstTimePrompt(prompt)
  //     dispatch(SET_FIRST_TIME_SEQUENCE(true))
  //   }
  // }, [prompt, first_time_sequence])
  useEffect(() => {
    if (use_custom_flow) {
      setFirstTimePrompt(prompt)
      // dispatch(SET_FIRST_TIME_SEQUENCE(true))
    }
  }, [use_custom_flow])

  const modalContent = {
    title: 'Editing the Sequence Will Reset Your Messages',
    message: 'This will delete all your messages and configured sequence steps.',
    onConfirm: () => {
      setOpenAreYouSureModal(false)
      handleResetCampaign()
    },
    onClose: () => {
      setOpenAreYouSureModal(false)
      // setCurrentKey('3')
    },
  }

  return (
    <div className={styles.CampaignGenerateReport}>
      <div className={styles.top}>
        <p style={{ fontSize: '16px', color: '#091E4299', margin: 0 }}>
          Select a template or choose 'Custom' to build your sequence manually
        </p>
      </div>
      <div className={styles.AIGeneratePrompt}>
        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', justifyContent: 'center' }}>
          {promptTitle.map((items, index) => (
            <div
              key={index}
              className={styles.cardBody}
              style={{
                flex: '1 1',
                minWidth: '200px',
                background: selectedCard === items.id ? 'linear-gradient(#7144FF, #B649F3)' : '#ffffff',
                border: selectedCard === items.id ? '2px solid transparent' : '2px solid #DFE1E6',
              }}
              onClick={() => handleSelectCard(items)}>
              <Card
                style={{ justifyContent: 'space-between' }}
                className={styles.cardStyling}
                bodyStyle={{
                  borderRadius: '8px',
                  backgroundColor: selectedCard === items.id ? '#f6f3fb' : '#ffffff',
                }}>
                {!user?.addons[items.addon_name] && (
                  <img src={premium_badge} alt='Small' className={styles.premium_badge} />
                )}
                {/*<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>*/}
                <div className={styles.cardMainContent}>
                  <div>
                    <div className={styles.cardHeader}>
                      <img src={promptImageMap[items.id]} alt='Prompt Icon' />
                    </div>
                    <h1 className={styles.warmUp}>{items.title}</h1>
                    <p className={styles.warmUpLorem}>{items.description}</p>
                  </div>
                  <p className={styles.warmUpEstimated}>
                    <img src={stopWatch} alt='Stopwatch' />{' '}
                    <span>Estimated time {items.id === 1 ? 'of 3-12 days' : 'depends on your selection'}</span>
                  </p>
                </div>
                {/*</div>*/}
              </Card>
            </div>
          ))}
        </div>

        <CustomCollapseFooter id='2' onBack={handleBack} onNext={handleNext} onSkip={onSkip} />
      </div>
      <AreYouSureModal
        open={openAreYouSureModal}
        onClose={modalContent.onClose}
        onConfirm={modalContent.onConfirm}
        title={modalContent.title}
        message={modalContent.message}
        confirmText={'I understand'}
      />
      <CustomCampaignModal
        myTemplates={templates}
        open={openCustomCampaignModal}
        onConfirm={(name) => {
          setOpenCustomCampaignModal(false)
          message.success(`${name} Selected`)
        }}
        onCancel={() => {
          setOpenCustomCampaignModal(false)
          dispatch(SET_PROMPT(0))
          setSelectedCard(null)
        }}
      />
    </div>
  )
}

export default CampaignGenerateReport
