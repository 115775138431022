import React, { useState, useEffect, useRef } from 'react'
import {
  Tag,
  Select,
  Button,
  Input,
  Layout,
  Spin,
  Avatar,
  message,
  Tooltip,
  Upload,
  Image,
  Dropdown,
  MenuProps,
} from 'antd'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import Linkify from 'linkify-react'
import {
  SendOutlined,
  CloseOutlined,
  SearchOutlined,
  UserAddOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import Picker from 'emoji-picker-react'
import menuIcon from 'common/assets/svg/menu_icon.svg'
import accountIcon from 'common/assets/svg/account_icon.svg'
import settingIcon from 'common/assets/svg/setting_icon.svg'
import styles from './MessageComponent.module.scss'
import bookmark from 'common/assets/svg/bookmark.svg'
import bookmark_black from 'common/assets/svg/bookmark_black.svg'
import bookmark_chat from 'common/assets/svg/bookmark_chat.svg'
import bookmark_chat_after from 'common/assets/svg/bookmark_chat_after.svg'
import double_tick from 'common/assets/svg/double_tick.svg'
import bookmark_blue from 'common/assets/svg/bookmark_blue.svg'
import empty_state_messages from 'common/assets/svg/empty_state_messages.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'
import telegram from 'common/assets/svg/tele_icon.svg'
import instagram from 'common/assets/svg/instagram_icon.svg'
import pin_icon from 'common/assets/svg/pin_chat.svg'
import emojiPickerIcon from 'common/assets/svg/emoji_picker.svg'
import pinned_chat from 'common/assets/svg/pinned_chat.svg'
import {
  useLazyChangeMetadataQuery,
  useLazyGetBookmarkQuery,
  useLazyGetDialogsQuery,
  useLazyGetMeetingBookedTagsQuery,
  useLazyGetMessagesQuery,
  useLazySendChatMessageQuery,
  useLazySetBookmarkQuery,
  useSaveConvrtObjectsMapMutation,
  useDeleteHubspotSyncMutation,
  useSaveHubspotUnifiedInboxMutation,
} from '../../APIs/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_CLOSE_LEFT_SIDEBAR,
  SET_COLLAPSE_CHAT,
  SET_DIALOGS_DATA,
  SET_DIALOGS_METADATA,
  SET_OPEN_SIDE_PROFILE,
  SET_PENDING_MESSAGES,
  SET_REUNIFY,
  SET_SELECTED_CHAT_RECIPIENT,
} from './state/chatSlice'
import ProfileSider from './ProfileSider'
import { post } from 'common/APIs/axios'
import CustomMeetingBook from './UI/CustomMeetingBook'
import CustomAvatar from './UI/CustomAvatar'
import TagDialog from './Tag'
import { trackEvent } from 'eventConfig'
import { FancyImage } from '../GeneralComponents/UI/FancyImage'
import { HubspotSyncSider } from './HubspotSyncSider'

const isWithinOneMinute = (date1: string | null, date2: string | null) => {
  const isValidDate = (date: string | null) => {
    if (date === null) return false
    const parsedDate = new Date(date)
    return !isNaN(parsedDate.getTime())
  }

  if (!isValidDate(date1) || !isValidDate(date2)) {
    return false
  }

  const date1Time = new Date(date1!).getTime()
  const date2Time = new Date(date2!).getTime()
  return Math.abs(date1Time - date2Time) <= 60000 // 1 minute
}

interface Props {
  channelId: string | null // Adjusted type definition to accept null
  channelMessages: {
    id: string
    text: string
    sender: string
    receiver: string
    timestamp: string
  }[] // Define the type for messages
  screenSize: { width: number; height: number }
  // onSendMessage: (newMessage: { text: string sender: string receiver: string }) => void // Callback function to send a new message
}

const MessageComponent: React.FC<Props> = ({ channelId, channelMessages, screenSize }) => {
  // API Calls
  const [getDialogs, { data: dialogsData }] = useLazyGetDialogsQuery()
  const [sendChatMessage] = useLazySendChatMessageQuery()
  const [getMessages, { data }] = useLazyGetMessagesQuery()
  const messagesData = data?.messages
  const [changeMetadata] = useLazyChangeMetadataQuery()
  const [getBookmarks, { data: bookmarksData }] = useLazyGetBookmarkQuery()
  const [setBookmark] = useLazySetBookmarkQuery()
  const [getMeetingBook, { data: meetingBook }] = useLazyGetMeetingBookedTagsQuery()
  const [file, setFile] = useState<any>(null)

  // useApp
  const {
    selected_chat_recipient,
    is_unify_finished,
    close_left_sidebar,
    selected_chat_platform,
    pendingMessages,
    dialogs_metadata,
    thread_from,
    limit_count,
  } = useAppSelector((state) => state.chat)
  const [currentSelectedRecipient, setCurrentSelectedRecipient] = useState<any>(selected_chat_recipient?.id)
  const additionalTags = JSON.parse(selected_chat_recipient?.tags || '[]')
  const dispatch = useAppDispatch()
  const { current_tags_dialog, openProfileSider } = useAppSelector((state) => state.chat)
  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === selected_chat_recipient.id)
  const tagsOfDialog = filteredTagsDialog?.data

  const mergeMessages = (fetchedMessages: any[]) => {
    if (!fetchedMessages) {
      return []
    }

    const latestMessage = fetchedMessages[fetchedMessages.length - 1]

    const baseData = {
      thread_id: latestMessage?.thread_id,
      sender_username: latestMessage?.sender_username,
      recipient_username: latestMessage?.recipient_username,
    }

    // Filter out pending messages that should be included based on the criteria
    const relevantPendingMessages = pendingMessages.filter(
      (pmsg: any) =>
        pmsg.thread_id === baseData.thread_id &&
        pmsg.sender_username === baseData.sender_username &&
        pmsg.recipient_username === baseData.recipient_username,
    )

    const isMessageInFetched = (message: any) => {
      return fetchedMessages.some(
        (msg) =>
          msg.message === message.message &&
          msg.thread_id === message.thread_id &&
          msg.outgoing === message.outgoing &&
          msg.sender_username === message.sender_username &&
          msg.recipient_username === message.recipient_username &&
          isWithinOneMinute(msg.sent_at, message.sent_at),
      )
    }

    // Filter out pending messages that are already in fetchedMessages
    const uniquePendingMessages = relevantPendingMessages?.filter((pmsg: any) => !isMessageInFetched(pmsg))
    // Combine unique fetched messages with new pending messages
    return [...fetchedMessages, ...uniquePendingMessages]
  }

  // useStates
  const [openHubSpotSync, setOpenHubSpotSync] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [updateMessage, setUpdateMessage] = useState(mergeMessages(messagesData))
  // const [openProfileSider, setOpenProfileSider] = useState(false)
  const [openAllTagDetail, setOpenAllTagDetail] = useState(false)
  // eslint-disable-next-line
  const [chatImg, setChatImg] = useState(bookmark_chat)
  const [nameImg, setNameImg] = useState(bookmark)
  const [pin, setPin] = useState(pin_icon)
  const [defaultPriority, setDefaultPriority] = useState(selected_chat_recipient?.priority)
  const [defaultMeetingBook, setDefaultMeetingBook] = useState(selected_chat_recipient?.meeting_book)
  const [bookmarkCollapsed, setBookmarkCollapsed] = useState(true)
  const [selectedMessage, setSelectedMessage] = useState('')
  const [filteredMessages, setFilteredMessages] = useState<string[]>([])
  const [chatImgStates, setChatImgStates] = useState<{ [key: string]: string }>({})
  const [loading, setLoading] = useState(false)
  const [showPicker, setShowPicker] = useState(false)
  const [saveHubspotContact] = useSaveConvrtObjectsMapMutation()
  const [deleteHubspotSync] = useDeleteHubspotSyncMutation()
  const [saveHubspotUnified] = useSaveHubspotUnifiedInboxMutation()

  // useRefs
  const messageEndRef = React.useRef<any>(null)
  const tagDetailRef = React.useRef<any>(null)
  const pickerRef = React.useRef<any>(null)
  const selectedChatRecipientRef = useRef(selected_chat_recipient)
  // useClickOutside(pickerRef, () => setShowPicker(false))

  // Functions & Methods
  const handleInputChange = (event: any) => {
    setSelectedMessage(event.target.value)
  }

  const handleChangePriority = async (priority: string) => {
    const dialogId = selected_chat_recipient.id
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      metadata: { priority: priority },
      convrt_index: selected_chat_recipient.convrt_index || selected_chat_recipient.index,
    })
    setDefaultPriority(priority)

    const newMetadata = { ...dialogs_metadata?.[dialogId], priority: priority }
    dispatch(SET_DIALOGS_METADATA({ dialogId: dialogId, metadata: newMetadata }))

    dispatch(SET_SELECTED_CHAT_RECIPIENT({ ...selected_chat_recipient, priority: priority }))
    await getDialogs({ thread_from, limit_count })
    post('/log', {
      action: `Unified Inbox: Priority Changed to: ${priority}`,
      event_source: 'frontend',
      convrt_internal_description: {
        event_purpose: 'Track when the priority of a message is changed to high on the unified inbox page',
        trigger: 'User changes message priority to high',
        related_screen: 'Inbox page',
        key_data_points: [],
        expected_outcome: 'Monitor changes to message priority to high',
        notes: 'This event tracks when a message’s priority is changed to high in the unified inbox.',
        date_started: 'old',
      },
      data: {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      },
    })
  }
  const numberToName = () => {
    const outreach = dialogsData?.telegram?.accounts
    const { receiver_number } = selected_chat_recipient

    if (receiver_number && outreach?.length > 0) {
      const contact = outreach.find((contact: any) => `${contact.area_code}${contact.number}` === `+${receiver_number}`)
      return contact ? contact?.username : `+${receiver_number}`
    }

    return `+${receiver_number}`
  }

  const handleChangeMeetingBook = async (meeting_book: string) => {
    const dialogId = selected_chat_recipient.id
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: dialogId,
      senderData: selected_chat_recipient.senderData,
      // convrt_index: selected_chat_recipient.convrt_index,
      metadata: { meeting_book: meeting_book },
      convrt_index: selected_chat_recipient.convrt_index || selected_chat_recipient.index,
    })

    const newMetadata = { ...dialogs_metadata?.[dialogId], meeting_book: meeting_book }
    dispatch(SET_DIALOGS_METADATA({ dialogId: dialogId, metadata: newMetadata }))

    setDefaultMeetingBook(meeting_book)
    await getDialogs({ thread_from })
    post('/log', {
      action: `Unified Inbox: Meeting Book Changed to: ${meeting_book}`,
      event_source: 'frontend',
      convrt_internal_description: {
        event_purpose: `Track when a meeting booking is changed to ${meeting_book} in the unified inbox page`,
        trigger: `User updates meeting status to ${meeting_book}`,
        related_screen: 'Unified Inbox Page',
        key_data_points: [],
        expected_outcome: 'Monitor changes in meeting booking status',
        notes: 'This event tracks when users update meeting bookings.',
        date_started: 'old',
      },
      data: {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      },
    })
  }

  const handlePinChatClick = async (check: boolean) => {
    const dialogId = selected_chat_recipient.id

    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      metadata: { pin: check },
      convrt_index: selected_chat_recipient.convrt_index || selected_chat_recipient.index,
    })

    const newMetadata = { ...dialogs_metadata?.[dialogId], pin: check }
    dispatch(SET_DIALOGS_METADATA({ dialogId: dialogId, metadata: newMetadata }))

    dispatch(SET_SELECTED_CHAT_RECIPIENT({ ...selected_chat_recipient, pin: check }))
    await getDialogs({ thread_from, limit_count })
    if (check) {
      trackEvent(check ? 'UNIFIED_INBOX_CHAT_PINNED' : 'UNIFIED_INBOX_CHAT_UNPINNED', {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      })
    }
  }
  const convertFileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
  const fetchAfterSend = async () => {
    const { data } = await getMessages({
      dialogId: selected_chat_recipient?.id,
      senderData: selected_chat_recipient?.senderData,
      platform: 'telegram',
    })
    if (data) {
      setUpdateMessage(data?.messages)
    }
  }
  const sendMessage = async () => {
    if (selectedMessage.trim() !== '' || (file && !loading)) {
      try {
        const lastMessage = updateMessage[updateMessage.length - 1]
        const lastMessageId = parseInt(updateMessage[updateMessage.length - 1].id)
        const sent_time = new Date().toISOString()
        // Generate the new ID for the next message
        const newMessageId = (lastMessageId + 1).toString()
        let b64data = null
        if (file) {
          b64data = await convertFileToBase64(file)
        }
        const newMessage = {
          id: newMessageId,
          dialog_id: lastMessage.dialog_id ? lastMessage.dialog_id : lastMessage?.thread_id,
          message: selectedMessage,
          sent_at: sent_time,
          receiver_number: lastMessage.receiver_number,
          outgoing: true,
          status: 'pending',
        }
        // const updatedMessages = [...updateMessage, newMessage]

        const lastFetchedMessage = messagesData[messagesData?.length - 1]

        const newPendingMessage = {
          message: selectedMessage,
          thread_id: lastFetchedMessage.dialog_id ? lastFetchedMessage.dialog_id : lastFetchedMessage?.thread_id,
          outgoing: true,
          sent_at: sent_time,
          updated_at: sent_time,
          sequence: lastMessage.sequence ? lastMessage.sequence + 1 : null,
          sender_username: lastFetchedMessage.sender_username ? lastFetchedMessage.sender_username : '',
          recipient_username: lastFetchedMessage.recipient_username ? lastFetchedMessage.recipient_username : '',
          status: 'pending',
        }
        dispatch(SET_PENDING_MESSAGES([...pendingMessages, newPendingMessage]))

        const sendChatMessageData = {
          dialog_id: lastMessage.dialog_id ? lastMessage.dialog_id : lastMessage?.thread_id,
          message: selectedMessage,
          senderData: selected_chat_recipient.senderData,
          platform: selected_chat_recipient.platform,
          file: file
            ? {
                data: b64data,
                name: file.name,
              }
            : null,
        }
        setSelectedMessage('')
        if (b64data) {
          setLoading(true)
        } else {
          setUpdateMessage([...updateMessage, newMessage])
        }
        const data = await sendChatMessage(sendChatMessageData)
        if (data?.error?.status) {
          if (data?.error?.status === 'PARSING_ERROR') {
            message.error('File size is too large. Please try again with a smaller file.')
          } else {
            message.error('An error occured. Please try again later.')
          }
          console.error('Error sending a message. ', data.error)
          setLoading(false)
          return
        }
        console.log('update message is ', updateMessage)
        if (b64data) {
          await fetchAfterSend()
        }
        trackEvent('UNIFIED_INBOX_USER_SENT_A_MESSAGE', { newMessage })
        setLoading(false)
        await getDialogs({ thread_from, limit_count })
        setFile(null)
      } catch (err) {
        message.error('An error occured. Please try again later.')
        console.error('Error sending a message. ', err)
      }
    }
  }

  const renderContact = (media: any, left = false) => {
    const contact = JSON.parse(media)
    const fullName = `${contact.first_name} ${contact.last_name}`
    return (
      <div style={{ display: 'flex', gap: 7 }}>
        <FancyImage
          className='unified_contact_image'
          name={contact.first_name}
          size={48}
          url=''
          style={{
            backgroundColor: 'white !important',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: left ? 7 : 3,
            alignItems: 'flex-start',
            fontSize: '16px',
            fontWeight: 'bold',
          }}>
          <span>{fullName}</span>
          <span
            onClick={() => {
              navigator.clipboard.writeText(contact.phone_number)
              message.success('Phone number copied to clipboard')
            }}
            style={{ fontSize: '14px', cursor: 'pointer', fontWeight: '400' }}>
            {contact.phone_number}
          </span>
        </div>
      </div>
    )
  }
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault()
        setSelectedMessage((prevText) => prevText + '\n')
      } else {
        event.preventDefault()
        sendMessage()
      }
    }
  }

  const changeBookmark = () => {
    let value = nameImg

    if (value === bookmark) {
      setNameImg(bookmark_blue)
    } else {
      setNameImg(bookmark)
    }
    setBookmarkCollapsed(!bookmarkCollapsed)
  }

  const suffix = <img src={nameImg} style={{ cursor: 'pointer' }} onClick={changeBookmark} alt='' />

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = filteredMessages.filter((msg) => msg.toLowerCase().includes(e.target.value.toLowerCase()))
    setFilteredMessages(filtered.map((msg) => msg))
  }

  const closeBookmark = () => {
    setBookmarkCollapsed(true)
    setNameImg(bookmark)
  }

  const changeChatBookmark = async (messageText: string) => {
    // Check if the message text is already in the filteredMessages
    if (!filteredMessages.includes(messageText)) {
      // If not present, add it to the filteredMessages array
      const updatedFilteredMessages = [...filteredMessages, messageText]
      // Update the state with the new array
      setFilteredMessages(updatedFilteredMessages)
    }

    // Update the state for the clicked message text
    setChatImgStates((prevState) => {
      if (!bookmarksData.includes(messageText)) {
        trackEvent(
          bookmarksData.includes(messageText)
            ? 'UNIFIED_INBOX_USER_DELETED_A_BOOKMARK'
            : 'UNIFIED_INBOX_USER_ADDED_A_BOOKMARK',
          { bookmark: messageText },
        )
      }
      return {
        ...prevState,
        [messageText]: prevState[messageText] === bookmark_chat ? bookmark_chat_after : bookmark_chat,
      }
    })

    await setBookmark({ platform: 'telegram', message: messageText })
    await getBookmarks()
  }

  const handleDeleteBookmark = async (messageText: string) => {
    await setBookmark({ platform: 'telegram', message: messageText })
    await getBookmarks()

    trackEvent('UNIFIED_INBOX_USER_DELETED_A_BOOKMARK', { bookmark: messageText })
  }

  const changePin = () => {
    const dialogId = selected_chat_recipient.id
    if (!dialogs_metadata[dialogId]?.pin) {
      setPin(pinned_chat)
      handlePinChatClick(true)
    } else {
      setPin(pin_icon)
      handlePinChatClick(false)
    }
  }

  const valuepass = (value: any) => {
    setSelectedMessage(value)
    setBookmarkCollapsed(true)
    setNameImg(bookmark)

    trackEvent('UNIFIED_INBOX_USER_USED_A_BOOKMARK', { bookmark: value })
  }

  const getColorByUsername = (username: string) => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#f44336', '#009688']
    const cleanedName = username.replace(/\s+/g, '').toUpperCase()
    let hash = 0
    for (let i = 0; i < cleanedName.length; i++) {
      if (!cleanedName) {
        return colors[0]
      }
      hash = cleanedName.charCodeAt(i) + ((hash << 5) - hash)
    }
    const index = Math.abs(hash) % colors.length
    return colors[index]
  }

  const userImage = (size: number, stringSize?: 'small' | 'medium') => {
    return selected_chat_recipient?.photo || selected_chat_recipient?.title ? (
      <CustomAvatar
        name={selected_chat_recipient?.title}
        src={selected_chat_recipient?.photo}
        size={stringSize || 'medium'}
        triggerRerender={selected_chat_recipient?.title}
        style={{ marginRight: '15px' }}
      />
    ) : (
      <Avatar size={size} style={{ minWidth: `${size}px` }} icon={<UserAddOutlined />} />
    )
  }

  const formatDate = (inputDate: string) => {
    const now = new Date()
    const sentDate = new Date(inputDate)
    const dateDiff = now.getTime() - sentDate.getTime()
    const seconds = Math.round(dateDiff / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)

    const formatTime = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    if (seconds < 60) {
      return 'A few seconds ago'
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`
    } else if (hours < 48) {
      // Yesterday
      return `Yesterday at ${formatTime(sentDate)}`
    } else if (days < 3) {
      // 2 days ago
      return `${days} days ago at ${formatTime(sentDate)}`
    } else {
      // Formatting date in MM/DD/YYYY, HH:MM format
      return sentDate.toLocaleDateString() + ' at ' + formatTime(sentDate)
    }
  }

  const handleEmojiClick = (emojiObject: any) => {
    setSelectedMessage((pre) => pre + emojiObject?.emoji)
  }

  const handleOpenSideBar = () => {
    dispatch(SET_OPEN_SIDE_PROFILE(!openProfileSider))
    if (screenSize.width < 1250) {
      dispatch(SET_COLLAPSE_CHAT(true))
    }
    dispatch(SET_CLOSE_LEFT_SIDEBAR(!openProfileSider))
  }

  // useClickOutside(tagDetailRef, () => setOpenAllTagDetail(false))

  const handleOpenSocialPage = () => {
    switch (selected_chat_platform) {
      case 'telegram':
        break
      case 'instagram':
        window.open(`https://www.instagram.com/${selected_chat_recipient?.recipient_username}`, '_blank')
        break
      case 'facebook':
        window.open(selected_chat_recipient?.recipient_username)
        break
    }
  }

  const renderMsgIconByStatus = (status: any) => {
    switch (status) {
      case 'pending':
        return (
          <Tooltip title={'On quque'}>
            <ClockCircleOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        )
      case 'failed':
        return (
          <Tooltip title={'Failed Sending'}>
            <WarningOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        )
      case null:
      case undefined:
        return <img src={double_tick} alt='' />
      default:
        return <img src={double_tick} alt='' />
    }
  }

  // useEffects
  const getChatdata = async (thread_from: any) => {
    const { error, data } = await getDialogs({ thread_from, limit_count })
    dispatch(SET_DIALOGS_DATA(data))
    if (error?.data?.status === 'empty_cache') {
      dispatch(SET_REUNIFY(true))
    }
  }

  useEffect(() => {
    if (is_unify_finished) {
      getChatdata(thread_from)
    }
  }, [is_unify_finished])

  useEffect(() => {
    selectedChatRecipientRef.current = selected_chat_recipient
  }, [selected_chat_recipient])

  useEffect(() => {
    const getMessagesData = async () => {
      setLoadingMessages(true)
      const { data, error } = await getMessages({
        dialogId: selected_chat_recipient?.id,
        senderData: selected_chat_recipient.senderData,
        platform: selected_chat_recipient.platform,
      })

      if (data?.messages && selectedChatRecipientRef.current?.id === data?.dialogId) {
        setUpdateMessage(mergeMessages(data.messages))
        setLoadingMessages(false)
      } else if (error) {
        setLoadingMessages(false)
      }
    }
    if (is_unify_finished && selected_chat_recipient?.id && selected_chat_recipient.senderData) {
      getMessagesData()
    }
  }, [
    selected_chat_recipient?.id,
    selected_chat_recipient?.senderData,
    selected_chat_recipient?.platform,
    is_unify_finished,
  ])

  useEffect(() => {
    let intervalId: any

    const fetchMessages = async () => {
      if (!is_unify_finished) {
        return
      }

      try {
        getChatdata(thread_from)
        if (selected_chat_recipient?.id) {
          const { data } = await getMessages({
            dialogId: selected_chat_recipient.id,
            senderData: selected_chat_recipient.senderData,
            platform: selected_chat_recipient.platform,
          })

          if (data?.messages) {
            setUpdateMessage(mergeMessages(data.messages))
          }
        }
      } catch (error) {
        console.error('Failed to fetch messages:', error)
      }
    }

    if (is_unify_finished) {
      intervalId = setInterval(fetchMessages, 12000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [selected_chat_recipient, is_unify_finished, pendingMessages])

  useEffect(() => {
    const dialogId = selected_chat_recipient.id
    const metadata = dialogs_metadata[dialogId]

    setPin(metadata?.pin ? pinned_chat : pin_icon)
    setDefaultPriority(metadata?.priority)
    setDefaultMeetingBook(metadata?.meeting_book)
    setOpenAllTagDetail(false)
    setCurrentSelectedRecipient(selected_chat_recipient?.id)
    if (currentSelectedRecipient !== selected_chat_recipient?.id) {
      setSelectedMessage('')
    }
  }, [selected_chat_recipient, dialogs_metadata, selected_chat_recipient.meeting_book])

  useEffect(() => {
    const getAllBookmarks = async () => {
      await getBookmarks()
    }
    if (is_unify_finished) {
      getAllBookmarks()
    }
  }, [is_unify_finished])

  useEffect(() => {
    if (bookmarksData?.length > 0) {
      for (const message of bookmarksData) {
        setChatImgStates((prevState) => ({
          ...prevState,
          [message]: bookmark_chat_after,
        }))
      }
    }
  }, [bookmarksData])

  useEffect(() => {
    if (messagesData) {
      // Filter out the messages that are now confirmed and remove them from pendingMessages
      const confirmedMessages = pendingMessages.filter(
        (pmsg: any) =>
          !messagesData.some(
            (msg: any) =>
              msg.message === pmsg.message &&
              msg.thread_id === pmsg.thread_id &&
              msg.outgoing === pmsg.outgoing &&
              msg.sender_username === pmsg.sender_username &&
              msg.recipient_username === pmsg.recipient_username &&
              isWithinOneMinute(msg.sent_at, pmsg.sent_at),
          ),
      )

      // Update the pendingMessages state with the remaining unconfirmed messages
      dispatch(SET_PENDING_MESSAGES(confirmedMessages))

      // Merge fetched messages with the pending messages
      const updatedMessages = mergeMessages(messagesData)

      // Update the `updateMessage` state with the merged messages
      setUpdateMessage(updatedMessages)
    }
  }, [messagesData])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tagDetailRef.current && !tagDetailRef.current.contains(event.target as Node)) {
        setOpenAllTagDetail(false) // Close dropdown
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const getMeetingBooked = async () => {
      await getMeetingBook()
    }
    getMeetingBooked()
  }, [])
  const handleSelectHubspotContact = async (id: string) => {
    return saveHubspotContact({
      dialog_id: selected_chat_recipient?.id,
      contact_id: id,
      hubspot_type: 'CONTACT',
      convrt_item_type: 'telegram_dialog',
    }).then((rs: any) => {
      if (rs.err) {
        message.error('Some thing went wrong. Please try again')
        return
      }
      message.success('Save successfully.')
    })
  }
  const handleSyncHubspotContacts = async (value: boolean) => {
    if (value) {
      return saveHubspotUnified({
        dialog_id: selected_chat_recipient?.id,
        is_sync: true,
        sender_id: selected_chat_recipient?.receiver_number,
      }).then((rs: any) => {
        if (rs.err) {
          message.error('Some thing went wrong. Save Failed!')
          return
        }
      })
    } else {
      return deleteHubspotSync({
        dialog_id: selected_chat_recipient?.id,
      }).then((rs: any) => {
        if (rs.err) {
          message.error('Some thing went wrong. Please try again')
          return
        }
        message.success('Save successfully.')
      })
    }
  }
  if (!selected_chat_recipient.senderData) {
    if (!is_unify_finished) return <></>
    return (
      <div className={styles.emptyState}>
        <img src={empty_state_messages} alt='empty' />
        <p>No Chats to View</p>
        <span>Please choose one of the Chats to view</span>
      </div>
    )
  }
  const renderLink = ({ attributes, content }: any) => {
    const { href } = attributes
    return (
      <span
        style={{ background: 'none', border: 'none', color: '#4b96ff', textDecoration: 'none', cursor: 'pointer' }}
        onClick={() => window.open(href, '_blank')}>
        {content}
      </span>
    )
  }
  const renderReplyTo = (replyTo: any) => {
    try {
      const parsed = JSON.parse(replyTo)
      const { user, message } = parsed
      return (
        <div
          style={{
            width: '100%',
            padding: '8px 12px',
            backgroundColor: '#f1f1f1',
            borderLeft: '3px solid var(--color-blue-03)',
            borderRadius: '6px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              color: 'var(--color-blue-03)',
              marginBottom: '4px',
            }}>
            {user}
          </div>

          <Tooltip title={message}>
            <div
              style={{
                fontSize: '14px',
                color: '#333',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100px',
              }}>
              {message}
            </div>
          </Tooltip>
        </div>
      )
    } catch (e) {
      return <></>
    }
  }
  const optionLinkify = { target: '_blank', rel: 'noopener noreferrer', render: renderLink }
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.enrichDropdown__item} onClick={changePin}>
          <span>Pin Chat</span>
        </div>
      ),
      icon: <img style={{ marginLeft: '5px', marginRight: '12px' }} src={pin} alt='pin' />,
    },
    {
      key: '2',
      label: (
        <div className={styles.enrichDropdown__item} onClick={handleOpenSideBar}>
          <span>View Client Profile</span>
        </div>
      ),
      icon: <img src={accountIcon} width='26px' height={'26px'} alt='accountIcon' />,
    },
    ...(selected_chat_platform === 'telegram' && selected_chat_recipient.platform === 'telegram'
      ? [
          {
            key: '3',
            label: (
              <div className={styles.enrichDropdown__item} onClick={() => setOpenHubSpotSync(true)}>
                <span>Manage sync</span>
              </div>
            ),

            icon: <img src={settingIcon} width='26px' height={'26px'} alt='accountIcon' />,
          },
        ]
      : []),
  ]
  return (
    <Layout>
      <Content>
        <div
          className={`${styles['MessageComponent']} flex flex-column relative max-h-full`}
          style={{ userSelect: 'text' }}>
          <div className={styles.MessageComponent__TopSection}>
            <div className={styles.MessageComponent__TopSection__left}>
              <div onClick={handleOpenSideBar}></div>
              <div className={`${styles['left_recipient']} left_recipient`}>
                <div className={styles.headerDiv}>
                  <div className={styles.flexDiv} style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div onClick={handleOpenSideBar} className={styles.userImage}>
                        {userImage(48)}
                      </div>
                      <Tooltip title={selected_chat_recipient?.title}>
                        <h2
                          className={styles.truncatedText}
                          onClick={handleOpenSideBar}
                          style={{ cursor: 'pointer', fontSize: '1rem' }}>
                          {selected_chat_recipient?.title.length > 55
                            ? `${selected_chat_recipient?.title.substring(0, 55)?.trim()}...`
                            : selected_chat_recipient?.title}
                        </h2>
                      </Tooltip>

                      <img
                        onClick={handleOpenSocialPage}
                        src={
                          selected_chat_recipient.platform === 'instagram'
                            ? instagram
                            : selected_chat_recipient.platform === 'facebook'
                            ? facebook
                            : telegram
                        }
                        alt=''
                        style={{ margin: '0px 5px', height: '20px', cursor: 'pointer' }}
                      />

                      {openAllTagDetail && tagsOfDialog?.length > 0 && (
                        <div ref={tagDetailRef} className={styles['chat_receipt_box__bottomSection__tag__detail']}>
                          <TagDialog showDescription={false} />
                        </div>
                      )}
                    </div>
                    <div
                      className={styles.MessageComponent__TopSection__right}
                      style={{ justifySelf: 'flex-end', marginLeft: 'auto', flexWrap: 'wrap', gap: '5px' }}>
                      <CustomMeetingBook
                        key={selected_chat_recipient.id}
                        defaultValue={defaultMeetingBook}
                        value={defaultMeetingBook}
                        onChange={handleChangeMeetingBook}
                        getMeetingBooked={getMeetingBook}
                        customMeetingBooked={meetingBook}
                      />
                      {/*<Divider type='vertical' />*/}
                      <Select
                        className='selectPriority'
                        size='small'
                        defaultValue={defaultPriority}
                        value={defaultPriority}
                        style={{ minWidth: '90px' }}
                        placeholder='Priority'
                        onChange={handleChangePriority}>
                        <Select.Option value='high'>
                          <div className={styles.priority}>
                            <span className='select_dots'></span>
                            <span>High</span>
                          </div>
                        </Select.Option>
                        <Select.Option value='medium'>
                          <div className={styles.priority}>
                            <span className='select_dots' style={{ backgroundColor: '#FFC700' }}></span>
                            <span>Medium</span>
                          </div>
                        </Select.Option>
                        <Select.Option value='low'>
                          <div className={styles.priority}>
                            <span className='select_dots' style={{ backgroundColor: 'green' }}></span>
                            <span>Low</span>
                          </div>
                        </Select.Option>
                      </Select>

                      {/* </span> */}
                      {/* <span className={styles.enrich}>Enrich</span> */}

                      <div className={styles.MessageComponent__TopSection__left__tags}>
                        <span className={styles.tags}>
                          {/*{additionalTags.slice(0, 1).map((tag: any) => (*/}
                          {/*  <Tooltip title={<span style={{ color: 'black' }}>{tag}</span>} color='white'>*/}
                          {/*    <Tag key={tag}>{tag?.length > 30 ? tag?.slice(0, 30)?.trim() + '...' : tag}</Tag>*/}
                          {/*  </Tooltip>*/}
                          {/*))}*/}
                          {additionalTags.length > 0 && (
                            <Tooltip
                              title={
                                <div>
                                  {additionalTags.map((tag: any, index: number) => (
                                    <Tag
                                      key={index}
                                      style={{
                                        backgroundColor: '#f2efff',
                                        color: '#7043ff',
                                        borderColor: '#f2f0ff',
                                        marginBottom: '4px',
                                        display: 'inline-block',
                                      }}>
                                      {tag}
                                    </Tag>
                                  ))}
                                </div>
                              }
                              color='white'>
                              <span className='chat_receipt_box__bottomSection__more'>+{additionalTags.length}</span>
                            </Tooltip>
                          )}
                        </span>
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() => setOpenAllTagDetail((pre) => !pre)}>
                          {/*{tagsOfDialog?.slice(0, 1).map((tag: any, index: number) => (*/}
                          {/*  <TagItem key={index} title={tag.tag_name} />*/}
                          {/*))}*/}
                          {tagsOfDialog && (
                            <Tooltip
                              color='white'
                              title={
                                <div>
                                  {tagsOfDialog.map((tag: any, index: number) => (
                                    <Tag
                                      key={index}
                                      style={{
                                        backgroundColor: 'rgba(25, 188, 254, 0.15)',
                                        color: '#19bcfe',
                                        borderColor: '#f2f0ff',
                                        marginBottom: '4px',
                                        display: 'inline-block',
                                      }}>
                                      {tag.tag_name}
                                    </Tag>
                                  ))}
                                </div>
                              }>
                              <span className={styles['chat_receipt_box__bottomSection__tag__more']}>
                                +{tagsOfDialog?.length || 0}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div style={{ display: 'flex', gap: '5%' }}>
                        <div>
                          <img
                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                            src={pin}
                            alt='pin'
                            onClick={changePin}
                          />
                        </div>
                        <Dropdown trigger={['click']} menu={{ items }} placement='bottomLeft'>
                          <img src={menuIcon} alt='icon' style={{ cursor: 'pointer' }} />
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={styles.sentFrom}>
              <span>Sender: {selected_chat_recipient.sender_username}</span>
            </div> */}
          </div>

          {/* {selected_chat_recipient.can_be_used === false && (
            <div className={styles.outOfSyncBanner}>
              <span>This account is out of sync. Sent messages will be sent when the account is back online.</span>
            </div>
          )} */}
          {loadingMessages ? (
            <div className={styles.MessageComponent__middleSection}>
              <Spin tip='Loading' size='large'>
                <div className='content' />
              </Spin>
            </div>
          ) : (
            <div className={styles.MessageComponent__middleSection}>
              {[...updateMessage]?.reverse()?.map((msg: any, i: any) => {
                return (
                  <div key={i}>
                    {!msg.outgoing ? (
                      <div className={styles.left_chat}>
                        {userImage(24, 'small')}
                        <div className={styles.left_chat_text}>
                          {selected_chat_recipient?.platform === 'telegram' ? (
                            <>
                              <Linkify tagName='pre' options={optionLinkify}>
                                <div className={styles.groupUsername}>
                                  {selected_chat_recipient?.dialog_type === 'chat' && (
                                    <>
                                      <span
                                        style={{
                                          fontWeight: 600,
                                          color: getColorByUsername(msg.sender_username || 'a'),
                                        }}>
                                        {msg.sender_username}
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {msg?.media?.includes('ogg') ? (
                                    <>
                                      {msg?.media?.includes('ogg') && (
                                        <>
                                          <div className={styles.leftAudio}>
                                            <audio controls={true}>
                                              <source src={msg?.media} type='audio/ogg' />
                                            </audio>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <div className={styles.messageContainer}>
                                      {msg?.reply_to?.includes('{') && renderReplyTo(msg.reply_to)}

                                      {msg?.media && (
                                        <>
                                          {msg?.media?.includes('jpeg') && (
                                            <>
                                              <Image
                                                src={msg?.media}
                                                style={{ width: '100%', height: '200px', padding: 0 }}
                                              />
                                            </>
                                          )}
                                          {msg?.media?.includes('jpg') && (
                                            <>
                                              <Image
                                                src={msg?.media}
                                                style={{ width: '100%', height: '200px', padding: 0 }}
                                              />
                                            </>
                                          )}
                                          {msg?.media?.includes('png') && (
                                            <>
                                              <Image
                                                src={msg?.media}
                                                style={{ width: '100%', height: '200px', padding: 0 }}
                                              />
                                            </>
                                          )}
                                          {msg?.media?.includes('gif') && (
                                            <>
                                              <Image
                                                src={msg?.media}
                                                style={{ width: '100%', height: '200px', padding: 0 }}
                                              />
                                            </>
                                          )}
                                          {msg?.media?.includes('mp4') && (
                                            <>
                                              <video width='100%' height='200px' autoPlay loop muted src={msg?.media} />
                                            </>
                                          )}
                                          {msg?.media?.includes('phone_number') && renderContact(msg?.media, true)}
                                        </>
                                      )}
                                      <span
                                        style={{
                                          maxWidth: '200px',
                                          overflow: 'hidden',
                                          whiteSpace: 'initial',
                                        }}>
                                        {msg.message}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Linkify>
                            </>
                          ) : (
                            <Linkify tagName='p' options={{ target: '_blank', rel: 'noopener noreferrer' }}>
                              <div className={styles.groupUsername}>
                                {selected_chat_recipient?.dialog_type === 'chat' && (
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        color: getColorByUsername(msg.sender_username || 'a'),
                                      }}>
                                      {msg.sender_username}
                                    </span>
                                    <br />
                                  </>
                                )}
                                <>
                                  <span style={{ whiteSpace: 'pre-line' }}>{msg.message}</span>
                                </>
                              </div>
                            </Linkify>
                          )}
                          <span className={styles.left_chat_time}>
                            <span className={styles.left_chat_text__dots}></span>
                            {formatDate(msg.sent_at)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {selected_chat_recipient?.platform === 'telegram' ? (
                          <>
                            <div className={styles.right_chat}>
                              <div className={styles.right_chat_text}>
                                {msg?.media?.includes('ogg') ? (
                                  <>
                                    {msg?.media?.includes('ogg') && (
                                      <>
                                        <div className={styles.rightAudio}>
                                          <audio controls={true} muted>
                                            <source src={msg?.media} type='audio/ogg' />
                                          </audio>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <div className={styles.messageContent}>
                                    <Linkify tagName='pre' options={optionLinkify}>
                                      <>
                                        <div className={styles.messageContainerRight}>
                                          {msg?.reply_to?.includes('{') && renderReplyTo(msg.reply_to)}

                                          {msg?.media && (
                                            <>
                                              {msg?.media?.includes('jpeg') && (
                                                <>
                                                  <Image
                                                    src={msg?.media}
                                                    style={{ width: '100%', height: '200px', padding: 0 }}
                                                  />
                                                </>
                                              )}

                                              {msg?.media?.includes('jpg') && (
                                                <>
                                                  <Image
                                                    src={msg?.media}
                                                    style={{ width: '100%', height: '200px', padding: 0 }}
                                                  />
                                                </>
                                              )}
                                              {msg?.media?.includes('png') && (
                                                <>
                                                  <Image
                                                    src={msg?.media}
                                                    style={{ width: '100%', height: '200px', padding: 0 }}
                                                  />
                                                </>
                                              )}
                                              {msg?.media?.includes('gif') && (
                                                <>
                                                  <Image
                                                    src={msg?.media}
                                                    style={{ width: '100%', height: '200px', padding: 0 }}
                                                  />
                                                </>
                                              )}
                                              {msg?.media?.includes('mp4') && (
                                                <>
                                                  <video
                                                    width='100%'
                                                    height='200px'
                                                    autoPlay
                                                    loop
                                                    muted
                                                    src={msg?.media}
                                                  />
                                                </>
                                              )}
                                              {msg?.media?.includes('phone_number') && renderContact(msg?.media)}
                                            </>
                                          )}
                                          <span
                                            style={{
                                              maxWidth: '200px',
                                              overflow: 'hidden',
                                              whiteSpace: 'initial',
                                            }}>
                                            {msg.message}
                                          </span>
                                        </div>
                                      </>
                                    </Linkify>
                                    <img src={double_tick} alt='' />
                                  </div>
                                )}
                                <span className={styles.left_chat_time}>
                                  <span>
                                    <span className={styles.left_chat_text__dots}></span>
                                    {formatDate(msg.sent_at)}
                                  </span>
                                  <img
                                    src={chatImgStates[msg.message] || chatImg}
                                    style={{ height: '20px', width: '16px', marginLeft: '5px' }}
                                    onClick={() => changeChatBookmark(msg.message)}
                                    alt=''
                                  />
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={styles.right_chat}>
                              <div className={styles.right_chat_text}>
                                <div className={styles.messageContent}>
                                  <Linkify tagName='p' options={{ target: '_blank' }}>
                                    <span style={{ whiteSpace: 'pre-line' }}>{msg.message}</span>
                                  </Linkify>
                                  {renderMsgIconByStatus(msg.status)}
                                </div>
                                <span className={styles.left_chat_time}>
                                  <span>
                                    <span className={styles.left_chat_text__dots}></span>
                                    {formatDate(msg.sent_at)}
                                  </span>
                                  <div className={styles.rightBottomMessage}>
                                    {msg.status !== 'failed' && (
                                      <>
                                        {msg.sent_from === 'ai_responder' ? (
                                          <span className={styles.messageInfo}>AI Automatic Responder</span>
                                        ) : msg.from_ai ? (
                                          <span className={styles.messageInfo}>Sent by Convrt AI</span>
                                        ) : null}
                                      </>
                                    )}
                                    {msg.status === 'failed' && (
                                      <span style={{ color: '#DF667C', marginLeft: '3px' }}>Failed</span>
                                    )}
                                    <img
                                      src={chatImgStates[msg.message] || chatImg}
                                      style={{ height: '20px', width: '16px', marginLeft: '5px' }}
                                      onClick={() => changeChatBookmark(msg.message)}
                                      alt=''
                                    />
                                  </div>
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )
              })}
              <div ref={messageEndRef} />
            </div>
          )}
          <div className={styles.MessageComponent__bottomSection}>
            <span className='MessageComponent__bottomSection__middle'>
              <div className={styles.emojiAbsolute}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={emojiPickerIcon}
                  alt='picker icon'
                  onClick={() => setShowPicker((pre) => !pre)}
                />
                <div ref={pickerRef} className={styles.emojiSelect}>
                  <Picker open={showPicker} onEmojiClick={handleEmojiClick} autoFocusSearch={false} />
                </div>
              </div>
              <div className={styles.bookmarkAbsolute}>{suffix}</div>
              <div className={styles.clipAbsolute}>
                <Upload
                  beforeUpload={(file) => {
                    // check if file is the right type
                    if (
                      file.type === 'image/jpeg' ||
                      file.type === 'image/png' ||
                      file.type === 'video/mp4' ||
                      file.type === 'image/gif'
                    ) {
                      setFile(file)
                      return false
                    } else {
                      message.error('Please upload only image or video file')
                      return false
                    }
                  }}
                  showUploadList={false}
                  accept='.jpg, .jpeg, .png, .mp4, .gif'>
                  <PaperClipOutlined style={{ color: 'var(--color-gray-01)', fontSize: '20px' }} />
                </Upload>
              </div>
              <div className='fileAbsolute'></div>
              {file && (
                <>
                  <div className={styles.fileAbsolute}>
                    <Tag
                      closable
                      onClose={() => {
                        setFile(null)
                      }}>
                      <Tooltip title={file?.name}>
                        {file?.name?.length > 7 ? file?.name?.slice(0, 7) + '...' : file?.name}
                      </Tooltip>
                    </Tag>
                  </div>
                </>
              )}
              <Input.TextArea
                className='messageComponentInput'
                placeholder={`Message from ${selected_chat_recipient.sender_username || numberToName()}`}
                size='large'
                autoSize={{ minRows: 1, maxRows: 6 }}
                value={selectedMessage}
                onChange={handleInputChange}
                onPressEnter={handleKeyPress}
                disabled={loading}
              />
              {loading && (
                <div className={styles.loadingSendMessage}>
                  <Spin style={{ marginRight: '5px' }} />
                </div>
              )}
            </span>
            <span className='MessageComponent__bottomSection__right'>
              <Button type='primary' icon={<SendOutlined />} onClick={sendMessage}></Button>
            </span>
          </div>
        </div>
      </Content>
      <Sider
        width='340px'
        className={`${styles['MessageComponent_sider']} MessageComponent_sider`}
        collapsedWidth='0'
        collapsed={bookmarkCollapsed}>
        <div className={styles.MessageComponent_sider__topSection}>
          <h1>
            <img src={bookmark_black} alt='' /> Chat Bookmark
          </h1>
          <CloseOutlined onClick={closeBookmark} />
        </div>
        <Input size='large' placeholder='Search for messages' prefix={<SearchOutlined />} onChange={handleSearch} />
        {bookmarksData?.map((message: string, index: number) => (
          <div className={styles.sider_bookmark} key={index} onClick={() => valuepass(message)}>
            <p>{message}</p>
            <CloseOutlined
              className={styles.close_icon}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteBookmark(message)
              }}
            />
          </div>
        ))}
      </Sider>
      {selected_chat_recipient && openHubSpotSync && (
        <HubspotSyncSider
          open={openHubSpotSync}
          onClose={() => setOpenHubSpotSync(false)}
          onChangeSyncContact={handleSyncHubspotContacts}
          onSelectContact={handleSelectHubspotContact}
          dialogId={selected_chat_recipient.id}
        />
      )}
      <ProfileSider
        isVisible={openProfileSider}
        onOpen={handleOpenSideBar}
        onClose={() => {
          dispatch(SET_OPEN_SIDE_PROFILE(false))
          dispatch(SET_CLOSE_LEFT_SIDEBAR(!close_left_sidebar))
          dispatch(SET_COLLAPSE_CHAT(false))
        }}
        userImage={
          <CustomAvatar
            name={selected_chat_recipient?.title}
            src={selected_chat_recipient?.photo}
            size='xl'
            triggerRerender={selected_chat_recipient?.title}
          />
        }
        openHubSpotSync={() => {
          setOpenHubSpotSync(true)
        }}
        onChangeSyncContact={handleSyncHubspotContacts}
      />
    </Layout>
  )
}

export default MessageComponent
