import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import { useLazyGetUserDetailsQuery } from 'common/APIs/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'

export interface IAuthProvider {
  user: any
  login: any
  logout: any
  refetchUser: any
  refetchUserLite: any
  updateUserDetails: any
  userDetails: any
  loginWithoutOutreach: any
}

const AuthContext = createContext<IAuthProvider | null>(null)

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useLocalStorage(LocalStorageKeys.ACCESS_TOKEN, null)
  const [, setHubspot] = useLocalStorage(LocalStorageKeys.HUBSPOT_ACCESS_TOKEN, null)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()

  const [userDetails, setUserDetails] = useLocalStorage('user', null)
  const navigate = useNavigate()

  const login = async (data: any) => {
    setUser(data.accessToken)
    setHubspot(data.hubspotToken)
    localStorage.setItem('fetchDeals', 'true')
    data.user?.subscription_type === 'BETA'
      ? localStorage.setItem('showBanner', 'true')
      : localStorage.setItem('showBanner', 'false')
    // Userpilot.identify(data.user.id, {
    //   name: data.user.name,
    //   email: data.user.email,
    //   created_at: data.user.subscription_created_at,
    // })
    refetchUser(data.user)
  }

  const loginWithoutOutreach = async (data: any) => {
    setUser(data.accessToken)
    setHubspot(data.hubspotToken)
    localStorage.setItem('fetchDeals', 'true')
    data.user?.subscription_type === 'BETA'
      ? localStorage.setItem('showBanner', 'true')
      : localStorage.setItem('showBanner', 'false')
    refetchUserWithoutOutreach(data.user)
  }
  const logout = () => {
    setUser(null)
    setHubspot(null)
    localStorage.clear()
    navigate('/login', { replace: true })
  }
  const refetchUserWithoutOutreach = (user: any) => {
    // const autoCheckReplies = async () => {
    //   await refetcGetAutoCheckReplies()
    // }

    // autoCheckReplies()
    // refetcGetAutoCheckReplies()
    setUserDetails(user)
  }
  const refetchUser = async (user: any) => {
    // user.did_funnel = true
    // const autoCheckReplies = async () => {
    //   await refetcGetAutoCheckReplies()
    // }

    // autoCheckReplies()
    // refetcGetAutoCheckReplies()
    const { data: userData } = await refetchGetUserDetails()
    const u = userData ? userData : user
    setUserDetails(u)
    if (u?.progress?.getting_started?.completed) {
      navigate('/outreach')
    } else {
      navigate('/getting-started')
    }
    window.location.reload()
  }

  const refetchUserLite = (user: any) => {
    setUserDetails(user)
  }

  const updateUserDetails = async () => {
    const { data } = await refetchGetUserDetails()
    data && setUserDetails(data)
  }

  const value = useMemo(
    () => ({
      user,
      userDetails,
      login,
      logout,
      refetchUser,
      refetchUserLite,
      updateUserDetails,
      loginWithoutOutreach,
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext) as IAuthProvider
