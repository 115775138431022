import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Input, Divider, Breadcrumb } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Grid, AutoSizer, Index } from 'react-virtualized'
import LoadingIcon from 'common/assets/svg/loading_stars.svg'

// Styles and Assets
import styles from './GroupsContent.module.scss'
import refinement_icon from 'common/assets/svg/refinement_icon.svg'

// Component Imports
import GroupCard from './GroupCard'
import PlatformTag from './PlatformTag'
import GroupSkeleton from './GroupSkeleton'
import { MyGroupImport } from './MyGroups'
import NotSeeing from './Components/NotSeeing'
import LoadMore from './Components/LoadMore'
// State and API Hooks
import { useLazyGetPointsStoreQuery } from 'common/APIs/SettingsApi'
import { ImportLeadsOptionsModal } from './ImportLeadsOptionsModal'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_MAIN_EXPLORE } from 'common/components/Campaigns/state/outreachAICampaignSlice'
import MainExplore from './MainExplore'
import { AllFailedCollections } from 'common/components/Campaigns/Helpers/CampaignConstants'
import RotatingText from 'common/components/SocialConnection/RotatingText'
import { useUser } from 'Utils/UserContext'

// Types and Interfaces
interface Group {
  name: string
  username: string
  leads: number
  platform: string
  locked: boolean
  description: string
  img_url: string
  tags: string[]
  progress?: any
  status?: any
  source_type: any
  added_at?: any
  track_mode?: boolean
  title?: any
  mine?: boolean
  suggested?: boolean
  is_list?: boolean
  is_web3Event?: boolean
  is_web3Group?: boolean
}

interface GroupsContentProps {
  groupsData: Group[]
  filterObject: any
  setSelectedGroup: (group: Group) => void
  setSelectedGroupName: (name: string) => void
  myGroupsTab: boolean
  handleCustomImport: () => void
  initialLoading?: boolean
  onLinkedinCollect: (group: Group) => void
  openCustomImport: boolean
  setOpenCustomImport: (open: boolean) => void
  importData: any
  setImportData: (data: any) => void
  icpData: any
  myHubLoading: boolean
  setFilterObject: (filterObject: any) => void
  goToProgress: (type?: any) => void
  goToMyHub: any
  loadIncrement: number
  setLoadIncrement: any
  selectedType: string
  exploreLoading: object
  searchValue: string
  setSearchValue: (value: string) => void
}
// Utility Functions
const matchesSearch = (obj: any, searchValue: string): boolean => {
  const lowerCaseSearchValue = searchValue.toLowerCase()

  let tagsArray: string[] = []
  if (obj?.tags) {
    try {
      // Attempt to parse tags if it's a JSON string
      tagsArray = Array.isArray(obj.tags) ? obj.tags : JSON.parse(obj.tags)
    } catch {
      // If parsing fails, treat it as invalid and ignore
      tagsArray = []
    }
  }
  return (
    obj?.name?.toLowerCase()?.includes(lowerCaseSearchValue) ||
    obj?.title?.toLowerCase()?.includes(lowerCaseSearchValue) ||
    obj?.username?.toLowerCase()?.includes(lowerCaseSearchValue) ||
    tagsArray.some((tag: any) => {
      if (typeof tag === 'string') {
        return tag?.toLowerCase().includes(lowerCaseSearchValue)
      } else {
        return (tag?.label || '')?.toLowerCase().includes(lowerCaseSearchValue)
      }
    }) ||
    obj?.description?.toLowerCase()?.includes(lowerCaseSearchValue)
  )
}

const getIsExploreLoading = (exploreLoading: any) => {
  let amountLoaded = 0
  Object.keys(exploreLoading).forEach((key) => {
    if (exploreLoading[key]) {
      amountLoaded++
    }
  })
  return amountLoaded < 3 && Object.keys(exploreLoading)?.length > 0
}
const GroupsContent: React.FC<GroupsContentProps> = ({
  groupsData = [],
  filterObject,
  setSelectedGroup,
  setSelectedGroupName,
  myGroupsTab,
  handleCustomImport,
  initialLoading,
  onLinkedinCollect,
  openCustomImport,
  setOpenCustomImport,
  importData,
  setImportData,
  icpData,
  myHubLoading,
  setFilterObject,
  goToProgress,
  loadIncrement,
  setLoadIncrement,
  selectedType,
  exploreLoading,
  searchValue,
  setSearchValue,
  goToMyHub,
}) => {
  // State Management
  const { user } = useUser()
  const messages = [
    'Generating the best suggestions for you...',
    'Getting the relevant suggestions based on your ICP...',
    'Preparing the suggestions...',
  ]
  const onImportingProgress = filterObject?.a === 'On Importing Progress'
  const { mainExplore, active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatformsArray = Object.entries(active_platforms)
    ?.filter(([key, value]) => value)
    ?.map(([key, value]) => key)
  const selectedPlatform = truePlatformsArray?.[0]
  const [getPointsStore] = useLazyGetPointsStoreQuery()
  const [pointsStore, setPointsStore] = useState()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [importLeadsOptionsModalVisible, setImportLeadsOptionsModalVisible] = useState<any>(false)
  const isExploreLoading = getIsExploreLoading(exploreLoading)
  const carouselRef = useRef<any>()
  const [, setCarouselIndex] = useState(0)
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([
    {
      title: 'Explore All',
    },
  ])
  const dispatch = useAppDispatch()
  const customCollect = () => {
    setImportLeadsOptionsModalVisible(true)
  }
  const handleFacebookShouldJoin = async (group: Group) => {
    // are any of my senders inside? if yes, return him, if not, return false
    const myGroups: any = [...groupsData].filter((g) => g.mine)
    const found = myGroups.find((g: any) => g.username === group.username)
    if (found) {
      return found.outreach_username
    }
    return false
  }
  const handleGroups = (groups: Group[], searchValue: string) => {
    if (!groups) return []
    let toReturn = groups

    // First apply filters
    if (myGroupsTab) {
      toReturn = filterMyHubGroups(toReturn)
    } else {
      toReturn = filterExploreGroups(toReturn)
    }

    // Then apply search
    toReturn = toReturn.filter((group) => matchesSearch(group, searchValue))

    // Finally sort
    return sortGroups(toReturn)
  }
  const filterExploreGroups = (groups: Group[]): Group[] => {
    let filtered = groups
    dispatch(SET_MAIN_EXPLORE(filterObject?.a === 'Explore All' && !filterObject?.b && filterObject?.c?.length === 0))
    if (filterObject?.a) {
      switch (filterObject?.a) {
        case 'Explore All':
          filtered = groups
          break
        case 'Suggested':
          filtered = groups?.filter((group) => group?.suggested === true)
          break
        case 'From My Accounts':
          filtered = groups?.filter((group) => group?.mine === true)
          break
        // case 'Groups':
        //   filtered = groups?.filter((group) => group?.is_web3Group === true)
        //   break
        // case 'Events':
        //   filtered = groups?.filter((group) => group?.is_web3Event === true)
        //   break
      }
    }
    if (filterObject?.b) {
      switch (filterObject?.b) {
        case 'Hashtags':
          filtered = filtered?.filter((group) => group.source_type === 'hashtag')
          break
        case 'Groups':
          if (user?.user_type === 'web3') {
            filtered = filtered?.filter((group) => group.platform === 'telegram' && group?.is_web3Group === true)
          } else {
            filtered = filtered?.filter((group) => group.platform === 'facebook')
          }
          break
        case 'Searches':
          filtered = filtered?.filter((group) => group.platform === 'linkedin')
          break
        case 'Pages':
          filtered = filtered?.filter((group) => group.platform === 'instagram' && group.source_type !== 'hashtag')
          break
        case 'Lists':
          filtered = filtered?.filter(
            (group) => group.is_list || group.source_type === 'list' || group.source_type === 'lists',
          )
          break
        case 'Conferences':
          filtered = filtered?.filter((group) => group.source_type === 'event' || group.is_web3Event === true)
          break
      }
    }
    if (filterObject?.c) {
      if (filterObject?.c?.length > 0) {
        filtered = filtered?.filter((group) => {
          return filterObject?.c?.includes(group.platform?.toLowerCase())
        })
      }
    }
    return filtered
  }
  const filterMyHubGroups = (groups: Group[]): Group[] => {
    let filtered = groups
    if (filterObject?.a) {
      switch (filterObject?.a) {
        case 'Explore All':
          filtered = groups
          break
        case 'On Importing Progress':
          filtered = groups?.filter((group: any) => {
            if (AllFailedCollections.includes(group?.status)) {
              return false
            }
            if (group?.status === 'completed') {
              return false
            }
            const numCollectedLeads = +group?.collected_leads || 0
            const cumulative_leads_fetched = +group?.cumulative_leads_fetched || 0
            const latest_request = +group?.latest_request || 0
            const numRequestedLeads = +group?.number_of_requested_leads || 0
            const requestedLeads = latest_request > 0 ? latest_request : numRequestedLeads
            const notFull = group?.progress === 100 && numCollectedLeads < numRequestedLeads
            const hideProgressBar =
              group?.status === 'success' || group?.status === 'partial_success' || group?.status === 'reach_limit'
            const isRestricted = group?.status === 'restricted_access'

            const collectionProgress: any = (() => {
              if (group?.platform === 'linkedin') {
                return group?.progress // Get the progress from db
              }

              if (numCollectedLeads > numRequestedLeads) {
                return 100 // Set to 100
              }

              if (notFull) {
                return 99 // Set to a value less than 100
              }

              if (group?.progress !== undefined && numRequestedLeads > 0) {
                const collectedLeads =
                  +numCollectedLeads >= +cumulative_leads_fetched
                    ? +numCollectedLeads - +cumulative_leads_fetched
                    : numCollectedLeads

                return Math.ceil((collectedLeads / requestedLeads) * 100) // Calculate the percentage manually
              }

              return false
            })()
            return collectionProgress < 100 && collectionProgress !== false && !hideProgressBar && !isRestricted
          })
          break
        case 'On Tracking':
          filtered = groups?.filter((group) => group.track_mode === true)
          break
        case 'Completed':
          filtered = groups?.filter(
            (group) =>
              group.status === 'success' ||
              group.status === 'partial_success' ||
              group.status === 'reach_limit' ||
              group.status === 'completed',
          )
          break
      }
    }
    if (filterObject?.b) {
      switch (filterObject?.b) {
        case 'Hashtags':
          filtered = filtered?.filter((group) => group.source_type === 'hashtag')
          break
        case 'Groups':
          if (user?.user_type === 'web3') {
            filtered = filtered?.filter((group) => group?.is_web3Group === true)
            // TO DO: mark the above and unmark the below:
            // filtered = filtered?.filter((group) => group.platform === 'facebook' || group.platform === 'telegram')
          } else {
            filtered = filtered?.filter((group) => group.platform === 'facebook')
          }
          break
        case 'Searches':
          filtered = filtered?.filter((group) => group.platform === 'linkedin')
          break
        case 'Pages':
          filtered = filtered?.filter((group) => group.platform === 'instagram' && group.source_type !== 'hashtag')
          break
        case 'Lists':
          filtered = filtered?.filter(
            (group) => group.is_list || group.source_type === 'list' || group.source_type === 'lists',
          )
          break
        case 'Conferences':
          filtered = filtered?.filter((group) => group.source_type === 'event' || group.is_web3Event === true)
          break
      }
    }
    // allow two platforms to be selected and perform an OR operation
    if (filterObject?.c) {
      if (filterObject?.c?.length > 0) {
        filtered = filtered?.filter((group) => {
          return filterObject?.c?.includes(group.platform?.toLowerCase())
        })
      }
    }

    return filtered
  }

  const sortGroups = (groups: Group[]): Group[] => {
    if (!groups) return []
    if (myGroupsTab) {
      return [...groups].sort((a, b) => {
        const aTime = a.added_at ? new Date(a.added_at).getTime() : 0
        const bTime = b.added_at ? new Date(b.added_at).getTime() : 0
        return bTime - aTime // Newer first
      })
    }
    return [...groups].sort((a, b) => {
      // sort by selected platform first
      const aPlatform = a.platform === selectedPlatform ? 1 : 0
      const bPlatform = b.platform === selectedPlatform ? 1 : 0
      if (aPlatform > bPlatform) return -1
      if (aPlatform < bPlatform) return 1
      return 0
    })
  }
  // Effects
  useEffect(() => {
    const getPoints = async () => {
      const { data } = await getPointsStore()
      setPointsStore(data?.response)
    }
    getPoints()
  }, [])
  useEffect(() => {
    if (filterObject?.a === 'Explore All') {
      setBreadCrumbItems((prev: any) => [
        {
          title: 'Explore All',
        },
      ])
    } else {
      setBreadCrumbItems((prev: any) => [
        {
          title: (
            // eslint-disable-next-line
            <a
              style={{
                cursor: 'pointer',
              }}
              href='#'
              onClick={() => {
                setFilterObject((prev: any) => {
                  const copy = { ...prev }
                  return {
                    ...copy,
                    a: 'Explore All',
                  }
                })
              }}>
              Explore All
            </a>
          ),
        },
        {
          title: filterObject?.a,
        },
      ])
    }
  }, [filterObject])
  useEffect(() => {
    // Define the function to check screen size
    const checkScreenSize = () => {
      const isWidthConstrained = window.innerWidth < 1600
      const isHeightConstrained = window.innerHeight < 900
      const isLowResolution = window.innerWidth * window.innerHeight < 1366 * 768

      setIsSmallScreen(isWidthConstrained || isHeightConstrained || isLowResolution)
    }
    // Initial check
    checkScreenSize()
    // Add event listener
    window.addEventListener('resize', checkScreenSize)

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])
  useEffect(() => {
    let interval: NodeJS.Timeout
    interval = setInterval(() => {
      setCarouselIndex((prevIndex) => {
        const newIndex = prevIndex + 1
        if (newIndex < messages.length) {
          if (carouselRef.current) {
            carouselRef.current.goTo(newIndex)
          }
          return newIndex
        }
        return prevIndex
      })
    }, 6000)

    return () => clearInterval(interval)
  }, [])
  // Event Handlers
  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  // Derived Values
  const proccessedGroups = handleGroups(groupsData, searchValue)
  const visibleData = proccessedGroups
  const slicedData = visibleData.slice(0, loadIncrement)

  const notSeeingWhatYouNeed = (
    <LoadMore
      loadIncrement={loadIncrement}
      totalLength={visibleData.length}
      onLoadMore={() => setLoadIncrement((prev: any) => prev + 9)}
      onCollectLeads={customCollect}
      isSmallScreen={isSmallScreen}
    />
  )
  const listsNotSeeingWhatYouNeed = (
    <LoadMore
      loadIncrement={loadIncrement}
      totalLength={visibleData.length}
      onLoadMore={() => setLoadIncrement((prev: any) => prev + 9)}
      onCollectLeads={customCollect}
      isSmallScreen={isSmallScreen}
      list
    />
  )

  const exploreLoadingScreen = (
    <div className={styles.loadingScreen}>
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
        <img src={LoadingIcon} className='loading-image' alt='' />
      </div>
      <p className={styles.truncatedText}>Generating Suggestions For You</p>
      <RotatingText messages={messages} spanStyle={{ fontSize: '26px' }} />
    </div>
  )
  // Render Styles
  const contentStyle: React.CSSProperties = {
    minHeight: 669,
    color: '#fff',
    padding: '24px 10px 0px 20px',
    backgroundColor: 'white',
  }
  const rowCount = Math.ceil(visibleData.length / 3)
  const columnCount = 3
  const totalRows = Math.ceil(slicedData.length / columnCount) + 1

  return (
    <>
      <Content style={contentStyle}>
        {/* Custom Group Import Component */}
        {myGroupsTab && (
          <MyGroupImport
            refinement={searchValue}
            pointsStore={pointsStore}
            openCustomImport={openCustomImport}
            setOpenCustomImport={setOpenCustomImport}
            importData={importData}
            setImportData={setImportData}
            customCollect={customCollect}
          />
        )}
        {!myGroupsTab && filterObject?.a !== 'Explore All' && (
          <>
            <Breadcrumb items={breadCrumbItems} style={{ marginBottom: 20 }} />
          </>
        )}
        {/* Search Input Section */}
        {(groupsData?.length > 0 || initialLoading || myHubLoading) && (myGroupsTab ? true : !mainExplore) && (
          <div className={styles.input}>
            <Input
              prefix={<img style={{ marginRight: '4px' }} src={refinement_icon} alt='' />}
              placeholder={`Search by Name, Username, Bio or Tags`}
              defaultValue={searchValue}
              value={searchValue}
              onChange={onSearchChange}
            />
          </div>
        )}

        <Row gutter={10} className={`${styles.rowStyle} groupsContentRowScrollContainer`}>
          {/* Groups Display Section */}
          {visibleData?.length > 0 && (myGroupsTab ? !myHubLoading : !initialLoading && !isExploreLoading) ? (
            <React.Fragment>
              {/* Category Header */}
              <Col span={24} style={{ maxHeight: 87 }} className={styles.colStyle}>
                <div className={styles.categoryHeader}>
                  <div className={styles.leftSide}>
                    <span style={{ fontWeight: 600 }}>{filterObject?.a || 'Explore All'}</span>
                    <Divider type='vertical' />
                    <span>
                      {visibleData.length} {selectedType}
                      {visibleData.length === 1 ? '' : 's'}
                    </span>
                  </div>
                </div>

                {/* Platform Tags */}
                {user?.user_type !== 'web3' && (
                  <div className={styles.platforms}>
                    {filterObject?.c?.map((platform: any) => (
                      <PlatformTag
                        platform={platform}
                        selectedPlatform={' '}
                        removePlatform={() => {
                          setFilterObject((prev: any) => {
                            const copy = { ...prev }
                            return {
                              ...copy,
                              c: copy?.c?.filter((item: any) => item !== platform),
                            }
                          })
                        }}
                      />
                    ))}
                  </div>
                )}
              </Col>

              {/* Virtualized Grid of Groups */}
              {myGroupsTab ? (
                <>
                  <div style={{ width: '100%', height: '70%' }}>
                    <AutoSizer>
                      {({ width }) => {
                        const columnCount = Math.max(1, Math.min(Math.floor(width / 250), 3)) // At least 1 column, maximum 3
                        const columnWidth = Math.floor(width / columnCount) // Dynamically fill available space
                        return (
                          <Grid
                            // style={{ paddingBottom: isSmallScreen ? '50px' : '0px' }}
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            height={500}
                            rowCount={rowCount + (onImportingProgress || filterObject?.b === 'Lists' ? 0 : 1)}
                            rowHeight={(index: Index) => {
                              const toReturn =
                                index.index === rowCount
                                  ? onImportingProgress || filterObject?.b === 'Lists'
                                    ? 270
                                    : 525
                                  : 270
                              return toReturn
                            }}
                            width={width + 10}
                            cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                              const index = rowIndex * 3 + columnIndex

                              if (rowIndex === rowCount) {
                                if (columnIndex === 0 && !onImportingProgress && filterObject?.b !== 'Lists') {
                                  return (
                                    <NotSeeing
                                      key={key}
                                      style={{
                                        ...style,
                                        width: '100%',
                                        textAlign: 'center',
                                        top: style?.top || 1000 + 500,
                                        marginTop: '50px',
                                      }}
                                      onClick={customCollect}
                                      isSmallScreen={isSmallScreen}
                                    />
                                  )
                                }
                                return null
                              }

                              if (index >= visibleData.length) return null
                              const group = visibleData[index]
                              return (
                                <div key={key} style={style}>
                                  <div style={{ padding: '2px 10px 0px 10px' }}>
                                    <GroupCard
                                      key={group?.name || group?.username || group?.title}
                                      group={group}
                                      onSelect={setSelectedGroup}
                                      setSelectedGroupName={setSelectedGroupName}
                                      myGroupsTab={myGroupsTab}
                                      refinement={searchValue}
                                      pointsStore={pointsStore}
                                      onLinkedinCollect={onLinkedinCollect}
                                      importData={importData}
                                      icpData={icpData}
                                      handleFacebookShouldJoin={handleFacebookShouldJoin}
                                      setImportData={setImportData}
                                      goToProgress={goToProgress}
                                      goToMyHub={goToMyHub}
                                      isSmallScreen={isSmallScreen}
                                    />
                                  </div>
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                    </AutoSizer>
                  </div>
                </>
              ) : (
                <>
                  {mainExplore ? (
                    <>
                      <MainExplore
                        setFilterObject={setFilterObject}
                        groupsData={groupsData}
                        setSelectedGroup={setSelectedGroup}
                        setSelectedGroupName={setSelectedGroupName}
                        myGroupsTab={myGroupsTab}
                        searchValue={searchValue}
                        pointsStore={pointsStore}
                        onLinkedinCollect={onLinkedinCollect}
                        importData={importData}
                        icpData={icpData}
                        handleFacebookShouldJoin={handleFacebookShouldJoin}
                        setImportData={setImportData}
                        goToProgress={goToProgress}
                        goToMyHub={goToMyHub}
                        onCollectLeads={customCollect}
                        isSmallScreen={isSmallScreen}
                      />
                    </>
                  ) : (
                    <div style={{ width: '100%', height: '70%' }}>
                      <AutoSizer>
                        {({ width }) => {
                          const columnCount = Math.max(1, Math.min(Math.floor(width / 270), 3)) // At least 1 column, maximum 3
                          const columnWidth = Math.floor(width / columnCount) // Dynamically fill available space
                          return (
                            <Grid
                              // style={{ paddingBottom: isSmallScreen ? '100px' : '0px' }}
                              columnCount={columnCount}
                              columnWidth={columnWidth}
                              height={500}
                              rowCount={totalRows}
                              rowHeight={(index: Index) => {
                                const toReturn =
                                  index.index === totalRows - 1 || (totalRows === 1 && index.index === 1)
                                    ? 530 + (slicedData.length < visibleData.length ? 30 : 0)
                                    : 270
                                return toReturn
                              }}
                              width={width + 10}
                              cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                                const index = rowIndex * columnCount + columnIndex

                                if (rowIndex === Math.ceil(slicedData.length / columnCount)) {
                                  if (columnIndex === 0) {
                                    return (
                                      <div
                                        key={key}
                                        style={{
                                          ...style,
                                          width: '100%',
                                          textAlign: 'center',
                                          top: style?.top || 1000 + 500,
                                        }}>
                                        {notSeeingWhatYouNeed}
                                      </div>
                                    )
                                  }
                                  return null
                                }

                                if (index >= slicedData.length) return null

                                const group = slicedData[index]
                                return (
                                  <div key={key} style={style}>
                                    <div style={{ padding: '2px 10px 0px 10px' }}>
                                      <GroupCard
                                        key={group?.name || group?.username || group?.title}
                                        group={group}
                                        onSelect={setSelectedGroup}
                                        setSelectedGroupName={setSelectedGroupName}
                                        myGroupsTab={myGroupsTab}
                                        refinement={searchValue}
                                        pointsStore={pointsStore}
                                        onLinkedinCollect={onLinkedinCollect}
                                        importData={importData}
                                        icpData={icpData}
                                        handleFacebookShouldJoin={handleFacebookShouldJoin}
                                        setImportData={setImportData}
                                        goToProgress={goToProgress}
                                        goToMyHub={goToMyHub}
                                        isSmallScreen={isSmallScreen}
                                      />
                                    </div>
                                  </div>
                                )
                              }}
                            />
                          )
                        }}
                      </AutoSizer>
                    </div>
                  )}
                </>
              )}
            </React.Fragment>
          ) : (
            // Empty State or Loading State
            <>
              {!myGroupsTab && isExploreLoading ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}>
                    {exploreLoadingScreen}
                  </div>
                </>
              ) : (
                <>
                  {(myGroupsTab ? myHubLoading : initialLoading) ? (
                    // Loading Skeleton
                    <Col span={24} style={{ maxHeight: 87 }} className={styles.colStyle}>
                      <div className={styles.categoryHeader}>
                        <div className={styles.leftSide}>
                          <span style={{ fontWeight: 600 }}>{filterObject?.a || 'Explore All'}</span>
                          <Divider type='vertical' />
                          <span>0 Results</span>
                        </div>
                      </div>
                      <div className={styles.platforms}>
                        <div>
                          {user.user_type !== 'web3' && (
                            <PlatformTag
                              isLoading={initialLoading}
                              platform={'instagram'}
                              selectedPlatform={'instagram'}
                            />
                          )}
                        </div>
                      </div>
                      <div style={{ width: '100%', height: '70%' }}>
                        <AutoSizer>
                          {({ width }) => (
                            <Grid
                              style={{ paddingBottom: '20px' }}
                              columnCount={3}
                              columnWidth={width / 3}
                              height={500}
                              rowCount={2}
                              rowHeight={270}
                              width={width + 10}
                              cellRenderer={({ columnIndex, key, rowIndex, style }) => (
                                <div key={key} style={style}>
                                  <div style={{ padding: '2px 10px 0px 10px' }}>
                                    <GroupSkeleton />
                                  </div>
                                </div>
                              )}
                            />
                          )}
                        </AutoSizer>
                      </div>
                    </Col>
                  ) : // Empty State with "Collect Leads" Button
                  filterObject?.b !== 'Lists' ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                      }}>
                      {notSeeingWhatYouNeed}
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}>
                        {listsNotSeeingWhatYouNeed}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Row>
      </Content>
      {importLeadsOptionsModalVisible && (
        <ImportLeadsOptionsModal
          open={importLeadsOptionsModalVisible}
          onClose={() => setImportLeadsOptionsModalVisible(false)}
          importData={importData}
          pointsStore={pointsStore}
          goToProgress={goToProgress}
          goToMyHub={goToMyHub}
        />
      )}
    </>
  )
}

export default GroupsContent
