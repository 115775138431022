import React, { useEffect, useState } from 'react'
import { Table, Skeleton, Drawer, Button, Input, Divider, Tooltip } from 'antd'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import gray_eye from 'common/assets/svg/gray_eye.svg'
import drag from 'common/assets/svg/drag.svg'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { TableProps } from 'antd'
import styles from './ModularCampaignsTable.module.scss'
import { useLazySaveCampaignsDefualtColumnsQuery } from 'common/APIs/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
import _ from 'lodash'
import {
  defaultTypes,
  initHidden,
  nonHideableColumns,
  fixedColumns,
  fixedColumnsLocations,
} from './Helpers/CampaignsConstants'

interface ModularCampaignsTableProps extends TableProps<any> {
  columns: any
  campaigns: any[]
  loadingTable?: boolean
  pageSize?: number
  rowSelection?: any
  isExpanded?: boolean
  openDrawer: boolean
  setOpenDrawer: (open: boolean) => void
  defaultColumns?: any
  dynamicColumns?: any
}

const ModularCampaignsTable: React.FC<ModularCampaignsTableProps> = ({
  columns,
  campaigns,
  loadingTable = false,
  pageSize = 10,
  rowSelection,
  isExpanded = false,
  openDrawer,
  setOpenDrawer,
  defaultColumns,
  dynamicColumns,
  ...tableProps
}) => {
  const [user, setUser] = useLocalStorage<any>('user', null)
  const { default_campaigns_columns } = user || {}
  const currentDefaultColumns =
    Array.isArray(default_campaigns_columns) && default_campaigns_columns?.length >= nonHideableColumns.length
      ? default_campaigns_columns?.map((col: any) => col.key)
      : defaultColumns?.filter((col: any) => !initHidden.includes(col.key))?.map((col: any) => col.key)
  const hiddenDefaultColumns =
    Array.isArray(default_campaigns_columns) && default_campaigns_columns?.length >= nonHideableColumns.length
      ? default_campaigns_columns?.filter((col: any) => col?.hidden)?.map((col: any) => col.key)
      : []
  const [searchTerm, setSearchTerm] = useState('')
  const [updateCampaignsDefaultColumns] = useLazySaveCampaignsDefualtColumnsQuery()
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [allColumns, setAllColumns] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const toggleDrawer = () => setOpenDrawer(!openDrawer)

  const handleClose = () => {
    let visibleColumnsNew = columns?.filter((col: any) => currentDefaultColumns?.includes(col.key))

    visibleColumnsNew = visibleColumnsNew.sort((a: any, b: any) => {
      return currentDefaultColumns.indexOf(a.key) - currentDefaultColumns.indexOf(b.key)
    })
    setVisibleColumns(visibleColumnsNew)
    setAllColumns(columns)
    toggleDrawer()
  }

  const handleSave = async () => {
    setLoading(true)
    // const columns = visibleColumns?.map((col: any) => col.key)

    const columns = visibleColumns?.map((col: any) => ({
      key: col.key,
      type: defaultTypes.includes(col.type) ? 'default' : col.type || 'default',
    }))

    await updateCampaignsDefaultColumns({
      columns: columns,
    })
    setUser({ ...user, default_campaigns_columns: columns })
    toggleDrawer()
    setLoading(false)
  }

  const modifiedColumns = visibleColumns.map((column: any) => ({
    ...column,
  }))

  const handleResetToDefault = () => {
    const resetDefaultColumns = defaultColumns
      ?.filter((col: any) => !initHidden.includes(col.key))
      ?.map((col: any) => col.key)
    const resetVisibleColumns = columns.filter((col: any) => resetDefaultColumns.includes(col.key))

    const sortedVisibleColumns = resetVisibleColumns.sort((a: any, b: any) => {
      return resetDefaultColumns.indexOf(a.key) - resetDefaultColumns.indexOf(b.key)
    })

    setVisibleColumns(sortedVisibleColumns)
    setAllColumns(columns)
  }

  useEffect(() => {
    // if (!openDrawer) {
    const visibleColumnsNew = columns?.filter((col: any) => currentDefaultColumns?.includes(col.key))

    setVisibleColumns(visibleColumnsNew)
    setAllColumns(columns)
    // }
  }, [dynamicColumns])

  useEffect(() => {
    if (!loading && !openDrawer) {
      const missingColumns = default_campaigns_columns?.filter(
        ({ key }: any) => !columns.some((col: any) => col.key === key),
      )

      const temporaryColumns: any = missingColumns?.map(({ key, type }: any) => ({
        key,
        title: key,
        align: 'center',
        ellipsis: true,
        width: 140,
        type: type,
        render: () => <span></span>,
      }))

      const sortedColumns = [...columns, ...(temporaryColumns ? temporaryColumns : [])]
        ?.filter((col: any) => currentDefaultColumns?.includes(col.key))
        .sort((a: any, b: any) => currentDefaultColumns.indexOf(a.key) - currentDefaultColumns.indexOf(b.key))
        ?.map((col: any) => {
          return { ...col, hidden: hiddenDefaultColumns?.includes(col.key) }
        })

      // Move the 'actions' column to the end if it's in the columns
      const actionsColumnIndex = sortedColumns.findIndex((col: any) => col.key === 'actions')
      if (actionsColumnIndex !== -1) {
        const actionsColumn = sortedColumns.splice(actionsColumnIndex, 1)[0]
        sortedColumns.push(actionsColumn) // Add 'actions' column to the end
      }

      setVisibleColumns(sortedColumns)
    }
  }, [columns])

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result
    const fixedEndIndices = fixedColumnsLocations
      .filter((col) => col.location === -1) // Columns fixed at the end
      .map(() => visibleColumns.length - 1) // Last column
    const lastFixedIndex = Math.max(...fixedEndIndices, -Infinity)

    if (destination.index <= 4 || destination.index >= lastFixedIndex) {
      // Invalid drag-and-drop, revert
      return
    }
    if (nonHideableColumns?.includes(draggableId) && destination.droppableId?.startsWith('allColumns')) return
    if (!destination) return
    if (destination.droppableId !== 'visibleColumns' && visibleColumns.length === 1) {
      return
    }
    if (source.droppableId === destination.droppableId) {
      const items = Array.from(source.droppableId === 'visibleColumns' ? visibleColumns : allColumns)
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)
      source.droppableId === 'visibleColumns' ? setVisibleColumns(items) : setAllColumns(items)
    } else {
      const sourceList = Array.from(source.droppableId === 'visibleColumns' ? visibleColumns : allColumns)
      const destinationList = Array.from(destination.droppableId === 'visibleColumns' ? visibleColumns : allColumns)
      console.log(5)
      const [movedItem] = sourceList.splice(source.index, 1)
      destinationList.splice(destination.index, 0, movedItem)
      console.log(6)
      if (source.droppableId === 'visibleColumns') {
        console.log(7)
        setVisibleColumns(sourceList)
        setAllColumns(destinationList)
      } else {
        console.log(8)
        setVisibleColumns(destinationList)
        setAllColumns(sourceList)
      }
    }
  }

  // Filter columns based on the search term
  const filteredVisibleColumns = visibleColumns?.filter((col: any) => {
    return (col.displayName || _.startCase(col.key)).toLowerCase().includes(searchTerm?.toLowerCase())
  })

  const filteredHiddenColumns = allColumns?.filter((col: any) => {
    return (col.displayName || _.startCase(col.key)).toLowerCase().includes(searchTerm?.toLowerCase())
  })

  // Group columns by type dynamically
  const groupColumnsByType = (columns: any[]) => {
    return columns.reduce((acc: any, col: any) => {
      const type = col?.type || 'default'
      if (!acc[type]) acc[type] = []
      acc[type].push(col)
      return acc
    }, {})
  }

  const groupedHiddenColumns = groupColumnsByType(filteredHiddenColumns)

  const handleToggleColumnVisibility = (key: string) => {
    const updatedColumns = visibleColumns.map((col: any) => (col.key === key ? { ...col, hidden: !col.hidden } : col))
    setVisibleColumns(updatedColumns)
  }

  const renderGrayEyeIcon = (col: any) => {
    const isVisible = visibleColumns.some((obj: any) => obj.key === col.key)

    if (nonHideableColumns?.includes(col.key)) return <></>

    return (
      <Tooltip title={isVisible ? 'Hide from table' : 'Add to table'} placement='top' className='modularTable'>
        <img
          src={gray_eye}
          alt='Toggle Visibility'
          className={isVisible ? styles.grayEyeActive : styles.grayEyeMuted}
          onClick={() => handleToggleColumnVisibility(col.key)}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    )
  }

  return (
    <>
      <Table
        {...tableProps}
        dataSource={
          loadingTable
            ? [...Array(pageSize)].map((_, index) => ({
                key: `key${index}`,
              }))
            : campaigns
        }
        columns={
          loadingTable
            ? modifiedColumns.map((column: any) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        style={{
                          margin: '0px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingLeft: '5px',
                          height: '22px',
                          paddingRight: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          opacity: 0.5,
                        }}
                        className='campaignsPageTable'
                        key={column.key}
                        title={true}
                        paragraph={false}
                        active
                      />
                    )
                  },
                }
              })
            : modifiedColumns
        }
        rowSelection={rowSelection}
        showSorterTooltip={false}
      />

      <Drawer
        className='modularTable'
        rootClassName='modularTable'
        maskClosable={false}
        bodyStyle={{ backgroundColor: '#f3f4f6' }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Properties</span>
            <div className={styles.rightSide}>
              <span className={styles.reset} onClick={handleResetToDefault}>
                Reset to Default
              </span>
              <Button type='primary' onClick={handleSave} loading={loading}>
                Save
              </Button>
            </div>
          </div>
        }
        placement='right'
        closable={true}
        onClose={handleClose}
        open={openDrawer}
        width={450}>
        <Input
          prefix={<SearchOutlined style={{ color: '#97A0AF', fontSize: '16px' }} />}
          placeholder='Search for Properties'
          size='large'
          style={{ marginBottom: '1rem', fontSize: '14px' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className={styles.title}>
          <span style={{ fontWeight: 600 }}>Showing in table</span>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='visibleColumns'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', flexDirection: 'column' }}>
                {filteredVisibleColumns.map((col: any, index: any) => (
                  <Draggable key={col.key} draggableId={col.key + index} index={index}>
                    {(provided) => (
                      <div
                        className={styles.draggableItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{ ...provided.draggableProps.style }}>
                        <div
                          className={styles.handle}
                          style={fixedColumns.includes(col.key) ? { cursor: 'default' } : {}}
                          {...(fixedColumns.includes(col.key) ? {} : provided.dragHandleProps)}>
                          {!fixedColumns.includes(col.key) ? (
                            <img src={drag} alt='' style={fixedColumns.includes(col.key) ? { opacity: 0.3 } : {}} />
                          ) : (
                            <div style={{ padding: '0px 12px' }} />
                          )}
                        </div>
                        <div className={styles.innerBody}>
                          <span> {col.displayName || _.startCase(col.key)}</span>
                          <div className={styles.icons}>
                            <Button
                              type='link'
                              style={nonHideableColumns.includes(col.key) ? { cursor: 'default' } : {}}
                              onClick={() => {
                                if (nonHideableColumns.includes(col.key)) {
                                  return
                                }
                                setVisibleColumns(visibleColumns.filter((c: any) => c.key !== col.key))
                              }}>
                              {!nonHideableColumns.includes(col.key) && (
                                <CloseOutlined style={{ color: '#5e6c83', fontSize: '10px' }} />
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Divider />
          <div className={styles.title} style={{ margin: 0 }}>
            <span style={{ fontWeight: 600 }}>All Columns</span>
          </div>
          {Object.keys(groupedHiddenColumns).map((type) => (
            <div key={type}>
              <div className={styles.subtitle}>
                <span style={{ textAlign: 'center' }}>{_.startCase(type)}</span>
              </div>
              <Droppable droppableId={`allColumns_${type}`}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    {groupedHiddenColumns[type].map((col: any, index: any) => (
                      <Draggable key={col.key} draggableId={col.key} index={index}>
                        {(provided) => (
                          <div
                            className={styles.draggableItem}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{ ...provided.draggableProps.style }}>
                            <div
                              className={styles.handle}
                              style={{ cursor: 'default' }}
                              // {...provided.dragHandleProps}
                            >
                              {/* <img src={drag} alt='' style={{ opacity: 0.3 }} /> */}
                              {/* <div style={{ padding: '0px 12px' }} /> */}
                            </div>
                            <div className={styles.innerBody}>
                              <span>{col.displayName || _.startCase(col.key)}</span>
                              <div className={styles.icons}>
                                <Button
                                  type='link'
                                  onClick={() => {
                                    if (nonHideableColumns.includes(col.key)) return

                                    const isVisible = visibleColumns.some((c: any) => c.key === col.key)
                                    let updatedVisibleColumns

                                    if (isVisible) {
                                      // Remove the column from visibleColumns
                                      updatedVisibleColumns = visibleColumns.filter((c: any) => c.key !== col.key)
                                    } else {
                                      // Add the column to visibleColumns at the appropriate position
                                      const actionsIndex = visibleColumns.findIndex((c: any) => c.key === 'actions')
                                      const insertIndex = actionsIndex !== -1 ? actionsIndex : visibleColumns.length
                                      updatedVisibleColumns = [...visibleColumns]
                                      updatedVisibleColumns.splice(insertIndex, 0, col)
                                    }

                                    setVisibleColumns(updatedVisibleColumns)
                                  }}
                                  style={
                                    nonHideableColumns.includes(col.key) ? { opacity: 0.3, cursor: 'default' } : {}
                                  }>
                                  {renderGrayEyeIcon(col)}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </Drawer>
    </>
  )
}

export default ModularCampaignsTable
